import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { hasRoles } from 'acl'

import {
  actions,
  config,
  constants
} from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const List = ({ model, userRoles }) => {

  const linker = useNavigate()
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, item.id)

    return (
      <Row
        key={index}
        item={item}
        onView={onView}
        {...itemProps}/>
    )
  }, [linker])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}
      actionButtons={
        <>
          {!(userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)) && (
            <Stack
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
              gap={1}
            >
              <Stack flexDirection="row" gap={2}>
                <Box width={{ xs: '100%', md: '215px' }}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={linker(constants.PATH_ADD)}
                  >
                    {"Request Vehicle Relocation"}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          )}
        </>
      }
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ requestVehicleRelocations, settings }) => ({
  model: requestVehicleRelocations,
  userRoles: settings.roles
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
