import React, { createContext, useMemo, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Card, CardContent } from '@material-ui/core'
import PropTypes from 'prop-types'
/**
 * Form View
 */
import LeaseInformation from './lease-information'
import VehicleInformation from './vehicle-information'
import AdditionalServices from './additional-services'
import Accessories from './accessories'

export const FormContext = createContext({})

const Form = ({
  onSubmit,
  userRoles,
  activeTab,
  data,
}) => {
  const [values, setValues] = useState({
  })

  const [searchName, setSearchName] = useState('');
  const [results, setResults] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
  }

  const onSelectChange = (id) => (value) => {
    setValues({ ...values, [id]: value })
  }

  const onSubmitForm = () => {
    onSubmit(values)
  }

  const memoizedValues = useMemo(() => {
    return {
      values,
      results,
      searchName,
      selectedDriver,
      data,
      onSelectChange,
      onInputChange,
      setSearchName,
      setResults,
      setSelectedDriver,
    }
  }, [
    values,
    results,
    searchName,
    selectedDriver,
    data,
  ])

  return (
    <FormContext.Provider value={{ ...memoizedValues, setValues }}>
      <Card
        sx={{
          boxShadow: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
        }}
      >
        <CardContent
          sx={{
            p: 3,
          }}
        >
          <ValidatorForm
            autoComplete="off"
            noValidate
            onSubmit={onSubmitForm}
          >
            {activeTab === "lease_information" &&
              <LeaseInformation userRoles={userRoles} data={data}/>
            }
            {activeTab === "vehicle_information" && 
              <VehicleInformation
                data={data}
              />
            }
            {activeTab === "additional_services" &&
              <AdditionalServices />
            }
            {activeTab === "accessories" &&
              <Accessories />
            }
          </ValidatorForm>
        </CardContent>
      </Card>
    </FormContext.Provider>
  )
}

Form.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default Form