import React, {useContext} from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { AccessoriesForm } from "./accessories-form"
import { AccessoriesFormUte } from "./accessories-form-ute"
import { useTheme } from "@mui/material/styles"
import { FormContext } from '../form'

const Accessories = () => {
  const formContext = useContext(FormContext);
  const theme = useTheme()
  const {
    transferValues,
  } = formContext

  const [currentAccessories, setCurrentAccessories] = React.useState({
    SignWriting: false,
    Canopy: false,
    SeatCovers: false,
    Towbar: false,
    SeatRequiredVanOnly: false,
    RoofRack: false,
  })

  const [currentAccessoriesInfo, setCurrentAccessoriesInfo] = React.useState({
    SignWriting: false,
    Canopy: false,
    SeatCovers: false,
    Towbar: false,
    SeatRequiredVanOnly: false,
    RoofRack: false,
  })

  const [currentAccessoriesNotes, setCurrentAccessoriesNotes] = React.useState({
    SignWriting: "",
    Canopy: "",
    SeatCovers: "",
    Towbar: "",
    SeatRequiredVanOnly: "",
    RoofRack: "",
  })

  const [currentAccessoriesInfoNotes, setCurrentAccessoriesInfoNotes] = React.useState({
    SignWriting: "",
    Canopy: "",
    SeatCovers: "",
    Towbar: "",
    SeatRequiredVanOnly: "",
    RoofRack: "",
  })

  /**
   * UTE States
   */
  const [uteAccessories, setUteAccessories] = React.useState({
    FlatDeck: false,
    BackFlipCover: false,
    WeldDeck: false,
    AccidentManagement: false,
    DeckLiner: false,
  })

  const [uteAccessoriesInfo, setUteAccessoriesInfo] = React.useState({
    FlatDeck: false,
    BackFlipCover: false,
    WeldDeck: false,
    AccidentManagement: false,
    DeckLiner: false,
  })

  const [uteAccessoriesNotes, setUteAccessoriesNotes] = React.useState({
    FlatDeck: "",
    BackFlipCover: "",
    WeldDeck: "",
    AccidentManagement: "",
    DeckLiner: "",
  })

  const [uteAccessoriesInfoNotes, setUteAccessoriesInfoNotes] = React.useState({
    FlatDeck: "",
    BackFlipCover: "",
    WeldDeck: "",
    AccidentManagement: "",
    DeckLiner: "",
  })

  const handleCopyAccessories = () => {
    setCurrentAccessoriesInfo(currentAccessories)
    setCurrentAccessoriesInfoNotes(currentAccessoriesNotes)
  }

  const handleCopyUteAccessories = () => {
    setUteAccessoriesInfo(uteAccessories)
    setUteAccessoriesInfoNotes(uteAccessoriesNotes)
  }

  return (
    <Stack>
      <Stack gap={2}>
        <Stack>
          <Stack
            flexDirection={{ xs: "column", md: "row" }} gap={4}
          >
            <Stack flex={1}>
              <AccessoriesForm
                title={transferValues?.vehicleregistration}
                accessories={currentAccessories}
                setAccessories={setCurrentAccessories}
                accessoriesNotes={currentAccessoriesNotes}
                setAccessoriesNotes={setCurrentAccessoriesNotes}
              />
            </Stack>
            <Stack flex={1}>
              <AccessoriesForm
                title="Accessory Information"
                accessories={currentAccessoriesInfo}
                setAccessories={setCurrentAccessoriesInfo}
                accessoriesNotes={currentAccessoriesInfoNotes}
                setAccessoriesNotes={setCurrentAccessoriesInfoNotes}
              />
            </Stack>
          </Stack>
          <Stack py={3} maxWidth={206}>
            <Button variant="outlined" onClick={handleCopyAccessories}>
              Copy All Accessories
            </Button>
          </Stack>
        </Stack>

        <Stack>
          <Stack
            flexDirection={{ xs: "column", md: "row" }} gap={4}
          >
            <Stack flex={1}>
              <AccessoriesFormUte
                title="MQM980 (UTE Only)"
                accessories={uteAccessories}
                setAccessories={setUteAccessories}
                accessoriesNotes={uteAccessoriesNotes}
                setAccessoriesNotes={setUteAccessoriesNotes}
              />
            </Stack>
            <Stack flex={1}>
              <AccessoriesFormUte
                title="Accessory Information (UTE Only)"
                accessories={uteAccessoriesInfo}
                setAccessories={setUteAccessoriesInfo}
                accessoriesNotes={uteAccessoriesInfoNotes}
                setAccessoriesNotes={setUteAccessoriesInfoNotes}
              />
            </Stack>
          </Stack>
          <Stack py={3} maxWidth={206}>
            <Button variant="outlined" onClick={handleCopyUteAccessories}>
              Copy All Accessories
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Divider sx={{ mb: 2, borderColor: theme.palette.accent.sky }} />

      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap={1}
      >
        <Box width={{ xs: "100%", md: "205px" }}>
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={() => console.log('close')}
          >
            Close
          </Button>
        </Box>
        <Stack flexDirection="row" gap={2}>
          <Box width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => console.log("save and exit")}
            >
              Save and Exit
            </Button>
          </Box>
          <Box width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => console.log("proceed")}
            >
              Proceed
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Accessories