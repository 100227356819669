import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, List, ListItem } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useSelectedFleets, useSettingsFleetValues } from 'features/settings'
import Brand from '../../brand'
import FleetSelector from '../fleet-selector'
import Fleets from './fleets'
import FleetsToggle from './fleets-toggle'
import LinkButton from './link-button'

import { useDrawerMenu } from './menu-config'

const MobileNavigation = ({ open, onClose, userRoles }) => {
  const { menu } = useDrawerMenu(userRoles);
  const dispatch = useDispatch()

  const [selectFleet, setSelectFleet] = useState(false)

  const onToggleFleetSelector = () =>
    setSelectFleet(!selectFleet)

  const selectedFleets = useSelectedFleets()
  const options = useSettingsFleetValues()
  const hasFleets = !!options.length

  const MAX_FLEET = 16
  const maxSelectedFleets = selectedFleets.length > MAX_FLEET
  const croppedSelectedfleets = maxSelectedFleets ? selectedFleets.slice(0, MAX_FLEET) : selectedFleets

  const contains = (value) => window.location.href.includes(value)

  const [seeMore, setSeeMore] = useState(false)

  const onLinkTo = (path) => () => {
    onClose()
    dispatch(push(path))
  }

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="temporary"
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          bgcolor: 'background.header'
        }
      }}
    >
      <Box
        width='100%'
        height='100%'
      >
        <Box
          width='100%'
          padding='20px'
        >
          <Brand variant="light" />
        </Box>

        <List>
          <Fleets
            items={seeMore ? selectedFleets : croppedSelectedfleets}
            hasFleets={hasFleets}
          />

          <FleetsToggle
            isMax={maxSelectedFleets}
            title={!seeMore ? `${selectedFleets.length - MAX_FLEET} more` : 'hide'}
            onSeeMore={() => setSeeMore(!seeMore)}
            onToggle={() => onToggleFleetSelector()}
            seeLabel={seeMore ? 'Hide' : 'See More'}
          />
            
          {menu.map(({ label, path, visible, subMenu }, index) => visible && label && path && !subMenu ? (
            <ListItem disableGutters key={`main-menu-item-${index}`} sx={{ px: 3 }}>
              <LinkButton
                label={label}
                onNavigate={onLinkTo(path)}
                isActive={contains(path)}
              />
            </ListItem>
            ) : (
              <Box key={`sub-menu-${index}`}>
                <ListItem disableGutters sx={{ px: 3 }}>
                  <LinkButton
                    label={label}
                    onNavigate={onLinkTo(path)}
                  />
                </ListItem>
                {/* render sub menu */}
                <ListItem disableGutters sx={{ pl: 6 }}>
                  <List sx={{ p: 0 }}>
                    {subMenu.map(({ label, path, visible }, index) => visible && label && path && (
                      <ListItem disableGutters key={`sub-menu-item-${index}`}>
                        <LinkButton
                          label={label}
                          onNavigate={onLinkTo(path)}
                          isActive={contains(path)}
                        />
                      </ListItem>
                    ))}

                  </List>
                </ListItem>
              </Box>
          ))}
        </List>
      </Box>

      {selectFleet && (
        <FleetSelector onClose={onToggleFleetSelector} />
      )}
    </Drawer>
  )
}

MobileNavigation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})

export default connect(mapStateToProps)(MobileNavigation)