import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const weekDays = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]


const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F1F4F7',
    borderRadius: 6,
  },
  calender: {
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    }
  },
  header: {
    height: 53,
    paddingTop: 5,
    textAlign: 'center',
    backgroundColor: '#012A46',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  title: {
    color: '#FFF',
    padding: 10,    
    fontSize: 14,
  },
  weekDayHeaderRow: { marginBottom: -10 },
  selectable: { cursor: 'pointer', },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.up(990)]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    }
  },
  day: {
    flex: 1,
    height: 50,
    fontSize: '18px',
    textAlign: 'center',
    justifyContent: 'center',
    margin: '10px 18px 0 18px',
    paddingTop: '14px',
    userSelect: 'none',
    [theme.breakpoints.down(1000)]: {
      fontSize: '18px',
      height: 60,
      paddingTop: '20px',
      margin: '10px 10px 0 10px',
    },
    [theme.breakpoints.down(700)]: {
      fontSize: '18px',
      height: 60,
      paddingTop: '20px',
      margin: '10px 4px 0 4px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      height: 40,
      paddingTop: '11px',
      margin: '10px 4px 0 4px',
    }
  },
  greyCircle: {
    //backgroundColor: '#DDDDDD',
    //borderRadius: 50,
  },
  previouslySelected: {
    backgroundColor: '#FFF',
    borderRadius: 50,
    borderColor: '#DDDDDD',
    borderWidth: 2,
  },
  selectedDay: {
    color: '#FFF',
    borderRadius: 50,
    backgroundColor: '#00B6B0',
  },
  disabledDay: { color: '#DDDDDD' },
  labelText: {
    color: '#666666',
    textTransform: 'uppercase',
    letterSpacing: 1.2,
  },

  // legend styles
  legend: { margin: '10px 0', },
  legendKeys: {
    // backgroundColor: '#F1F4F7',
    padding: theme.spacing(2),
    // borderRadius: 6,
    // maxWidth: '400px',
  },
  legendKeyContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  legendKeyIconContainer: {
    padding: theme.spacing(0, 1),
  }
}))

const CustomCalender = ({
  disabled,
  activeMonth,
  selectedDays = [],
  previouslySelectedDays = [],
  onSelectDates,
  readOnly,
  disabledDatesAfter,
  disabledDates
}) => {

  const classes = useStyles()
  const theme = useTheme()

  const [calenderMatrix, setCalenderMatrix] = useState()
  // dayjs('2019-01-25').daysInMonth() // 31

  useEffect(() => {
    // generate calender on load
    const matrix = generateMatrix(activeMonth)
    setCalenderMatrix(matrix)
    // eslint-disable-next-line
  }, [])

  const generateMatrix = (activeMonth) => {
    let matrix = []

    // Create header
    matrix[0] = weekDays
  
    const year = activeMonth.getFullYear()
    const month = activeMonth.getMonth()
    
    const firstDay = new Date(year, month, 1).getDay()
    let maxDays = nDays[month]
    if (month === 1) { // for February
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        maxDays += 1
      }
    }
        
    let counter = 1
    for (let row = 1; row < 7; row++) {
      matrix[row] = []
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = -1
        if (row === 1 && col >= firstDay) { // Fill in rows only after the first day of the month
          matrix[row][col] = counter++
        } else if (row > 1 && counter <= maxDays) { // Fill in rows only if the counter's not greater than the number of days in the month
          matrix[row][col] = counter++
        }
      }
    }
    return matrix
  }


  const onSelectDate = (day) => {
    console.log('[onSelectDate]: ', day)
    onSelectDates && onSelectDates(day)
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F4F7',
          borderRadius: 6,
        }}
      >
        
        {/* Calender Title */}
        <Box
          sx={{
            height: 53,
            paddingTop: 2,
            textAlign: 'center',
            backgroundColor: '#E6EBF0',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Typography variant="h5" 
            sx={{
              color: '#000',
              fontWeight: 600,
            }}
          >
            {months[activeMonth.getMonth()]}  {activeMonth.getFullYear()}
          </Typography>
        </Box>

        {/* Calender Area */}
        <Box
          sx={{
            paddingBottom: -1,
            [theme.breakpoints.down('xs')]: {
              paddingBottom: 10,
            }
          }}
        >
          {/* calenderMatrix[0] - week day labels, calenderMatrix[1-5] week rows */}
          {calenderMatrix && calenderMatrix.map((row, rowIndex) => {
            
            const isWeekDayHeader = rowIndex === 0
            const isSecondLastRow = rowIndex === calenderMatrix.length - 2
            // const isLastRow = rowIndex === calenderMatrix.length - 1
            const isEmptyRow = row.filter(value => value !== -1).length === 0

            return isEmptyRow ? null : (
              <Box key={`fbt-calender-row=${rowIndex}`}
                className={clsx(classes.rowStyle, isWeekDayHeader ? classes.weekDayHeaderRow : '')}
                sx={{ paddingBottom: isSecondLastRow ? 0 : 0 }}
              >
                  {row.map((day, index) => {
                    
                    const isValidDay = day !== -1
                    const isDisabledDays = disabledDates && disabledDatesAfter
                      ? day > disabledDatesAfter || disabledDates.includes(day)
                      : disabledDates
                        ? disabledDates.includes(day) : false
                    
                    const isSelected = isValidDay && !isWeekDayHeader && selectedDays.includes(day)
                    const isPreviouslySelectedDays = isValidDay && !isWeekDayHeader && previouslySelectedDays.includes(day)
                    const useGreyCircle = isValidDay && !isDisabledDays && !isWeekDayHeader && !isSelected && !isPreviouslySelectedDays
                    const isSelectable = isPreviouslySelectedDays || useGreyCircle
                    
                    const cellStyle = disabled
                      ? clsx(classes.day, classes.disabledDay)
                      : clsx(
                        classes.day,
                        isSelectable ? classes.selectable : '',
                        isWeekDayHeader ? classes.labelText : '',
                        isSelected ? classes.selectedDay : '',
                        isDisabledDays ? classes.disabledDay : '',
                        isPreviouslySelectedDays ? classes.previouslySelected : '',
                        useGreyCircle ? classes.greyCircle : '',
                      )

                    return (
                      <Typography
                        key={`custom-calender-day-${index}`}
                        onClick={() => !isValidDay || readOnly || isDisabledDays ? {} : onSelectDate(day)}
                        className={cellStyle}
                      >
                        {isValidDay && day}
                      </Typography>
                    )
                  })}
              </Box>
            )
          })}
        </Box>
      </Box>
      {/* End of Calender Area */}

      {/* Legend Area */}
      <Box
        sx={{
          margin: '10px 0',
        }}
      >
        <Grid
          style={{
            padding: '2'
          }}
        >
          {/* <Stack gap='20px' width='50%' textAlign='center'> */}
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant='h5'
              sx={{
                color: '#666666',
                textTransform: 'uppercase',
                letterSpacing: 1.2,
              }}
            >
              Legend
            </Typography>
          {/* </Stack> */}
          </Grid>
          <Grid item xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              [theme.breakpoints.down('xs')]: {
                display: 'block',
              }
            }}
          >
          {[
            {
              label: 'Previously Selected',
              color: '#FFF', // white
            }, {
              label: 'Selectable',
              color: '#DDDDDD', // light grey
            }, {
              label: 'Current Selection',
              color: '#00B6B0', // dark blue
            }            
          ].map(({ label, color }, legendIndex) => (
            <Box key={`legend-${legendIndex}`} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  paddingY: 0,
                  paddingX: 1
                }}
              >           
                <FiberManualRecordIcon 
                  style={{
                    color, width: 15, height: 15,
                    backgroundColor: color,
                    border: `1px solid ${color === '#FFF' ? '#DDDDDD' : color}`,
                    borderRadius: 10,
                  }}
                />
              </Box>
              <Typography variant='h6'>
              {label}
            </Typography>                
            </Box>
          ))}
          </Grid>

        </Grid>
      </Box>
      {/* End of Legend Area */}
    </>
  )
}

CustomCalender.propTypes = {
  disabled: PropTypes.bool,
  activeMonth: PropTypes.any,
  selectedDays: PropTypes.array,
  previouslySelectedDays: PropTypes.array,
  readOnly: PropTypes.bool,
  disabledDatesAfter: PropTypes.number,
  disabledDates: PropTypes.array,
  onSelectDates: PropTypes.func.isRequired,
};

export default CustomCalender