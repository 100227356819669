import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  
  const hasList = (list) => list && list.length > 0
  const { costCentres, serviceDueDates, agreementTypes, estServiceDueDateStatuses, serviceBookedFlags, fmoTypes, controllers } = filters

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
        <Box>
          {hasList(costCentres) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </Box>
          )}
          {hasList(serviceDueDates) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="serviceDueDate"
                label="Estimated next service due"
                options={serviceDueDates}
                value={filtered.serviceDueDate}
                onChange={onSelect('serviceDueDate')} />
            </Box>
          )}
          {hasList(agreementTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </Box>
          )}
          {hasList(estServiceDueDateStatuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="estServiceDueDateStatus"
                label="Service Due Date Status"
                options={estServiceDueDateStatuses}
                value={filtered.estServiceDueDateStatus}
                onChange={onSelect('estServiceDueDateStatus')} />
            </Box>
          )}   
          {hasList(serviceBookedFlags) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="serviceBooked"
                label="Service Booked"
                options={serviceBookedFlags}
                value={filtered.serviceBooked}
                onChange={onSelect('serviceBooked')} />
            </Box>
          )}             
          {hasList(fmoTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </Box>
          )}
          {hasList(controllers) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="controller"
                label="Controller"
                options={controllers}
                value={filtered.controller}
                onChange={onSelect('controller')} />
            </Box>
          )}
        </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
