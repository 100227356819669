import React from 'react'
import PropTypes from 'prop-types'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SingleSelect } from 'frame/components'
import { useCollapsible } from 'utils'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const { collapsed, onToggleCollapsible } = useCollapsible(true)
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { costCentres, trackings, agreementTypes, fmoTypes } = filters

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
        onClick={onToggleCollapsible}
      >
        <Typography variant="h5">Filters</Typography>
        {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>
      <Divider />
      <Collapse in={collapsed}>
        <Box>
          {hasList(costCentres) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </Box>
          )}
          {hasList(trackings) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="tracking"
                label="Tracking"
                options={trackings}
                tight
                value={filtered.tracking}
                onChange={onSelect('tracking')} />
            </Box>
          )}
          {hasList(agreementTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </Box>
          )}
          {hasList(fmoTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </Box>
          )}      
        </Box>
      </Collapse>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
