import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useTheme } from "@mui/material/styles"

const FuelCardPromo = () => {
  const theme = useTheme()

  const promoConfig = {
    title: 'Apply for Z Business now',
    content: () => <>Simply enter your details and obtain the correct discount with the TFS promotional code: <strong>TOYOTA</strong></>,
    backgroundImage: '',
  }

  const onApply = () => window.open("https://www.toyota.co.nz/toyota-finance-leasing/fuel-card/", "_blank")
  
  return (
    <Card
      sx={{
        height: '100%',
        minHeight: '360px',
        position: 'relative',
        [theme.breakpoints.down("md")]: { minHeight: "400px" },
        [theme.breakpoints.down("sm")]: { minHeight: "100%" },
        backgroundImage: props => props.backgroundImage ? `url("${props?.backgroundImage}")` : 'url("/static/images/marketing/fuelpromo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#002849',
      }}
    >
      <CardContent>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={6}>
            <Card
              sx={{
                maxWidth: '50%',
                position: 'absolute',
                left: 20,
                bottom: 20,
                right: 20,
                [theme.breakpoints.down(1500)]: { maxWidth: '60%' },
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 700 }}>
                  {promoConfig?.title}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '19px' }}>
                  {promoConfig?.content()}
                </Typography>
                <Box
                  sx={{
                    display: 'block',
                    marginTop: 1,
                  }}
                >
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={onApply}
                    sx={{
                      minWidth: 180,
                      marginTop: theme.spacing(1),
                      [theme.breakpoints.down('xs')]: { minWidth: '100%' }
                    }}
                  >
                    Apply Now
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
  )
}

export default FuelCardPromo
