import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export const DialogHeader = ({
  sx,
  children,
  ref,
  title,
}) => {
  const theme = useTheme()
  return (
    <Box
      ref={ref}
      sx={{
        p: 3
      }}
    >
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        width='100%'
      >
        <Typography
          fontSize={24}
          color='black'
        >
          {title}
        </Typography>
        {children}
      </Stack>
    </Box>
  )
}