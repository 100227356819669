import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../module'
import Form from '../new/form'
import TabMenu from "../../../../features/fleet/components/drill-down-modal/tab-menu"
import { formTabs } from "../../config"

export const ItemVehicleSummaryContext = createContext({})

const Item = ({
  id,
  data,
  fleetIds,
  fetchStatus,
  saveStatus,
  userRoles,
  onFetch,
  onUpdateLeaseInformation,
  onUpdateAdditionalServices,
  onUpdateVehicleInformation,
  onUpdateAccessories,
  onDelete,
}) => {
  const navigate = useNavigate()
  const { match } = useRouter()
  const [activeTab, setActiveTab] = useState(() => {
    const tabFromUrl = window.location.pathname?.split('/').pop();
      return tabFromUrl || formTabs[0].value;
  });
  const {vehicleOrderDetails} = data;

  const onClose = navigate(constants.PATH_ALL)

  const onSave = (payload) => {
    if(activeTab === 'lease_information'){
      onUpdateLeaseInformation(payload)
    } else if(activeTab === 'vehicle_information'){
      onUpdateVehicleInformation(payload)
    } else if(activeTab === 'additional_services'){
      onUpdateAdditionalServices(payload)
    } else if(activeTab === 'accessories'){
      onUpdateAccessories(payload)
    }
  }

  // Same as componentDidMount
  // We either use material ui 'useStyles' or we wrap the component
  // in an HoC and pass the styles down or completely
  // redo the way styles are done if we want to change this.
  useEffect(() => {
    onFetch(id, fleetIds)
    navigate(`${match.url}${constants.PATH_LEASE_INFORMATION}`)(0)
  }, [id, fleetIds]) // eslint-disable-line

  useEffect(() => {
    const tabFromUrl = window.location.pathname?.split('/').pop();
      setActiveTab(tabFromUrl || formTabs[0].value);
  }, [window.location.pathname]);

  const isLoading = fetchStatus === 'processing' && ! data
  const isSaving = saveStatus === 'processing'
  const hasErrored = fetchStatus === 'error'
  const savingError = saveStatus === 'error'

  const loadingErrorMessage = hasErrored
    ? 'Whoops, we could not find that request order booking right now.'
    : null

  const tabsConfig = [
    {
      label: 'Lease Information',
      path: `${match.url}${constants.PATH_LEASE_INFORMATION}`,
      visible: true,
      isDataAvailable: (vehicleOrderDetails?.requestType || vehicleOrderDetails?.leaseType),
    }, {
      label: 'Vehicle Information',
      path: `${match.url}${constants.PATH_VEHICLE_INFORMATION}`,
      visible: true,
      isDataAvailable: (vehicleOrderDetails?.make || vehicleOrderDetails?.model || vehicleOrderDetails?.variant || vehicleOrderDetails?.colour || vehicleOrderDetails?.driveTrain || vehicleOrderDetails?.transmission),
    }, {
      label: 'Additional Services',
      path: `${match.url}${constants.PATH_ADDITIONAL_SERVICES}`,
      visible: true,
    }, {
      label: 'Accessories',
      path: `${match.url}${constants.PATH_ACCESSORIES}`,
      visible: true,
    },
  ].filter(x => x.visible)

  return (
    <Dialog
      open
      autoSize
      size="md"
      aria-labelledby="Request Vehicle Order"
      breadcrumbs=""
      title={`Request Vehicle Order`}
      loading={isLoading || isSaving}
      onClose={onClose}
      tabsConfig={tabsConfig}
      tabMenu={<TabMenu config={tabsConfig} />}
    >
      <Grid container>
        <Grid item md={12} xs={12}>
          <ItemVehicleSummaryContext.Provider value={{
            activeTab,
            setActiveTab,
          }}>
            <Form
              data={data}
              disabled={isLoading || isSaving}
              error={savingError}
              errorMessage={loadingErrorMessage}
              userRoles={userRoles}
              onClose={onClose}
              onSubmit={onSave}
              onDelete={onDelete}
              activeTab={activeTab}
              isEdit={data?.vehicleOrderDetails?.status === "Draft"}
            />
          </ItemVehicleSummaryContext.Provider>
        </Grid>
      </Grid>
    </Dialog>
  )
}

Item.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  data: PropTypes.object,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFetch: PropTypes.func.isRequired,
  onUpdateLeaseInformation: PropTypes.func.isRequired,
  onUpdateVehicleInformation: PropTypes.func.isRequired,
  onUpdateAdditionalServices: PropTypes.func.isRequired,
  onUpdateAccessories: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

export default Item