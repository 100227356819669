import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import LoadingView from './loading-view'
import { useProfile } from 'features/account/hooks'

// sections
import ChangeDriverForm from './change-driver-form'
import ConfirmForm from './confirm-form'
import NewDriverForm from './new-driver-form'

import AssignedView from './assigned-view'
import UnassignedView from './unassigned-view'
import PoolVehicleView from './pool-vehicle-view'
import PoolConfirmForm from '../driver/pool-confirm-form'

import Typography from "@mui/material/Typography"
import StyledModalV2 from 'frame/components/styled-modal-v2/styled-modal-v2'


export const sections = {
  NEW_DRIVER: 'NEW_DRIVER', // create new driver to assign to current vehicle
  CHANGE_DRIVER: 'CHANGE_DRIVER', // search for existing driver to assign to current vehicle step
  CONFIRM_CHANGE_DRIVER_STEP: 'CONFIRM_CHANGE_DRIVER_STEP', // confirm assign driver to vehicle step
  POOL_VEHICLE_VIEW: 'POOL_VEHICLE_VIEW', // when vehicle is a pool vehicles
  UNASSIGNED_VIEW: 'UNASSIGNED_VIEW', // when vehicle is unassigned
  ASSIGNED_VIEW: 'ASSIGNED_VIEW', // when vehicle is assigned
}

const defaultBackFlags = {
  fromChangeDriver: false,
  fromNewDriver: false,
  fromUnassigned: false,
  fromAssigned: false,
  fromPoolVehicle: false,
}

const DriverDetailsForm = ({
  driverData,
  fleetData,
  // flags
  driverFlags,
  fleetFlags,
  fleetIds,
  driverConfirmationMessage,
  userRoles,
  // functions
  onSaveNewDriver,
  onUpdateDriver,
  onFetchDriver,
  onResetDriverFlags,
  onResetFleetFlags,
  onTerminateDriver,
  onTogglePoolVehicle,
  onUnassignVehicle,
  onResetLookups,
  onSaveTemporary,
}) => {

  const profile = useProfile()

  const [values, setValues] = useState({ // for change driver only
    // vehicle values - stay unchanged from current fleet data
    accountName: fleetData.accountName,
    thirdPartyNumber: fleetData.thirdPartyNumber,
    agreementNo: fleetData.agreementNo,
    registration: fleetData.registration,
    costCentre: fleetData.costCentre,
    location: fleetData.location,
    controller: fleetData.controller,
    vehicleNotes: fleetData.vehicleNotes,
    // values that get updated in change-driver form on new driver select
    driver: driverData.driver,
    email: driverData.email ? driverData.email.trim() : null,
    mobile: driverData.mobile ? driverData.mobile.trim() : null,
    driverNotification: driverData.driverNotification || 'In App',
    uniqueId: driverData.uniqueId,
    effectiveDate: null,
    startDate: fleetData.startDate, // for confirm screen - start date field min date value
    sourceSystem: fleetData.sourceSystem
  })

  const [lastSection, setLastSection] = useState(sections.DRIVER_DETAILS)
  const [activeSection, setActiveSection] = useState(sections.DRIVER_DETAILS)
  const [subView, setSubView] = useState(null)

  const [onBackFlags, setOnBackFlags] = useState(defaultBackFlags)
  
  const [loading, setLoading] = useState(true)
  const [isRefetching, setIsRefetching] = useState(false)

  const onNavigate = (section) => () => {
    setLastSection(activeSection) // save last active section
    setActiveSection(section)
  }
  
  const [searchHistory, setSearchHistory] = useState()

  // flags
  const isSaving = driverFlags.update === 'processing' || driverFlags.create === 'processing' 
                    || driverFlags.togglePoolVehicle === 'processing' || driverFlags.fetch === 'processing'

  // modal states
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  
  
  useEffect(() => { // 1.5 seconds fake loading on load
    loading && setTimeout(() => {  setLoading(false) }, 1500)
    onResetDriverFlags() // reset driver flags on load
    onResetLookups() // clear any previous lookup data
    onResetFleetFlags()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    onPageReload()
    // eslint-disable-next-line 
  }, [fleetData.poolVehicle, driverData.driver])

  const onPageReload = () => {
    console.log('[setActiveSection useEffect]: ', { 'isPoolVehicle': fleetData.poolVehicle, 'isUnassigned': !driverData.driver })
    if(fleetData.poolVehicle === 'Yes') {
      onNavigate(sections.POOL_VEHICLE_VIEW)()
    } else if(!driverData.driver) {
      onNavigate(sections.UNASSIGNED_VIEW)()
    } else {
      onNavigate(sections.ASSIGNED_VIEW)()
    } // eslint-disable-next-line
  }
  const onOpenDateForm = () => {
    onNavigate(sections.POOL_CONFIRM_FORM)()
  }

  useEffect(() => {
    
    // processing
    if(driverFlags.create === 'processing' || driverFlags.update === 'processing' || driverFlags.delete === 'processing'
        || driverFlags.togglePoolVehicle === 'processing' || driverFlags.unassignVehicle === 'processing') {
      setLoading(true)
    }

    // on success
    if(driverFlags.create === 'processed' || driverFlags.update === 'processed' || driverFlags.delete === 'processed'
        || driverFlags.togglePoolVehicle === 'processed' || driverFlags.unassignVehicle === 'processed') {
      setLoading(false)
      setShowSuccessModal(true)
      console.log('[on success useEffect]: ', { fleetData, driverData, driverFlags })

      // update view
      driverFlags.unassignVehicle === 'processed' || driverFlags?.unassignVehicleFromPoolVehicle
        ? setActiveSection(sections.UNASSIGNED_VIEW)
        : driverFlags.togglePoolVehicle === 'processed'
          ? setActiveSection(sections.POOL_VEHICLE_VIEW)
          : driverFlags.update === 'processed' && setActiveSection(sections.DRIVER_DETAILS)
      
      setIsRefetching(true)
      onFetchDriver(driverData.agreementNo, fleetIds) // refetch
      
      setValues({ ...values, effectiveDate: moment() }) // reset values
    }

    // on error
    if(driverFlags.create === 'error' || driverFlags.update === 'error' || driverFlags.delete === 'error'
        || driverFlags.togglePoolVehicle === 'error' || driverFlags.unassignVehicle === 'error') {
      setLoading(false)
      setShowErrorModal(true)
      console.log('[on error useEffect]: ', { fleetData, driverData, driverFlags })
      
      // reset values
      setValues({ ...values, effectiveDate: moment() })
    }
    // eslint-disable-next-line
  }, [driverFlags.create, driverFlags.update, driverFlags.delete, driverFlags.togglePoolVehicle, driverFlags.unassignVehicle])

  useEffect(() => { // to extend loading time until fleetData and DriverData is refetched with latest data
    if(isRefetching && driverFlags.fetch === 'processed' && (fleetFlags.fetch === 'processed' || fleetFlags.fetch === 'none' || fleetFlags.fetch === 'error')) {
      setIsRefetching(false)
    } // eslint-disable-next-line
  }, [driverFlags.fetch, fleetFlags.fetch])
  
  const onChangeDriverSave = (values) => {
    const uniqueId = values.uniqueId || ''
    // store selected Vehicle details temporary for the confirmation message
    onSaveTemporary({ currentDriver: driverData.driver, isPoolVehicle: fleetData.poolVehicle })

    console.log('[change driver onSave]: ', values)
    setSearchHistory(null)

    values.uniqueId
      ? onUpdateDriver(uniqueId, values)
      : onSaveNewDriver({ ...values, uniqueId: '00000000-0000-0000-0000-000000000000' })  // for create new driver for fleets with existing drivers
  }

  const onConfirmCancel = () => { // used in CONFIRM_CHANGE_DRIVER_STEP view
    setValues({ // reset driver data
      ...driverData,
      driver: driverData.driver,
      email: driverData.email ? driverData.email : null,
      mobile: driverData.mobile ? driverData.mobile : null,
      driverNotification: driverData.driverNotification || null,
      uniqueId: driverData.uniqueId,
    })
    setSearchHistory(null)
    onPageReload()
  }

  const onCreateNewDriver = (flags) => () => {
    setOnBackFlags({ ...onBackFlags, ...flags })
    setValues({ ...values, driver: null, email: null, mobile: null, uniqueId: null, driverNotification: 'In App' })
    onNavigate(sections.NEW_DRIVER)()
  }

  const onProceedToConfirm = (flags) => () => {
    setOnBackFlags({ ...onBackFlags, ...flags })
    onNavigate(sections.CONFIRM_CHANGE_DRIVER_STEP)()
  }

  if(loading || isRefetching) return <LoadingView activeSection={activeSection} />

  return (
    <>
      {activeSection === sections.NEW_DRIVER ? (
        <NewDriverForm
          disabled={isSaving}
          values={values}
          onChange={setValues}
          driverFlags={driverFlags}
          onProceed={onProceedToConfirm({ fromNewDriver: true })}
          onBack={onNavigate(onBackFlags.fromUnassigned
            ? sections.UNASSIGNED_VIEW : onBackFlags.fromChangeDriver
              ? sections.CHANGE_DRIVER : onBackFlags.fromPoolVehicle
                ? sections.POOL_VEHICLE_VIEW : sections.ASSIGNED_VIEW)}
        />
      ) : activeSection === sections.CHANGE_DRIVER ? (
        <ChangeDriverForm
          values={values}
          onChange={setValues}
          onProceed={onProceedToConfirm(false)}
          onCreateNewDriver={onCreateNewDriver({ fromChangeDriver: true })}
          onBack={onNavigate(onBackFlags.fromUnassigned
              ? sections.UNASSIGNED_VIEW : onBackFlags.fromPoolVehicle
                ? sections.POOL_VEHICLE_VIEW : sections.ASSIGNED_VIEW
          )}
          originalValues={driverData}
          fleetData={fleetData}
          searchHistory={searchHistory}
          setSearchHistory={setSearchHistory}
        />
      ) : activeSection === sections.CONFIRM_CHANGE_DRIVER_STEP ? (
        <ConfirmForm
          values={values}
          disabled={isSaving}
          fleetData={fleetData}
          onChange={setValues}
          onSubmit={onChangeDriverSave}
          onClose={onConfirmCancel}
          onBack={onNavigate(onBackFlags.fromUnassigned
            ? sections.UNASSIGNED_VIEW : onBackFlags.fromNewDriver
              ? sections.NEW_DRIVER : sections.CHANGE_DRIVER)}
        />
      ) : activeSection === sections.UNASSIGNED_VIEW ? (
        <UnassignedView
          driverFlags={driverFlags}
          fleetData={fleetData}
          driverData={driverData}
          onCreateNewDriver={onCreateNewDriver({ fromUnassigned: true })}
          userRoles={userRoles}
          onAssignExistingDriver={() => {
            setOnBackFlags({ ...onBackFlags, fromUnassigned: true })
            onNavigate(sections.CHANGE_DRIVER)()
          }}
          onTogglePoolVehicle={onTogglePoolVehicle}
          onOpenDateForm={onOpenDateForm}
        />
      ) : activeSection === sections.POOL_VEHICLE_VIEW ? (
        <PoolVehicleView
          fleetData={fleetData}
          driverData={driverData}
          onCreateNewDriver={onCreateNewDriver({ fromPoolVehicle: true, fromChangeDriver: false })}
          onAssignExistingDriver={() => {
            setOnBackFlags({ ...onBackFlags, fromPoolVehicle: true })
            onNavigate(sections.CHANGE_DRIVER)()
          }}
          onTogglePoolVehicle={onTogglePoolVehicle}
          onUnassignVehicle={onUnassignVehicle}
          userRoles={userRoles}
        />
      ) : activeSection === sections.POOL_CONFIRM_FORM ? (

        <PoolConfirmForm 
          values={values}
          disabled={false}
          fleetData={fleetData}
          onChange={setValues}
          onSubmit={onChangeDriverSave}
          onTogglePoolVehicle={onTogglePoolVehicle}
          onBack={onNavigate(onBackFlags.fromUnassigned
            ? sections.UNASSIGNED_VIEW : onBackFlags.fromNewDriver
              ? sections.NEW_DRIVER : sections.CHANGE_DRIVER)}
          onClose={onConfirmCancel}
        />
      ) : ( // sections.ASSIGNED_VIEW
        <AssignedView
          driverFlags={driverFlags}
          fleetData={fleetData}
          driverData={driverData}
          onUnassignVehicle={onUnassignVehicle}
          onTerminateDriver={onTerminateDriver}
          onChange={setValues}
          onTogglePoolVehicle={onTogglePoolVehicle}
          userRoles={userRoles}
          onAssignExistingDriver={() => {
            setOnBackFlags({ ...onBackFlags, fromAssignedVehicle: true })
            onNavigate(sections.CHANGE_DRIVER)()
          }}
          onSubmit={onChangeDriverSave}
          onOpenDateForm={onOpenDateForm}
        />
      )}
      <StyledModalV2
        open={showSuccessModal || showErrorModal}
        onClose={
          () => {
            setShowErrorModal(false)
            setShowSuccessModal(false)
            onResetDriverFlags() // reset driver flags
          }
        }
        content={
          <Typography variant="h4" textAlign="center">
            {driverConfirmationMessage ?? "You have successfully updated this vehicle record."} 
          </Typography>
        }
        status={showSuccessModal ? "success" : "error"}
      />
    </>
  )
}

DriverDetailsForm.propTypes = {
  driverData: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  driverFlags: PropTypes.object.isRequired,
  fleetFlags: PropTypes.object.isRequired,
  fleetIds: PropTypes.array.isRequired,
  driverConfirmationMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onSaveNewDriver: PropTypes.func.isRequired,
  onUpdateDriver: PropTypes.func.isRequired,
  onTerminateDriver: PropTypes.func.isRequired,
  onFetchDriver: PropTypes.func.isRequired,
  onResetDriverFlags: PropTypes.func.isRequired,
  onResetFleetFlags: PropTypes.func.isRequired,
  onTogglePoolVehicle: PropTypes.func.isRequired,
  onResetLookups: PropTypes.func.isRequired,
  onSaveTemporary: PropTypes.func.isRequired,
}

export default DriverDetailsForm

