import * as z from "zod";

export const schema = z.object({
  driverName: z
    .string({ required_error: "Driver name is required" })
    .min(1, { message: "Driver name is required" })
    .regex(/^[a-zA-Z\s]+$/, "Driver name must be a valid name"),
  driverEmail: z
    .string({ required_error: "Driver email is required" })
    .min(1, { message: "Driver email is required" })
    .email("Driver email must be a valid email")
    .regex(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Driver email must be a valid email"
    ),
  term: z
    .number({ required_error: "Term is required" })
    .positive("Term must be a positive number"),
  kms: z
    .number({ required_error: "Kms is required" })
    .positive("Kms must be a positive number"),
  numberoftyres: z
    .number({ required_error: "Number of tyres is required" })
    .min(0, { message: "Number of tyres must be 0 or more" }),
});
