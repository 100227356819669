import React from "react"
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useRouter } from "utils"
import { themeV5 } from "theme/v5"

const TabMenu = ({
  config
}) => {
  const { history } = useRouter()
  const ButtonItem = ({ label, isActive, onClick, disabled, isDataAvailable }) => {
    return (
      <Button
        sx={{
          bgcolor: isActive ? "white" : "transparent",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderWidth: 1,
          borderColor: isActive ? "white" : "#98B6BE",
          borderStyle: "solid",
          flex: 1,
          color: isDataAvailable ? themeV5.palette.success.main : themeV5.palette.text.primary ,
          "&:hover": {
            bgcolor: isActive ? "white" : "transparent",
          }
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <Typography
          fontSize={14}
          fontWeight={isActive ? 600 : 400}
        >
          {label}
        </Typography>
      </Button>
    )
  }

  return (
    <Stack
      flexDirection="row"
    >
      {config?.map((item) => (
        <ButtonItem
          key={item?.path}
          label={item?.label}
          onClick={() => history.push(item?.path)}
          isActive={window.location.pathname.includes(item?.path)}
          disabled={item?.disabled}
          isDataAvailable={item?.isDataAvailable}
        />
      ))}
    </Stack>
  )
}

export default TabMenu