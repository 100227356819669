import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getStatusColor } from 'listable'
import { createRowSelector } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import Options from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings';

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, selected, onSelect, onView }) => {

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1838FeatureToggle = featureToggleList.includes('TFP1838')

  const onSafeRowClick = createRowSelector(onView)

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      selected={selected.indexOf(item.id) !== -1}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.referenceId && (
        <TableCellView>
          {item.referenceId || '-'}
        </TableCellView>
      )}
      {columns.vehicleRegistration && (
        <TableCellView>
          {item.vehicleRegistration || '-'}
        </TableCellView>
      )}
      {columns.driverName && (
        <TableCellView>
          {item.driverName || '-'}
        </TableCellView>
      )}
      {columns.driverMobile && (
        <TableCellView>
          {item.driverMobile || '-'}
        </TableCellView>
        // <TableCellView>
        //   {item.driverMobile ? (
        //     <a className="ignore" href={`tel:${item.driverMobile}`}>
        //       {item.driverMobile}
        //     </a>
        //   ) : (
        //     '-'
        //   )}
        // </TableCellView>
      )}

      {tfp1838FeatureToggle && columns.contactEmail && (
        <TableCellView>
          {item.contactEmail || '-'}
        </TableCellView>
      )}

      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.pickup && (
        <TableCellView>
          {item.pickup || '-'}
        </TableCellView>
      )}
      {columns.destination && (
        <TableCellView>
          {item.destination || '-'}
        </TableCellView>
      )}
      {columns.effectiveDate && (
        <TableCellView>
          {item.effectiveDate ? moment(item.effectiveDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.status && (
        <TableCellView>
          {item.status ? (
            <Label
              color={getStatusColor(item.status)}
              variant="outlined">
              {item.status}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      <TableCellView sx={{ width: "50px", maxWidth: "50px" }}>
        <Options
          className="ignore"
          id={item.id}
          onView={onView} />
      </TableCellView>
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: types.IDListType.isRequired,
  onSelect: PropTypes.func,
  onView: PropTypes.func.isRequired,
}

export default Row
