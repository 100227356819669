import React from 'react'
import PropTypes from 'prop-types'
import CardActions from '@mui/material/CardActions'
import TablePagination from '@mui/material/TablePagination'
import { useTheme } from '@mui/material/styles'

import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { IconButton } from '@material-ui/core';
import { themeV5 } from 'theme/v5'

const PageListTableFooter = ({
  offset,
  rows,
  total,
  onChangePaginationOffset,
  onChangePaginationRows
}) => {
  const theme = useTheme()

  return (
    <CardActions
      sx={{
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
      }}
    >
      <TablePagination
        component="div"
        count={total}
        onPageChange={onChangePaginationOffset}
        onRowsPerPageChange={onChangePaginationRows}
        page={offset}
        rowsPerPage={rows}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage='Rows per page'
        ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (
          <div className="MuiTablePagination-actions">
            <IconButton
              onClick={(event) => onPageChange(event, page - 1)}
              disabled={page === 0}
              aria-label="previous page"
              style={{ color: page === 0 ? themeV5.palette.accent.sky : themeV5.palette.text.placeholder }}
            >
              <WestIcon />
            </IconButton>
            <IconButton
              onClick={(event) => onPageChange(event, page + 1)}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
              style={{ color: page >= Math.ceil(count / rowsPerPage) - 1 ? themeV5.palette.accent.sky : themeV5.palette.text.placeholder }}
            >
              <EastIcon />
            </IconButton>
          </div>
        )}
        sx={{
          fontSize: 10,
          color: 'black',
          '& .MuiTablePagination-selectLabel': {
            fontSize: 10,
          },
          '& .MuiTablePagination-displayedRows': {
            fontSize: 10,
          }
        }}
      />
    </CardActions>
  )
}

PageListTableFooter.propTypes = {
  offset: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChangePaginationOffset: PropTypes.func.isRequired,
  onChangePaginationRows: PropTypes.func.isRequired,
}

export default PageListTableFooter
