import React from 'react'
import { useDispatch } from 'react-redux'
import { DashboardGraph } from 'graph'
import { useGraphFetch, constants } from '../module'
import { useSelectedFleetIds } from 'features/settings'
import uuid from 'uuid/v4'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const Graph = () => {
  const dispatch = useDispatch()
  const selectedFleetIds = useSelectedFleetIds()

  const { loading, error, data } = useGraphFetch()
  const path = '/reports/extension-rentals'

  const onRefresh = () => {
    dispatch({
      type: constants.REQUEST_DATA,
      payload: null,
      options: { queryParams: { FleetId: selectedFleetIds.join() }, requestId: uuid() }
    })
  }

  return (
    <DashboardGraph
      isInertia
      title="Vehicles on Extension"
      to={path}
      loading={loading}
      error={error}
      minHeight={80}
      onRefresh={onRefresh}
      data={data}
    >
      <Stack justifyContent="center" alignItems="center" width="100%">
        {data && data.numberOfVehicles > 0 ? (
          <Typography
            variant="h6"
            color="error"
            fontSize={75}
            fontWeight={700}
          >
            {data.numberOfVehicles}
          </Typography>
        ) : (
          <Typography variant="bodyLarge">
            You currently have no vehicles on extension
          </Typography>
        )}
      </Stack>
    </DashboardGraph>
  )
}

export default Graph