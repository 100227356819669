import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { getReliefStatusColor } from "listable"
import { capitalize, createRowSelector } from "utils"
import { Label, FleetIcon } from "frame/components"
import RowOptions from "./row-options"
import { types } from "../../module"
import clip from "text-clipper"
import { useEnabledFeatureToggleList } from "features/settings"

import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableCellView from "frame/components/table-cell/table-cell"

const Row = ({ item, columns, selected, onView }) => {
  const onSafeRowClick = createRowSelector(onView)
  
  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes("TFP1944")

  return (
    <TableRow
      hover
      sx={{
        boxSizing: "border-box",
        cursor: "pointer",
        verticalAlign: "middle",
        height: "30px",
        borderBottom: "5px solid white",
      }}
      selected={selected.indexOf(item.id) !== -1}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: "white", width: "10px", p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {item.agreementNo || "-"}
        </TableCellView>
      )}
      {columns.createdAt && (
        <TableCellView>
          {item.createdAt ? moment(item.createdAt).format("DD/MM/YY") : "-"}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || "-"}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || "-"}
        </TableCellView>
      )}
      {columns.driver && (
        <TableCellView>
          {item.driver || "-"}
        </TableCellView>
      )}
      {columns.status && (
        <TableCellView>
          {item.status ? (
            <Label
              color={getReliefStatusColor(item.status)}
              variant="outlined">
              {capitalize(item.status)}
            </Label>
          ) : "-"}
        </TableCellView>
      )}
      {columns.isFromApp && (
        <TableCellView>
          {item.isFromApp !== null
            ? item.isFromApp ? "Yes" : "No"
            : "No"}
        </TableCellView>
      )}
      {columns.vehicleType && (
        <TableCellView>
          {item.vehicleType || "-"}
        </TableCellView>
      )}
      {columns.collectionDate && (
        <TableCellView>
          {item.collectionDate ? moment(item.collectionDate).format("DD/MM/YY") : "-"}
        </TableCellView>
      )}
      {columns.bookedReturnDate && (
        <TableCellView>
          {item.bookedReturnDate ? moment(item.bookedReturnDate).format("DD/MM/YY") : "-"}
        </TableCellView>
      )}
      {columns.pickupAddress && (
        <TableCellView>
          {item.pickupAddress ? clip(item.pickupAddress, 100) : "-"}
        </TableCellView>
      )}
      {columns.comments && (
        <TableCellView>
          {item.comments ? clip(item.comments, 100) : "-"}
        </TableCellView>
      )}
      <TableCellView isLast sx={{ width: "50px", maxWidth: "50px" }}>
        <RowOptions          
          className="ignore"
          id={item.it}
          onView={onView} />
      </TableCellView>
      <TableCell
        sx={{
          bgcolor: "white",
          width: "10px",
          p: 0,
          display: "flex"
        }}
      />
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: types.IDListType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
