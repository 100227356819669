import React from 'react'
import MuiDialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material/styles'

export const DialogContent = ({
  children,
  ref
}) => {
  const theme = useTheme()
  return (
    <MuiDialogContent
      ref={ref}
      sx={{
        position: 'relative',
        padding: { xs: '0 8px 8px 8px', sm: '0 24px 8px 24px' },
        pointerEvents: 'all',
        m: 0,
      }}
    >
      {children}
    </MuiDialogContent>
  )
}