import React from 'react'
import PropTypes from 'prop-types'
import { AntSwitch } from 'frame/components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { useTheme } from "@mui/material/styles"

import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
/**
 * Remove this dependency
 */
import { colors } from '@material-ui/core'

const TableHeaderSortableLabel = ({ noSort, active, center, children, direction, onClick, showToggle, toolTip, toggleAction, toggleValue }) => {
  const theme = useTheme()
  
  const isUp = active && direction === 'asc'
  const isDown = active && direction === 'desc'

  const handleChange = (e) => {
    toggleAction(true)
  }

  return (
    <>
      {showToggle && (
        <AntSwitch value={toggleValue} onChange={handleChange} name="sw" />
      )}
      <Button
        onClick={noSort ? () => {} : onClick}
        sx={{
          p: 0,
          px: 0,
          display: 'flex',
          justifyContent: 'flex-start',
          minWidth: 'auto',
          color: 'black'
        }}
      >

        {!noSort && (
          <Typography
            component='span'
            sx={{
              display: 'inline-block',
              width: '8px',
              height: '10px',
              transform: 'translateX(-12px)',
              '&::before': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                top: '0px',
                left: '150%',
                marginLeft: '-5px',
                borderTop: '5px solid',
                borderColor: isUp ? theme.palette.text.primary : colors.blueGrey[300],
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                transform: 'rotate(-180deg) scale(0.7)',
              },
              '&::after': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                bottom: '0px',
                left: '150%',
                marginLeft: '-5px',
                borderBottom: '5px solid',
                borderColor: isDown ? theme.palette.text.primary : colors.blueGrey[300],
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                transform: 'rotate(180deg) scale(0.7)',
              },
            }}
          />
        )}

        <Typography
          variant="bodyLarge"
          sx={{
            flex: 1,
            //fontSize: 9,
            //fontWeight: 500,
            letterSpacing: '0px',
            color: active ? theme.palette.text.primary : theme.palette.text.blueGrey,
            transition: 'all 0.3s ease-in-out',
            textAlign: 'left'
          }}
        >
          {children}
          {toolTip && (
            <Tooltip
              arrow
              placement="top"
              componentsProps={
                {
                  arrow: {
                    sx: {
                      color: theme.palette.common.white,
                      '&::before': {
                        border: '1px solid #dfdfdf',
                        backgroundColor: theme.palette.common.white,
                        boxSizing: 'border-box',
                      }
                    }
                  },
                  tooltip: {
                    sx: {
                      backgroundColor: theme.palette.common.white,
                      color: theme.palette.common.black,
                      border: '1px solid #dfdfdf',
                      borderRadius: '5px',
                      padding: theme.spacing(2),
                      minWidth: '400px',
                    }
                  }
                }
              }
              title={
                <>
                  <h3>{toolTip.title}</h3>
                  <Typography>{toolTip.description}</Typography>
                </>
              }
            >
              <IconButton
                aria-label="info"
                sx={{
                  margin: 0,
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <InfoOutlinedIcon
                  sx={{
                    width: 18,
                    height: 18,
                    marginLeft: '5px',
                    verticalAlign: 'middle',
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      </Button>
    </>
  )
}

TableHeaderSortableLabel.propTypes = {
  noSort: PropTypes.bool,
  active: PropTypes.bool,
  center: PropTypes.bool,
  showToggle: PropTypes.bool,
  toolTip: PropTypes.any,
  children: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default TableHeaderSortableLabel
