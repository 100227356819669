import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { types } from '../../module'
import { AntSwitch, FleetIcon } from 'frame/components'

import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const useStyles = makeStyles(theme => ({
  
  fleetIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  fleetName:{
    width: '180px'
  }
}))

const Row = ({ item, columns, toggleHandler }) => {

  const classes = useStyles()

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />

      {columns.fleet && (
        <TableCellView isFirst>
          <Box display='flex' alignItems='center'>
            <FleetIcon
              style = {{ width : '20px'}}
              key={`fleet-icon-${item.accountName}`} 
              fleetName={item.accountName} 
            />
            <Box ml={1}>{item.accountName || '-'}</Box>
          </Box>
        </TableCellView>
      )}

      {columns.driverAssignment && (
        <TableCellView>
          <AntSwitch
            style={{ marginLeft: 60 }}
            value={item.active}
            onChange={() => toggleHandler(item)}
            name="featureSettingSwitch"
          />
        </TableCellView>
      )}

      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  toggleHandler: PropTypes.func
}

export default Row
