import React from 'react'
import { ListItem } from '@mui/material'
import { FleetIcon } from 'frame/components'

const Fleets = ({ items, hasFleets }) => {
  const FleetList = ({ fleets }) => fleets?.map((fleet, index) =>
    <FleetIcon key={`fleet-icon-${index}`} fleetName={fleet.label} />
  )

  return (
    <ListItem
      display='flex'
      flexDirection='row'
      flexWrap='wrap'
      width='320px'
      sx={{
        flexWrap: 'wrap',
        '& > *': {
          marginY: 1,
          marginRight: 2
        }
      }}
    >
      {hasFleets && <FleetList fleets={items} />}
    </ListItem>
  )
}

export default Fleets