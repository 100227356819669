import React from 'react'
import PropTyes from 'prop-types'
import { connect } from 'react-redux'
import { useSettingsFleetValues } from 'features/settings'
import Details from "./details"

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

const Profile = ({ profile, errorMessage }) => {
  const fleets = useSettingsFleetValues()

  return (
    <Card
      sx={{
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
      data-id="profile-details"
    >
      <Details
        fleets={fleets}
        profile={profile}
        errorMessage={errorMessage}
      />
    </Card>
  )
}

Profile.propTypes = {
  profile: PropTyes.object.isRequired,
  errorMessage: PropTyes.string,
}

const mapStateToProps = ({ account }) => ({
  profile: account.profile,
  errorMessage: account.errorMessage,
})

export default connect(
  mapStateToProps,
)(Profile)
