import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createFilterOptions } from '@material-ui/lab'
import { useSelectedFleetIds } from 'features/settings'
import { useSelector } from 'react-redux'

import Autocomplete from '@mui/material/Autocomplete'
import KeyValue from './mui-textfield'

// OfflineLookupField is a lookup field that fetches the entire options list on load when used locally with autocomplete
const OfflineLookupField = ({
  onLookup,
  resourceModel,
  id,
  size,
  defaultOptions = [],
  lookupPath,
  onChange,
  value,
  label,
  disabled,
  showAllResult,
  allowFreeText,
  customFleetId,
  hideLabelOnMobile,
  refetchOnChange,
  resetDefaultValueOnChange,
  ...props
}) => {

  const fleetIds = useSelectedFleetIds()
  const [selectValue, setSelectValue] = React.useState({ text: value, value: value || '' })
  const [inputValue, setInputValue] = React.useState(value || '')

  const [options, setOptions] = useState(defaultOptions)
  
  const lookupResults = useSelector(state => state[resourceModel]?.lookup
    ? state[resourceModel]?.lookup[lookupPath]
    : [])
  
  const lookupFlag = useSelector(state => state[resourceModel]?.flags?.lookup)
  const isLoading = lookupFlag === 'processing'

  const onSelect = (_, selectValue) => {
    if (onChange && selectValue) {
      setSelectValue(selectValue)
      onChange(selectValue)
    }
  }

  useEffect(() => { // update dropdown options on lookup results value change
    lookupResults && setOptions(lookupResults)
  }, [lookupResults])
  
  useEffect(() => { // fetch all lookup options on load
    const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
    showAllResult || value.length > 0 ? onLookup(lookupPath, '', fleetIdParam) : setOptions([])
    // eslint-disable-next-line
  }, refetchOnChange ? [refetchOnChange] : [])

  
  useEffect(() => { // to fix default value not updating issue
    resetDefaultValueOnChange && setSelectValue({ text: value, value: value })
    // eslint-disable-next-line
  }, resetDefaultValueOnChange ? [resetDefaultValueOnChange] : [])

  const onInputChange = (_, inputValue) => {
    setInputValue(inputValue)
    allowFreeText && onChange({ text: inputValue, value: inputValue })
  }

  const labelParams = hideLabelOnMobile ? null : { label }
  
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.text,
  })

  return (
    <Autocomplete
      disableClearable
      id={`auto-suggest-${id}`}
      freeSolo={!!allowFreeText}
      disabled={disabled}
      loading={isLoading}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={option => option.text || ''}
      renderInput={params => (
        <KeyValue
          label={label}
          {...params}
        />
        // <TextValidator
        //   {...props}
        //   {...params}
        //   {...labelParams}
        //   inputProps={{ ...params.inputProps, maxLength: 50 }}
        // />
      )}
      value={selectValue}
      onChange={onSelect}
      inputValue={inputValue}
      onInputChange={onInputChange}
      size='small'
      sx={{
        '& label': {
          fontSize: 12,
          color: '#00B6B0',
          top: '3px',
        },
        '& .MuiInputBase-root:before': {
          content: 'none',
        },
        '& .MuiInputBase-root:after': {
          content: 'none',
        } 
      }}
    />
  )
}

OfflineLookupField.propTypes = {
  onLookup: PropTypes.func.isRequired,
  resourceModel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  defaultOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  lookupPath: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showAllResult: PropTypes.bool,
  allowFreeText: PropTypes.bool,
  customFleetId: PropTypes.string,
  hideLabelOnMobile: PropTypes.bool,
  refetchOnChange: PropTypes.any,
  resetDefaultValueOnChange: PropTypes.any,
}

export default OfflineLookupField
