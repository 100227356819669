import React from 'react'
import PropTypes from 'prop-types'
import { AntSwitch } from 'frame/components'
import { types } from '../../module'
import moment from 'moment'
import { useFleetId } from 'features/settings'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'
import { useIsUserATfs } from 'utils'
import { getAppConfig } from 'app-config'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, onEnable, onDisable }) => {
  const dispatch = useDispatch()
  const fleedId = useFleetId()
  const isTfsUser = useIsUserATfs()
  const config = getAppConfig()

  const activeFBTNotifyToggle = useSelector(state => state.featureToggles?.activeFBTNotifyToggle)

  const handleChange = (checked) => {
    if(item.feature === 'Non-TFM') {// refetch dashboard data on non-tfm toggle - remove once permenant 
      dispatch({ type: 'graphMaturing_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch maturing graph
      dispatch({ type: 'graphWofExpiry_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch wof graph
      dispatch({ type: 'graphServiceExpiry_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch service tracking graph
      dispatch({ type: 'graphKmTracking_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch km tracking graph
    }    
    checked && item.feature ? onEnable(item.feature) : onDisable(item.feature)
  }
  
  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
        // cursor: 'pointer',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.feature && (
        <TableCellView isFirst>
          {item.feature || '-'}
        </TableCellView>
      )}
      {columns.description && (
        <TableCellView>
          {item.description && item.description.length > 0
            ? (
              <ul style={{ marginLeft: '-26px' }}>
                {item.description.map((desc, index) =>
                  <li key={`${item.feature}-description-${index}`}>
                    {desc}
                  </li>
                )}
              </ul>
            ) : '-'
          }
        </TableCellView>
      )}

      {columns.reportsImpacted && (
        <TableCellView>
          {item.reportsImpacted && item.reportsImpacted.length > 0
            ? (
              <ul style={{ marginLeft: '-26px' }}>
                {item.reportsImpacted.map((report, index) =>
                  <li key={`${item.feature}-reportsImpacted-${index}`}>
                    {report}
                  </li>
                )}
              </ul>
            ) : '-'
          }
        </TableCellView>
      )}

      {columns.lastToggledDate && (
        <TableCellView>
          {item.lastToggledDate ? moment(item.lastToggledDate).format('DD/MM/YY hh:mm A') : '-'}
        </TableCellView>
      )}
      {columns.lastToggledBy && (
        <TableCellView>
          {item.lastToggledBy || '-'}
        </TableCellView>
      )}

      {columns.featureToggle && (
        <TableCellView>
          {config.env === process.env.REACT_APP_PROD_ENV && !isTfsUser // if env is production, and user is not a tfs user, hide switches
          ? (item.active ? 'ON' : 'OFF')
          : (
            <AntSwitch
            value={(item.feature === 'FBTNotifyOpen' || item.feature === 'FBTNotifyRemind' || item.feature === 'FBTNotifyClosed')
              && activeFBTNotifyToggle && activeFBTNotifyToggle !== item.feature ? false : item.active}
            onChange={handleChange}
            name="featureSwitch"
          />
          )}
        </TableCellView>
      )}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>

  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
}

export default Row
