import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from "@mui/material/styles"

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const PasswordIndicator = ({
  passwordMatch,
  meetsPasswordLength,
  hasNumber,
  hasLowercase,
  hasUppercase,
  hasSymbol
}) => {

  const theme = useTheme()

  const IconView = ({ isActive }) => {
    if (isActive) {
      return (
        <CheckCircleIcon
          sx={{
            color: isActive ? theme.palette.status.success : 'inherit'
          }}
        />
      )
    }
    return (
      <CancelIcon />
    )
  }

  return (
    <Stack py={1} gap={1}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconView isActive={passwordMatch} />
        <Typography variant="bodyLarge">
          Password Match
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconView isActive={meetsPasswordLength} />
        <Typography variant="bodyLarge">
          At least 8 or more characters
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconView isActive={hasNumber} />
        <Typography variant="bodyLarge">
          Contain at least one number
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconView isActive={hasLowercase} />
        <Typography variant="bodyLarge">
          Contain at least one lowercase character
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconView isActive={hasUppercase} />
        <Typography variant="bodyLarge">
          Contain at least one uppercase character
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconView isActive={hasSymbol} />
        <Typography variant="bodyLarge">
          Contain at least one symbol
        </Typography>
      </Stack>
    </Stack>
  )
}

PasswordIndicator.propTypes = {
  passwordMatch: PropTypes.bool.isRequired,
  meetsPasswordLength: PropTypes.bool.isRequired,
  hasNumber: PropTypes.bool.isRequired,
  hasLowercase: PropTypes.bool.isRequired,
  hasUppercase: PropTypes.bool.isRequired,
  hasSymbol: PropTypes.bool.isRequired,
}

export default PasswordIndicator
