import React, { useState } from "react"
import PropTypes from "prop-types"
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from "frame/components"
import { connect } from "react-redux"
import moment from "moment"
import { submitKmReading, reset } from "features/fleet/actions"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import MuiTextfield from "frame/components/mui-textfield/mui-textfield"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { schema } from "./add-km-reading-form-schema"
import DatePicker from "frame/components/date-picker-v5"
import StyledModalV2 from "frame/components/styled-modal-v2"

const AddKmReadingForm = ({ flags, apiResponse, onReset, onBack, fleetData, isMiles, onAddKmReading, errorMessage }) => {
  console.log("MILES", isMiles);
  const theme = useTheme()
  
  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const [values, setValues] = useState({
    completedDate: moment(),
    odometer: ""
  })

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  
  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })

  const onSubmit = () => {
    const payload = {
      thirdPartyNumber: fleetData?.thirdPartyNumber,
      agreementNumber: fleetData?.agreementNo,
      vehicleRegistration: fleetData?.registration,
      completedDate: values.completedDate,
      odometer: +values.odometer,
    }
    console.log("[onKmReading] payload: ", payload)
    onAddKmReading(payload)
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card
          sx={{
            boxShadow: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <FormProvider>
            <form
              id="service-record-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <CardContent
                sx={{
                  p: 3
                }}
              >
                <Stack gap={4}>
                  <Stack>
                    <Typography variant="h5" fontWeight={600}>
                      Add new KM Reading
                    </Typography>
                    <Typography variant="bodyMedium" fontWeight={300}>
                      Add new details of an odometer reading
                    </Typography>
                  </Stack>
                  <Stack gap={2} width={{ xs: "100%", md: "50%" }}>
                    <Stack>
                      <DatePicker
                        variant="filled"
                        label="Service Date"
                        format="Do MMMM YYYY"
                        value={typeof values.completedDate === "undefined" ? null : dayjs(values.completedDate)}
                        onChange={onSelectChange("completedDate")}
                        disabled={isMiles}
                      />
                    </Stack>
                    <Stack gap={1}>
                      <Controller
                        name="odometer"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="odometer"
                            error={Boolean(methods.formState.errors?.odometer?.message)}
                            label="Odometer Reading"
                            value={values.odometer || ""}
                            onChange={onInputChange}
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.odometer?.message}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  gap={1}
                >
                  <Box width={{ xs: "100%", md: "205px" }}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={onBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Stack flexDirection="row" gap={2}>
                    <Box width={{ xs: "100%", md: "205px" }}>
                      <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        {"Save"}
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              </CardContent>
            </form>
          </FormProvider>
        </Card>
      </LocalizationProvider>

      <StyledModalV2 
        open={flags.submitKmReading === "processed" || flags.submitKmReading === "error"}
        onClose={() => {
          flags.submitKmReading === "processed" && onBack()
          onReset()
        }}
        content={
          <Typography variant="h4" textAlign="center">
            {apiResponse}
          </Typography>
        }
        status={flags.submitKmReading === "processed" ? "success" : "error"}
      />
    </>
  )}

  AddKmReadingForm.propTypes = {
    flags: PropTypes.object.isRequired,
    fleetData: PropTypes.object.isRequired,
    errorMessage: PropTypes.string.isRequired,
    apiResponse: PropTypes.string.isRequired,
    // func
    onAddKmReading: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onBack: PropTypes.func,
  }
  
  const mapStateToProps = ({ fleet }) => ({
    flags: fleet.flags,
    errorMessage: fleet.errorMessage || "",
    apiResponse: fleet.apiResponse || "",
  })
  
  const mapDispatchToProps = (dispatch) => ({
    onAddKmReading: (payload) => {
      dispatch(submitKmReading(payload))
    },
    onReset: () => {
      dispatch(reset())
    },
  })

  export default connect(mapStateToProps,mapDispatchToProps)(AddKmReadingForm)