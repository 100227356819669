import React from 'react'
import PropTypes from 'prop-types'
import Form from './form'

const Password = () => {
  return (
    <Form />
  )
}

Password.propTypes = {
  onSave: PropTypes.func.isRequired,
}

export default Password
