import React from 'react'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const LinkButton = ({ label, onNavigate, isActive }) => {
  return (
    <Button
      data-cy={`${label.replace(' ', '_').toLowerCase()}`}
      onClick={() => onNavigate()}
      sx={{
        justifyContent: 'flex-start',
        width: '100%',
        borderRadius: 0,
        px: 0,
        py: 1,
      }}
    >
      <Typography
        variant='body1'
        color='white'
        fontWeight={700}
        sx={{
          borderBottom: isActive ? '2px solid white' : '2px solid transparent',
        }}
      >
        {label}
      </Typography>
    </Button>
  )
}

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
}

export default LinkButton