import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ValidatorForm } from "react-material-ui-form-validator"
import { Alert } from "frame/components"
import moment from "moment"
import { lookup } from "../../../actions"
import DriverLookupField from "./driver-lookup-field"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

const ChangeDriverForm = ({
  values,
  onChange,
  disabled,
  onProceed,
  onCreateNewDriver,
  onBack,
  searchHistory,
  setSearchHistory,
  onLookup,
  originalValues,
  fleetData,
}) => {
  
  const form = useRef()
  const theme = useTheme()
  
  const [showError, setShowError] = useState(null)
  const [selectedDriver, setSelectedDriver] = useState(null)

  const onFleetLookupChange = (option) => {
    setShowError(null)
    option && console.log("[ChangeDriverForm - onFleetLookupChange]", option)
    setSelectedDriver(option && option.value ? option.value : null)

    option && onChange({
      ...values,
      uniqueId: option.value || null,
      driver: option.text,
      email: option.email ? option.email.trim() : null,
      mobile: option.mobile ? option.mobile.trim() : null,

      // overwrite values in confirm step on select different driver
      effectiveDate: option.effectiveDate || fleetData.effectiveDate || moment(),
      costCentre: option.costCentre || fleetData.costCentre,
      location: option.location || fleetData.location,
      controller: option.controller || fleetData.controller,
      vehicleNotes: option.vehicleNotes|| fleetData.vehicleNotes,
    })
  }

  const onProceedValidation = () => {
    if (form.current && selectedDriver) {
      !values.email && setShowError("Driver email is required to proceed.")
      form.current.submit()
    } else {
      setShowError("Please select a driver to proceed.")
      setTimeout(() => setShowError(null), 3000)
    }
  }

  const onCreateNew = () => {
    setSearchHistory(null)
    onCreateNewDriver()
  }

  const onBackHandler = () => {
    setSearchHistory(null) 
    onChange({ // reset values
      ...values,
      uniqueId: originalValues.uniqueId || null,
      driver: originalValues.driver || null,
      email: originalValues.email || null,
      mobile: originalValues.mobile || null,
    })
    onBack()
  }

  const onEmailChange = (value) => {
    setSearchHistory({ ...searchHistory, email: value })
    onChange({ ...values, email: value })
  }

  return (
    <ValidatorForm
      autoComplete="off"
      noValidate
      ref={form}
      onSubmit={onProceed}
    >
      <Card
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <CardContent
          sx={{
            p: 3,
          }}
        >
          <Stack gap={1}>
            <Typography variant="h5" fontWeight={600}>
              Search for an existing driver
            </Typography>

            <Stack>
              <DriverLookupField
                fullWidth
                id="driver"
                variant="outlined"
                customFleetId={values.thirdPartyNumber}
                disabled={disabled}
                label="Enter Driver Name"
                lookupPath="drivername"
                resourceModel="fleet"
                onLookup={onLookup}
                onChange={onFleetLookupChange}
                currentDriver={selectedDriver}
                searchHistory={searchHistory}
                setSearchHistory={setSearchHistory}
                originalValues={originalValues}
                values={values}
                onEmailChange={onEmailChange}
              />
            </Stack>
          </Stack>

          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="secondary"
                disabled={disabled}
                variant="contained"
                onClick={onBackHandler}
              >
                Back
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  disabled={disabled}
                  variant="outlined"
                  onClick={onCreateNew}
                >
                  Create New Driver
                </Button>
              </Box>
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="primary"
                  disabled={disabled}
                  variant="contained"
                  onClick={onProceedValidation}
                >
                  Proceed
                </Button>
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </ValidatorForm>
  )
}

ChangeDriverForm.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  originalValues: PropTypes.object,
  fleetData: PropTypes.object,
  searchHistory: PropTypes.object,
  setSearchHistory: PropTypes.func,
  // func
  onLookup: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateNewDriver: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId, size = 100) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey, Size: size }
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDriverForm)