import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createRowSelector } from 'utils'
import RowActions from './row-options'
import { types } from '../../module'
import { capitalize } from 'utils'
import { FleetIcon, Label } from 'frame/components'
import { getDriverStatusColor } from 'listable'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import moment from 'moment'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import TableCellView from 'frame/components/table-cell/table-cell'

// columns and selected props is passed from src\listable\components\list.js line 197
const Row = ({ item, columns, selected, onView, onTerminate, onSelectRow,userRoles }) => {

  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures

  const [checkboxValue, setCheckboxValue] = useState(false)

  const onSafeRowClick = createRowSelector(onView)

  const isTerminated = item.terminated

  const onSelect = (uniqueId) => ({ target: { checked } }) => {
    setCheckboxValue(checked)
    let updatedChecked = selected
    if(checked) { // add to checked list state
      updatedChecked.push(uniqueId)
      onSelectRow(updatedChecked)
    } else { // removed from checked list state
      updatedChecked = updatedChecked && updatedChecked.filter(id => id !== uniqueId)
      onSelectRow(updatedChecked)
    }
  }
  
  useEffect(() => {
    setCheckboxValue(selected && selected.includes(item.uniqueId))
    // eslint-disable-next-line
  }, [selected])

  const disabledInvite = item.status === 'Terminated' || item.activated === 'Verified'

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
        position: 'relative'
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />

      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}

      {/* {columns.agreementNo && (
        <TableCellView>
          {item.agreementNo || '-'}
        </TableCellView>
      )} */}

      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}

      {columns.email && (
        <TableCellView>
          {item.email || '-'}
        </TableCellView>
      )}

      {columns.mobile && (
        <TableCellView>
          {item.mobile || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {!isTerminated && item.costCentre ? item.costCentre : '-'}
        </TableCellView>
      )}

      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.location && (
        <TableCellView>
          {item.location || 'Unknown'}
        </TableCellView>
      )}

      {columns.controller && (
        <TableCellView>
          {!isTerminated && item.controller ? item.controller : '-'}
        </TableCellView>
      )}

      {columns.vehicleNotes && (
        <TableCellView>
          {!isTerminated && item.vehicleNotes ? item.vehicleNotes : '-'}
        </TableCellView>
      )}

      {columns.driverNotification && (
        <TableCellView>
          {item.driverNotification && item.driverNotification === 'n/a'
            ? 'N/A'
            : item.driverNotification ? capitalize(item.driverNotification) : '-'}
        </TableCellView>
      )}

      {columns.status && (
        <TableCellView>
          <Label
            color={getDriverStatusColor(item.status)}
            variant="outlined">
            {item.status || '-'}
          </Label>
        </TableCellView>
      )}

      {/* activated values: None, Unverified, Verified */}
      {columns.activated && (
        <TableCellView>
          <Label
            color={getDriverStatusColor(item.activated)}
            variant="outlined">
            {item.activated || '-'}
          </Label>
        </TableCellView>
      )}
      
      {/* invited values: None, Expired, Invited */}
      {columns.invited && (
        <TableCellView>
          <Label
            color={getDriverStatusColor(item.invited)}
            variant="outlined">
            {item.invited || '-'}
          </Label>
        </TableCellView>
      )}

      {columns.lastLogin && (
        <TableCellView>
          {item.lastLogin ? moment(item.lastLogin).format('Do MMMM YYYY h:mm A') : 'never'}
        </TableCellView>
      )}
      <TableCellView sx={{ position: 'relative'}} isLast>
        <Stack 
          sx={{
            flexDirection: 'row',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            alignItems: 'center',
          }}
        >
          <Checkbox
            className="ignore"
            color="default"
            checked={checkboxValue}
            onChange={onSelect(item.uniqueId)}
            disabled={disabledInvite}
          />

          <RowActions
            className="ignore"
            onView={onView}
            onTerminate={item.status !== 'Terminated' && !isAlfaDown ? onTerminate : null}
            driverName={item.driver}
            userRoles={userRoles}
          />
        </Stack>
      </TableCellView>
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onTerminate: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default Row
