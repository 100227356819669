import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextfield from '../../../frame/components/mui-textfield'
import axios from 'axios';
import { getAppConfig } from 'app-config';
import { getAuthClient } from 'auth';

const AsyncAutocomplete = ({ id, name, control, label, value, disabled, apiEndpoint, onSelectedOptionChange, rules = {} }) => {
  const config = getAppConfig();
  const authClient = getAuthClient();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const baseURL = config.app.api;

  useEffect(() => {
    const fetchData = async () => {
      if (inputValue) {
        try {
          const accessToken = await authClient.getTokenSilently();
          const headers = { Authorization: `Bearer ${accessToken}` };
          const fullURL = new URL(apiEndpoint, baseURL);
          const response = await axios.get(fullURL.toString(), {
            params: { Search: inputValue },
            headers: headers,
          });
          const data = response.data.data || [];
          setOptions(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 300); // Add a delay to debounce API calls

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, apiEndpoint, authClient]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) => option.text || ""}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, value) => {
            field.onChange(value);
            if (value) {
              onSelectedOptionChange(value);
            }
          }}
          value={value ? value : null}
          getOptionSelected={(option, value) => option.text === value.text}
          renderInput={(params) => (
            <MuiTextfield
              {...params}
              id={id}
              label={label}
              //value={value}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error ? fieldState.error.message : null}
            />
          )}
        />
      )}
    />
  );
};

export default AsyncAutocomplete;
