import React from 'react'
import Typography from '@mui/material/Typography'

const CardTitle = ({ variant = "h4", title }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontWeight: 'bold',
        fontSize: '18px',
        paddingBottom: '12px'
      }}
    >
      {title}
    </Typography>
  )
}

export { CardTitle }