import * as z from "zod";

export const schema = z.object({
  nickname: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "Please enter a valid name" }),
  email: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" })
    .email("Must be a valid email"),
});
