import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MenuV5 from '@mui/material/Menu'
import { MenuItemView } from './popover/MenuItemView'
import { useLatestFbtSubmission, useLatestFbtSummary } from 'features/fbt/navigation-config'
// import { constants as fromFbtSubmissions } from 'features/fbt-submissions'
// import { constants as fromFbtSummary } from 'features/fbt-summary'
import { useLocation } from "react-router"

const FBTPopover = ({ open, anchor, linker, fleetIds, onClose, onRequestFbtSubmissionsFilters, onRequestFbtSummaryFilters }) => {
  const location = useLocation()
  const latestFbtSubmission = useLatestFbtSubmission()
  const latestFbtSummary = useLatestFbtSummary()

  useEffect(() => {
    console.log('[FBTPopover] Request Fbt Filters ') // fetch fbt filters
    onRequestFbtSubmissionsFilters(fleetIds)
    onRequestFbtSummaryFilters(fleetIds)
  }, [open]) // eslint-disable-line

  if ( ! anchor.current) {
    return null
  }

  const isValidFbtLink = (path) => path.includes('month') || path.includes('quarter')

  return (
    <MenuV5
      keepMounted
      id="menu-actions"
      anchorEl={anchor.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: {
            '& ul': {
              padding: 0,
            }
          }
        },
      }}
    >
      <div>
        {[
          { label: 'FBT Submissions', link: latestFbtSubmission.link },
          { label: 'FBT Summary', link: latestFbtSummary.link }
        ].map(({ label, link }, index) => {
          return (
            <MenuItemView
              key={`fbt-menu-item-${index}`}
              disabled={!isValidFbtLink(link)}
              onClick={isValidFbtLink(link) ? linker(link) : null}
              title={label}
              isActive={link.split("?")?.[0] === location.pathname}
            />
          )
        })}
      </div>
    </MenuV5>
  )
}

FBTPopover.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  fleetIds: PropTypes.array.isRequired,
  // func
  onClose: PropTypes.func.isRequired,
  onRequestFbtSubmissionsFilters: PropTypes.func.isRequired,
  onRequestFbtSummaryFilters: PropTypes.func.isRequired,
}

const mapStateToProps = ({ settings }) => ({
  fleetIds: settings.fleet.selected,
})

const mapDispatchToProps = (dispatch) => ({
  onRequestFbtSubmissionsFilters: (fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch({
      type: `fbtSubmissions_request_filters`,
      payload: null,
      options: { queryParams: { FleetId } }
    })
  },
  onRequestFbtSummaryFilters: (fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch({
      type: `fbtSummary_request_filters`,
      payload: null,
      options: { queryParams: { FleetId } }
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FBTPopover)

