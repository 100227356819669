import palette from './palette'

export default {
  h1: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '28px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  h2: {
    color: palette.brand.black,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  h3: {
    color: palette.brand.black,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  h4: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  h5: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  h6: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  // subtitle1: {
  //   color: palette.text.primary,
  //   fontSize: '16px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '25px',
  //   fontFamily: 'Inter, sans-serif',
  // },
  // subtitle2: {
  //   color: palette.text.secondary,
  //   fontWeight: 400,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px',
  //   fontFamily: 'Inter, sans-serif',
  // },
  // body1: {
  //   color: palette.text.primary,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px',
  //   fontFamily: 'Inter, sans-serif',
  // },
  // body2: {
  //   color: palette.text.secondary,
  //   fontSize: '12px',
  //   letterSpacing: '-0.04px',
  //   lineHeight: '18px',
  //   fontFamily: 'Inter, sans-serif',
  // },
  // bodySmall: {
  //   color: palette.text.primary,
  //   fontSize: '12px',
  //   letterSpacing: '-0.04px',
  //   lineHeight: '18px',
  //   fontFamily: 'Inter, sans-serif',
  // },
  // link1: {
  //   color: palette.text.primary,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px',
  //   fontFamily: 'Inter, sans-serif',
  //   animation: '0.3s all ease-out',
  //   '&:hover': {
  //     color: palette.brand,
  //   }
  // },
  // link2: {
  //   color: palette.text.secondary,
  //   fontSize: '12px',
  //   letterSpacing: '-0.04px',
  //   lineHeight: '18px',
  //   fontFamily: 'Inter, sans-serif',
  //   animation: '0.3s all ease-out',
  //   '&:hover': {
  //     color: palette.brand,
  //     animation: '0.3s all ease-out',
  //   }
  // },
  // linkSubtitle2: {
  //   color: palette.text.secondary,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px',
  //   textDecoration: 'none',
  //   fontFamily: 'Inter, sans-serif',
  //   animation: '0.3s all ease-out',
  //   '&:hover': {
  //     color: palette.brand,
  //   }
  // },
  // button: {
  //   color: palette.text.primary,
  //   fontSize: '14px',
  //   fontFamily: 'Inter, sans-serif',
  // },
  // caption: {
  //   color: palette.text.secondary,
  //   fontSize: '11px',
  //   fontFamily: 'Inter, sans-serif',
  //   letterSpacing: '0.33px',
  //   lineHeight: '13px'
  // },
  // overline: {
  //   color: palette.text.secondary,
  //   fontSize: '11px',
  //   fontFamily: 'Inter, sans-serif',
  //   fontWeight: 500,
  //   letterSpacing: '0.33px',
  //   lineHeight: '13px',
  //   textTransform: 'uppercase'
  // },

  bodyLarge: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  bodyMedium: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: 0,
    fontFamily: 'Inter, sans-serif',
  },
  bodySmall: {
    color: palette.brand.black,
    fontWeight: 400,
    fontSize: 9,
    lineHeight: '15px',
    letterSpacing: '-2%',
    fontFamily: 'Inter, sans-serif',
  },
  button: {
    color: palette.brand.black,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: '0px',
    fontFamily: 'Inter, sans-serif',
  }
}
