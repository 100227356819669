import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { fleets, costCentres, locations, fleetControllers, statuses } = filters

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
        <Box>                   
        {hasList(fleets) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="fleet"
                label="Fleet"
                options={fleets}
                value={filtered.fleet}
                onChange={onSelect('fleet')} />
            </Box>
          )}
          {hasList(costCentres) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </Box>
          )}
          {hasList(locations) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="location"
                label="Location"
                options={locations}
                value={filtered.location}
                onChange={onSelect('location')} />
            </Box>
          )}
          {hasList(fleetControllers) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="controller"
                label="Fleet Controller"
                options={fleetControllers}
                value={filtered.controller}
                onChange={onSelect('controller')} />
            </Box>
          )}
          {hasList(statuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="status"
                label="Status"
                options={statuses}
                value={filtered.status}
                onChange={onSelect('status')} />
            </Box>
          )}             
        </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
