import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from "frame/components"
import { completeServiceBooking, cancelServiceBooking, reset } from "features/report-vehicle-servicing/actions"
import moment from "moment"
import { useEnabledFeatureToggleList } from "features/settings"
import { hasRoles } from "acl"
import { useIsAlfaDownDisableFeatures } from "features/drivers"


import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import MuiTextfield from "frame/components/mui-textfield/mui-textfield"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { schema } from "./complete-service-booking-form-schema"
import DatePicker from "frame/components/date-picker-v5"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

const CompleteServiceBookingForm = ({
  flags,
  readOnly,
  fleetData,
  serviceBookingData,
  apiResponse,
  // func
  onCompleteServiceBooking,
  onCancelServiceBooking,
  onReset,
  userRoles
}) => {

  const theme = useTheme()

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      completedLocation: serviceBookingData?.bookedLocation ? serviceBookingData?.bookedLocation : null,
      odometer: null,
    }
  })

  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)

  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")
  const disableButtons = userPermissionsFeatureToggle && hasRoles(["fleetcoordinator"], userRoles)
  
  const disableOutOfHoursFeatureToggle = featureToggleList.includes("OutOfHoursDisabled") // "ALFA out of hours DISABLE FEATURES" limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? true : isAlfaDownDisableDriverFeatures

  const [values, setValues] = useState({
    completedDate: serviceBookingData?.bookedDate ? moment(serviceBookingData?.bookedDate) : moment(),
    completedLocation: serviceBookingData?.bookedLocation ? serviceBookingData?.bookedLocation : "",
    odometer: ""
  })

  const disabled = disableButtons || isAlfaDown || flags.completeServiceBooking === "processing" || flags.cancelServiceBooking === "processing"

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  
  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })
  
  const onMarkAsComplete = () => {
    setShowDisclaimer(false)
    const payload = {
      agreementNumber: fleetData.agreementNo,
      vehicleRegistration: fleetData.registration,
      completedDate: values.completedDate,
      completedLocation: values.completedLocation,
      odometer: +values.odometer,
    }
    console.log("[onAddServiceBooking] payload: ", payload)
    onCompleteServiceBooking(payload)
  }

  const onSubmit = () => setShowDisclaimer(true)
  const onCancel = () => onCancelServiceBooking({ agreementNumber: fleetData?.agreementNo })

  console.log(methods.formState.errors, "methods.formState.errors")

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <FormProvider>
          <form
            id="service-bookings-confirm-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <CardContent
              sx={{
                p: 3
              }}
            >
              <Stack gap={4}>
                <Stack>
                  <Typography variant="h5" fontWeight={600}>
                    Service Bookings
                  </Typography>
                  <Typography variant="bodyMedium" fontWeight={300}>
                    Cancel or confirm you have completed your service
                  </Typography>
                </Stack>
                <Stack gap={2} width={{ xs: "100%", md: "50%" }}>
                  <Stack>
                    <DatePicker
                      variant="filled"
                      label="Service Date"
                      format="Do MMMM YYYY"
                      value={typeof values.completedDate === "undefined" ? null : values?.completedDate}
                      onChange={onSelectChange("completedDate")}
                    />
                  </Stack>
                  <Stack gap={1}>
                    <Controller
                      name="completedLocation"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="completedLocation"
                          error={Boolean(methods.formState.errors?.completedLocation?.message)}
                          label="Service Dealership"
                          value={values.completedLocation || ""}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.completedLocation?.message}
                    </Typography>
                  </Stack>
                  <Stack gap={1}>
                    <Controller
                      name="odometer"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="odometer"
                          error={Boolean(methods.formState.errors?.odometer?.message)}
                          label="Odometer Reading"
                          value={values.odometer || ""}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.odometer?.message}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

              <Stack
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                gap={1}
              >
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    color="error"
                    disabled={disabled}
                    variant="outlined"
                    onClick={() => setShowConfirmCancelModal(true)}
                  >
                    Cancel Service Booking
                  </Button>
                </Box>
                <Stack flexDirection="row" gap={2}>
                  <Box width={{ xs: "100%", md: "205px" }}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      disabled={disabled}
                      variant="contained"
                    >
                      Mark as Completed
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </form>
        </FormProvider>
      </Card>
    </LocalizationProvider>
      
      {/* Mark as Complete - Disclaimer Modal */}
      {showDisclaimer && (
        <StyledModalV2
          open={showDisclaimer}
          onClose={() => setShowDisclaimer(false)}
          content={
            <Typography variant="h4" textAlign="center">
              I confirm the Service Record data being entered is true and correct and it can be relied upon by both my organisation and Toyota Finance New Zealand (TFNZ). TFNZ accepts no liability for any errors or omissions.
            </Typography>
          }
          actions={
            <StyledModalV2Actions
              onCancel={() => setShowDisclaimer(false)}
              onConfirm={onMarkAsComplete}
            />
          }
        />
      )}
      
      {/* for cancel confirmation */}
        <StyledModalV2
          open={showConfirmCancelModal}
          onClose={() => setShowConfirmCancelModal(false)}
          content={
            <Stack gap={2} justifyContent="center" alignItems="center">
              <Typography variant="h4" textAlign="center">
                By clicking on confirm you are indicating that the service booking you have recorded for this vehicle is not proceeding.
              </Typography>
              <Typography variant="h4" textAlign="center">
                NOTE: cancelling your service booking will only update the vehicle record within the Portal. You may need to contact the servicing dealership to confirm the cancellation.  
              </Typography>
            </Stack>
          }
          actions={
            <StyledModalV2Actions
              onCancel={() => setShowConfirmCancelModal(false)}
              onConfirm={onCancel}
            />
          }
        />

      {/* Success/Error modal */}
      <StyledModalV2
        open={flags.addServiceBooking === "processed"
          || flags.addServiceBooking === "error" 
          || flags.completeServiceBooking === "error"
          || flags.cancelServiceBooking === "error"
        }
        onClose={() => onReset()}
        success={flags.addServiceBooking === "processed" ? "success" : "error"}
        content={
          <Typography variant="h4" textAlign="center">
            {apiResponse}
          </Typography>
        }
      />
    </>
  )
}

CompleteServiceBookingForm.propTypes = {
  readOnly: PropTypes.bool,
  flags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  apiResponse: PropTypes.string.isRequired,
  // func
  onCompleteServiceBooking: PropTypes.func.isRequired,
  onCancelServiceBooking: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ reportVehicleServicing,settings }) => ({
  flags: reportVehicleServicing.flags,
  apiResponse: reportVehicleServicing.apiResponse || "",
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onCompleteServiceBooking: (payload) => {
    dispatch(completeServiceBooking(payload))
  },
  onCancelServiceBooking: (payload) => {
    dispatch(cancelServiceBooking(payload))
  },
  onReset: () => {
    dispatch(reset())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CompleteServiceBookingForm)