import MuiAvatar from './MuiAvatar'
import MuiAutocomplete from './MuiAutocomplete'
import MuiButton from './MuiButton'
import MuiCardActions from './MuiCardActions'
import MuiCardContent from './MuiCardContent'
import MuiCardHeader from './MuiCardHeader'
import MuiChip from './MuiChip'
import MuiIconButton from './MuiIconButton'
import MuiInputBase from './MuiInputBase'
import MuiLinearProgress from './MuiLinearProgress'
import MuiListItemIcon from './MuiListItemIcon'
import MuiMenu from './MuiMenu'
import MuiMenuItem from './MuiMenuItem'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiPaper from './MuiPaper'
import MuiTabs from './MuiTabs'
import MuiTableHead from './MuiTableHead'
import MuiTableCell from './MuiTableCell'
import MuiTableRow from './MuiTableRow'
import MuiToggleButton from './MuiToggleButton'
import MuiTypography from './MuiTypography'
import MuiPickersYear from './MuiPickersYear'


export default {
  MuiAvatar,
  MuiAutocomplete,
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaper,
  MuiTabs,
  MuiTableHead,
  MuiTableCell,
  MuiTableRow,
  MuiToggleButton,
  MuiTypography,
  MuiPickersYear
}
