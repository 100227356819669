import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Label, FleetIcon } from 'frame/components'
import { getDateColor } from 'listable'
import { toCurrency } from 'utils'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ account, item, columns }) => {
  //const isPremium = account === 'premium'
  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {item.agreementNo || '-'}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}      
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.extensions && (
        <TableCellView>
          {item.extensions ? toCurrency(item.extensions) : '-'}
        </TableCellView>
      )}
      {columns.refurbCosts && (
        <TableCellView>
          {item.refurbCosts ? toCurrency(item.refurbCosts) : '-'}
        </TableCellView>
      )}
      {columns.excessKmCost && (
        <TableCellView>
          {item.excessKmCost ? toCurrency(item.excessKmCost) : '-'}
        </TableCellView>
      )}
      {columns.underKmCredit && (
        <TableCellView>
          {item.underKmCredit ? toCurrency(item.underKmCredit) : '-'}
        </TableCellView>
      )}
      {columns.total && (
        <TableCellView>
          {item.total ? toCurrency(item.total) : '-'}
        </TableCellView>
      )}
      {columns.returnDate && (
        <TableCellView>
          {item.returnDate ? (
            <Label
              color={getDateColor(item.returnDate)}
              variant="outlined">
              {moment(item.returnDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {/* {(isPremium && columns.disposalDate) && (
        <TableCellView>
          {item.disposalDate ? (
            <Label
              color={getDateColor(item.disposalDate)}
              variant="outlined">
              {moment(item.disposalDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )} */}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
