import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Label, FleetIcon, StyledModal } from 'frame/components'
import { createRowSelector, useIsWhiteListed } from 'utils'
import { isUserIdAuth0, getRole, getUserStatusColor } from 'listable'
import { types } from '../../module'
import { hasRoles } from 'acl'
import { getUserStatus } from '../../utils'
import moment from 'moment'

import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, onView, userRoles, onDelete }) => {
  const isWhiteListedUser = useIsWhiteListed()
  const onSafeRowClick = createRowSelector(onView)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={item.invitationVerified || isUserIdAuth0(item.id) ? onSafeRowClick : undefined}>
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.nickname && (
        <TableCellView isFirst>
          {item.nickname || '-'}
        </TableCellView>
      )}
      {columns.email && (
        <TableCellView>
          {item.email || '-'}
        </TableCellView>
      )}
      {columns.role && (
        <TableCellView>
          {item.role ? getRole(item.role.name) : '-'}
        </TableCellView>
      )}
      {columns.fleets && (
        <TableCellView>
          {item.fleets && item.fleets.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                maxWidth: 200,
              }}
            >
              {item.fleets.map((node, index) =>
                <FleetIcon key={index} fleetName={node.name} />
              )}
            </Box>
          ) : (
            item.role && item.hasAccount && item.role.name.toLowerCase() === 'superadmin' ?  'Unrestricted Access' : ''
          )
        }
        </TableCellView>
      )}
      {columns.blocked && (
        <TableCellView>
          <Label
            color={getUserStatusColor(item.hasAccount, item.blocked)}
            variant="outlined">
             {getUserStatus(item)}
          </Label>
        </TableCellView>
      )}
      {columns.lastLogin && (
        <TableCellView>
          {item.lastLogin ? moment(item.lastLogin).format('DD/MM/YYYY hh:mm A') : '-'}
        </TableCellView>
      )}
      <TableCellView>
        {(!item.hasAccount || item.blocked) && hasRoles(['superadmin', 'fleetcontroller'], userRoles) && isWhiteListedUser &&
          <Button
            className="ignore"
            color="primary"
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}>
            Delete
          </Button>
        }
      </TableCellView>
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
      
      {/* for deleting user */}
      <StyledModal
        open={openDeleteModal}
        title={`Confirm Delete User`}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={() => onDelete(item.id)}>
        {`Please confirm to delete user ${item.nickname}.`}
      </StyledModal>
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Row
