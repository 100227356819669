import React from 'react'
import PropTypes from 'prop-types'
import MenuV5 from '@mui/material/Menu'
import { MenuItemView } from './popover/MenuItemView'

import { constants as fromRequestReliefVehicles } from 'features/request-relief-vehicles'
import { constants as fromRequestVehicleRelocations } from 'features/request-vehicle-relocations'
import { constants as fromRequestVehicleSummary } from 'features/request-vehicle-summary'

import { useEnabledFeatureToggleList } from 'features/settings'
import { useLocation } from "react-router"

const ActionsPopover = ({ anchor, linker, open, onClose }) => {
  const location = useLocation()
  const featureToggleList = useEnabledFeatureToggleList()
  const requestVehicleOrderToggle = featureToggleList.includes('Vehicle request form and report (TFP-2162)')

  if ( ! anchor.current) {
    return null
  }

  return (
    <MenuV5
      id="menu-reports"
      anchorEl={anchor.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: {
            '& ul': {
              padding: 0,
            }
          }
        },
      }}
    >
      <div>
        <MenuItemView
          onClick={linker(fromRequestReliefVehicles.PATH_ADD)}
          title="Request relief vehicle"
          isActive={fromRequestReliefVehicles.PATH_ADD === location.pathname}
        />
        <MenuItemView
          onClick={linker(fromRequestVehicleRelocations.PATH_ADD)}
          title="Request vehicle relocation"
          isActive={fromRequestVehicleRelocations.PATH_ADD === location.pathname}
        />
        { 
          requestVehicleOrderToggle &&
          <MenuItemView
            onClick={linker(fromRequestVehicleSummary.PATH_ADD)}
            title="Request Vehicle Order"
            isActive={fromRequestVehicleSummary.PATH_ADD === location.pathname}
          />
        }
      </div>
    </MenuV5>
  )
}

ActionsPopover.propTypes = {
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default ActionsPopover
