import React from 'react'
import moment from 'moment'
import { Label, FleetIcon } from 'frame/components'
import { useRouter } from 'utils'
import { getDateColor, obfuscateAgreementNumber } from 'listable'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow' 
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import TableCellView from 'frame/components/table-cell/table-cell'
import { themeV5 } from 'theme/v5'

const Row = ({ item, columns }) => {
  const { history, location } = useRouter()

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp1938FeatureToggle = featureToggleList.includes('TFP1938')
  const tfp1971FeatureToggle = featureToggleList.includes('TFP1971')

  const onViewDocumentsReport = () => { 
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Upcoming Maturities',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.assetBranch && tfp1938FeatureToggle && (
        <TableCellView>
          {item.assetBranch || '-'}
        </TableCellView>
      )}
      {columns.assetFunction && tfp1938FeatureToggle && (
        <TableCellView>
            {item.assetFunction || '-'}
        </TableCellView>
      )}       
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.maturityDate && (
        <TableCellView>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.originalMaturityDate && tfp1971FeatureToggle && (
        <TableCellView>
          {moment(item.originalMaturityDate).format('DD/MM/YY') || '-'}
        </TableCellView>
      )}      
      {columns.term && (
        <TableCellView>
          {item.term || '-'}
        </TableCellView>
      )}
      {columns.currentTerm && (
        <TableCellView>
          {item.currentTerm || '-'}
        </TableCellView>
      )}
      {columns.monthsOnVerbal && (
        <TableCellView>
          {item.monthsOnVerbal || '-'}
        </TableCellView>
      )}
      {columns.status && (
        <TableCellView>
          {item.status || '-'}
        </TableCellView>
      )}
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCellView>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton
                onClick={onViewDocumentsReport}
              >
                <InsertDriveFileOutlinedIcon sx={{ color: themeV5.palette.primary.main }}/>
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCellView>
      )}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
