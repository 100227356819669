import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { types } from "../../module"
import { useSelectedFleets, useSelectedFleetIds } from "features/settings"
import { lookupRequest } from "utils"

//import { useDebounce } from "@uidotdev/usehooks";
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Autocomplete from "@mui/material/Autocomplete"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import MuiTextfield from "frame/components/mui-textfield"
import MuiFormSelect from "frame/components/mui-form-select"
import InputAdornment from '@mui/material/InputAdornment';

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "./form-schema"
import { useCheckEmailAvailable } from "frame/hooks/useCheckEmailAvailable"

const Form = ({ error, values, setValues, disabled, onClose, onSubmit, setShowAssignForm }) => {
  const theme = useTheme()
  const userSelectedFleets = useSelectedFleets()
  const fleetIds = useSelectedFleetIds()

  const [submitting, setSubmitting] = useState(false)
  const [invalidFleetError, setShowInvalidFleetError] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [submitType, setSubmitType] = useState(null)

  const [emailValue, setEmailValue] = React.useState("");
  const debouncedEmail = useDebounce(emailValue, 500);

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const { isAlreadyExists } =
  useCheckEmailAvailable({
    // value: methods.getValues("email"),
    value: debouncedEmail === "" ? undefined : debouncedEmail,
    isValid: methods.formState.errors.email?.message === undefined,
  })


  const fleetOptions = userSelectedFleets.map(item => ({
    label: item.label,
    value: item.label,
  }))
  
  const onFleetChange = (value) => {
    const fleet = userSelectedFleets.filter(f => f.label === value)[0]
    const thirdPartyNumber = fleet ? fleet.value : null
    setValues({
      ...values,
      thirdPartyNumber: thirdPartyNumber,
      accountName: value ?? "",
    })
    methods.setValue("accountName", value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onInputChange = ({ target: { id, name, value } }) => {
    if (id === "email") {
      setEmailValue(value)
    }

    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSelectChange = (key) => (value) =>
    setValues({ ...values, [key]: value })

  const onSubmitCreate = (data) => {
    if (submitType === "create") {
      return onCreate()
    }

    return onCreateAssign()
  }

  const onCreateAssign = () => {
    setShowAssignForm(true)
  }

  const onCreate = () => {
    return onSubmit(values)
  }

  const triggerValidate = () => {
    methods.setError("email", {
      type: "root.random",
      message: "Email already exists",
    })
    methods.setError("emailValue", {
      type: "root.random",
      message: "Email already exists",
    })
    methods.trigger("driver")
    methods.trigger("accountName")
  }

  const validAccountName = values.accountName && values.accountName.length > 0

  useEffect(() => {
    isAlreadyExists && triggerValidate()
  }, [isAlreadyExists])
  
  useEffect(() => {
    loaded && setShowInvalidFleetError(!validAccountName)
    setLoaded(true)// eslint-disable-next-line 
  }, [validAccountName])

  useEffect(() => {
    if(!validAccountName && submitting) {
      setSubmitting(false)
      setShowInvalidFleetError(true)
    }// eslint-disable-next-line 
  }, [submitting])
        
  useEffect(() => {
    async function fetchAndsetThirdPartyNumber() {
      if(userSelectedFleets.length === 1) {
        const data = await lookupRequest(fleetIds, "/lookup/fleetId", userSelectedFleets[0].label)
        data && setValues({
          ...values,
          thirdPartyNumber: data.value || "-",
        })
      }
    }
    fetchAndsetThirdPartyNumber() // eslint-disable-next-line
  }, [])

  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "5px",
      }}
    >
      <FormProvider>
        <form
          id="drivers-add-form"
          onSubmit={methods.handleSubmit(onSubmitCreate)}
        >
          <CardContent
            sx={{
              p: 3
            }}
          >
            <Stack gap={2} width={{ xs: "100%", md: "50%" }}>
              <Stack>
                <Typography variant="h5" fontWeight={600}>
                  New Driver Details
                </Typography>
              </Stack>
              
              <Stack gap={2}>
                {/* Fleet */}
                <Stack gap={1}>
                  <Controller
                    id="accountName"
                    name="accountName"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={fleetOptions}
                        getOptionLabel={option => option.label || ""}
                        onSelect={(event) => {
                          onFleetChange(event.target.value)
                        }}
                        onChange={(event) => {
                          onFleetChange(event.target.value)
                        }}
                        renderInput={params => (
                          <MuiTextfield
                            {...params}
                            error={Boolean(methods.formState.errors?.accountName?.message)}
                            label="Fleet*"
                            value={values.accountName || ""}
                          />
                        )}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.accountName?.message}
                  </Typography>
                </Stack>
                {/* Name/Driver */}
                <Stack gap={1}>
                  <Controller
                    name="driver"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="driver"
                        error={Boolean(methods.formState.errors?.driver?.message)}
                        label="Name*"
                        value={values.driver || ""}
                        onChange={onInputChange}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.driver?.message}
                  </Typography>
                </Stack>
                {/* Email */}
                <Stack gap={1}>
                  <Controller
                    id="email"
                    name="email"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="email"
                        error={
                          Boolean(methods.formState.errors?.email?.message)
                        }
                        label="Email*"
                        value={values.email || ""}
                        onChange={onInputChange}
                        // onBlur={handleEmailAvailability}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.email?.message}
                  </Typography>
                </Stack>
                {/* Mobile */}
                <hr />
                <Stack gap={1}>
                  <Controller
                    name="mobile"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="mobile"
                        error={Boolean(methods.formState.errors?.mobile?.message)}
                        label="Mobile"
                        type="tel"
                        value={values.mobile || ""}
                        onChange={onInputChange}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.mobile?.message}
                  </Typography>
                </Stack>
                {/* Notification */}
                <Stack gap={1}>
                  <Controller
                    name="driverNotification"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiFormSelect
                        {...field}
                        id="driverNotification"
                        disabled={disabled}
                        label="Notification Method"
                        labelId="driverNotification-label"
                        options={["Email", "Text", "In App", "N/A"]}
                        value={values.driverNotification || "In App"}
                        onChange={(event) =>
                          onSelectChange("driverNotification", event?.target?.value)
                        }
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              gap={1}
            >
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="error"
                  disabled={disabled}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
              <Stack flexDirection="row" gap={2}>
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    type={isAlreadyExists ? "button" : "submit" }
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSubmitType("create")
                    }}
                  >
                    {"Create"}
                  </Button>
                </Box>

                <Box>
                  <Button
                    fullWidth
                    type={isAlreadyExists ? "button" : "submit" }
                    color="primary"
                    variant="contained"
                    onClick={() => setSubmitType("create-assign")}
                  >
                    {"Create and Assign Vehicle"}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </form>
      </FormProvider>
    </Card>
  )
}

Form.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setShowAssignForm: PropTypes.func,
}

export default Form
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}