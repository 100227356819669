import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useNavigate } from 'utils'
import AddBookingDetailsForm from './form/add-booking-details-form'
import CompleteServiceBookingForm from './form/complete-service-booking-form'
import AddServiceRecordForm from './form/add-service-record-form'
import { fetchServiceBooking, reset } from 'features/report-vehicle-servicing/actions'
import moment from 'moment'
import { constants } from '../../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'

import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import ServiceBookingsContent from "./service-bookings"
import StyledModalV2 from 'frame/components/styled-modal-v2'
import Typography from "@mui/material/Typography"

const viewConstants = {
  SERVICE_BOOKING_MENU: 'MENU', // default
  ADD_BOOKING_DETAILS_FORM: 'ADD_BOOKING_DETAILS_FORM',
  ADD_SERVICE_RECORD_FORM: 'ADD_SERVICE_RECORD_FORM',
  VIEW_SERVICE_BOOKING: 'VIEW_SERVICE_BOOKING',
}

const ServiceBookings = ({ id, flags, apiResponse, driverData, fleetData, serviceBookingData, onReset, onFetchServiceBooking,userRoles }) => {

  const theme = useTheme()
  const navigate = useNavigate()

  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const disabled = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures //false
  
  const disableButtons = disabled ? true : (userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles))

  const [activeView, setActiveView] = useState(viewConstants.SERVICE_BOOKING_MENU)
  
  useEffect(() => { // on load
    onReset() // reset flags
    onFetchServiceBooking(id) // fetch service booking data
  }, []) // eslint-disable-line
  
  useEffect(() => { // refetches service booking on add/cancel/complete service booking
    if(flags?.completeServiceBooking === 'processed' || flags?.cancelServiceBooking === 'processed' || flags?.addServiceBooking === 'processed') {
      onFetchServiceBooking(id)
    }
    if(flags?.cancelServiceBooking === 'processed' || flags?.completeServiceBooking === 'processed') {
      setActiveView(viewConstants.SERVICE_BOOKING_MENU)
    }
    if (window.location.pathname.includes(constants.PATH_SERVICE_BOOKINGS_ADD_SERVICE_RECORD)){
      setActiveView(viewConstants.ADD_SERVICE_RECORD_FORM)
    }
  }, [flags?.completeServiceBooking, flags?.cancelServiceBooking, flags?.addServiceBooking]) // eslint-disable-line
  
  // service booking exists - in the future
  const serviceBookingExistsInTheFuture = serviceBookingData && serviceBookingData.bookedDate && moment(serviceBookingData.bookedDate).isAfter(moment())
  
  if(serviceBookingExistsInTheFuture) { // is in the future
    return (
      <AddBookingDetailsForm
        readOnly
        fleetData={fleetData}
        serviceBookingData={serviceBookingData}
      />
    )
  }

  // service booking exists - today or past
  if(serviceBookingData && serviceBookingData.bookedDate
    && (moment(serviceBookingData.bookedDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') // is today
        || moment(serviceBookingData.bookedDate).isBefore(moment()))) {  // is in the past
    return (
      <CompleteServiceBookingForm
        readOnly={disableButtons}
        fleetData={fleetData}
        serviceBookingData={serviceBookingData}
      />
    )
  }
  /* 
  */

  return (
    <>    
      {activeView === viewConstants.ADD_BOOKING_DETAILS_FORM ? (
        <AddBookingDetailsForm        
          fleetData={fleetData}
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
          }}
        />
      ) : activeView === viewConstants.ADD_SERVICE_RECORD_FORM ? (
        <AddServiceRecordForm
          fleetData={fleetData}
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
            navigate(`${constants.PATH_ALL}/${id}${constants.PATH_SERVICE_BOOKINGS}`)()
          }}
        />
      ) : (
        <ServiceBookingsContent>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={{ xs: 1, md: 2 }}>
            <Stack minWidth={{ xs: '100%', md: '205px' }}>
              <Button
                // disabled={disableButtons}
                color="primary"
                variant="contained"
                onClick={() => setActiveView(viewConstants.ADD_BOOKING_DETAILS_FORM)}
              >
                Add Booking Details
              </Button>
            </Stack>
            <Stack minWidth={{ xs: '100%', md: '205px' }}>
              <Button
                // disabled={disableButtons}
                color="primary"
                variant="contained"
                onClick={() => {
                  onReset()
                  setActiveView(viewConstants.ADD_SERVICE_RECORD_FORM)
                }}
              >
                Add Service Record
              </Button>
            </Stack>
          </Stack>
        </ServiceBookingsContent>
      )}

      <StyledModalV2 
        open={flags.cancelServiceBooking === 'processed' || flags.cancelServiceBooking === 'error' || flags?.completeServiceBooking === 'processed'
        || (serviceBookingExistsInTheFuture && (flags.addServiceBooking === 'processed' || flags.addServiceBooking === 'error'))}
        onClose={() => onReset()}
        status={
          flags.cancelServiceBooking === 'processed' ||
          flags.addServiceBooking === 'processed' ||
          flags?.completeServiceBooking === 'processed'
          ? "success"
          : "error"
        }
        content={
          <Typography variant="h4" textAlign="center">
            {apiResponse}
          </Typography>
        }
      />
    </>
  )
}

ServiceBookings.propTypes = {
  id: PropTypes.string.isRequired,
  flags: PropTypes.object.isRequired,
  apiResponse: PropTypes.string.isRequired,
  driverData: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  serviceBookingData: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

  // func
  onReset: PropTypes.func.isRequired,
  onFetchServiceBooking: PropTypes.func.isRequired,
}

const mapStateToProps = ({ reportVehicleServicing, fleet,settings }, ownProps) => ({
  id: ownProps.match.params.id,
  flags: reportVehicleServicing.flags,
  apiResponse: reportVehicleServicing.apiResponse || '',
  driverData: fleet.related,
  fleetData: fleet.data?.[ownProps.match.params.id],
  serviceBookingData: reportVehicleServicing?.relatedServiceBooking || {},
  userRoles: settings.roles,

})

const mapDispatchToProps = (dispatch) => ({
  onFetchServiceBooking: (agreementNo) => {
    dispatch(fetchServiceBooking(null, { resourceId: agreementNo }))
  },
  onReset: () => {
    dispatch(reset())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceBookings))
