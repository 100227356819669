import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const hasList = (list) => list.length > 0
  const { costCentres, effectiveDates, status } = filters

  const onSelectChange = (key) => (value) =>
    onFilter({
      [key]: value,
    })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
      <Box>
        {hasList(costCentres) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="costCentre"
              label="Cost Centre"
              options={costCentres}
              value={filtered.costCentre}
              tight
              onChange={onSelectChange('costCentre')} />
          </Box>
        )}
        {hasList(effectiveDates) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="effectiveDates"
              label="Effective Date"
              options={effectiveDates}
              value={filtered.effectiveDate}
              tight
              onChange={onSelectChange('effectiveDate')} />
          </Box>
        )}
        {hasList(status) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="status"
              label="Status"
              options={status}
              value={filtered.status}
              tight
              onChange={onSelectChange('status')} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
