import React from "react";
import { useTheme } from "@mui/material/styles"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const VariationDetails = ({
  items,
  maturityDateExpired
}) => {
  const theme = useTheme()
  return (
    <Stack>
      <Stack
        borderRadius="5px"
        border="1px solid"
        borderColor={theme.palette.accent.sky}
        p={3}
        flexDirection={{ xs: "column", md: "row" }}
        flexWrap="wrap"
        gap={3}
      >
        {items?.map((item, index) => (
          <Stack
            key={`contract-details-${index}`}
            flex={1}
            minWidth={{ xs: "100%", md: "calc(100%/3)" }}
            maxWidth={{ xs: "100%", md: "calc(100%/3)" }}
          >
            <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
              {item?.label}
            </Typography>
            {item?.label === "Maturity Date" ? (
              <>
                {maturityDateExpired ? (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Typography variant="bodyLarge">
                      {item?.value}
                    </Typography>
                    <Stack
                      paddingX={1}
                      borderRadius="5px"
                      border="1px solid"
                      borderColor={theme.palette.status.error}
                    >
                      <Typography variant="bodySmall" color={theme.palette.status.error}>
                        Expired
                      </Typography>
                    </Stack>
                  </Stack>
                ): (
                  <Typography variant="bodyLarge">
                    {item?.value}
                  </Typography>
                )}
              </>
            ): (
              <Typography variant="bodyLarge">
                {item?.value}
              </Typography>
            )}
          </Stack>
        ))}

      </Stack>
    </Stack>
  )
}

export default VariationDetails