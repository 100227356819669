import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { constants as fromDrivers } from 'features/drivers'
import { useNavigate } from 'utils'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

const useStyles = makeStyles(theme => ({
  text: {
    color: '#FFF',
    fontSize: '12px',
  },
  imageContainer: {
    textAlign: 'center',
  },
  promoImage: {
    
  },
  actions: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  button: {
    minWidth: 180,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
}))

const PromoCard = () => {

  const theme = useTheme()
  const classes = useStyles()
  const tightView = useMediaQuery(theme.breakpoints.down(1600))
  const navigate = useNavigate()

  const onInviteDriver = navigate(fromDrivers.PATH_ALL)
  
  return (
    <Card
      sx={{
        height: { xs: '100%', sm: '440px' },
        maxHeight: '440px',
        backgroundColor: '#002849',
        backgroundSize: '250px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '-50px -30px',
        backgroundImage: 'url("/static/images/marketing/car.png")',
      }}
    >
      <CardContent>
        <Stack flexDirection={{ xs: "column", sm: "row" }} gap={2}>
          <Stack flex={1} maxWidth="50%">
            <Box
              component="img"
              src="/static/images/marketing/dashboard-app-promo.png" alt=""
              sx={{
                marginTop: '30px',
              }}
            />
          </Stack>
          <Stack flex={2}>
            <Typography
                sx={{
                  color: '#FFF',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  [theme.breakpoints.down(1600)]: { marginTop: '20px', textAlign: 'center' },
                  [theme.breakpoints.down('sm')]: { textAlign: 'left' },
                  [theme.breakpoints.down('xs')]: { textAlign: 'center' },
                }}
              >
                Toyota MyDrive
              </Typography>
              <br />
              <Typography
                sx={{
                  color: '#FFF',
                  fontSize: '12px',
                }}
              >
                The MyDrive app provides drivers with a holistic view of their vehicle for the duration of its lifecycle. Whether you are looking to keep up to date with general health and safety obligations, manage incidents or simply update vehicle mileage, the interactive dashboards and in-life notifications will ensure important events are always visible to you. 
                <br /><br />
                Direct integration with the ToyotaFleet Portal ensures that all updates recorded in app are transferred and stored in your ToyotaFleet Portal account, helping you stay on top of managing your fleet.
              </Typography>

              <Box
                sx={{
                  display: 'block',
                  marginTop: 1,
                }}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onInviteDriver}
                  sx={{
                    minWidth: { xs: '100%', sm: '180px' },
                    marginTop: theme.spacing(1),
                  }}
                >
                  Invite Driver
                </Button>
              </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default PromoCard
