

import React, { Fragment } from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import moment from 'moment'

const VehicleWof = ({ data }) => {
  const theme = useTheme()

  const noData = !data.wofExpiry
  const isExpired = moment(data.wofExpiry).isBefore(moment())
  const wofMonth = data.wofExpiry ? Number(moment(data.wofExpiry).format('M')) : 0

  const ViewHoles = ({ children, value }) => {
    return (
      <Stack
        minWidth="24px"
        minHeight="24px"
        bgcolor={wofMonth === value ? theme.palette.accent.teal : theme.palette.white}
        borderRadius="100%"
        border="2px solid"
        borderColor={theme.palette.accent.teal}
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        {children}
      </Stack>
    )
  }

  const ViewVerticalHoles = () => {
    return (
      <>
        {[...Array(5)]?.map((value, index) => (
          <Fragment key={`punch-holes-vertical-${value}`}>
            <ViewHoles value={index}>
              <Typography
                variant="bodyMedium"
                color={wofMonth === value ? theme.palette.brand.white : theme.palette.accent.teal}
              >
                {index + 1}
              </Typography>
            </ViewHoles>
          </Fragment>
        ))}
      </>
    )
  }

  const ViewHorizontalHoles = () => {
    return (
      <>
        {[...Array(7)]?.map((value, index) => (
          <Fragment key={`punch-holes-horizontal-${value}`}>
            <ViewHoles value={index + 6}>
              <Typography
                variant="bodyMedium"
                color={wofMonth === index + 6 ? theme.palette.brand.white : theme.palette.accent.teal}
              >
                {index + 6}
              </Typography>
            </ViewHoles>
          </Fragment>
        ))}
      </>
    )
  }

  const ViewNoData = () => {
    if (!noData) {
      return null
    }

    return (
      <Stack>
        <Typography
          variant="h5"
          color={theme.palette.brand.red}
          fontWeight={600}
          textAlign="center"
        >
          No Warrant of Fitness
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.brand.red}
          fontWeight={600}
          textAlign="center"
        >
          data available
        </Typography>
      </Stack>
    )
  }

  const ViewMain = () => {
    if (noData) {
      return null
    }

    return (
      <Stack gap={1}>
        <Stack flexDirection="row" gap={1}>
          <Stack gap={1}>
            <ViewVerticalHoles />
          </Stack>
          <Stack
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              color={theme.palette.black}
              fontWeight={600}
            >
              Warrant of Fitness
            </Typography>
            <Typography
              variant="h1"
              fontWeight={600}
              color={theme.palette.black}
            >
              {data.wofExpiry ? moment(data.wofExpiry).format('YYYY') : <span>Expired</span>}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          gap={1}
        >
          <ViewHorizontalHoles />
        </Stack>
        {isExpired && (
          <Typography
            variant="h5"
            color={theme.palette.brand.red}
            textAlign="center"
            fontWeight={600}
          >
            Expired {data.wofExpiry && moment(data.wofExpiry).format('DD/MM/YYYY')}
          </Typography>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      border={isExpired ? '4px solid' : '2px solid'}
      borderColor={isExpired ? theme.palette.brand.red : theme.palette.accent.sky}
      padding={2}
      borderRadius={1}
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <ViewNoData />
      <ViewMain />
    </Stack>  
  )
}

export default VehicleWof