import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledModal, PageLoader } from 'frame/components'
import { fbtReasonOptions } from '../actions/types'

import CreateIcon from '@material-ui/icons/CreateOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Stack from "@mui/material/Stack"
import TableCellView from "frame/components/table-cell"
import Typography from '@mui/material/Typography'

import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

const getRanges = (array) => {
  let ranges = [], rstart, rend
  if(array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      rstart = array[i]
      rend = rstart
      while (array[i + 1] - array[i] === 1) {
        rend = array[i + 1] // increment the index if the numbers sequential
        i++
      }
      ranges.push(rstart === rend ? rstart + '' : rstart + '-' + rend)
    }
  }
  return ranges
}

const FbtSubmissionsTable = ({ data = [], loading = false, onSave, onEdit, onDelete,userRoles }) => {
  const [deleteSubmission, setDeleteSubmission] = useState()

  const headers = [
    { title: 'Dates', align: 'center' },
    { title: 'Reasons', align: 'left' },
    { title: 'Actions', align: 'center' },
  ]

  const useLastEntrySavePrompt = data.length === 1 && onSave // only used in edit modal
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) : true
  const onConfirmDelete = () => {    
    onDelete(deleteSubmission)  // normal entry delete
    setDeleteSubmission(null)
    useLastEntrySavePrompt && onSave(true) // on last entry delete - delete, save, close modal
  }

  return (
    <>
    <Stack gap={2}>
      <TableContainer>
        <Table stickyHeader aria-label="service history table"
          sx={{
            p: 2,
            borderSpacing: "0 5px",
          }}
        >
          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableCellView 
                  key={`table-heading-${index}`}
                  sx={{ bgcolor: "white" }}
                >
                  {item.title}
                </TableCellView>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow >
                  <TableCellView colSpan={3}>
                    <PageLoader minHeight={220}/>
                  </TableCellView>
              </TableRow>
            ) : data && data.length > 0 ? data.map((record, rowIndex) => (
              <TableRow 
                hover
                tabIndex={-1}
                key={`table-row-${rowIndex}`}
                sx={{
                  height: "40px",
                  borderBottom: "5px solid white",
                }}
              >
                <TableCellView key={`table-days-column-${rowIndex}`} isFirst>
                  {record?.days && record?.days.length > 0 ? getRanges(record?.days).join(', ') : '-'}
                </TableCellView>

                <TableCellView key={`table-reason-column-${rowIndex}`}>
                  {fbtReasonOptions.includes(record?.reason)
                    ? record?.reason
                    : !record?.reason.includes('Other: ')
                      ? `Other: ${record?.reason}`
                      : record?.reason || '-'}
                </TableCellView>

              {showButtons &&
                <TableCellView key={`table-edit-column-${rowIndex}`} isLast>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => onEdit(record)} size="small" color='primary'>
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => setDeleteSubmission(record)} size="small" color='error'>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCellView> 
                }

              </TableRow>
            )) : (
              <TableRow>
                <TableCellView colSpan={headers.length}>
                  No Records.
                </TableCellView>
              </TableRow>
            )}
            
          </TableBody>

        </Table>
      </TableContainer>
    </Stack>
      {/* Confirm Delete Modal */}
      <StyledModalV2
        open={!!deleteSubmission}
        title={useLastEntrySavePrompt ? `Confirm Delete and Save Submission` : `Confirm Delete Submission`}
        onClose={() => setDeleteSubmission(null)}
        content={
          <Typography variant="h4" textAlign="center">
            {`Please confirm to delete submission for ${deleteSubmission?.days.length > 1 ? 'dates': 'date'} ${getRanges(deleteSubmission?.days).join(', ')}`}
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setDeleteSubmission(null)}
            onConfirm={onConfirmDelete}
          />
        }
      />
    </>
  )
}

FbtSubmissionsTable.propTypes = {
  data: PropTypes.array,
  onSave: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FbtSubmissionsTable