
import palette from "../palette"

export default {
  styleOverrides: {
    root: {
      borderBottom: "1px solid",
      borderColor: palette.accent.sky,
    },
  }
}

