import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'


const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  console.log(filters)
  const { costCentres, makes, models, maturityStatuses, wofStatuses, registrationStatuses, fleetContollers, agreementTypes, fmoTypes } = filters

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
        <Box>

          {hasList(costCentres) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                tight
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </Box>
          )}
          {hasList(makes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="make"
                label="Make"
                options={makes}
                tight
                value={filtered.make}
                onChange={onSelect('make')} />
            </Box>
          )}
          {hasList(models) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="model"
                label="Model"
                options={models}
                tight
                value={filtered.model}
                onChange={onSelect('model')} />
            </Box>
          )}
          {hasList(maturityStatuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="maturityStatus"
                label="Maturity Status"
                options={maturityStatuses}
                tight
                value={filtered.maturityStatus}
                onChange={onSelect('maturityStatus')} />
            </Box>
          )}
          {hasList(wofStatuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="wofStatus"
                label="WoF Status"
                options={wofStatuses}
                tight
                value={filtered.wofStatus}
                onChange={onSelect('wofStatus')} />
            </Box>
          )}
          {hasList(registrationStatuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="registrationStatus"
                label="Registration Status"
                options={registrationStatuses}
                tight
                value={filtered.registrationStatus}
                onChange={onSelect('registrationStatus')} />
            </Box>
          )}
          {hasList(fleetContollers) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="fleetContoller"
                label="Controller"
                options={fleetContollers}
                tight
                value={filtered.fleetContoller}
                onChange={onSelect('fleetContoller')} />
            </Box>
          )}
          {hasList(agreementTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                tight
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </Box>
          )}    
          {hasList(fmoTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                tight
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </Box>
          )}
        </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
