import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../../module'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

import { FormContext } from '../form'

import Button from "@mui/material/Button"
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MuiFormSelect from 'frame/components/mui-form-select'
import KeyValue from 'frame/components/mui-textfield'
import { useTheme } from '@mui/material/styles'

const VehicleInformation = ({ onSubmit }) => {
  const formContext = useContext(FormContext);
  const theme = useTheme()
  const navigate = useNavigate()
  const { match } = useRouter()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const requestTypeNew = 'New/Extra vehicle';

  const {
    values,
    onInputChange,
    data,
    setValues,
  } = formContext

  const isEdit = data?.vehicleOrderDetails?.status === "Draft" || data?.vehicleOrderDetails?.status === null

  const handleCopyAllFields = () => {
    values.make = values.make1;
    values.model = values.model1;
    values.variant = values.variant1;
    values.colour = values.colour1;
    values.drivetrain = values.drivetrain1;
    values.transmission = values.transmission1;
  };

  const onClose = navigate(constants.PATH_ALL)

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    navigate(constants.PATH_ALL);
  };

  const onSubmitForm = () => {
    setValues({
      ...values,
      make: values?.make,
      variant: values?.variant,
      colour: values?.colour,
      driveTrain: values?.driveTrain,
      transmission: values?.transmission,
      vehicleNotes: values?.vehicleNotes,
      id: values?.id,
    });
  }

  useEffect(() => {
    if (!data) {
      return
    }

    const vehicleOrderDetails = data?.vehicleOrderDetails;
    setValues((values) => ({
      ...values,
      make: vehicleOrderDetails?.make,
      model: vehicleOrderDetails?.model,
      variant: vehicleOrderDetails?.variant,
      colour: vehicleOrderDetails?.colour,
      driveTrain: vehicleOrderDetails?.driveTrain,
      transmission: vehicleOrderDetails?.transmission,
      vehicleNotes: vehicleOrderDetails?.vehicleNotes,
      id: vehicleOrderDetails?.id,
    }))
  }, [data])

  return (
    <Stack>
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <>
          {!isEdit && (
            <>
              {(values.requestType !== requestTypeNew || values.vehicleRegistration) && (
                <>
                  <Stack width="50%">
                    <Stack flex={1}>
                      <Typography variant="h5" fontWeight={600}>
                        {values?.vehicleRegistration?.value} {values?.year} {values?.make1} {values?.model1} {values?.colour1}
                      </Typography>
                    </Stack>
                    <Stack flex={1} paddingTop={2}>
                      <KeyValue
                        id="make1"
                        label='Make'
                        disabled={true}
                        value={values.make1 || ''}
                      />
                    </Stack>
                    <Stack flex={1} paddingTop={2}>
                      <KeyValue
                        id="model1"
                        label='Model'
                        disabled={true}
                        value={values.model1 || ''}
                      />
                    </Stack>
                    <Stack flex={1} paddingTop={2}>
                      <KeyValue
                        id="variant1"
                        label='Variant'
                        disabled={true}
                        value={values.variant1 || ''}
                      />
                    </Stack>
                    <Stack flex={1} paddingTop={2}>
                      <KeyValue
                        id="colour1"
                        label='Colour'
                        disabled={true}
                        value={values.colour1 || ''}
                      />
                    </Stack>
                    <Stack flex={1} paddingTop={2}>
                      <MuiFormSelect
                        id="drivetrain1"
                        disabled={true}
                        label="Drive Train"
                        labelId="drivetrain1-label"
                        options={["Petrol", "Diesel", "Hybrid", "Plug-in Hybrid", "Electric"]}
                        value={values.drivetrain1 || ''}
                      />
                    </Stack>
                    <Stack flex={1} paddingTop={2}>
                      <MuiFormSelect
                        id="transmission1"
                        disabled={true}
                        label="Transmission"
                        labelId="transmission1-label"
                        options={["Manual", "Auto"]}
                        value={values.transmission1 || ''}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
            </>
          )}
        </>
        <Stack width="50%">
          <Stack flex={1}>
            {!isEdit && (
              <Typography variant="h5" fontWeight={600}>
                {"New Request"}
              </Typography>
            )}
          </Stack>
          <Stack flex={1} paddingTop={2}>
            <KeyValue
              id="make"
              label='Make'
              value={values.make || ''}
              inputProps={{ maxLength: 50 }}
              onChange={(event) => {
                setValues({
                  ...values,
                  make: event?.target?.value
                })
              }}
            />
          </Stack>
          <Stack flex={1} paddingTop={2}>
            <KeyValue
              id="model"
              label='Model'
              value={values.model || ''}
              inputProps={{ maxLength: 50 }}
              onChange={onInputChange}
            />
          </Stack>
          <Stack flex={1} paddingTop={2}>
            <KeyValue
              id="variant"
              label='Variant'
              value={values.variant || ''}
              inputProps={{ maxLength: 50 }}
              onChange={onInputChange}
            />
          </Stack>
          <Stack flex={1} paddingTop={2}>
            <KeyValue
              id="colour"
              label='Colour'
              value={values.colour || ''}
              inputProps={{ maxLength: 50 }}
              onChange={onInputChange}
            />
          </Stack>
          <Stack flex={1} paddingTop={2}>
            <MuiFormSelect
              id="driveTrain"
              disabled={false}
              label="Drive Train"
              labelId="drivetrain-label"
              options={["Petrol", "Diesel", "Hybrid", "Plug-in Hybrid", "Electric"]}
              value={values.driveTrain || ''}
              onChange={( event ) => {
                setValues({
                  ...values,
                  driveTrain: event?.target?.value
                })
              }}
            />
          </Stack>
          <Stack flex={1} paddingTop={2}>
            <MuiFormSelect
              id="transmission"
              disabled={false}
              label="Transmission"
              labelId="transmission-label"
              options={["Manual", "Auto"]}
              value={values.transmission || ''}
              onChange={( event ) => {
                setValues({
                  ...values,
                  transmission: event?.target?.value
                })
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <>
        {!isEdit && (
          <>
            {(values.requestType !== requestTypeNew || values.vehicleRegistration) && (
              <>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2} paddingBottom={2}>
                  <Stack width={{ xs: "100%", md: "205px" }}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="outlined"
                      onClick={handleCopyAllFields}
                    >
                      Copy All Fields
                    </Button>
                  </Stack>
                </Stack>
              </>
            )}
          </>
        )}
      </>
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
        <Stack width="100%">
          <KeyValue
            id="vehicleNotes"
            label='Notes'
            value={values.vehicleNotes || ''}
            inputProps={{ maxLength: 50 }}
            onChange={onInputChange}
          />
        </Stack>
      </Stack>
      <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

      <StyledModalV2
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setConfirmDialogOpen(false)}
            onConfirm={handleConfirmClose}
          />
        }
      />
      <Stack
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        gap={1}
      >
        <Stack width={{ xs: "100%", md: "205px" }}>
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          gap={1}
        >
          <Stack width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="outlined"
              onClick={() => onSubmitForm()}
            >
              Save and Exit
            </Button>
          </Stack>
          <Stack width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              onClick={() => navigate(`${match.url}${constants.PATH_ADDITIONAL_SERVICES}`)(0)}
            >
              Proceed
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

VehicleInformation.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
}

export default VehicleInformation