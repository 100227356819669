import React, {useContext} from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../../module'

import { AdditionalServicesForm } from "./additional-services-form"
import { useTheme } from "@mui/material/styles"
import { FormContext } from '../form'

const AdditionalServices = () => {
  const formContext = useContext(FormContext);
  const navigate = useNavigate()
  const { match } = useRouter()
  const theme = useTheme()
  const {
    transferValues,
  } = formContext

  const [currentServices, setCurrentServices] = React.useState({
    FuelCardAndReporting: false,
    ReliefVehicle: false,
    RUCManagement: false,
    AccidentManagement: false,
    TollManagementRecharge: false,
    InfringementManagementRecharge: false,
    FBTReporting: false,
    Telematics: false,
    FleetPortal: false,
    DriverApp: false,
  })

  const [additionalServices, setAdditionalServices] = React.useState({
    FuelCardAndReporting: false,
    ReliefVehicle: false,
    RUCManagement: false,
    AccidentManagement: false,
    TollManagementRecharge: false,
    InfringementManagementRecharge: false,
    FBTReporting: false,
    Telematics: false,
    FleetPortal: false,
    DriverApp: false,
  })

  const handleCopy = () => {
    setAdditionalServices(currentServices)
  }

  const handleProceed = () => {
    console.log(`${match.url}${constants.PATH_ACCESSORIES}`)
    navigate(`${match.url}${constants.PATH_ACCESSORIES}`)(0)
  }

  return (
    <Stack>
      <Stack
        flexDirection={{ xs: "column", md: "row" }} gap={2}
      >
        <Stack flex={1}>
          <AdditionalServicesForm
            title={transferValues?.vehicleregistration}
            services={currentServices}
            setServices={setCurrentServices}
          />
        </Stack>
        <Stack flex={1}>
          <AdditionalServicesForm
            title="Additional Services Information"
            services={additionalServices}
            setServices={setAdditionalServices}
          />
        </Stack>
      </Stack>
      <Stack py={3} maxWidth={206}>
        <Button variant="outlined" onClick={handleCopy}>
          Copy All Services
        </Button>
      </Stack>

      <Divider sx={{ mb: 2, borderColor: theme.palette.accent.sky }} />

      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap={1}
      >
        <Box width={{ xs: "100%", md: "205px" }}>
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={() => console.log('close')}
          >
            Close
          </Button>
        </Box>
        <Stack flexDirection="row" gap={2}>
          <Box width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => console.log("save and exit")}
            >
              Save and Exit
            </Button>
          </Box>
          <Box width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => handleProceed()}
            >
              Proceed
            </Button>
          </Box>
        </Stack>
      </Stack>

    </Stack>
  )
}

export default AdditionalServices