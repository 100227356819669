import { connect } from 'react-redux'
import New from './new'
import { 
  createLeaseInformation, 
  createVehicleInformation,
  createAdditionalServices,
  createAccessories,
  fetchContract, 
  lookup, 
  resetFlags 
} from '../../actions'


const mapStateToProps = ( settings, requestVehicleSummary ) => {
  const fleetIds = settings.fleet.selected
  const relatedContract = requestVehicleSummary.related
  const fetchContractStatus = requestVehicleSummary.flags?.fetchContract || 'none'
  const saveStatus = requestVehicleSummary.flags?.create || 'none'
  const errorMessage = requestVehicleSummary.errorMessage
  const userRoles = settings.roles

  return {
    fleetIds,
    relatedContract,
    fetchContractStatus,
    saveStatus,
    errorMessage,
    userRoles
  }
}

const mapDispatchToProps = (dispatch) => ({
  onFetchContract: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetchContract(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
  onSaveLeaseInformation: (payload) => {
    dispatch(createLeaseInformation(payload))
  },
  onSaveVehicleInformation: (payload) => {
    dispatch(createVehicleInformation(payload))
  },
  onSaveAdditionalServices: (payload) => {
    dispatch(createAdditionalServices(payload))
  },
  onSaveAccessories: (payload) => {
    dispatch(createAccessories(payload))
  },
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
  onReset: () => {
    dispatch(resetFlags())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(New)
