import React from "react"
import Card from "@mui/material/Card"
import MuiCardContent from "@mui/material/CardContent"

const CardContent = ({
  children,
  sx
}) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "5px",
        ...sx
      }}
    >
      <MuiCardContent
        sx={{
          p: 3,
        }}
      >
        {children}
      </MuiCardContent>
    </Card>
  )
}

export default CardContent