import React from 'react'
import PropTypes from 'prop-types'
import { types } from '../../module'
import { createRowSelector } from 'utils'
import { fbtMonthName } from 'listable'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, onEdit }) => {

  const onSafeRowClick = createRowSelector(onEdit)

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />

      {columns.driverName && (
        <TableCellView isFirst>
          {item.driverName || '-'}
        </TableCellView>
      )}

      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}

      {/* {columns.totalDaysVehicleAvailable && (
        <TableCellView>
          {item.totalDaysVehicleAvailable || '-'}
        </TableCellView>
      )}       */}
      {columns.exemptDays && (
        <TableCellView>
        {item.exemptDays === null ? '-' : item.exemptDays}
        </TableCellView>
      )}
      {/* {columns.daysAvailableForPvtUse && (
        <TableCellView>
          {item.daysAvailableForPvtUse || '-'}
        </TableCellView>
      )} */}
      {columns.exemptNotes && (
        <TableCellView>
          {item.exemptNotes || '-'}
        </TableCellView>
      )}
      {/* {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )} */}
      {columns.month && (
        <TableCellView>
          {fbtMonthName(item.month) || '-'} 
        </TableCellView>
      )}
      {/* {columns.rego && (
        <TableCellView>
          {item.rego || '-'}
        </TableCellView>
      )}
      {columns.vehicleYear && (
        <TableCellView>
          {item.vehicleYear || '-'}
        </TableCellView>
      )} */}

      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      } 
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default Row
