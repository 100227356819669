import React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

const TableCellView = ({
  children,
  isCenter = false,
  isFirst = false,
  isLast = false,
  ...props
}) => {
  const theme = useTheme()
  const Wrapper = ({ children }) => {
    return (
      <TableCell
        sx={{
          borderTop: 'none',
          borderBottom: 'none',
          px: 2,
          py: 1,
          background: theme.palette.neutral.mid500,
          borderTopLeftRadius: isFirst ? '8px' : '0px',
          borderBottomLeftRadius: isFirst ? '8px' : '0px',
          borderTopRightRadius: isLast ? '8px' : '0px',
          borderBottomRightRadius: isLast ? '8px' : '0px',
          lineHeight: 0.8,
          ...props.sx,
        }}
      >
        {children}
      </TableCell>
    )
  }

  const View = () => {
    return (
      <>
        <Typography variant='bodyLarge'>
          {children}
        </Typography>
      </>
    )
  }

  if (isCenter) {
    return (
      <Wrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <View />
        </Box>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <View />
    </Wrapper>
  )
}

export default TableCellView