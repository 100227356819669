import React from 'react'
import MenuItemV5 from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'

export const MenuItemView = ({ title, onClick, disabled = false, isActive }) => {
  const theme = useTheme()
  return (
    <MenuItemV5 onClick={onClick}
      sx={{
        minHeight: "48px !important",
        fontSize: 14,
        fontWeight: 700,
        borderBottom: "1px solid",
        borderColor: theme.palette.accent.sky,
        backgroundColor: "transparent",
        color: isActive ? "#00B6B0" : "#000000",
        "&:hover": {
          cursor: isActive ? "default" : "pointer",
          backgroundColor: isActive ? "transparent" : theme.palette.accent.sky,
        }
      }}
      disabled={disabled}
    >
      {title}
    </MenuItemV5>
  )
}