import React from 'react'
import PropTypes from 'prop-types'
import IndustryNewsItem from './industry-news-item'

const IndustryNewsList = ({
  items,
}) => {
  return (
    <>
      {items?.map(({ title, teaser, imageUri, uri }, index) => (
        <IndustryNewsItem
          key={title}
          title={title}
          teaser={teaser}
          imageUri={imageUri}
          uri={uri}
        />
      ))}
    </>
  )
}

IndustryNewsList.propTypes = {
  items: PropTypes.array,
}

export default IndustryNewsList