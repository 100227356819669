import React from 'react'
import { types } from '../../module'
import { toCurrency } from 'utils'
import moment from 'moment'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns }) => {
  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />

      {columns.startDate && (
        <TableCellView isFirst>
          {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.endDate && (
        <TableCellView>
          {item.endDate ? moment(item.endDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.vehicleRegistration && (
        <TableCellView>
          {item.vehicleRegistration || '-'}
        </TableCellView>
      )}      
      {columns.vehicleYear && (
        <TableCellView>
          {item.vehicleYear || '-'}
        </TableCellView>
      )}
      {columns.makeModel && (
        <TableCellView>
          {item.makeModel || '-'}          
        </TableCellView>
      )}
      {columns.costPrice && (
        <TableCellView>
          {item.costPrice ? toCurrency(item.costPrice) : '-'}
        </TableCellView>
      )}      
      {columns.totalDaysVehicleAvailable && (
        <TableCellView>
          {item.totalDaysVehicleAvailable === null ? '-' : item.totalDaysVehicleAvailable}
        </TableCellView>
      )}
      {columns.daysAvailableForPrivateUse && (
        <TableCellView>
          {item.daysAvailableForPrivateUse === null ? '-' : item.daysAvailableForPrivateUse}
        </TableCellView>
      )}
      {columns.exemptDays && (
        <TableCellView>
          {item.exemptDays === null ? '-' : item.exemptDays}
        </TableCellView>
      )}
      {columns.fbtValueOfBenefit && (
        <TableCellView>
          {item.fbtValueOfBenefit ? toCurrency(item.fbtValueOfBenefit) : '-'}
        </TableCellView>
      )}
      {columns.gstPortion && (
        <TableCellView>
          {item.gstPortion ? toCurrency(item.gstPortion) : '-'}
        </TableCellView>
      )}
      {columns.fbtPayableExclGst && (
        <TableCellView>
          {item.fbtPayableExclGst ? toCurrency(item.fbtPayableExclGst) : '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.driverName && (
        <TableCellView>
          {item.driverName || '-'}
        </TableCellView>
      )}
      {columns.driverEmail && (
        <TableCellView>
          {item.driverEmail || '-'}
        </TableCellView>
      )}
      {columns.driverMobile && (
        <TableCellView>
          {item.driverMobile || '-'}
        </TableCellView>
      )}
      {/* {columns.quarter && (
        <TableCellView>
          {item.quarter || '-'}
        </TableCellView>
      )} */}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
