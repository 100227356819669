import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { PageLoader } from 'frame/components'
import { useSelectedFleetIds } from 'features/settings'
import { useSelector } from 'react-redux'

import MuiTextField from 'frame/components/mui-textfield/mui-textfield'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

const AssignFleetLookupField = ({
  id,
  resourceModel,
  lookupPath,
  customFleetId,
  currentDriverUniqueId,
  searchHistory,
  // func
  setSearchHistory,
  setSelectedVehicle,
  onLookup,
  onChange,
}) => {
  const theme = useTheme()
  const fleetIds = useSelectedFleetIds()

  const [selectFleet, setSelectFleet] = useState(null)
  const [searchKey, setSearchKey] = useState(searchHistory ? searchHistory.input : '')

  const lookupResults = useSelector(state => state[resourceModel]?.lookup && state[resourceModel]?.lookup[lookupPath]
    ? state[resourceModel]?.lookup[lookupPath]
    : [])
  
  const lookupFlag = useSelector(state => state[resourceModel]?.flags?.lookup)
  const isLoading = lookupFlag === 'processing'
  
  
  useEffect(() => {
    // load selected vehicle on load from history if exists
    searchHistory && setSelectFleet(searchHistory.selected)
  }, []) // eslint-disable-line 

  useEffect(() => { // update dropdown options on lookup results value change
    if(searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0) {
      // save search key for re-rendering search when form is load from back btn from confirm from
      setSearchHistory({ ...searchHistory, input: searchKey, options: lookupResults })
    } // eslint-disable-next-line
  }, [searchKey])

  const onInputChange = ({ target }) => {
    const input = target.value || ''
    setSearchKey(input)
    console.log('lookup onInputChange ', input)
    
    if (input.length > 0) {
      const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
      onLookup(lookupPath, input.trim(), fleetIdParam, 10)
    } else {
      onChange(null)
      setSelectFleet(null)
      setSearchHistory && setSearchHistory(null)
    }
  }

  const onSelectFleet = (rowData) => () => {
    setSelectFleet(rowData.value)
    setSearchHistory && setSearchHistory({ ...searchHistory, selected: rowData.value }) // rowData.value = store agreementNo
    onChange(rowData)
    console.log('[onSelectFleet]: ', rowData)
    setSelectedVehicle(rowData)
  }

  const isCurrentDriver = (oldDriver, newDriver) => oldDriver === newDriver
  const hasValues = searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0 && !isLoading
  
  return (
    <Stack>
      <Stack>
        <MuiTextField
          variant="filled"
          id={id || 'lookup-search-vehicle'}
          fullWidth
          label="Search Vehicle"
          name="searchVehicle"
          type="text"
          value={searchKey}
          onChange={onInputChange}          
          onKeyPress={(ev) => {
            ev.key === 'Enter' && ev.preventDefault()
          }}
        />
      </Stack>

      <Stack
        border='1px solid'
        borderColor={hasValues ? theme.palette.accent.sky : 'transparent'}
        borderRadius='5px'
        marginTop={hasValues ? '20px': '0px'}
        data-id="driver-results-list"
      >
        <Table
          size="small"
          pb={3}
        >
          {hasValues && !isLoading && (
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyLarge" fontWeight={600}>Registration</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyLarge" fontWeight={600}>Vehicle</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyLarge" fontWeight={600}>Current Driver</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {hasValues && lookupResults.map((rowData, index) => {
              console.log(isCurrentDriver(rowData.uniqueId, currentDriverUniqueId), "isCurrentDriver(rowData.uniqueId, currentDriverUniqueId)")

              return (
                <TableRow
                  key={`${index}-${rowData.value}`}
                  onClick={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId) ? null : onSelectFleet(rowData)}
                >
                  <TableCell style={{ maxWidth: '20px' }}>
                    <Radio
                      disabled={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId)}
                      style={{ marginLeft: '-10px' }}
                      size="small"
                      color="default"
                      checked={selectFleet === rowData.value}
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': `radio-btn-${index}-${rowData.value}` }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isCurrentDriver(rowData) ? '#BDBDBD !important' : 'inherit',
                      minWidth: '150px'
                    }}
                  >
                    <Typography variant="bodyMedium">
                      {rowData.text || '-'} {/* Registration No. */}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isCurrentDriver(rowData) ? '#BDBDBD !important' : 'inherit',
                    }}
                  >
                    <Typography variant="bodyMedium">
                      {rowData.vehicle || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isCurrentDriver(rowData) ? '#BDBDBD !important' : 'inherit',
                      minWidth: '250px'
                    }}
                  >
                    <Typography variant="bodyMedium">
                      {rowData.currentDriver || '-'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
          
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PageLoader minHeight={180} />
          </div>
        )}

        {lookupResults && lookupResults.length === 0 && !isLoading && searchKey && searchKey.length > 0 && (
          <Typography variant="body1">
            No vehicle found.
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

AssignFleetLookupField.propTypes = {
  id: PropTypes.string.isRequired,
  resourceModel: PropTypes.string,
  lookupPath: PropTypes.string,
  customFleetId: PropTypes.string,
  currentDriverUniqueId: PropTypes.string,
  searchHistory: PropTypes.object,
  // func
  setSearchHistory: PropTypes.func,
  setSelectedVehicle: PropTypes.func,
  onLookup: PropTypes.func,
  onChange: PropTypes.func,
}

export default AssignFleetLookupField
