import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const hasList = (list) => list && list.length > 0
  const { statuses, fleetNames } = filters

  const onSelectChange = (key) => (value) =>
    onFilter({
      [key]: value,
    })

  const fleetOptions = fleetNames && fleetNames.map(({ value, text }) => {
    return {
      label: text,
      value
    }
  })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
      <Box>
        {fleetNames && hasList(fleetNames) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="fleet"
              label="Fleet"
              options={fleetOptions}
              value={filtered.thirdPartyNumber}
              tight
              onChange={onSelectChange('thirdPartyNumber')} />
          </Box>
        )}
        {statuses && hasList(statuses) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="status"
              label="Status"
              options={statuses}
              value={filtered.status}
              tight
              onChange={onSelectChange('status')} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
