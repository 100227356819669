import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  // const hasList = (list) => list.length > 0
  const { completedStatus } = filters
  console.log('[completedStatus]: ', completedStatus)

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
        <Box>

          {/* {hasList(completedStatus) && ( */}
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="completedStatus"
              label="Submission Status"
              customNoneLabel="All"
              options={['Completed', 'Not Completed']}
              tight
              value={filtered.completedStatus}
              onChange={onSelect('completedStatus')} />
          </Box>
          {/* )} */}
        </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
