import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Toolbar, Tooltip } from '@material-ui/core'

import { useSelectedFleets, useSettingsFleetValues, useSelectedPremiumFleets } from 'features/settings'
import { getInitials, useRouter } from 'utils'
import { useProfile } from 'features/account'
import { IEWarningPopup, FleetIcon, Ribbon, Brand } from 'frame/components'

/**
 * V5
 */
import { Avatar } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import NavlinkItem from './nav-link'

// NavLink specific style for Nav Button
import styles from "./nav-link/styles.module.css"

// popover menu components
import FBTPopover from './fbt-popover'
import ActionsPopover from './actions-popover'
import AccountPopover from './account-popover'
// import NotificationsPopover from './notifications-popover'

import ReportsPopover from './reports-popover'
import RequestsPopover from './requests-popover'

// paths
import { HOMEPAGE_PATH } from 'features/homepage'
import { DASHBOARD_PATH } from 'features/dashboard'
import { constants as fromDrivers } from 'features/drivers'
import { constants as fromFleet } from 'features/fleet'

// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AccountIcon from '@material-ui/icons/AccountCircle'

import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'

import { useTheme } from "@mui/material/styles"

const TopBarDesktop = ({ showAlfaOutageMessage, userRoles, showIsPortalReadOnlyMessage }) => {
  const theme = useTheme()
  const selectedFbtFleets = useSelectedPremiumFleets()
  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? hasRoles(['superadmin', 'fleetcontroller'], userRoles) : true
  const SustainabilityFeatureToggle = false

  // refs for popover menus
  const fbtAnchor = useRef()
  const actionsAnchor = useRef()
  const accountAnchor = useRef()
  const reportsAnchor = useRef()
  const requestsAnchor = useRef()
  // const notificationsAnchor = useRef()
  // const notificationList = []

  const profile = useProfile()
  const { history, location } = useRouter()
  const selectedFleets = useSelectedFleets()
  const options = useSettingsFleetValues()

  const hasFleets = !!options.length
  const noFbtFleetSelected = selectedFbtFleets.length === 0

  const [menu, setMenu] = useState()

  const onOpenMenu = (value) => () => setMenu(value)
  const onCloseMenu = () => setMenu()
  const isOpen = (value) => value === menu

  const onLogout = () => {
    onCloseMenu()
    localStorage.clear()
    localStorage.setItem('FORCE_LOGOUT_ALL_SESSIONS', true) // to logout other tab sessions if any
    history.push('/logout')
  }

  const createLinkTo = (to) => () => {
    onCloseMenu()
    history.push(to)
  }

  const contains = (value) => location.pathname.includes(value)

  // for fleet icons displayed on nav bar
  const MAX_FLEET = 10
  const maxSelectedFleets = selectedFleets.length > MAX_FLEET
  const fleets = maxSelectedFleets ? selectedFleets.slice(0, MAX_FLEET) : selectedFleets

  const isUAT = window.location.href.includes('toyotafleetapp.bornuat1.co.nz') || window.location.href.includes('uat.toyotafleet.app')
  const isDev = window.location.href.includes('dev-sites')
  const isLocal = window.location.href.includes('local')
  const isDemo = window.location.href.includes('demo')
  const isIEBrowser = /*@cc_on!@*/false || !!document.documentMode

  const isRootPage = window.location.pathname === '/'

  return (
    <Box
      backgroundColor='background.header'
      flexGrow={1}
    >
      <Toolbar
        position="static"
        sx={{
          flex: 1,
          minHeight: '74px',
          bgcolor: 'background.header',
        }}
      >
        <RouterLink
          to="/"
          style={{
            display: 'flex'
          }}
        >
          <Brand variant="light" />
        </RouterLink>
        {isUAT && <p className={styles.envLabel}>(UAT Site)</p>}
        {isDev && <p className={styles.envLabel}>(Dev Site)</p>}
        {isLocal && <p className={styles.envLabel}>(Local Site)</p>}
        {isDemo && <p className={styles.envLabel}>(Demo Site)</p>}
        {isIEBrowser && <IEWarningPopup />}

        <Box flex={1} />
        <Box
          display='flex'
          paddingTop='4px'
        >
          {hasFleets && (
            <Box
              flexDirection='row'
              alignItems='center'
              display={{ md: 'none', lg: 'flex' }}
            >
              {fleets.map((node, index) =>
                <Box
                  key={`navbar-fleet-icon-${index}`}
                  marginTop={1}
                  marginRight={1}
                >
                  <FleetIcon fleetName={node.label} />
                </Box>
              )}

              {maxSelectedFleets &&
                <Tooltip title={`${selectedFleets.length - MAX_FLEET} more`}>
                  <MoreHorizIcon
                    sx={{ color: 'white', mt: 1 }}
                  />
                </Tooltip>
              }
              <Divider
                orientation="vertical"
                sx={{
                  margin: 1,
                  width: '1px',
                  height: '32px',
                  opacity: 0.3,
                  bgcolor: theme.palette.white
                }}
              />
            </Box>
          )}

          <NavlinkItem
            data-cy="home"
            label="Home"
            path={HOMEPAGE_PATH}
          />

          <NavlinkItem
            data-cy="dashboard"
            label="Dashboard"
            path={DASHBOARD_PATH}
          />

          <Button
            data-cy="reports"
            aria-label="reports"
            aria-controls="menu-reports"
            aria-haspopup="true"
            className={contains('reports') ? styles.activeNav : styles.nav}
            ref={reportsAnchor}
            onClick={onOpenMenu('reports')}
          >
            REPORTS
          </Button>

          <NavlinkItem
            data-cy="tfs-fleet"
            label="MyFleet"
            path={fromFleet.PATH_ALL}
          />

          <NavlinkItem
            data-cy="drivers"
            label="Drivers"
            path={fromDrivers.PATH_ALL}
          />

          <Button
            aria-label="requests"
            aria-controls="menu-requests"
            aria-haspopup="true"
            className={contains('relief-vehicles') || contains('vehicle-relocations') ? styles.activeNav : styles.nav}
            ref={requestsAnchor}
            onClick={onOpenMenu('requests')}
          >
            REQUEST SUMMARY
          </Button>

          {fbtFeatureToggle && (
            <Button
              aria-label="fbt reports"
              aria-controls="fbt-reports"
              aria-haspopup="true"
              className={contains('fbt') ? styles.activeNav : styles.nav}
              ref={fbtAnchor}
              onClick={() => noFbtFleetSelected ? history.push('/fbt/submission') : onOpenMenu('fbt')()}>
              FBT
            </Button>
          )}

          {SustainabilityFeatureToggle && (
            <NavlinkItem
              data-cy="sustainability"
              label="Sustainability"
              path={'/sustainability'}
            />
          )}

          <Divider
            orientation="vertical"
            sx={{
              margin: 1,
              width: '1px',
              height: '32px',
              opacity: 0.3,
              bgcolor: theme.palette.white
            }}
          />
          {showButtons && (
            <Button
              aria-label="quick actions"
              aria-controls="menu-actions"
              aria-haspopup="true"
              color="inherit"
              className={styles.nav}
              ref={actionsAnchor}
              onClick={onOpenMenu('actions')}
            >
              REQUESTS
            </Button>
          )}
        </Box>

        <IconButton
          data-cy="avatar"
          aria-label="account of current user"
          aria-controls="menu-account"
          aria-haspopup="true"
          color="inherit"
          ref={accountAnchor}
          onClick={onOpenMenu('account')}
        >
          {(profile && profile.nickname) ? (
            <Avatar
              data-cy="avatar-icon"
              src={profile.picture}
              sx={{
                width: 26,
                height: 26,
                color: 'dark',
                fontSize: 12,
              }}
            >
              {getInitials(profile.nickname)}
            </Avatar>
          ) : (
            <AccountIcon
              sx={{
                color: 'light',
                animation: '0.3s all ease-out',
                '&:hover': { color: 'light' },
              }}
            />
          )}
        </IconButton>

        {/* Popover Menus */}
        <ReportsPopover
          anchor={reportsAnchor}
          open={isOpen('reports')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />
        <RequestsPopover
          anchor={requestsAnchor}
          open={isOpen('requests')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />

        <AccountPopover
          anchor={accountAnchor}
          open={isOpen('account')}
          user={profile}
          linker={createLinkTo}
          onClose={onCloseMenu}
          onLogout={onLogout}
        />

        <FBTPopover
          anchor={fbtAnchor}
          open={isOpen('fbt')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />

        <ActionsPopover
          anchor={actionsAnchor}
          open={isOpen('actions')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />

        {/* <NotificationsPopover
          anchor={notificationsAnchor}
          notifications={notificationList}
          open={isOpen('notifications')}
          linker={createLinkTo}
          onClose={onCloseMenu} /> */}
      </Toolbar>

      {showIsPortalReadOnlyMessage ? (
        <Ribbon text="PORTAL IS DOWN FOR MAINTENANCE: Please note, the Fleet Portal is in read-only mode" />
      ) :
        showAlfaOutageMessage && (
          <Ribbon text="READ-ONLY: Please note, the Fleet Portal is in read-only mode between the hours of 6pm and 6am." />
        )
      }
    </Box>
  )
}

TopBarDesktop.propTypes = {
  showAlfaOutageMessage: PropTypes.bool,
  showIsPortalReadOnlyMessage: PropTypes.bool,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TopBarDesktop
