import React from 'react'
import moment from 'moment'
import { Label, FleetIcon } from 'frame/components'
import { getWoFStatusColor, obfuscateAgreementNumber } from 'listable'
import { types } from '../../module'
import { useRouter } from 'utils'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import TableCellView from 'frame/components/table-cell/table-cell'
import { themeV5 } from 'theme/v5'

const Row = ({ item, columns }) => {
  const { history, location } = useRouter()

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp1935FeatureToggle = featureToggleList.includes('TFP1935')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'WoF & Registration',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}      
      {columns.driver && (
        <TableCellView>
            {item.driver || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.controller && tfp1935FeatureToggle && (
        <TableCellView>
          {item.controller || '-'}
        </TableCellView>
      )}      
      {columns.wofExpiry && (
        <TableCellView>
          {item.wofExpiry ? (
            <Label
              color={getWoFStatusColor(item.wofStatus)}
              variant="outlined">
              {moment(item.wofExpiry).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.wofStatus && (
        <TableCellView>
          {(item.wofExpiry && item.wofStatus) ? (
            <Label
              // color={getDateColor(item.wofExpiry)}
              color={getWoFStatusColor(item.wofStatus)}
              variant="outlined">
              {item.wofStatus}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.registrationExpiry && (
        <TableCellView>
          {item.registrationExpiry ? (
            <Label
              color={getWoFStatusColor(item.registrationStatus)}
              variant="outlined">
              {moment(item.registrationExpiry).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.registrationStatus && (
        <TableCellView>
          {(item.registrationStatus) ? (
            <Label
              color={getWoFStatusColor(item.registrationStatus)}
              variant="outlined">
              {item.registrationStatus}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.agreementType && (
        <TableCellView>
          {item.agreementType || '-'}
        </TableCellView>
      )}      
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCellView>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton
                onClick={onViewDocumentsReport}
              >
                <InsertDriveFileOutlinedIcon sx={{ color: themeV5.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCellView>
      )}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
