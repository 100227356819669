import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {  isUserIdAuth0 } from 'listable'
import { Label, StyledModal } from 'frame/components'
import { createRowSelector, getInitials, getInitialsColor } from 'utils'
import { getRole, getUserStatusColor, getUserInvitedColor } from 'listable'
import { types } from '../../module'
import { hasRoles } from 'acl'
import { getUserStatus, getUserInvitationStatus } from 'features/admin-users/utils'
import { useIsWhiteListed } from 'utils'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TableCellView from 'frame/components/table-cell/table-cell'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

const Row = ({ item, columns, fleets, onView, userRoles, onDelete }) => {
  const isWhiteListedUser = useIsWhiteListed()
  const onSafeRowClick = createRowSelector(onView)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={item.invitationVerified || isUserIdAuth0(item.id) ? onSafeRowClick : undefined}>
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.nickname && (
        <TableCellView isFirst>
          {item.nickname || '-'}
        </TableCellView>
      )}
      
      {columns.email && (
        <TableCellView>
          {item.email || '-'}
        </TableCellView>
      )}

      {columns.role && (
        <TableCellView>
          {item.role ? getRole(item.role.name) : '-'}
        </TableCellView>
      )}

      {columns.fleets && (
        <TableCellView>
          {item.fleets && item.fleets.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                maxWidth: 200,
              }}
            >
              {item.fleets.map((node, index) => {
                const initals = getInitials(node.name)
                return (
                  <Tooltip title={node.name} key={index}>
                    <Avatar
                      sx={{
                        marginTop: 0.5,
                        marginRight: 0.5,
                        marginBottom: 0.5,
                        width: 22,
                        height: 22,
                        fontSize: 12,
                      }}
                      style={{ backgroundColor: getInitialsColor(initals) }}>
                      {initals}
                    </Avatar>
                  </Tooltip>
                )
              })}
            </Box>
          ) : (
            item.role && item.hasAccount && item.role.name.toLowerCase() === 'superadmin' ?  'Unrestricted Access' : ''
          )
        }
        </TableCellView>
      )}

      {columns.blocked && (
        <TableCellView>
          <Label
            color={getUserStatusColor(item.hasAccount, item.blocked)}
            variant="outlined">
            {getUserStatus(item)}
          </Label>
        </TableCellView>
      )}
      
      {columns.invitationVerified && (
        <TableCellView>
          <Label
            color={getUserInvitedColor(item.hasAccount, item.invitationVerified, item.invitationExpired)}
            variant="outlined">
            {getUserInvitationStatus(item)}
          </Label>
        </TableCellView>
      )}

      <TableCellView>
        { (!item.hasAccount || item.blocked) && hasRoles(['superadmin', 'fleetcontroller'], userRoles) && isWhiteListedUser &&
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}>
            Delete
          </Button>

        }
      </TableCellView>
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
      
      {/* for confirm delete user invitation */}
      <StyledModalV2
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        content={
          <Typography variant="h2" textAlign="center">
            {`Delete User Invitation`}
            <Typography variant="h5" textAlign="center">
              {`Please confirm that you wish to delete this user invitation.`}
            </Typography>
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={() => onDelete(item.id)}
          />
        }
      />
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  fleets: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Row
