import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import moment from 'moment'

const Odometer = ({ data }) => {
  const generateOdometerValues = () => {
    const odometerReadingString = data.lastOdoReading ? String(data.lastOdoReading) : ''
    let odometerReading = data.lastOdoReading ? Array.from(odometerReadingString, Number) : []

    if(odometerReadingString.length < 6) {
      const diff = 6 - odometerReadingString.length
      for(let i = 0; i < diff; i++) {
        odometerReading.unshift(0) // add 0s in front of array
      }  
    }
    return odometerReading
  }

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%" 
    >
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Stack flexDirection="row" alignItems="center">
          {generateOdometerValues().map((number, index) => (
            <Stack
              key={`odometerNo-${index}`}
              sx={{
                width: '22px',
                height: '44px',
                backgroundImage: 'url("/static/images/digital_service_book/odometer_bg.png")',
                background: 'cover',
                backgroundRepeat: 'no-repeat',
                textAlign: 'center',
                position: 'relative',
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                {number}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Typography variant="h6" fontWeight={600}>
          km
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h5" fontWeight={700}>
          Date of Last KM Reading
        </Typography>
        <Typography variant="h5" fontWeight={600}>
          {data.lastKmReading ? moment(data.lastKmReading).format('DD/MM/YYYY') : 'N/A'}
        </Typography>
      </Stack>
    </Stack>
  )
}

Odometer.propTypes = {
  data: PropTypes.object,
}

export default Odometer
