import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextValidator } from 'react-material-ui-form-validator'
import { PageLoader, EmailTextField } from 'frame/components'
import { useSelectedFleetIds } from 'features/settings'
import { useSelector } from 'react-redux'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import KeyValue from 'frame/components/mui-textfield'

const DriverLookupField = ({
  id,
  values,
  originalValues,
  customFleetId,
  disabled,
  label,
  lookupPath,
  resourceModel,
  currentDriver,
  searchHistory,
  // func
  setSearchHistory,
  onEmailChange,
  onLookup,
  onChange,
}) => {
  const theme = useTheme()
  const fleetIds = useSelectedFleetIds()

  const [searchKey, setSearchKey] = useState(searchHistory ? searchHistory.input : '')

  const lookupResults = useSelector(state => state[resourceModel]?.lookup && state[resourceModel]?.lookup[lookupPath]
    ? state[resourceModel]?.lookup[lookupPath]
    : [])
  
  const lookupFlag = useSelector(state => state[resourceModel]?.flags?.lookup)
  const isLoading = lookupFlag === 'processing'

  useEffect(() => {
    // load selected vehicle on load from history if exists
    if(searchHistory) {
      searchHistory.selected && onChange({
        ...searchHistory.selected, // only overwrite selected driver details on load - uniqueId, driver, email, phone
        // effectiveDate, cc, location, controller, vehicleNotes only get updated on select new driver
        effectiveDate: values.effectiveDate, 
        costCentre: values.costCentre, 
        location: values.location,
        controller: values.controller,
        vehicleNotes: values.vehicleNotes,
      })
      searchHistory.email && onEmailChange(searchHistory.email)
    }
  }, []) // eslint-disable-line 

  useEffect(() => { // update dropdown options on lookup results value change
    // save search key for re-rendering search when form is load from back btn from confirm from
    setSearchHistory({
      ...searchHistory,
      input: searchKey && searchKey.length > 0 ? searchKey : '',
      options: lookupResults && lookupResults.length > 0 ? lookupResults : []
    })// eslint-disable-next-line
  }, [searchKey])
  
  const onInputChange = ({ target: { value } }) => {
    
    const input = value || ''
    setSearchKey(input)
    console.log('lookup onInputChange ', input)
    
    if(input.length > 0) {
      const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
      onLookup(lookupPath, value.trim(), fleetIdParam, 10)
    } else {
      onChange(null)
      setSearchHistory && setSearchHistory(null)
    }
  }

  const onSelectDriver = (rowData) => () => {
    setSearchHistory && setSearchHistory({ ...searchHistory, selected: rowData }) // store selected driver
    onChange(rowData)
    console.log('[onSelectDriver]: ', rowData)
  }

  const isCurrentDriver = (newDriver) => originalValues.uniqueId === newDriver.value
  const hasValues = searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0 && !isLoading
  
  return (
    <Stack>
      <Stack>
        <KeyValue
          variant="filled"
          id={id || 'lookup-search-driver'}
          disabled={disabled}
          fullWidth
          label={label}
          name="searchDriver"
          type="text"
          value={searchKey}
          onChange={onInputChange}          
          onKeyPress={(ev) => {
            ev.key === 'Enter' && ev.preventDefault()
          }}
        />
      </Stack>

      <Stack
        border='1px solid'
        borderColor={hasValues ? theme.palette.accent.sky : 'transparent'}
        borderRadius='5px'
        marginTop={hasValues ? '20px': '0px'}
        data-id="driver-results-list"
      >
        {hasValues && (
          <Table
            size="small"
            pb={3}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyLarge" fontWeight={600}>Driver</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyLarge" fontWeight={600}>Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyLarge" fontWeight={600}>Status</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {lookupResults.map((rowData, index) => (
                <TableRow
                  key={`${index}-${rowData.value}`}
                  onClick={isCurrentDriver(rowData) ? null : onSelectDriver(rowData)}
                >
                  <TableCell
                    sx={{ minWidth: '20px' }}
                  >
                    <Radio
                      disabled={isCurrentDriver(rowData)}
                      style={{ marginLeft: '-10px' }}
                      size="small"
                      color="default"
                      checked={currentDriver === rowData.value}
                      name="select-driver-radio-button"
                      inputProps={{ 'aria-label': `radio-btn-${index}-${rowData.value}` }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      color: isCurrentDriver(rowData) ? '#BDBDBD !important' : 'inherit',
                      minWidth: '250px'
                    }}
                  >
                    <Typography variant="bodyMedium">{rowData.text || '-'}</Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      color: isCurrentDriver(rowData) ? '#BDBDBD !important' : 'inherit',
                      minWidth: '250px'
                    }}
                  >
                    {(currentDriver !== rowData.value && !rowData.email) || rowData.email ? <Typography variant="bodyMedium">{rowData.email || '-'}</Typography> : (
                      <EmailTextField
                        fullWidth
                        size="small"
                        id="email"
                        label="Email"
                        // disabled={disabled}
                        inputProps={{ maxLength: 50 }}
                        value={rowData.email
                          ? rowData.email.trim()
                          : searchHistory && searchHistory.email ? searchHistory.email : ''
                        }
                        variant="outlined"
                        onChange={onEmailChange}
                      />
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      color: isCurrentDriver(rowData) ? '#BDBDBD !important' : 'inherit'
                    }}
                  >
                    <Typography variant="bodyMedium">{rowData.status || '-'}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        
          
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PageLoader />
          </div>
        )}

        {lookupResults && lookupResults.length === 0 && !isLoading && searchKey && searchKey.length > 0 && (
          <Typography variant="body1">
            No driver found.
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

DriverLookupField.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  originalValues: PropTypes.object,
  customFleetId: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  lookupPath: PropTypes.string.isRequired,
  currentDriver: PropTypes.string,
  searchHistory: PropTypes.object,
  // func
  setSearchHistory: PropTypes.func,
  onEmailChange: PropTypes.func,
  onChange: PropTypes.func,
  onLookup: PropTypes.func,
}

export default DriverLookupField
