import { createMuiTheme as createThemeV4 } from '@material-ui/core/styles';

import palette from './palette'
import typography from './typography'
import overrides from './overrides'

export const themeV4 = createThemeV4({
  palette,
  typography,
  overrides,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
})