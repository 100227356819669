import palette from '../palette'
export default {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      fontWeight: 400,
      fontFamily: 'Inter, sans-serif',
      '&:hover': {
        boxShadow: 'none'
      },
      '&.MuiButton-containedSizeMedium': {
        height: '35px',
      },
      '&.MuiButton-outlinedSizeMedium': {
        height: '35px',
      },
    },
    outlined: {
      backgroundColor: palette.white,
    },
  }
}
