import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import MenuIcon from '@material-ui/icons/Menu'
import Brand from '../brand'
import MobileNavigation from './mobile-navigation'
import { Ribbon } from 'frame/components'
/**
 * V5
 */
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'

const TopBarMobile = ({ showAlfaOutageMessage, showIsPortalReadOnlyMessage }) => {
  const [open, setOpen] = useState(false)

  return (
    <Box backgroundColor='background.header' flexGrow={1}>
      <Toolbar
        position="static"
        sx={{
          flex: 1,
          minHeight: 74,
        }}
      >
        <RouterLink to="/" style={{ display: 'flex' }}>
          <Brand variant="light" />
        </RouterLink>
        <Box flex={1} />
        <Box
          display='flex'
        >
          <IconButton
            sx={{
              '& svg': {
                fill: 'white'
              }
            }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      
      <MobileNavigation open={open} onClose={() => setOpen(false)} />

      {
      showIsPortalReadOnlyMessage ? (
        <Ribbon text="READ-ONLY: Please note, the Fleet Portal is in read-only mode between the hours of 6pm and 6am." />
      ) :
      showAlfaOutageMessage && (
        <Ribbon text="READ-ONLY: Please note, the ability to add/edit driver accounts and vehicle assignment is unavailable between the hours of 6pm and 6am."/>
      ) 
      }
    </Box>
  )
}

TopBarMobile.propTypes = {
  showAlfaOutageMessage: PropTypes.bool,
  showIsPortalReadOnlyMessage: PropTypes.bool,
}

export default TopBarMobile
