import React from 'react'
import PropTypes from 'prop-types'
import { createRowSelector, useRouter } from 'utils'
import { FleetIcon, Label } from 'frame/components'
import { constants, types } from '../../module'
import moment from 'moment'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useEnabledFeatureToggleList } from 'features/settings'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'

import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, openedDocumentId, expanded, onExpandRow }) => {
  const { history } = useRouter()
  
  const hasDocuments = item.documentsList && item.documentsList.length > 0
  const isSupportPDFPreview = navigator.mimeTypes && navigator.mimeTypes['application/pdf'] && navigator.mimeTypes['application/pdf'].enabledPlugin

  const onSafeRowClick = createRowSelector(() => hasDocuments ? onExpandRow() : {})

  const onPreviewDocument = (documentId) => () => {
    isSupportPDFPreview && history.push(`${constants.PATH_SINGLE}/${documentId}`)
  }

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  
  return (
    <>
      <TableRow
        hover
        sx={{
          boxSizing: 'border-box',
          cursor: 'pointer',
          verticalAlign: 'middle',
          height: '30px',
          borderBottom: '5px solid white',
        }}
        onClick={onSafeRowClick}
      >
        <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
        
        {columns.accountName && (
          <TableCellView isFirst>
            <FleetIcon fleetName={item.accountName} />
          </TableCellView>
        )}
        {columns.agreementNo && (
          <TableCellView>
            {item.agreementNo || '-'}
          </TableCellView>
        )}
        {columns.registration && (
          <TableCellView>
            {item.registration || '-'}
          </TableCellView>
        )}
        {columns.driverNote && tfp1944FeatureToggle && (
          <TableCellView>
            {item.driverNote || '-'}
          </TableCellView>
        )}
        {columns.driver && (
          <TableCellView>
            {item.driver || '-'}
          </TableCellView>
        )}
         <TableCellView align="right" sx={{ maxWidth: "50px", width: "50px" }}>
           {hasDocuments && (
              <IconButton aria-label="expand row" size="small">
                {!!expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
           )}
        </TableCellView>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={!!expanded} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: '#F4F6F8',
                  }}
                >
                  <TableCellView 
                    sx={{
                      whiteSpace: 'nowrap',
                      minWidth: 100,
                      width: 100,
                    }}
                  >
                    Date
                  </TableCellView>
                  <TableCellView
                    sx={{
                      whiteSpace: 'nowrap',
                      minWidth: 100,
                      width: 100,
                    }}
                  >
                    Type
                  </TableCellView>
                  <TableCellView
                    sx={{
                      padding: 0,
                      width: '100%',
                    }}
                  >
                    Document
                  </TableCellView>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasDocuments && item.documentsList.map(({ id, archiveDate, documentName, documentType, documentUri }, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      verticalAlign: 'text-top',
                      cursor: 'pointer',
                    }}
                    onClick={onPreviewDocument(id)}
                  >
                     <TableCellView
                        sx={{
                          whiteSpace: 'nowrap',
                          minWidth: 100,
                          width: 100,
                        }}
                      >
                      {archiveDate ? moment(archiveDate).format('DD/MM/YYYY') : '-'}
                    </TableCellView>
                    <TableCellView
                      sx={{
                        whiteSpace: 'nowrap',
                        minWidth: 100,
                        width: 100,
                      }}
                    >
                      {documentType || '-'}
                    </TableCellView>
                    <TableCellView
                      sx={{
                        padding: 0,
                        width: '100%',
                      }}
                    >
                      {isSupportPDFPreview ? (
                        <Box
                          component="span"
                          sx={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {documentName}
                        </Box>
                      ) : (
                        <Box
                          component="a"
                          sx={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          href={documentUri}
                          download
                        >
                          {documentName}
                        </Box>
                      )}
                      {openedDocumentId === `${id}` && isSupportPDFPreview && (
                        <Box
                          component={Label}
                          sx={{
                            color: '#4CAF50 !important',
                            borderColor: '#4CAF50 !important', 
                            marginLeft: 3,
                          }}
                          variant="outlined"
                        >
                          open
                        </Box>
                      )}
                    </TableCellView>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  openDocumentId: PropTypes.string,
}

export default Row
