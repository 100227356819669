import React from 'react'
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const StatusIcon = {
  "success": <CheckCircleIcon color="primary" sx={{ fontSize: 40 }} />,
  "error": <ErrorOutlineIcon color="error" sx={{ fontSize: 40 }} />,
}

const StyledModalV2 = ({
  content,
  actions,
  status,
  open,
  onClose,
  title,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Card
        sx={{
          borderRadius: "5px",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          width: 'auto',
          p: 2,
        }}
      >
        <CardContent>
          {status && (
            <Stack
              justifyContent="center"
              alignItems="center"
            >
              {StatusIcon[status]}
            </Stack>
          )}
          {onClose &&
            <Stack
              flex={1}
              alignItems="flex-end"
              position="absolute"
              top={8}
              right={8}
            >
              <IconButton
                onClick={onClose}
                sx={{
                  display: 'flex',
                  margin: 0,
                  padding: 0,
                  width: 30,
                  height: 30,
                  background: 'transparent',
                  borderRadius: 15,
                  border: 'none',
                  transition: '0.3s all ease-out',
                  cursor: 'pointer',
                }}
              >
                <CancelOutlinedIcon 
                  color="error"
                  sx={{
                    margin: 0,
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>
            </Stack>}
          {title && (
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="h3">
                {title}
              </Typography>
            </Stack>
          )}
          {content &&
            <Stack pt={2} pb={4} px={2}>
              {content}
            </Stack>}
        </CardContent>

        {actions && actions}
      </Card>
    </Modal>
  )
}

export default StyledModalV2
