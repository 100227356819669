import React from "react";
import { useTheme } from "@mui/material/styles"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { statusColor } from "utils/statusColor";
import moment from 'moment'

const DetailsInfoWrapper = ({
  dataId,
  items,
}) => {
  const theme = useTheme()
  return (
    <Stack data-id={dataId}>
      <Stack
        borderRadius='5px'
        border='1px solid'
        borderColor={theme.palette.accent.sky}
        p={3}
        flexDirection='row'
        flexWrap='wrap'
        rowGap={3}
      >
        {items?.map((item, index) => (
          <Stack
            key={`contract-details-${index}`}
            flex={1}
            minWidth='calc(100%/3)'
            maxWidth='calc(100%/3)'
          >
            <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
              {item?.label}
            </Typography>
            {item?.label === "Status" ? (
              <Typography
                variant="bodySmall"
                fontWeight={700}
                color={statusColor(item?.value)}
                sx={{
                  border: "1px solid",
                  borderColor: statusColor(item?.value),
                  borderRadius: "6px",
                  maxWidth: "fit-content",
                  px: 1,
                  py: 0.3
                }}
              >
                {String(item?.value)?.toUpperCase()}
              </Typography>
            ): (
              <Typography variant="bodyLarge" pr={6}>
                {item?.format && item?.format === 'Do MMMM YYYY' ? moment(item?.value).format(item?.format) : item?.value}
              </Typography>
            )}
          </Stack>
        ))}

      </Stack>
    </Stack>
  )
}

export default DetailsInfoWrapper;