import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useSelectedFleetIds, useSettingsFleetValues, actions as fromSettings } from 'features/settings'
import Form from './fleet-selector-form'
import FormCleaner from './fleet-selector-form-cleaner'
import { useProfile } from 'features/account'


const FleetSelector = ({ onClose }) => {
  const profile = useProfile()
  const dispatch = useDispatch()
  const fleets = useSettingsFleetValues()
  const selectedFleets = useSelectedFleetIds()

  const [values, setValues] = useState(selectedFleets)
  const [saving, setSaving] = useState(false)
  const flags = useSelector(state => state.settings?.flags)

  const onSubmit = (fleetIds) => {
    // console.log('[onChangeFleet]', JSON.stringify(fleetIds, null, 2)) // eslint-disable-line
    
    if(JSON.stringify(fleetIds) === JSON.stringify(selectedFleets)) {
      onClose() // close if not changes are made to fleets
      return
    }

    /* 
      save selected fleet preferences to api,
      (see onFleetSelectedEffect effect src\features\settings\effects.js line 159)
    */
   console.log('[FleetSelector] onSubmit called ', fleetIds)
    setSaving(true)
    // saves user fleetid preference to api
    dispatch(fromSettings.saveSelectedFleetsPreferences('selected_fleet_ids', JSON.stringify(fleetIds)))
  }

  useEffect(() => {
    if(saving && flags.saveUserSelectedFleets === 'processed' && flags.fetchUserSelectedFleets === 'processed') {
      setSaving(false) // turn off loader
      onClose() // close fleet selector modal
    }// eslint-disable-next-line
  }, [flags])

  const showCleanerForm = profile?.email === 'tomasz@borndigital.co.nz'
  const FleetSelectForm = showCleanerForm ? FormCleaner : Form

  return (
    <Dialog
      open autoSize
      aria-labelledby="Fleet Selector"
      title="Fleet Selector"
      loading={saving}
      onClose={onClose
    }>
      <FleetSelectForm
        values={values}
        setValues={setValues}
        fleets={fleets}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  )
}

FleetSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default FleetSelector
