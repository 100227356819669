import React, { useEffect, useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { useProfileUpdater } from '../../hooks'
import PasswordIndicator from './passwordIndicator'

import Alert from "@mui/material/Alert"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Snackbar from "@mui/material/Snackbar"
import MuiTextfield from 'frame/components/mui-textfield'
import { useTheme } from "@mui/material/styles"

const initialState = {
  password: '',
  confirmPassword: '',
}

const Form = () => {
  const theme = useTheme()
  const { loading, save, status, errorMessage } = useProfileUpdater()
  const [values, setValues] = useState(initialState)
  const [showModal, setShowModal] = useState(false)

  const [isValid, setIsValid] = useState(false);

  const hasNumber = (value) =>  /(.*\d.*)/.test(value)
  const hasLowercase = (value) => /(.*[a-z].*)/.test(value)
  const hasUppercase = (value) => /(.*[A-Z].*)/.test(value)
  const hasSymbol = (value) => /[-!$%^&@#*()_+|~=`{}[\]:";'<>?,./]/.test(value)

  useEffect(() => {

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value !== values.password ? false : true
    })

    ValidatorForm.addValidationRule('hasNumber', (value) => hasNumber(value))
    ValidatorForm.addValidationRule('hasLowercase', (value) => hasLowercase(value))
    ValidatorForm.addValidationRule('hasUppercase', (value) => hasUppercase(value))
    ValidatorForm.addValidationRule('hasSymbol', (value) => hasSymbol(value))

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch')
      ValidatorForm.removeValidationRule('hasNumber')
      ValidatorForm.removeValidationRule('hasLowercase')
      ValidatorForm.removeValidationRule('hasUppercase')
      ValidatorForm.removeValidationRule('hasSymbol')
    }

  }, [values.password])

  const onSubmit = () => {
    // console.log('[onSubmit]', JSON.stringify(values, null, 2))
    save(values)
  }

  useEffect(() => {
    setShowModal(status === 'error' || status === 'done')
  }, [status])

  useEffect(() => {
    // Update the isValid state variable whenever values.password or values.confirmPassword changes
    const passwordIsValid = values.password.length > 7 && hasNumber(values.password) && hasLowercase(values.password) && hasUppercase(values.password) && hasSymbol(values.password);
    const confirmPasswordIsValid = values.password === values.confirmPassword;
    setIsValid(passwordIsValid && confirmPasswordIsValid);
  }, [values.password, values.confirmPassword]);

  const onChange = event => {
    event.persist()
    setValues({ ...values, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })
  }

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
      <Card
        sx={{
          boxShadow: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
        }}
        data-id="profile-details"
      >
        <Stack
          p={3}
          gap={3}
        >
          <Stack
            borderRadius="5px"
            border="1px solid"
            borderColor={theme.palette.accent.sky}
            p={3}
            flexDirection="column"
            gap={2}
          >
            <Stack>
              <Typography variant="h5" fontWeight={600}>
                Change Password
              </Typography>
              <Typography variant="bodyMedium">
                Password must be at least 8 characters in length containing both lowercase and uppercase letters, numbers and special characters.
              </Typography>
            </Stack>

            <Stack flexDirection="row" gap={3}>
              <Stack flex={1}>
                <MuiTextfield
                  disabled={loading}
                  fullWidth
                  onCopy={e => e.preventDefault()}
                  label="New Password"
                  name="password"
                  type="password"
                  value={values.password}
                  validators={['required', 'minStringLength:8', 'hasNumber', 'hasLowercase', 'hasUppercase', 'hasSymbol']}
                  errorMessages={['This field is required', 'Passwords must be at least 8 characters', 'Passwords must contain atleast 1 number',
                    'Passwords must contain atleast 1 lowercase character', 'Passwords must contain atleast 1 uppercase character', 'Passwords must contain atleast 1 symbol']}
                  onChange={onChange}
                />
              </Stack>
              <Stack flex={1}>
                <MuiTextfield
                  disabled={loading}
                  fullWidth
                  onCopy={e => e.preventDefault()}
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  validators={['isPasswordMatch', 'required']}
                  errorMessages={['Password mismatch', 'This field is required']}
                  onChange={onChange}
                />
              </Stack>
            </Stack>

            <Stack>
              <Typography variant="bodyMedium">Password Policy:</Typography>
              <PasswordIndicator
                passwordMatch={values.password !== '' && values.password === values.confirmPassword}
                meetsPasswordLength={values.password.length > 7}
                hasNumber={hasNumber(values.password)}
                hasLowercase={hasLowercase(values.password)}
                hasUppercase={hasUppercase(values.password)}
                hasSymbol={hasSymbol(values.password)}
              />
            </Stack>

            <Stack
              flexDirection={{ xs: 'column', md: 'row' }}
              marginBottom = '2'
              display= 'flex'
              alignItems= 'center'
              justifyContent= 'right'
            >
              <Box 
                width={{ xs: '100%', md: '205px' }}
              >
                <Button
                  fullWidth
                  color="primary"
                  disabled={!isValid || loading}
                  type="submit"
                  variant="contained"
                >
                  Save New Password
                </Button>
              </Box>
            </Stack>

          </Stack>
        </Stack>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showModal}
        autoHideDuration={3000}
        onClose={() => setShowModal(false)}
      >
        <Alert
          severity={status === 'done' ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {status === 'done'
            ? 'Password updated successfully'
            : `Something went wrong while trying to change your password. ${errorMessage ? `Error: ${errorMessage}` : ''}`.trim()
          }
        </Alert>
      </Snackbar>

    </ValidatorForm>
  )
}

export default Form
