import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DialogHeader } from './dialog-header'
import { DialogClose } from './dialog-close'
import { DialogContent } from './dialog-content'
import { DialogLoader } from './dialog-loader'

const PageDialog = ({
  children,
  errorMessage,
  open,
  title,
  breadcrumbs,
  loading,
  onClose,

  /*
    TODO: refactor out to use primaryButton Object like in PageHeader component after new tfs fleet record view modal is permanent
    Only used in tfs fleet old view modal and contract variation view modal
  */
  action, // button label
  onAction,
  hideAction,
  disablePrimaryButtonAction,
  secondaryAction, // button label
  onSecondaryAction,
  hideSecondaryAction,
  disableSecondaryAction,

  size,
  minHeight,
  autoSize, // used auto height for scroll
  tabsConfig, // use for modal tabs
  headerMinHeight, // tfs modal only

  tabMenu,
  sx,
  ...props
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const scroll = fullScreen ? 'body' : 'paper'

  const modalMinHeight = minHeight ? minHeight : window.innerHeight - 64
  const [headerHeight, setHeaderHeight] = useState(headerMinHeight ? headerMinHeight + 1 : 93)

  const elHeader = useRef()
  const elBody = useRef()

  const loaderStyle = {
    top: headerHeight,
    height: `calc(100% - ${headerHeight}px)`,
  }

  useEffect(() => {
    if (elBody.current) {
      elBody.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [loading])

  if (loading) {
    return (
      <DialogLoader style={loaderStyle} />
    )
  }

  return (
    <Dialog
      {...props}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={size ? size : 'lg'}
      open={open}
      scroll={scroll}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.neutral.mid,
          ...sx
        }
      }}
      sx={{
        alignItems: "flex-start"
      }}
    >
      <DialogHeader
        ref={elHeader}
        title={title}
      >
        {/* <PageHeader
          className={classes.titles}
          breadcrumbs={breadcrumbs}
          title={title}
          tabsConfig={tabsConfig}
          loading={loading}
          options={['titleWordWrap']}

          primaryButton={{
            label: action,
            action: onAction,
            hide: hideAction,
            disable: disablePrimaryButtonAction,
          }}
  
          secondaryButton={{
            label: secondaryAction,
            action: onSecondaryAction,
            hide: hideSecondaryAction,
            disable: disableSecondaryAction,
          }}
        /> */}
        <DialogClose onClose={onClose} />
      </DialogHeader>

      {tabMenu ? <Box px={3}>{tabMenu}</Box> : null}

      <DialogContent
        ref={elBody}
      >
        <Box style={{ minHeight: 'auto', marginBottom: autoSize ? '20px' : 0 }}>
          {children}
        </Box>
      </DialogContent>

      {!loading && errorMessage && (
        <DialogLoader style={loaderStyle}>
          <Typography variant="body1">
            {errorMessage}
          </Typography>
        </DialogLoader>
      )}
    </Dialog>
  )
}

PageDialog.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.string,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,

  action: PropTypes.string,
  onAction: PropTypes.func,
  hideAction: PropTypes.bool,
  disablePrimaryButtonAction: PropTypes.bool,
  secondaryAction: PropTypes.string,
  onSecondaryAction: PropTypes.func,
  hideSecondaryAction: PropTypes.bool,
  disableSecondaryAction: PropTypes.bool,

  size: PropTypes.string,
  minHeight: PropTypes.number,
  autoSize: PropTypes.bool,
  tabsConfig: PropTypes.array,

  tabMenu: PropTypes.element
}

export default memo(PageDialog)