import React, { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form'
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { ValidatorForm } from "react-material-ui-form-validator"
import { addDraft, editSaveDraft } from "../actions"
import { fbtReasonOptions } from "../actions/types"
import { useNavigate } from "utils"
import { constants } from "../module"
import moment from "moment"
import CustomCalender from "./CustomCalender"
import { useProfile } from "features/account"
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './form-schema'

import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import { useTheme } from "@mui/material/styles"
import KeyValue from "frame/components/mui-textfield"
import MuiFormSelect from "frame/components/mui-form-select"

// used in add new fbt driver record modal, edit fbt row entry, fbt web form
const AddEditSubmission = ({ id = 0, form, fbtWebForm, filtered, daysToDisable, originalEditRecord, onAddDraft, onEditSaveDraft }) => {
  const theme = useTheme()
  const newFbtRecord = { days: [], previouslySelectedDays: [], reasonType: "", reason: "" }

  const profile = useProfile()
  const navigate = useNavigate()

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const isAddForm = window.location.pathname.includes("add/add-submission")
  const isWebForm = window.location.pathname.includes("fbt-web-form")
  const isEditing = window.location.pathname.includes("edit")

  const [values, setValues] = useState(isAddForm ? newFbtRecord : form)

  const formChangesMade = JSON.stringify(values) !== JSON.stringify(originalEditRecord)

  const activeMonth = filtered && filtered?.month && filtered?.year
    ? moment(`${filtered?.year}-${moment().month(filtered?.month).format("M")}`, "YYYY-MM").toDate() // parse filtered monthName/Year to date
    : moment().add(-1, "month").toDate() // fallback - last month

  const disableReasonField = isEditing
    ? (!values?.days || values?.days.length === 0) && (!values?.previouslySelectedDays || values?.previouslySelectedDays.length === 0)
    : !values?.days || values?.days.length === 0
  
  const disableAddOrEditSave = isEditing
    ? ((!values?.days || values?.days.length === 0) && (!values?.previouslySelectedDays || values?.previouslySelectedDays.length === 0)) || (values?.reasonType === "Other" && !values?.reason)
    : !values?.days || values?.days.length === 0 || !values?.reason  || (values?.reasonType === "Other" && !values?.reason)

  useEffect(() => {
    isAddForm && setValues(newFbtRecord)
  }, [daysToDisable])  // eslint-disable-line
  
  const onCancel = navigate(`/fbt/submissions/${id}/${constants.PATH_VIEW_SUBMISSIONS}`)
  
  const onSubmitForm = () => {
    if(isEditing) {
      onEditSaveDraft(values)
    } else {
      onAddDraft({
        ...values,
        reason: fbtReasonOptions.includes(values?.reason) ? values?.reason : `Other: ${values?.reason}`
      })
    }
    
    navigate(isWebForm ? `/fbt-web-form/form/view-submissions` : `${constants.PATH_ALL}/${id}${constants.PATH_VIEW_SUBMISSIONS}`)()
  }
  
  const onSelectDates = (selectedDay) => {
    let updatedDays = values?.days || []
    let previouslySelectedDays = values?.previouslySelectedDays || []

    if(previouslySelectedDays.includes(selectedDay)) { // when selecting previouslySelectedDays - for edit form only
      previouslySelectedDays = previouslySelectedDays.filter(day => day !== selectedDay) // remove day from previouslySelectedDays
    } else {
      // handle add/remove days
      if(!updatedDays.includes(selectedDay)) {
        updatedDays.push(selectedDay)
      } else {
        updatedDays = updatedDays.filter(x => x !== selectedDay)
      }
    }

    setValues({
      ...values,
      days: updatedDays.sort((a, b) => a - b),
      previouslySelectedDays: previouslySelectedDays.sort((a, b) => a - b),
    })
  }

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmitForm}>
      <Card elevation={isWebForm ? 0 : 1}
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <CardContent
           sx={{
            p: 3,
          }}
        >
          <Stack
            gap={2}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">
                Please enter the dates on which the vehicle was&nbsp;
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                  not
                </span> 
                &nbsp;available for private use.
              </Typography>
            </Stack>
            <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
              {/* Calender Section */}
                <Stack
                  flex={1}
                  minHeight={"100px"}
                >
                  <CustomCalender
                    activeMonth={activeMonth}
                    selectedDays={values?.days}
                    onSelectDates={onSelectDates}
                    previouslySelectedDays={values?.previouslySelectedDays}
                    disabledDates={daysToDisable}
                    // disabledDatesAfter={13}
                  />
                </Stack>
              {/* End of Calender Section */}
              <Stack gap="20px" width="50%">
                <Controller
                  name="fbtReason"
                  control={methods.control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <MuiFormSelect
                      {...field}
                      id="fbtReason"
                      disabled={disableReasonField}
                      label="Select a Reason"
                      labelId="fbtReason-label"
                      options={fbtReasonOptions}
                      value={values?.reasonType || ''}
                      onChange={(event) => setValues({
                        ...values,
                        reasonType: event.target.value,
                        reason: event.target.value !== "Other" ? event.target.value : ""
                      })}
                    />
                  )}
                />
                {values?.reasonType === "Other" && (
                  <KeyValue
                    label="Add Details"
                    name="specify"
                    value={values?.reason}
                    disabled={disableReasonField}
                    onChange={event => setValues({
                      ...values,
                      reason: event?.target?.value || "",
                    })}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
            
          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            {isWebForm ? (
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button fullWidth color="secondary" variant="contained" onClick={navigate(`/fbt-web-form/availability`)}>
                  Back
                </Button>
              </Box>
            ) : (
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Box>
            )}
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!formChangesMade || disableAddOrEditSave}
              >
                {isEditing ? "Save" : "Add"}
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </ValidatorForm>
  )
}

AddEditSubmission.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  form: PropTypes.object,
  fbtWebForm: PropTypes.object,
  filtered: PropTypes.object,
  originalEditRecord: PropTypes.object,
  daysToDisable: PropTypes.array.isRequired,
  // func
  onAddDraft: PropTypes.func.isRequired,
  onEditSaveDraft: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fbtSubmissions }, ownProps) => ({
  id: ownProps.match.params?.id,
  form: fbtSubmissions?.form,
  fbtWebForm: fbtSubmissions?.fbtWebForm,
  filtered: fbtSubmissions?.filtered,
  originalEditRecord: fbtSubmissions?.originalEditRecord,
  daysToDisable: fbtSubmissions?.daysToDisable || [],
})

const mapDispatchToProps = (dispatch) => ({
  onAddDraft: (payload) => {
    dispatch(addDraft(payload))
  },
  onEditSaveDraft: (payload) => {
    dispatch(editSaveDraft(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSubmission)