import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import PageListHeader from "./page-list-header"
// import PageListLoading from "./page-list-loading"
import PageListLoadingSkeleton from "./page-list-loading-skeleton"
import PageListTableHeader from "./page-list-table-header"
import PageListFooter from "./page-list-table-footer"
import { Modal } from "frame/components"
import useSyncScroll from "use-sync-scroll-hook"
import { useScroll } from "utils"

import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import Typography from "@mui/material/Typography"
import Collapse from "@mui/material/Collapse"
import { useTheme } from "@mui/material/styles"
import { themeV5 } from "theme/v5"

const PageList = ({
  actions,
  banner,
  error,
  errorMessage,
  disclaimerMessage,
  noRecordsMessage,
  loading,
  title,
  subtitle,
  headers,
  listable,
  children,
  hideFilterLabel,
  useDocumentPanel,

  // table pagination stuff
  offset,
  rows,
  total,
  onChangePaginationOffset,
  onChangePaginationRows,
  customComponent,
  // column sorting/toggling stuff
  noColumnSort,
  columnsSort,
  selected,
  filtering,
  showColumns,
  onToggleColumnsSort, // table column sort
  onCheckAll, // used in driver report only
  noPagination, // to hide pagination controls
  noShowingResults, // to hide "Showing x results" and disclaimer container altogether
  ...rest
}) => {
  const theme = useTheme()
  // eslint-disable-next-line
  const { scrollY } = useScroll()
  const stickyHeaderRef = useRef()
  const tableRef = useRef()
 
  useSyncScroll([stickyHeaderRef, tableRef]) // syncs sticky header and table horizontal scroll

  const [open, setOpen] = useState(false) // for disclaimerMessage modal

  const onClose = () => setOpen(false) // for disclaimerMessage modal

  const empty = listable.length <= 0
  const safeOffset = total <= 0 ? 0 : offset
  
  const onChangePage = (event, nextPage) => {
    onCheckAll(false)
    window.scrollTo({ top: 0 })
    onChangePaginationOffset(nextPage)
  }

  const onChangeRowsPerPage = event => {
    window.scrollTo({ top: 0 })
    onChangePaginationRows(event.target.value)
  }

  const noRecordsError = errorMessage === "No records found"

  const showStickyHeader = showColumns ? window.scrollX > 610 : window.scrollY > 220
  
  const isFbtReport = window.location.pathname.includes("fbt")
  const hasLeftSideBar = window.location.pathname.includes("admin")

  const ResultsView = () => {
    if (noShowingResults && !disclaimerMessage) {
      return "";
    }

    return (
      <Typography
        color="black"
        variant="bodyLarge"
        pb={1}
        component="div"
      >
        {noShowingResults 
          ? "" 
          : noPagination
            ? `Showing ${total} results`
            : `Page ${offset + 1} — ${total ? Math.ceil(total / rows) : 1}, showing ${listable.length} of ${total} results`
        }        

        {disclaimerMessage ? (
          <>{". "}
            <Typography
              variant="bodyLarge"
              onClick={() => setOpen(true)}
              sx={{
                color: themeV5.palette.text.link,
                textDecoration: "none",
                cursor: "pointer"
              }}
            >
              {disclaimerMessage?.linkLabel ? disclaimerMessage?.linkLabel : "View disclaimer for this report"}
            </Typography>{"."}
          </>
        ) : "."}
      </Typography>
    )
  }

  const EmptyView = ({ children }) => {
    return (
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(3),
          minHeight: 240,
          textAlign: "center",
          borderBottom: `1px solid ${theme.palette.divider}`
        }}
      >
        {children}
      </Typography>
    )
  }

  return (
    <Box
      {...rest}
      sx={{
        marginBottom: { xs: useDocumentPanel ? theme.spacing(3) : theme.spacing(10), lg: theme.spacing(10) }
      }}
    >
      <ResultsView />

      {disclaimerMessage && (
        <Modal
          open={open}
          title={disclaimerMessage.title}
          onClose={onClose}
          children={disclaimerMessage.message}
        />
      )}

      <Grid item xs={12}>
        <Card
          sx={
            useDocumentPanel ? {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            } : null
          }
        >
          <PageListHeader
            actions={actions}
            filtering={hideFilterLabel ? false : filtering}
            title={title}
            subtitle= {subtitle}
            customComponent={customComponent}
          />
          <CardContent
            sx={{
              flex: 1,
              padding: 0,
              overflowX: "hidden",
            }}
          >
            <Collapse in={showColumns}>
              {banner}
            </Collapse>
                
            {/* Sticky Header */}
            <Box
              ref={stickyHeaderRef}
              sx={{
                top: showStickyHeader ? 0 : -110,
                position: "fixed",
                overflowX: "auto",
                paddingLeft: '16px',
                zIndex: 999,
                left: 0,
                right: 0,
                transition: "top 0.2s ease", // for slide in effect
                backgroundColor: "#F4F6F8",

                ...(isFbtReport && {
                  marginLeft: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(3),
                    md: theme.spacing(35),
                  },
                  marginRight: {
                    xs: theme.spacing(0),
                    sm: theme.spacing(1),
                    md: theme.spacing(1),
                  }
                }),

                ...(hasLeftSideBar ? {
                  marginLeft: {
                    xs: theme.spacing(2),
                    md: theme.spacing(13),
                    lg: theme.spacing(35),
                  },
                  marginRight: {
                    xs: theme.spacing(0),
                    sm: theme.spacing(1),
                    md: theme.spacing(1),
                  }
                }: {
                  margin: {
                    margin: theme.spacing(0, 3),
                    "@media screen and (max-width: 600px)": {
                      margin: theme.spacing(0, 2),
                    },
                    "@media screen and (max-width: 388px)": {
                      margin: theme.spacing(0, 1),
                    }
                  }
                }),
              }}
            >
              <Table
                stickyHeader
                // sx={{
                //   borderSpacing: "0 5px",
                //   p: 2,
                // }}
              >
                <PageListTableHeader
                  noColumnSort={noColumnSort}
                  headers={headers}
                  selected={selected}
                  columnsSort={columnsSort}
                  onToggleColumnsSort={onToggleColumnsSort}
                  onCheckAll={onCheckAll}
                />
              </Table>
            </Box>

            <Box 
              ref={tableRef}
              sx={{
                overflowX: "scroll",
                transform: "scaleX(-1) rotate(180deg)",
              }}
            >
              <Table
                sx={{
                  transform: "scaleX(-1) rotate(180deg)",
                  borderCollapse: "unset",
                  borderSpacing: "0 5px",
                  p: 2,
                  tableLayout: 'fixed'
                }}
              >
                <PageListTableHeader
                  noColumnSort={noColumnSort}
                  headers={headers}
                  selected={selected}
                  columnsSort={columnsSort}
                  onToggleColumnsSort={onToggleColumnsSort}
                  onCheckAll={onCheckAll}
                />
                <TableBody>
                  {loading ? (
                    <PageListLoadingSkeleton headers={headers.filter(x => x.visible)} />
                  ) : children}
                </TableBody>
              </Table>
            </Box>

            { ! loading && ! error && empty && (
              <EmptyView>
                {noRecordsMessage || "No records found"}
              </EmptyView>
            )}

            { error && ! loading && empty && !noRecordsError && (
              <EmptyView>
                Something went wrong requesting these records.
                <br />
                Please refresh to try again.
              </EmptyView>
            )}
            
            { noRecordsError && ! loading && empty && (
              <EmptyView>
                {errorMessage}
              </EmptyView>
            )}
            
            {/* {loading && (
              <PageListLoading headers={headers} />
            )} */}
            <br/>
          </CardContent>
          
          {!noPagination && (
            <PageListFooter
              offset={safeOffset}
              rows={rows}
              total={total}
              onChangePaginationOffset={onChangePage}
              onChangePaginationRows={onChangeRowsPerPage}
            />
          )}
        </Card>
      </Grid>
    </Box>
  )
}

const Header = PropTypes.shape({
  center: PropTypes.bool,
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  sortKey: PropTypes.string,
  width: PropTypes.number,
})

PageList.propTypes = {
  customComponent: PropTypes.any,
  actions: PropTypes.any,
  banner: PropTypes.element,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disclaimerMessage: PropTypes.object,
  noRecordsMessage: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([ PropTypes.string, Header ])
  ).isRequired,
  listable: PropTypes.array.isRequired,
  children: PropTypes.any,
  hideFilterLabel: PropTypes.bool,
  useDocumentPanel: PropTypes.bool,
  
  // table pagination stuff
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  onChangePaginationOffset: PropTypes.func.isRequired,
  onChangePaginationRows: PropTypes.func.isRequired,

  // column sorting/toggling stuff
  columnsSort: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.string,
  }),
  selected: PropTypes.array,
  filtering: PropTypes.bool,
  showColumns: PropTypes.bool,
  onToggleColumnsSort: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  noPagination: PropTypes.bool,
}

export default PageList
