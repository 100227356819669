import React from "react"
import { useTheme } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import DownloadCarousel from "./download-carousel"
import { CardTitle } from "../common/card-title"

const Downloads = () => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: '100%',
        minHeight: '317px',
        [theme.breakpoints.down("sm")]: { minHeight: "100%" },
      }}
    >
      <CardContent>
        <CardTitle title="Downloads" />
        <Divider />
        <Stack pt={2}>
          <DownloadCarousel />
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Downloads
