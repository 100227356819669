import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Page, PageHeader, PageList, PageSearch, SnackbarPopup } from 'frame/components'
import Columns from './columns'
import Options from './list-options'
import { useDispatch } from 'react-redux'
import { actions as fromSettings } from 'features/settings'
import qs from 'query-string'
import { useRouter } from 'utils'
import SkeletonTable from './skeleton-table'
import { useEnabledFeatureToggleList } from 'features/settings'

import Stack from "@mui/material/Stack"

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginTop: '25px',
  },
  results: {
    marginTop: theme.spacing(3)
  },
  loadingCard: {
    height: 'calc(100vh - 400px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 600px)'
    },
  },
}))

const List = ({
  config,
  actions,
  filters,
  fleetId,
  model,
  renderItem,
  routes,
  maskComponent,
  customActions,
  documentPreviewUri,
  renderViewAllRow,
  primaryButton,
  secondaryButton,
  dropdown,
  documentListClass,
  actionButtons,
}) => {
  
  const dispatch = useDispatch()
  const { location } = useRouter()
  
  const [delayLoading, setDelayLoading] = useState(!!config.delayLoading) // for custom delay loading - if config.delayLoading is set to timeout seconds
  
  const isFbtPages = window.location.pathname.includes('/fbt')
  const classes = useStyles({
    isFbtPages,
    backgroundImage: config.backgroundImage,
  })

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1872FeatureToggle = featureToggleList.includes('TFP1872')
  const isFbtSubmissionsPage = window.location.pathname.includes('/fbt/submissions')

  const ChildRoutes = routes
  const Filters = filters

  // table config
  const hideButtons = config?.hideButtons || false
  const hideSearch = config?.hideSearch || false
  const noDownload = (isFbtSubmissionsPage && !tfp1872FeatureToggle) || config?.noDownload || false
  const noColumnSort = config?.noColumnSort || false
  const noPagination = config?.noPagination || false
  const noShowingResults = config?.noShowingResults || false
  const showSearchOnTableHeader = config?.showSearchOnTableHeader || false

  useEffect(() => { // clear search/filters and fetch list on page load

    if (config.delayLoading) {
      setTimeout(() => setDelayLoading(false), config.delayLoading)
    }   
    const queryParams = { FleetId: fleetId }
    filters && dispatch(actions.requestFilters(null, { queryParams }))
    
    // to check if redirecting from dashboard column filter links
    const urlParams = qs.parse(location.search) 
    const isDashboardFiltered = urlParams && Object.keys(urlParams).length > 0

    // call fetchList if not from dashboard filter links - so it doesnt overwrite the filtered fetch results
    !isDashboardFiltered && dispatch(actions.fetchList())
    
    console.log(`[listable]: `, {
      pageTitle: config.title,
      currentPageModule: actions.currentPageModule,
      urlParams,
      isDashboardFiltered,
    })
    dispatch(fromSettings.setCurrentModule(actions.currentPageModule))

    return () => {
      dispatch(actions.clearFilters())
      dispatch(actions.clearSearchKey())
    }
  }, []) // eslint-disable-line

  const methods = useMemo(() => {
    const result = {}
    Object.keys(actions).forEach((key) => {
      result[key] = (...args) => dispatch(actions[key](...args))
    })
    return result
  }, [actions, dispatch])

  // flags
  const loaded = model.flags?.list?.status === 'success'
  const loading = model.flags?.list?.status === 'loading'
  const error = model.flags?.list?.status === 'error'
  const errorMessage = model.flags?.list?.errorMessage
  const total = noPagination ? Object.entries(model.data).length : model.list?.size || 0

  // remove duplicate ids - preventing duplicate records
  const listed = [...new Set(model?.list?.ids)]
    .map(nodeId => model.data?.[nodeId])
    .filter(x => x)


  const listable = loaded && listed
    ? listed
    : []

  // console.log('listable', listable)
  const headers = config.headers.map(node => ({
    ...node,
    visible: model.columns[node.key],
    showToggle: config?.model?.find(x => x.key === node.key)?.column?.showToggle,
    toolTip: config?.model?.find(x => x.key === node.key)?.column?.toolTip,
    toggleAction: config?.model?.find(x => x.key === node.key)?.column?.toggleAction,
    toggleValue: config?.model?.find(x => x.key === node.key)?.column?.toggleValue,
  }))
  
  const Banner = () => (// show/hide table columns area component
    <Columns
      config={config}
      columns={model.columns}
      onToggleColumn={methods.toggleColumn}
      onSelectAllColumns={methods.toggleSelectAllColumns}
    />
  )

  // const onDownload = () => methods.onDownload(`?Columns=year&Columns=model&Columns=colour&Columns=make&FleetId=111924`)
  const onDownload = (selected) =>
    methods.onDownload({
      columns: model.columns,
      selected,
      fleetId,
      title: config.title
    })

  const onRefresh = () => {
    if (customActions && customActions.onRefetch) { // used in fbt reports to avoid clearFilter action to persist month/quarter filters
      customActions.onRefetch()
    } else {
      dispatch(actions.clearFilters()) // clears all filters and triggers refetches
      const queryParams = { FleetId: fleetId }
      dispatch(actions.requestFilters(null, { queryParams }))      
    }
  }

  // selecting all rows for driver page
  const onCheckAll = (selectedAll) => selectedAll
    ? dispatch(actions.selectRow(listable.map(data => data.uniqueId)))
    : dispatch(actions.selectRow([]))
  
  return (
    <Page title={config.title} sx={{ px: 3 }}>
      <Stack my={2}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!hideSearch && !maskComponent && !delayLoading && !showSearchOnTableHeader && (
            <PageSearch
              searchKey={model.searchKey}
              onSearch={methods.changeSearchKey}
            />
          )}

          {actionButtons && actionButtons}
        </Stack>

        {filters && !hideButtons && (
          <Filters />
        )}

        {delayLoading ? (
          <SkeletonTable />
        ) : maskComponent ? (
          maskComponent
        ) : (
          <PageList
            customComponent={showSearchOnTableHeader ? (
              <PageSearch
                searchKey={model.searchKey}
                onSearch={methods.changeSearchKey}/>
              ) : undefined
            }
            actions={hideButtons ? null : (
              <Options
                columns={model.columns}
                filtering={model.filtering}
                hideFilterLabel={config?.disabledClearFilter}

                // top right table functions
                onClearFilters={customActions && customActions.onClearFilters ? customActions.onClearFilters : methods.clearFilters}
                onShowFilters={!!filters ? methods.showFilters : null}
                onRefresh={onRefresh}
                onDownload={noDownload ? null : onDownload}
                onToggleShowColumns={methods.toggleShowColumns}
              />
            )}

            banner={<Banner />}
            className={documentListClass ? documentListClass : classes.results}
            error={error}
            errorMessage={errorMessage}
            disclaimerMessage={config.disclaimerMessage}
            noRecordsMessage={config.noRecordsMessage}
            loading={loading}
            title={config?.tableName || config.title}
            subtitle={config.subtitle}
            headers={headers}
            listable={listable}
            hideFilterLabel={config?.disabledClearFilter}
            useDocumentPanel={!!documentPreviewUri}
            
            // table pagination stuff
            offset={model.pagination.offset}
            rows={model.pagination.rows}
            total={total}
            onChangePaginationOffset={methods.setPaginationOffset}
            onChangePaginationRows={methods.setPaginationRows}
            
            // column sorting/toggling stuff
            noColumnSort={noColumnSort}
            selected={model.selected}
            columnsSort={model.columnsSort}
            filtering={model.filtering}
            showColumns={model.showColumns}
            onToggleColumnsSort={methods.toggleColumnSort} // table column sort
            onCheckAll={onCheckAll} // used in driver report only
            noPagination={noPagination}
            noShowingResults={noShowingResults}
          >
            {listable.map((item, index) =>
              renderItem(item, index, {
                columns: model.columns,
                selected: model.selected,
              })
            )}

            {(renderViewAllRow) && renderViewAllRow(listable)}
          </PageList>
        )}

        {routes && (
          <ChildRoutes />
        )}
        
        {model && model.downloadStatus && (
          <SnackbarPopup
            coloured
            success={model.downloadStatus.toLowerCase().includes('success')}
            error={model.downloadStatus.toLowerCase().includes('error')}
            message={model.downloadStatus}
          />
        )}
      </Stack>
    </Page>
  )
}

List.propTypes = {
  actions: PropTypes.any.isRequired,
  renderViewAllRow: PropTypes.func,
  config: PropTypes.any.isRequired,
  filters: PropTypes.any,
  fleetId: PropTypes.string.isRequired,
  model: PropTypes.any.isRequired,
  renderItem: PropTypes.func.isRequired,
  routes: PropTypes.any,
  maskComponent: PropTypes.any,
  customActions: PropTypes.object,
  documentPreviewUri: PropTypes.string,
  
  primaryButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    color: PropTypes.string,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),

  secondaryButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    color: PropTypes.string,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),

  dropdown: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),
}


export default List
