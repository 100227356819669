import React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"

export const StyledModalV2Actions = ({
  onCancel,
  onConfirm
}) => {
  return (
    <Stack
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="center"
      gap={1}
    >
      <Stack flexDirection="row" gap={2}>
        <Box width={{ xs: "100%", md: "180px" }}>
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
      <Box width={{ xs: "100%", md: "180px" }}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Box>
    </Stack>
  )
}