
export const moduleName = `requestVehicleSummary`

export const RESET_FLAGS = `${moduleName}_reset_flags`

export const CREATE_LEASE_INFORMATION = `${moduleName}_create_lease_information`
export const CREATE_ERROR_LEASE_INFORMATION = `${moduleName}_create_error_lease_information`
export const CREATE_FULFILLED_LEASE_INFORMATION = `${moduleName}_create_fulfilled_lease_information`

export const CREATE_VEHICLE_INFORMATION = `${moduleName}_create_vehicle_information`
export const CREATE_ERROR_VEHICLE_INFORMATION = `${moduleName}_create_error_vehicle_information`
export const CREATE_FULFILLED_VEHICLE_INFORMATION = `${moduleName}_create_fulfilled_vehicle_information`

export const CREATE_ADDITIONAL_SERVICES = `${moduleName}_create_additional_services`
export const CREATE_ERROR_ADDITIONAL_SERVICES = `${moduleName}_create_error_additional_services`
export const CREATE_FULFILLED_ADDITIONAL_SERVICES = `${moduleName}_create_fulfilled_additional_services`

export const CREATE_ACCESSORIES = `${moduleName}_create_accessories`
export const CREATE_ERROR_ACCESSORIES = `${moduleName}_create_error_accessories`
export const CREATE_FULFILLED_ACCESSORIES = `${moduleName}_create_fulfilled_accessories`

export const FETCH = `${moduleName}_fetch`
export const FETCH_ERROR = `${moduleName}_fetch_error`
export const FETCH_FULFILLED = `${moduleName}_fetch_fulfilled`

export const FETCH_CORRESPONDING_CONTRACT = `${moduleName}_fetch_corresponding_contract`
export const FETCH_CORRESPONDING_CONTRACT_ERROR = `${moduleName}_fetch_corresponding_contract_error`
export const FETCH_CORRESPONDING_CONTRACT_FULFILLED = `${moduleName}_fetch_corresponding_contract_fulfilled`

export const FETCH_CONTRACT = `${moduleName}_fetch_contract`
export const FETCH_CONTRACT_ERROR = `${moduleName}_fetch_contract_error`
export const FETCH_CONTRACT_FULFILLED = `${moduleName}_fetch_contract_fulfilled`

export const UPDATE_LEASE_INFORMATION = `${moduleName}_update_lease_information`
export const UPDATE_ERROR_LEASE_INFORMATION = `${moduleName}_update_error_lease_information`
export const UPDATE_FULFILLED_LEASE_INFORMATION = `${moduleName}_update_fulfilled_lease_information`

export const UPDATE_VEHICLE_INFORMATION = `${moduleName}_update_vehicle_information`
export const UPDATE_ERROR_VEHICLE_INFORMATION = `${moduleName}_update_error_vehicle_information`
export const UPDATE_FULFILLED_VEHICLE_INFORMATION = `${moduleName}_update_fulfilled_vehicle_information`

export const UPDATE_ADDITIONAL_SERVICES = `${moduleName}_update_additional_services`
export const UPDATE_ERROR_ADDITIONAL_SERVICES = `${moduleName}_update_error_additional_services`
export const UPDATE_FULFILLED_ADDITIONAL_SERVICES = `${moduleName}_update_fulfilled_additional_services`

export const UPDATE_ACCESSORIES = `${moduleName}_update_accessories`
export const UPDATE_ERROR_ACCESSORIES = `${moduleName}_update_error_accessories`
export const UPDATE_FULFILLED_ACCESSORIES = `${moduleName}_update_fulfilled_accessories`

export const DELETE = `${moduleName}_delete`
export const DELETE_ERROR = `${moduleName}_delete_error`
export const DELETE_FULFILLED = `${moduleName}_delete_fulfilled`

export const LOOKUP = `${moduleName}_lookup`
export const LOOKUP_ERROR = `${moduleName}_lookup_error`
export const LOOKUP_FULFILLED = `${moduleName}_lookup_fulfilled`