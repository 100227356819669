import React, { useMemo, useState } from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { hasRoles } from "acl"
import { useEnabledFeatureToggleList } from "features/settings"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

import CardContent from "frame/components/card-content"
import DetailsInfoWrapper from "frame/components/details-info-wrapper"

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import MuiTextfield from "frame/components/mui-textfield/mui-textfield"
import MuiFormSelect from "frame/components/mui-form-select"
import DatePicker from "frame/components/date-picker-v5"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"
import { schema } from "./schema"

dayjs.extend(advancedFormat)

const Form = ({ data, userRoles, onClose, onSave, onDelete }) => {
  const { booking, contract } = data
  const [values, setValues] = useState(booking)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const theme = useTheme()
  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
    defaultValues: {
      status: booking?.status ?? "",
      effectiveDate: booking?.effectiveDate ?? "",
      pickup: booking?.pickup ?? "",
      destination: booking?.destination ?? "",
      driverName: booking?.driverName ?? "",
      driverMobile: booking?.driverMobile ?? "",
      contactEmail: booking?.contactEmail ?? "",
      costCentre: booking?.costCentre ?? "",
      referenceId: booking?.referenceId ?? "",
    }
  })

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1838FeatureToggle = featureToggleList.includes("TFP1838")

  const onDeleteHandler = () => {
    onDelete(booking?.id, booking?.thirdPartyNumber)
    onClose()
  }

  const statusOptions = useMemo(() => ([{
    value: "requested",
    label: "Requested",
  }, {
    value: "pending",
    label: "Pending",
  }, {
    value: "completed",
    label: "Completed",
  }, {
    value: "cancelled",
    label: "Cancelled",
  }]), [])

  const onChange = (key, value) => {
    console.log(key, value)
    setValues({
      ...values,
      [key]: value,
    })
    methods.setValue(key, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSubmit = (data) => {
    console.log(data)
    const payload = {
      ...booking,
      id: values?.id || 0,
      ...data,
    }
    console.log(payload, "payload")
    // onSave()
  }

  const editable = contract && values?.status &&
    (values?.status.toLowerCase() === "requested" || values?.status.toLowerCase() === "pending")

  const getMaxEffectiveDate = () => {
    if (contract && contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
    >
      <CardContent>
        <FormProvider>
          <form
            id="request-vehicle-relocations"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
          <Stack gap={4}>
            <Stack gap={2}>
              <Typography variant="h5" fontWeight={600}>
                Relocation Request Details
              </Typography>
              {editable ? (
                <Stack
                  border="1px solid"
                  borderColor={theme.palette.accent.sky}
                  padding={2}
                  borderRadius={1}
                >
                  <Stack
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={2}
                  >
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="status"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiFormSelect
                            {...field}
                            id="status"
                            disabled={!Boolean(contract && hasRoles(["superadmin"], userRoles))}
                            label="Status"
                            labelId="status-label"
                            options={statusOptions}
                            value={values?.status}
                            onChange={(event) =>
                              onChange("status", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    {/* Effective Date */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="effectiveDate"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            label="Effective Date"
                            format="Do MMMM YYYY"
                            disabled={!editable}
                            maxDate={getMaxEffectiveDate()}
                            minDate={dayjs(values?.effectiveDate) < dayjs() ? dayjs(values?.effectiveDate) : dayjs()}
                            value={typeof values.effectiveDate === "undefined" ? null : values?.effectiveDate}
                            onChange={(value) => console.log(value)}
                          />
                        )}
                      />
                    </Stack>
                    {/* Pickup Address */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="pickup"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="pickup"
                            disabled={!editable}
                            label="Pickup Address"
                            value={values?.pickup}
                            onChange={(event) =>
                              onChange("pickup", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    {/* Destination Address */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="destination"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="destination"
                            disabled={!editable}
                            label="Destination Address"
                            value={values?.destination}
                            onChange={(event) =>
                              onChange("destination", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    {/* Contact Person */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="driverName"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="driverName"
                            disabled={!editable}
                            label="Contact Person"
                            value={values?.driverName}
                            onChange={(event) =>
                              onChange("driverName", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    {/* Mobile */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="driverMobile"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="driverMobile"
                            disabled={!editable}
                            label="Mobile"
                            value={values?.driverMobile}
                            onChange={(event) =>
                              onChange("driverMobile", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    {/* Email */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="contactEmail"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="contactEmail"
                            disabled={!editable}
                            label="Contact Email"
                            error={Boolean(methods.formState.errors?.contactEmail?.message)}
                            value={values?.contactEmail}
                            onChange={(event) =>
                              onChange("contactEmail", event?.target?.value)
                            }
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.contactEmail?.message}
                      </Typography>
                    </Stack>
                    {/* Cost Centre */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="costCentre"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="costCentre"
                            disabled={!editable}
                            label="Cost Centre"
                            value={values?.costCentre}
                            onChange={(event) =>
                              onChange("costCentre", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    {/* Reference ID */}
                    <Stack gap={1} flex={1} minWidth="calc(100%/2 - 24px)">
                      <Controller
                        name="referenceId"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="referenceId"
                            disabled={!editable}
                            label="Reference ID"
                            value={values?.referenceId}
                            onChange={(event) =>
                              onChange("referenceId", event?.target?.value)
                            }
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={1} minWidth="calc(100%/2 - 24px)" />
                  </Stack>
                </Stack>
              ): (
                <DetailsInfoWrapper
                  data-id="vehicle-relocation-booking-vehicle-details"
                  items={[
                    {
                      label: "Status",
                      value: booking?.status,
                    },
                    {
                      label: "Effective Date",
                      value: dayjs(booking?.effectiveDate).format("Do MMMM YYYY") ?? "-",
                    },
                    {
                      label: "Pickup Address",
                      value: booking?.pickup ?? "-",
                    },
                    {
                      label: "Destination Address",
                      value: booking?.destination ?? "-",
                    },
                    {
                      label: "Contact Name",
                      value: booking?.driverName ?? "-",
                    },
                    {
                      label: "Mobile",
                      value: booking?.driverMobile ?? "-",
                    },
                    {
                      label: "Email",
                      value: booking?.contactEmail ?? "-",
                    },
                    {
                      label: "Cost Centre",
                      value: booking?.costCentre ?? "-",
                    },
                    {
                      label: "Reference ID",
                      value: booking?.referenceId ?? "-",
                    }
                  ]}
                />
              )}
            </Stack>
            <Stack gap={2}>
              <Typography variant="h5" fontWeight={600}>
                Vehicle
              </Typography>
              <Stack
                border="1px solid"
                borderColor={theme.palette.accent.sky}
                padding={2}
                borderRadius={1}
                gap={2}
              >
                {contract ? (
                  <DetailsInfoWrapper
                    data-id="vehicle-relocation-booking-vehicle-details"
                    items={[
                      {
                        label: "Driver",
                        value: contract?.driver
                      },
                      {
                        label: "Location",
                        value: contract?.location
                      },
                      {
                        label: "Registration",
                        value: contract?.registration
                      },
                      {
                        label: "Cost Centre",
                        value: contract?.costCentre
                      },
                      {
                        label: "Make",
                        value: contract?.make
                      },
                      {
                        label: "Model",
                        value: contract?.model
                      },
                      {
                        label: "Year",
                        value: contract?.year
                      },
                      {
                        label: "Colour",
                        value: contract?.colour
                      },
                      {
                        label: "Accessories",
                        value: contract?.accessories
                      },
                      {
                        label: "",
                        value: ""
                      }
                    ]}
                  />
                ): (
                  <Typography gutterBottom variant="body1">
                    Contract not found for {booking?.vehicleRegistration}.
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          
          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "205px" }}>
                {hasRoles(["superadmin", "fleetcontroller"], userRoles) && (editable || !contract) && (
                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpenDeleteModal(true)}
                  >
                    Cancel Request
                  </Button>
                )}
              </Box>
              {editable && (
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Stack>
          </Stack>

          </form>
        </FormProvider>
      </CardContent>

      <StyledModalV2
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        content={
          <Stack gap={2}>
            <Typography variant="h4" textAlign="center">
              Please confirm that you wish to cancel this vehicle relocation request.
            </Typography>
          </Stack>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={onDeleteHandler}
          />
        }
      />
    </LocalizationProvider>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Form
