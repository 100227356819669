import React from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

const VehicleContractTyres = ({
  noOfTyres,
  remainingTyres,
}) => {
  const theme = useTheme()
  console.log(noOfTyres, 'noOfTyres')
  console.log(remainingTyres, 'remainingTyres')
  return (
    <Stack
      gap={2}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight={600}>
          Contract Tyres
        </Typography>
      </Stack>

      <Stack
        border='1px solid'
        borderColor={theme.palette.accent.sky}
        borderRadius={1}
        gap={2}
        p={2}
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={noOfTyres ? "flex-start" : "center"}
        alignItems="center"
      >
        {noOfTyres ? (
          <>
          {[...Array(noOfTyres)]?.map((_, index) => (
            <Stack key={`tyre-${index}`}>
              <img
                src="/static/images/digital_service_book/wheel_active.png"
                style={{
                  width: 60,
                  height: 60,
                  opacity: remainingTyres >= index + 1 || remainingTyres === null ? "100%" : "20%"
                }}
              />
            </Stack>
          ))}
          </>
        ): (
          <Typography
            variant="h5"
            color={theme.palette.brand.red}
            textAlign="center"
            fontWeight={600}
          >
            {'No tyres are included in this contract.'}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default VehicleContractTyres