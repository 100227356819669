import * as moduleTypes from './types'


export const resetFlags = () => ({
  type: moduleTypes.RESET_FLAGS,
})

// Create Lease Information
export const createLeaseInformation = (payload, options) => ({
  type: moduleTypes.CREATE_LEASE_INFORMATION,
  payload,
  options,
})

export const createLeaseInformationError = (payload, options) => ({
  type: moduleTypes.CREATE_ERROR_LEASE_INFORMATION,
  payload,
  options,
})

export const createLeaseInformationFulfilled = (payload, options) => ({
  type: moduleTypes.CREATE_FULFILLED_LEASE_INFORMATION,
  payload,
  options,
})

// Create Vehicle Information
export const createVehicleInformation = (payload, options) => ({
  type: moduleTypes.CREATE_VEHICLE_INFORMATION,
  payload,
  options,
})

export const createVehicleInformationError = (payload, options) => ({
  type: moduleTypes.CREATE_ERROR_VEHICLE_INFORMATION,
  payload,
  options,
})

export const createVehicleInformationFulfilled = (payload, options) => ({
  type: moduleTypes.CREATE_FULFILLED_VEHICLE_INFORMATION,
  payload,
  options,
})

export const createErrorVehicleInformation = (payload, options) => ({
  type: moduleTypes.CREATE_ERROR_VEHICLE_INFORMATION,
  payload,
  options,
})

export const createFulfilledVehicleInformation = (payload, options) => ({
  type: moduleTypes.CREATE_FULFILLED_VEHICLE_INFORMATION,
  payload,
  options,
})

// Create Additional Services
export const createAdditionalServices = (payload, options) => ({
  type: moduleTypes.CREATE_ADDITIONAL_SERVICES,
  payload,
  options,
})

export const createAdditionalServicesError = (payload, options) => ({
  type: moduleTypes.CREATE_ERROR_ADDITIONAL_SERVICES,
  payload,
  options,
})

export const createAdditionalServicesFulfilled = (payload, options) => ({
  type: moduleTypes.CREATE_FULFILLED_ADDITIONAL_SERVICES,
  payload,
  options,
})

// Create Accessories
export const createAccessories = (payload, options) => ({
  type: moduleTypes.CREATE_ACCESSORIES,
  payload,
  options,
})

export const createAccessoriesError = (payload, options) => ({
  type: moduleTypes.CREATE_ERROR_ACCESSORIES,
  payload,
  options,
})

export const createAccessoriesFulfilled = (payload, options) => ({
  type: moduleTypes.CREATE_FULFILLED_ACCESSORIES,
  payload,
  options,
})


export const fetch = (payload, options) => ({
  type: moduleTypes.FETCH,
  payload,
  options,
})

export const fetchError = (payload, options) => ({
  type: moduleTypes.FETCH_ERROR,
  payload,
  options,
})

export const fetchFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_FULFILLED,
  payload,
  options,
})

export const fetchCorrespondingContract = (payload, options) => ({
  type: moduleTypes.FETCH_CORRESPONDING_CONTRACT,
  payload,
  options,
})

export const fetchCorrespondingContractError = (payload, options) => ({
  type: moduleTypes.FETCH_CORRESPONDING_CONTRACT_ERROR,
  payload,
  options,
})

export const fetchCorrespondingContractFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_CORRESPONDING_CONTRACT_FULFILLED,
  payload,
  options,
})

export const fetchContract = (payload, options) => ({
  type: moduleTypes.FETCH_CONTRACT,
  payload,
  options,
})

export const fetchContractError = (payload, options) => ({
  type: moduleTypes.FETCH_CONTRACT_ERROR,
  payload,
  options,
})

export const fetchContractFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_CONTRACT_FULFILLED,
  payload,
  options,
})

// Update Lease Information
export const updateLeaseInformation = (payload, options) => ({
  type: moduleTypes.UPDATE_LEASE_INFORMATION,
  payload,
  options,
})

export const updateLeaseInformationError = (payload, options) => ({
  type: moduleTypes.UPDATE_ERROR_LEASE_INFORMATION,
  payload,
  options,
})

export const updateLeaseInformationFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_FULFILLED_LEASE_INFORMATION,
  payload,
  options,
})

// Update Vehicle Information
export const updateVehicleInformation = (payload, options) => ({
  type: moduleTypes.UPDATE_VEHICLE_INFORMATION,
  payload,
  options,
})

export const updateVehicleInformationError = (payload, options) => ({
  type: moduleTypes.UPDATE_ERROR_VEHICLE_INFORMATION,
  payload,
  options,
})

export const updateVehicleInformationFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_FULFILLED_VEHICLE_INFORMATION,
  payload,
  options,
})

// Update Additional Services
export const updateAdditionalServices = (payload, options) => ({
  type: moduleTypes.UPDATE_ADDITIONAL_SERVICES,
  payload,
  options,
})

export const updateAdditionalServicesError = (payload, options) => ({
  type: moduleTypes.UPDATE_ERROR_ADDITIONAL_SERVICES,
  payload,
  options,
})

export const updateAdditionalServicesFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_FULFILLED_ADDITIONAL_SERVICES,
  payload,
  options,
})

// Update Accessories
export const updateAccessories = (payload, options) => ({
  type: moduleTypes.UPDATE_ACCESSORIES,
  payload,
  options,
})

export const updateAccessoriesError = (payload, options) => ({
  type: moduleTypes.UPDATE_ERROR_ACCESSORIES,
  payload,
  options,
})

export const updateAccessoriesFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_FULFILLED_ACCESSORIES,
  payload,
  options,
})

export const remove = (payload, options) => ({
  type: moduleTypes.DELETE,
  payload,
  options,
})

export const removeError = (payload, options) => ({
  type: moduleTypes.DELETE_ERROR,
  payload,
  options,
})

export const removeFulfilled = (payload, options) => ({
  type: moduleTypes.DELETE_FULFILLED,
  payload,
  options,
})

// for lookup field
export const lookup = (payload, options) => ({
  type: moduleTypes.LOOKUP,
  payload,
  options,
})

export const lookupError = (payload, options) => ({
  type: moduleTypes.LOOKUP_ERROR,
  payload,
  options,
})

export const lookupFulfilled = (payload, options) => ({
  type: moduleTypes.LOOKUP_FULFILLED,
  payload,
  options,
})