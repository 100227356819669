import * as z from "zod";

export const schema = z.object({
  accountName: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
  driver: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
  email: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" })
    .email("Must be a valid email"),
  mobile: z
    .string()
    .refine(value => /^02\d{7,8}$/.test(value), {
      message: 'Invalid mobile number',
    })
    .optional()
    .or(z.literal('')),
});
