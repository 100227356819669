const white = '#FFFFFF'
const black = '#000000'
const blue = '#1E88E5'

const brand = {
  red: '#EB0A1E',
  black: '#000000',
  white: '#FFFFFF'
}

const status = {
  error: '#EB0A1E',
  warning: '#FF8A00',
  success: '#00B6B0',
}

export default {
  black,
  white,
  primary: {
    main: status.success,
  },
  secondary: {
    main: '#E6EBF0',
  },
  error: {
    main: brand.red,
  },
  success: {
    main: status.success,
  },
  text: {
    primary: black,
    secondary: black,
    tertiary: black,
    link: blue,
  },
  link: "#1e88e5",
  icon: black,
  background: {
    default: '#E6EBF0',
    paper: white,
    block: '#fafafa',
    header: '#172A45'
  },
  // new figma colors
  brand: { ...brand },
  accent: {
    blue: '#002849',
    teal: '#00B6B0',
    sky: '#98B6BE',
  },
  status: { ...status },
  neutral: {
    mid: '#E6EBF0',
    mid100: 'rgb(230,235,240, 0.1)',
    mid200: 'rgb(230,235,240, 0.2)',
    mid300: 'rgb(230,235,240, 0.3)',
    mid400: 'rgb(230,235,240, 0.4)',
    mid500: 'rgb(230,235,240, 0.5)',
    mid600: 'rgb(230,235,240, 0.6)',
    mid700: 'rgb(230,235,240, 0.7)',
    mid800: 'rgb(230,235,240, 0.8)',
    mid00: 'rgb(230,235,240, 0.9)',
    light: '#F3F5F8',
  },
}