import React from 'react'
import PropTypes from 'prop-types'

import MenuV5 from '@mui/material/Menu'
import { MenuItemView } from './popover/MenuItemView'

import { constants as fromRequestReliefVehicles } from 'features/request-relief-vehicles'
import { constants as fromRequestVehicleRelocations } from 'features/request-vehicle-relocations'
import { constants as fromRequestContractVariations } from 'features/request-contract-variations'
import { constants as fromRequestVehicleSummary } from 'features/request-vehicle-summary'

import { useEnabledFeatureToggleList } from 'features/settings'
import { useLocation } from "react-router"

const MenuList = [
  {
    title: 'Relief Vehicle Booking Summary',
    path: fromRequestReliefVehicles.PATH_ALL
  },
  {
    title: 'Vehicle Relocations Summary',
    path: fromRequestVehicleRelocations.PATH_ALL
  },
  {
    title: 'Contract Variations Summary',
    path: fromRequestContractVariations.PATH_ALL
  },
  {
    title: 'Vehicle Summary',
    path: fromRequestVehicleSummary.PATH_ALL
  },
]

const ActionsPopover = ({
  anchor,
  linker,
  open,
  onClose
}) => {
  const location = useLocation()
  
  const featureToggleList = useEnabledFeatureToggleList()
  const vehicleSummary = featureToggleList.includes('Vehicle request form and report (TFP-2162)');

  if ( ! anchor.current) {
    return null
  }

  return (
    <MenuV5
      id="menu-reports"
      anchorEl={anchor.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: {
            '& ul': {
              padding: 0,
            }
          }
        },
      }}
    >
      <div>
        {MenuList?.map((menuListItem) => {
          if (menuListItem.path === fromRequestVehicleSummary.PATH_ALL && vehicleSummary) {
            return (
              <MenuItemView
                key={menuListItem?.title}
                title={menuListItem?.title}
                onClick={linker(menuListItem?.path)}
                isActive={menuListItem?.path === location.pathname}
              />
            )
          }

          return (
            <MenuItemView
              key={menuListItem?.title}
              title={menuListItem?.title}
              onClick={linker(menuListItem?.path)}
              isActive={menuListItem?.path === location.pathname}
            />
          )
        })}
      </div>
    </MenuV5>
  )
}

ActionsPopover.propTypes = {
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default ActionsPopover
