import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useFleetId } from 'features/settings'
import { FleetNotFound } from 'frame/components'
import { Box, Grid } from '@material-ui/core'
import List from './list'
import DocumentPanel from './document-panel'
import { useMeasure } from 'utils'

const useStyles = makeStyles(theme => ({
  documentPanel: {
    top: 12,
    left: 20,
    padding: 0,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      position: 'static',
      padding: theme.spacing(2, 2, 4, 2),
    },
  },
  documentList:{
    maxHeight: Math.round(window.innerHeight - 240) + 'px',
    overflowY: 'auto',
    marginBottom: '0px',
    marginTop: '24px',
  }
}))

const ListWrapper = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const fleetId = useFleetId()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const [ref, { width: documentPanelWidth }] = useMeasure()
  if ( ! fleetId) {
    return (
      <FleetNotFound title={props.config.title} />
    )
  }
  
  const documentListProps = {...props, documentListClass:props.config.title==='Documents' ? classes.documentList : null};
  
  const isSupportPDFPreview = navigator.mimeTypes && navigator.mimeTypes['application/pdf'] && navigator.mimeTypes['application/pdf'].enabledPlugin

  return(
    <Box component={mobileView ? 'div' : Grid} container>
      <Box component={mobileView ? 'div' : Grid} item md={props.documentPreviewUri ? 4 : 12} sm={12}>
        <List {...documentListProps} fleetId={fleetId} driverAssignmentColumnHeaderInjection={props.driverAssignmentColumnHeaderInjection}/>
      </Box>        
      {isSupportPDFPreview && props.documentPreviewUri && (
        <Box component={mobileView ? 'div' : Grid} item md={8} sm={12} style={{ background: '#FFF' }} ref={ref}>
          <div className={classes.documentPanel}>
            <DocumentPanel
              fileUri={props.documentPreviewUri}
              height={Math.round(window.innerHeight - 133)}
              width={Math.round(documentPanelWidth - 40)}
            />
          </div>
        </Box>
      )}
    </Box>
  )
}

export default ListWrapper
