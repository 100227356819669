import React from "react"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const MuiTextField = styled((props) => {
  return (
    <TextField
      variant="filled"
      size="small"
      InputProps={{
        disableUnderline: true,
        sx: {
          fontSize: 14,
          color: props?.disabled ? "rgba(0, 0, 0, 0.38)" : "#000000",
        }
      }}
      InputLabelProps={{
        sx: {
          fontSize: 12,
          color: props?.disabled ? "rgba(0, 0, 0, 0.38)" : "#00B6B0",
          top: '3px',
        }
      }}
      {...props}
    />
  )
})(() => ({
  '& .MuiInputLabel-root.Mui-error': {
    boxShadow: 'none',
  },
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 0px 1px #98B6BE inset',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    '&.Mui-disabled': {
      background: '#F3F5F8'
    }
  },
  '& .MuiInputBase-input': {
    paddingTop: '18px',
    paddingBottom: '10px',
  },
  '& textarea': {
    paddingTop: `0px !important`,
  },
  '& .Mui-error': {
    boxShadow: '0px 0px 0px 1px #EB0A1E inset',
  }
}));

export default MuiTextField;