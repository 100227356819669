import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { constants } from '../../module'
import { useSelectedFleets } from 'features/settings'
import { Dialog } from 'frame/components'
import { trimObjectStrings, useNavigate } from 'utils'
import Form from './form'

import AssignForm from '../assign-form'
import ConfirmForm from '../confirm-form'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'
import Typography from "@mui/material/Typography"

const Add = ({ saveStatus, onSave, onLookup }) => {

  const navigate = useNavigate()
  const userSelectedFleets = useSelectedFleets()
  
  const [values, setValues] = useState({
    accountName: userSelectedFleets.length === 1 ? userSelectedFleets[0].label : null,
    driverNotification: 'In App', // initialize to avoid null param
  })

  const [saved, setSaved] = useState(false)
  // show form states
  const [showAssignForm, setShowAssignForm] = useState(false)
  const [showConfirmForm, setShowConfirmForm] = useState(false)
  
  // assign vehicle form states
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [searchHistory, setSearchHistory] = useState(null)
  
  // modal
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false)

  const onShowConfirmForm = () => { // assign form - proceed button
    setShowAssignForm(false)
    setShowConfirmForm(true)
  }

  const onShowAddDriverForm = () => { // assign form - back button
    setShowAssignForm(false)
    setShowConfirmForm(false) 
  }

  const onShowAssignForm = () => { // confirm form - back button
    setShowAssignForm(true)
    setShowConfirmForm(false) 
  }

  const onSubmit = (data) => {
    onSave(trimObjectStrings(data))
    console.log('[onSubmit add driver]: ', trimObjectStrings(data))
    setSaved(true)
  }

  const onClose = navigate(constants.PATH_ALL)

  useEffect(() => {
    // triggers when form is saved, save flags will update and close the form automatically
    if (saved && saveStatus === 'processed') {
      setValues({})
      onClose()
    }
  }, [saveStatus]) // eslint-disable-line

  const isSaving = saveStatus === 'processing'
  // const savingError = saveStatus === 'error'
  
  const driverName = values?.driver ? ` - ${values?.driver}` : ''
  const title = showAssignForm
    ? `Assign Vehicle to Driver${driverName}`
    : showConfirmForm && selectedVehicle
      ? selectedVehicle.text
      :  `Add New Driver`
  
  const hasValue = x => x && x.length > 0

  const showExitPrompt = (userSelectedFleets.length > 1 && values.accountName) || values.driverNotification !== 'n/a'
    || hasValue(values.driver) || hasValue(values.email) || hasValue(values.mobile) 
  
  const onModalClose = () => // unsaved changes prompt if any values has changed
    showExitPrompt
      ? setOpenUnsavedChangesModal(true)
      : onClose()

  return (
    <Dialog
      open autoSize
      size="md"
      aria-labelledby="Driver"
      title={title}
      loading={isSaving}
      onClose={onModalClose}
      sx={{
        height: "70%"
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          {showAssignForm ? (
            <AssignForm
              values={values}
              setValues={setValues}
              disabled={isSaving}
              selectedVehicle={selectedVehicle}
              onLookup={onLookup}
              onProceed={onShowConfirmForm}
              onBack={onShowAddDriverForm}
              setSelectedVehicle={setSelectedVehicle}
              searchHistory={searchHistory}
              setSearchHistory={setSearchHistory}
            />
          ) : showConfirmForm ? (
            <ConfirmForm
              selectedVehicle={selectedVehicle}
              values={values}
              setValues={setValues}
              disabled={isSaving}
              onSubmit={onSubmit}
              onClose={onModalClose}
              onBack={onShowAssignForm}
              onLookup={onLookup}
            />
          ) : (
            <Form
              // error={savingError}
              values={values}
              setValues={setValues}
              disabled={isSaving}
              onClose={onModalClose}
              onSubmit={onSubmit}
              setShowAssignForm={setShowAssignForm}
            />
          )}
        </Grid>
      </Grid>

      <StyledModalV2
        open={openUnsavedChangesModal}
        onClose={() => setOpenUnsavedChangesModal(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenUnsavedChangesModal(false)}
            onConfirm={onClose}
          />
        }
      />
    </Dialog>
  )
}

Add.propTypes = {
  saveStatus: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
}

export default Add
