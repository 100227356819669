import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'

export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const createLeaseInformationEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE_LEASE_INFORMATION,
    url: join(appConfig.app.api, 'Vehicle/CreateLeaseInformationRequest'), 
    verb: 'post',
    onSuccess: moduleActions.createLeaseInformationFulfilled,
    onError: moduleActions.createLeaseInformationError,
  })

  const createVehicleInformationEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE_VEHICLE_INFORMATION,
    url: join(appConfig.app.api, 'Vehicle/CreateVehicleInformationRequest'), 
    verb: 'post',
    onSuccess: moduleActions.createVehicleInformationFulfilled,
    onError: moduleActions.createVehicleInformationError,
  })

  const createAdditionalServicesEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE_ADDITIONAL_SERVICES,
    url: join(appConfig.app.api, 'Vehicle/CreateAdditionalServices'), 
    verb: 'post',
    onSuccess: moduleActions.createAdditionalServicesFulfilled,
    onError: moduleActions.createAdditionalServicesError,
  })

  const createAccessoriesEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE_ACCESSORIES,
    url: join(appConfig.app.api, 'Vehicle/CreateAccessories'), 
    verb: 'post',
    onSuccess: moduleActions.createAccessoriesFulfilled,
    onError: moduleActions.createAccessoriesError,
  })

  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
      url: join(appConfig.app.api, 'Report/GetAllVehicleRequest'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  const onFetchedEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromModuleTypes.FETCH_FULFILLED),
      map(({ payload }) => {
        const FleetId = payload.thirdPartyNumber
        const options = {
          resourceId: payload.agreementNo,
          queryParams: { FleetId }
        }
        return moduleActions.fetchCorrespondingContract({}, options)
      })
    )

  const fetchCorrespondingContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CORRESPONDING_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchCorrespondingContractFulfilled,
    onError: moduleActions.fetchCorrespondingContractError,
  })

  const updateLeaseInformationEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE_LEASE_INFORMATION,
    url: join(appConfig.app.api, 'Vehicle/UpdateLeaseInformationRequest'),
    verb: 'put',
    onSuccess: moduleActions.updateLeaseInformationFulfilled,
    onError: moduleActions.updateLeaseInformationError,
  })

  const updateVehicleInformationEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE_VEHICLE_INFORMATION,
    url: join(appConfig.app.api, 'Vehicle/UpdateVehicleInformationRequest'),
    verb: 'put',
    onSuccess: moduleActions.updateVehicleInformationFulfilled,
    onError: moduleActions.updateVehicleInformationError,
  })

  const updateAdditionalServicesEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE_ADDITIONAL_SERVICES,
    url: join(appConfig.app.api, 'Vehicle/UpdateAdditionalServices'), 
    verb: 'put',
    onSuccess: moduleActions.updateAdditionalServicesFulfilled,
    onError: moduleActions.updateAdditionalServicesError,
  })

  const updateAccessoriesEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE_ACCESSORIES,
    url: join(appConfig.app.api, 'Vehicle/UpdateAccessories'), 
    verb: 'put',
    onSuccess: moduleActions.updateAccessoriesFulfilled,
    onError: moduleActions.updateAccessoriesError,
  })

  const fetchContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchContractFulfilled,
    onError: moduleActions.fetchContractError,
  })

  const deleteEffect = makeActionRequestEffect({
    type: fromModuleTypes.DELETE,
    url: join(appConfig.app.api, 'vehiclerelocation/delete'),
    verb: 'delete',
    onSuccess: moduleActions.removeFulfilled,
    onError: moduleActions.removeError,
  })

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        fromModuleTypes.CREATE_FULFILLED_LEASE_INFORMATION,
        fromModuleTypes.UPDATE_FULFILLED_LEASE_INFORMATION,
        fromModuleTypes.CREATE_FULFILLED_ADDITIONAL_SERVICES,
        fromModuleTypes.UPDATE_FULFILLED_ADDITIONAL_SERVICES,
        fromModuleTypes.CREATE_FULFILLED_ACCESSORIES,
        fromModuleTypes.UPDATE_FULFILLED_ACCESSORIES,
        fromModuleTypes.DELETE_FULFILLED
      ),
      mergeMap(() => of(
        moduleActions.refetch(),
      ))
    )

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
  })

  return combineEpics(
    createLeaseInformationEffect,
    fetchEffect,
    fetchCorrespondingContractEffect,
    fetchContractEffect,
    updateLeaseInformationEffect,
    refetchEffect,
    onFetchedEffect,
    deleteEffect,
    lookupEffect,
    createVehicleInformationEffect,
    createAdditionalServicesEffect,
    updateAdditionalServicesEffect,
    updateVehicleInformationEffect,
    createAccessoriesEffect,
    updateAccessoriesEffect,
  )
}