import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from "@mui/material/styles"
import MuiSwitch from "frame/components/switch"

const servicesMenu = [
  {
    title: "Fuel Card and Reporting",
    name: "FuelCardAndReporting"
  },
  {
    title: "Relief Vehicle (14 Days)*",
    name: "ReliefVehicle"
  },
  {
    title: "RUC Management",
    name: "RUCManagement"
  },
  {
    title: "Accident Management",
    name: "AccidentManagement"
  },
  {
    title: "Toll Management / Recharge",
    name: "TollManagementRecharge"
  },
  {
    title: "Infringement Management Recharge",
    name: "InfringementManagementRecharge"
  },
  {
    title: "FBT Reporting",
    name: "FBTReporting"
  },
  {
    title: "Telematics",
    name: "Telematics"
  },
  {
    title: "Fleet Portal",
    name: "FleetPortal"
  },
  {
    title: "Driver App",
    name: "DriverApp"
  }
]

export const AdditionalServicesForm = ({
  title,
  services,
  setServices,
}) => {
  const theme = useTheme()
  return (
    <Stack>
      <Stack
        pb={3}
        borderBottom="1px solid"
        borderColor={theme.palette.neutral.mid}
      >
        <Typography variant="h6" fontWeight={600}>{title}</Typography>
      </Stack>
      <Stack>
        {servicesMenu?.map((service) => {
          return (
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1}
              py={2}
              borderBottom="1px solid"
              borderColor={theme.palette.neutral.mid}
              key={service.name}
            >
              <Stack flex={1}>
                <Typography variant="h6" maxWidth={190}>{service.title}</Typography>
              </Stack>
              <Stack flex={1}>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={Boolean(services[service?.name])}
                    onChange={(event) => {
                      setServices({
                        ...services,
                        [service?.name]: !Boolean(services[service?.name])
                      })
                    }}
                    name={service.name}
                  />
                }
                label=""
              />
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
