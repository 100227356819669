export default {
  styleOverrides: {
    root: {
      "& span": {
        fontSize: 14,
        fontWeight: 600,
      }
    },
  }
}
