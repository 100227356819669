import React from 'react'
import PropTypes from 'prop-types'
import SortableLabel from './page-list-table-header-sortable-label'
import { useEnabledFeatureToggleList } from 'features/settings'

import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTheme } from "@mui/material/styles"

const PageListTableHeader = ({ noColumnSort, headers, selected, displayRowsCount, columnsSort, onToggleColumnsSort, onCheckAll }) => {
  const theme = useTheme();
  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1839FeatureToggle = featureToggleList.includes('TFP1839')
  const tfp1929FeatureToggle = featureToggleList.includes('TFP1929')
  const tfp1838FeatureToggle = featureToggleList.includes('TFP1838')
  const tfp1845FeatureToggle = featureToggleList.includes('TFP1845')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp1935FeatureToggle = featureToggleList.includes('TFP1935')
  const tfp1971FeatureToggle = featureToggleList.includes('TFP1971')
  const tfp1978FeatureToggle = featureToggleList.includes('TFP1978')
  const tfp2005FeatureToggle = featureToggleList.includes('TFP2005')

  const isAdminUsersTable = window.location.pathname === '/admin/users' || '/admin/user-invitations'

  const createWidthStyles = (width) => ({
    width: `${width}px`,
    minWidth: `${width}px`,
    maxWidth: `${width}px`,
  })
  
  const isDriverPage = window.location.pathname.includes('/drivers')
  const isFbtPages = window.location.pathname.includes('/fbt')
  const isVehicleServicingReport = window.location.pathname.includes('/reports/vehicle-servicing')
  const isMyFleetPage = window.location.pathname.includes('/fleet')
  const isWofReport = window.location.pathname.includes('/reports/wof-registration')
  const isExtensionRentalsReport = window.location.pathname.includes('/reports/extension-rentals')

  // const onSelectAll = ({ target: { checked } }) =>
  //   onCheckAll(checked)
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            margin: 0,
            padding: 0,
            width: '10px',
            minWidth: '10px',
            maxWidth: '10px',
          }}
        />
       
        {headers.map((header, index) => {
          const headerIndex = `page-header-${header}-${index}`
          
          if (
              (isDriverPage && header.key === 'agreementNo')  // hide agreementNo column heading on driver report page
              || (isFbtPages && header.key === 'accountName') // hide accountName column heading on fbt page
              // hide document count column when documentsReportFeatureToggle is off
              || (!documentsReportFeatureToggle && header.key === 'documentsCount')              
              // hide controller column when tfp1839FeatureToggle is off, and page is Vehicle Service
              || (!tfp1839FeatureToggle && header.key === 'controller' && isVehicleServicingReport)
              // hide vehicle notes column when tfp1929FeatureToggle is off, and page is MyFleet
              || (!tfp1929FeatureToggle && header.key === 'vehicleNotes' && isMyFleetPage)
              // hide contact email column when tfp1838FeatureToggle is off
              || (!tfp1838FeatureToggle && header.key === 'contactEmail')
              // hide contract end km column when tfp1845FeatureToggle is off, and page is MyFleet
              || (!tfp1845FeatureToggle && header.key === 'contractEndKm' && isMyFleetPage)              
              // hide driver note column when tfp1944FeatureToggle is off
              || (!tfp1944FeatureToggle && header.key === 'driverNote')
              // hide controller column when tfp1935FeatureToggle is off, and page is WoF
              || (!tfp1935FeatureToggle && header.key === 'controller' && isWofReport)
              // hide contact email column when tfp1838FeatureToggle is off
              || (!tfp1838FeatureToggle && header.key === 'contactEmail')
              // hide original maturity date column when tfp1971FeatureToggle is off
              || (!tfp1971FeatureToggle && header.key === 'originalMaturityDate')
              // hide original maturity date column when tfp2005FeatureToggle is off
              || ((isVehicleServicingReport || isMyFleetPage) && !tfp2005FeatureToggle && header.key === 'originalMaturityDate')
              // hide original maturity date column when tfp2005FeatureToggle is off
              || (isVehicleServicingReport && !tfp2005FeatureToggle && header.key === 'maturityDate')
              // hide cost centre column when tfp1978FeatureToggle if off, and page is Extension Rentals
              || (!tfp1978FeatureToggle && header.key === 'costCentre' && isExtensionRentalsReport)
          )
            return null

          if (typeof header === 'string') {
            return (
              <TableCell key={headerIndex}>
                {header}
              </TableCell>
            )
          }

          if (header.key === 'checkbox') { // Checkbox column header
            return (
              <TableCell key={headerIndex} align="right" className="ignore" style={{ minWidth: 110 }}>
                {/* <Checkbox
                  color="default"
                  checked={selected.length >= 1 && selected.length === displayRowsCount}
                  onChange={onSelectAll}
                  className={classes.checkbox}
                /> */}
              </TableCell>
            )
          }
          
          if (header && header.visible && header.label) {

            const css = header.width
              ? createWidthStyles(header.width)
              : {}

            if (header.sortKey && columnsSort) {
              return (
                <TableCell key={headerIndex} style={css}>
                  <SortableLabel
                    toggleAction={header.toggleAction}
                    toggleValue={header.toggleValue}
                    showToggle={header.showToggle}
                    toolTip={header.toolTip}
                    noSort={noColumnSort}
                    active={header.sortKey === columnsSort.column}
                    center={header.center}
                    direction={columnsSort.direction}
                    onClick={() => onToggleColumnsSort(header.sortKey)}>
                    {header.label}
                  </SortableLabel>
                </TableCell>
              )
            }

            return (
              <TableCell key={headerIndex} style={css}>
                {header.label} 
              </TableCell>
            )
          }

          return null
        })}

        {!isDriverPage && (
          <TableCell 
            sx={{
              // minWidth: isAdminUsersTable ? 120 : 60,
              flex: 1,
              margin: 0,
              padding: 0,
            }}
          />
        )}
      </TableRow>
    </TableHead>
  )
}

const Header = PropTypes.shape({
  center: PropTypes.bool,
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  sortKey: PropTypes.string,
  width: PropTypes.number,
})

PageListTableHeader.propTypes = {
  noColumnSort: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, Header])).isRequired,
  selected: PropTypes.array,
  displayRowsCount: PropTypes.number,
  columnsSort: PropTypes.shape({ column: PropTypes.string, direction: PropTypes.string }),
  onToggleColumnsSort: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
}

export default PageListTableHeader
