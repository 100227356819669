import { useEffect, useState } from "react"
import { getAppConfig } from "app-config"
import { getAuthClient } from "auth"

export const useCheckEmailAvailable = ({
  value,
  isValid
}) => {
  const [status, setStatus] = useState({
    isAlreadyExist: null
  })
  const [isLoading, setLoading] = useState(false)

  const checkEmail = async (emailValue) => {
    setLoading(true)
    const config = getAppConfig()
    const authClient = getAuthClient()
    const accessToken = await authClient.getTokenSilently()
    const headers = { Authorization: `Bearer ${accessToken}` }

    const url = new URL(`${config.app.api}lookup/EmailExists`)
    url.searchParams.set("Search", emailValue)

    const response = await fetch(url.toString(), { headers })
    const responseData = await response.json()
    setLoading(false)

    return setStatus({
      isAlreadyExist: !responseData.apiResponse?.success && responseData.apiResponse?.message !== "",
    })
  }

  useEffect(() => {
    /**
     * Check: initial value of email is undefined, meaning email field is not yet dirty.
     * if value === undefined and isValid === true
     * dont check email yet
     */
    if (value === undefined && isValid) {
      return
    }
    /**
     * Check if value is not empty but invalid value
     */
    if (value && value.length <= 5 || !isValid) {
      return
    }

    checkEmail(value)
    return
  }, [value, isValid])

  return {
    isAlreadyExists: status.isAlreadyExist,
    isLoading,
  }
}