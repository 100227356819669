import React from 'react'
import { Layout } from 'frame'
import { Page } from 'frame/components'
import { GraphEndOfLease } from 'features/graph-end-of-lease'
import { GraphKmTracking } from 'features/graph-km-tracking'
import { GraphMaturing } from 'features/graph-maturing'
import { GraphOnOrder } from 'features/graph-on-order'
import { GraphServiceExpiry } from 'features/graph-service-expiry'
import { GraphWofExpiry } from 'features/graph-wof-expiry'
import { GraphVehicleOnExtension } from 'features/graph-vehicle-on-extension'

import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from '@mui/material/Grid'

const Dashboard = () => {
  return (
    <Layout>
      <Stack padding={3}>
        <Page title="Dashboard">
          <Stack pb={3}>
            <Typography variant="h2" fontWeight={600}>
              Dashboard Overview
            </Typography>
          </Stack>
          
          <Grid 
            container
            spacing={3}
            sx={{
              position: 'relative',
              '& > *': {
                height: '100%'
              }
            }}
          >
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <GraphMaturing />
            </Grid>
            
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <GraphOnOrder />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <GraphWofExpiry />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <GraphEndOfLease />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <GraphKmTracking />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <GraphServiceExpiry />
            </Grid>
            
            <Grid item xs={12}>
              <GraphVehicleOnExtension />
            </Grid>
          </Grid>
        </Page>
      </Stack>
    </Layout>
  )
}

export default Dashboard
