import * as z from "zod";

export const schema = z.object({
  completedLocation: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "Please enter a valid name" }),
  odometer: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "A valid odometer reading is required" })
    .or(z.literal('')),
});
