import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StyledModal } from "frame/components"
import { useIsAlfaDownDisableFeatures } from "features/drivers"
import { useEnabledFeatureToggleList } from "features/settings"
import { hasRoles } from "acl"
import moment from "moment"
import {isMiles, isSourceSystemLoaded} from "utils/sourceSystem"
import Spinner from "react-md-spinner"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import MuiSwitch from "frame/components/switch/switch"
import AssignedViewDetails from "./assigned-view-details"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

const AssignedView = ({ 
  driverFlags, 
  fleetData, 
  driverData, 
  userRoles, 
  onUnassignVehicle, 
  onTerminateDriver, 
  onTogglePoolVehicle, 
  onAssignExistingDriver,
  onOpenDateForm 
  }) => {

  const theme = useTheme()
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes("OutOfHoursDisabled") // "ALFA out of hours DISABLE FEATURES" limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  const disableTerminateDriverFeatureToggle = featureToggleList.includes("TFP1169")
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")
  const showButtons = userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(["superadmin","fleetcontroller"], userRoles) : true
  const disableButtons = userPermissionsFeatureToggle && hasRoles(["fleetcoordinator"], userRoles)
  const [isPoolVehicle, setIsPoolVehicle] = useState(fleetData.poolVehicle && fleetData.poolVehicle ? true : false)

  // modal flags
  const [openConfirmPoolVehicleToggleModal, setConfirmPoolVehicleToggleModal] = useState(false)
  const [openMakeUnassignedVehicleToggleModal, setMakeUnassignedVehicleToggleModal] = useState(false)
  const [openTerminateDriverModal, setOpenTerminateDriverModal] = useState(false)

  useEffect(() => {
    // reset toggle if togglePoolVehicle fails with error flag 
    if(driverFlags.togglePoolVehicle === "error") {
      setIsPoolVehicle(false)
    }
    
  // eslint-disable-next-line
  }, [driverFlags.togglePoolVehicle])

  useEffect(() => {
    // update pool vehicle toggle when fetch once done
    setIsPoolVehicle(fleetData.poolVehicle && fleetData.poolVehicle === true)
  // eslint-disable-next-line
  }, [fleetData.poolVehicle])
  
  const handlePoolVehicleToggle = (checked) => {;
    setIsPoolVehicle(checked === 'on' ? true : false)
    if (isMiles(fleetData.sourceSystem)) {
      onOpenDateForm()
    } else {
      setConfirmPoolVehicleToggleModal(true)
    }
  }

  const onConfirmPoolVehicleChange = () => {
    setConfirmPoolVehicleToggleModal(false)
    onTogglePoolVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber, moment(), isPoolVehicle)
  }
  
  const onCancelHandler = () => {
    handlePoolVehicleToggle(fleetData.poolVehicle && fleetData.poolVehicle === "Yes") // reset default values
    setConfirmPoolVehicleToggleModal(false)
    setIsPoolVehicle(false)
  }

  const onMakeUnassignedVehicle = () => {
    onUnassignVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber)
    setMakeUnassignedVehicleToggleModal(false)
  }
  
  return (
    <Card
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
      }}
    >
      <CardContent
        sx={{
          p: 3,
        }}
      >
        <Stack gap={2} pb={3}>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={{ xs: 1, md: 3 }}>
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                variant="outlined"
                data-cy="unassign-driver"
                disabled={isAlfaDown || disableButtons}
                onClick={() => setMakeUnassignedVehicleToggleModal(true)}
              >
                Make Unassigned Vehicle
              </Button>
            </Box>
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                variant="outlined"
                data-cy="terminate-driver"
                disabled={disableTerminateDriverFeatureToggle || isAlfaDown || disableButtons}
                onClick={() => setOpenTerminateDriverModal(true)}
              >
                Terminate Driver
              </Button>
            </Box>
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                data-cy="change-driver"
                disabled={isAlfaDown || disableButtons}
                onClick={onAssignExistingDriver}
              >
                {!driverData.driver ? "Assign" : "Change"} Driver
              </Button>
            </Box>
          </Stack>

          {showButtons && isSourceSystemLoaded(fleetData.sourceSystem) && (
            <Stack flexDirection="column" alignItems="flex-start">
              <Typography
                variant="h5"
                color={theme.palette.black}
                fontWeight={600}
              >
                Pool Vehicle
              </Typography>
                
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Typography
                  variant="h5"
                  color={theme.palette.black}
                >
                  Indicate if you would like to switch this to a pool vehicle
                </Typography>
                <MuiSwitch
                  disabled={isAlfaDown}
                  checked={isPoolVehicle ? true : false}
                  onChange={(event) => handlePoolVehicleToggle(event.target.value)}
                  name="poolVehicleSwitch"
                />
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" fontWeight={600}>
            Existing Driver Details
          </Typography>

          {!isSourceSystemLoaded(fleetData.sourceSystem) && (
            <Stack py={10} justifyContent="center" alignItems="center">
              <Spinner
                singleColor={theme.palette.dark}
                size={25}
              />
            </Stack>
          )}

          <AssignedViewDetails
            items={[
              {
                label: "Name",
                value: driverData.driver || "-",
              },
              {
                label: "Email",
                value: driverData.email || "-",
              },
              {
                label: "Phone",
                value: driverData.mobile || "-",
              },
              {
                label: "Notification Method",
                value: driverData.driverNotification || "-",
              }
            ]}
          />
        </Stack>
     
        <>
          {/* for make unassigned vehicle confirmation */} 
          <StyledModalV2
            open={openMakeUnassignedVehicleToggleModal}
            onClose={() => setMakeUnassignedVehicleToggleModal(false)}
            title="Removal of Assigned Driver"
            content={
              <Typography variant="h4" textAlign="center">
                Please confirm that you wish to remove {driverData.driver} from this vehicle, and make it unassigned with no specified driver.
              </Typography>
            }
            actions={
              <StyledModalV2Actions
                onCancel={() => setMakeUnassignedVehicleToggleModal(false)}
                onConfirm={onMakeUnassignedVehicle}
              />
            } 
          />         

          {/* for terminate driver confirmation */}
          <StyledModalV2
            open={openTerminateDriverModal}
            onClose={() => setOpenTerminateDriverModal(false)}
            content={
              <Stack gap={2} justifyContent="center" alignItems="center">
                <Typography variant="h4" textAlign="center">
                  <strong>IMPORTANT: Please confirm only if {driverData?.driver || "the driver"} no longer works for your organisation.</strong> 
                </Typography>
                <Typography variant="h4" textAlign="center">
                  Please <strong>CANCEL</strong> if you are simply changing a driver/vehicle combination and use <strong>CHANGE DRIVER</strong>.
                </Typography>
              </Stack>
            }
            actions={
              <StyledModalV2Actions
                onCancel={() => setOpenTerminateDriverModal(false)}
                onConfirm={() => onTerminateDriver(driverData.uniqueId, driverData.thirdPartyNumber)}
              />
            } 
          /> 

          {/* for pool vehicle toggle confirmation */}
          <StyledModalV2
            open={openConfirmPoolVehicleToggleModal}
            onClose={() => setConfirmPoolVehicleToggleModal(false)}
            content={
              <Typography variant="h4" textAlign="center">
                {isPoolVehicle
                  ? driverData.driver
                    ? "Please confirm that you wish to designate this as a pool vehicle. By doing so, you will be removing the assigned driver from the vehicle. "
                    : "Please confirm that you wish to designate this as a pool vehicle."
                  : "You are about to switch this to a non pool vehicle"
                }
              </Typography>
            }
            actions={
              <StyledModalV2Actions
                onCancel={onCancelHandler}
                onConfirm={onConfirmPoolVehicleChange}
              />
            } 
          /> 
        </>
      </CardContent>
    </Card>
  )
}

AssignedView.propTypes = {
  driverFlags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  driverData: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onUnassignVehicle: PropTypes.func.isRequired,
  onTerminateDriver: PropTypes.func.isRequired,
  onTogglePoolVehicle: PropTypes.func.isRequired,
  onAssignExistingDriver: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOpenDateForm: PropTypes.func.isRequired
}

export default AssignedView

