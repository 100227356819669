import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.css'

const NavLinkItem = ({
  label,
  path,
  props,
}) => {
  return (
    <NavLink
      to={path}
      className={styles.nav}
      activeClassName={styles.activeNav}
      {...props}
    >
      {label}
    </NavLink>
  )
}

export default NavLinkItem