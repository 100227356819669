import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledModal } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

const PoolVehicleView = ({
  fleetData,
  onCreateNewDriver,
  onAssignExistingDriver,
  // onTogglePoolVehicle,
  onUnassignVehicle,
  userRoles
}) => {
  const theme = useTheme()
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures  
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  // modal flags
  const [openMakeUnassignedVehicleToggleModal, setMakeUnassignedVehicleToggleModal] = useState(false)

  const onMakeUnassignedVehicle = () => { // Make unassigned vehicle when vehicle is a pool vehicle so need to call onTogglePoolVehicle
    // onTogglePoolVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber, false) // make fleet poolVehicle null
    onUnassignVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber)
    setMakeUnassignedVehicleToggleModal(false)
  }
  
  return (
    <Card
      sx={{
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    >
      <CardContent
        sx={{
          p: 3
        }}
      >
        <Stack
          border='1px solid'
          borderColor={theme.palette.accent.sky}
          padding={2}
          borderRadius={1}
          gap={2}
        >
          <Typography variant="h4">
            The vehicle is currently being used as a pool vehicle therefore no driver details are required at this time. Click on one of the options below if you would like to assign a driver to the vehicle or to make this an unassigned non-pool vehicle.
          </Typography>

          <Stack flexDirection="row" gap={3}>
            <Stack minWidth={{ xs: '100%', md: '205px' }}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="assign-driver-option"
                data-cy="assign-driver-option"
                variant="contained"
                onClick={onAssignExistingDriver}
              >
                Assign Existing Driver
              </Button>
            </Stack>
            <Stack minWidth={{ xs: '100%', md: '205px' }}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="create-new-driver-option"
                data-cy="create-new-driver-option"
                variant="contained"
                onClick={onCreateNewDriver}
              >
                Create New Driver
              </Button>
            </Stack>
            <Stack minWidth={{ xs: '100%', md: '205px' }}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="make-unassigned-vehicle-option"
                data-cy="make-unassigned-vehicle-option"
                variant="contained"
                onClick={() => setMakeUnassignedVehicleToggleModal(true)}
              >
                Make Unassigned Vehicle
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <StyledModalV2
          open={openMakeUnassignedVehicleToggleModal}
          onClose={() => setMakeUnassignedVehicleToggleModal(false)}
          title="Removal of Assigned Driver"
          content={
            <Typography variant="h4" textAlign="center" sx={{ px: 8 }}>
              Please confirm that you would like to change this from a pool vehicle to a non-pool vehicle with no specified driver.
            </Typography>
          }
          actions={
            <StyledModalV2Actions
              onCancel={() => setMakeUnassignedVehicleToggleModal(false)} 
              onConfirm={onMakeUnassignedVehicle}
            />
          }
        />
      </CardContent>
    </Card>
  )
}

PoolVehicleView.propTypes = {
  fleetData: PropTypes.object.isRequired,
  onCreateNewDriver: PropTypes.func.isRequired,
  onAssignExistingDriver: PropTypes.func.isRequired,
  // onTogglePoolVehicle: PropTypes.func.isRequired,
  onUnassignVehicle: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PoolVehicleView


