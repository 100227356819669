import React, { useCallback, useState, useEffect, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Listable } from "listable"
import { useNavigate } from "utils"
import Row from "./row"
import Filters from "../filters"
import { ChildRoutes } from "../../routes"
import { remove, reset, inviteDriver } from "../../actions"
import { actions, config, constants } from "../../module"
// import { useInView } from "react-intersection-observer"
import { useIsAlfaDownDisableFeatures } from "features/drivers"
import { useEnabledFeatureToggleList } from "features/settings"
import { hasRoles } from "acl"

import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import StyledModalV2 from "frame/components/styled-modal-v2"

const List = ({ model, fleetIds, selectedDrivers, onRemove, onReset, onSelectRow, onInviteDrivers, onSelectAll,userRoles }) => {

  const linker = useNavigate()
  const featureToggleList = useEnabledFeatureToggleList()

  const disableOutOfHoursFeatureToggle = featureToggleList.includes("OutOfHoursDisabled") // "ALFA out of hours DISABLE FEATURES" limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  // const [ref, inView] = useInView({ threshold: 0 }) // for triggering the confirmation modal timer when its in view 

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showDriverInviteConfirmationModal, setShowDriverInviteConfirmationModal] = useState(false)
  const [showDriverInviteConfirmationMessage, setShowDriverInviteConfirmationMessage] = useState(false)
  
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")
  const disableButtons = userPermissionsFeatureToggle && hasRoles(["fleetcoordinator"], userRoles)

  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, item.uniqueId)
    const onTerminate = (id) => () => onRemove(id, fleetIds)

    return (
      <Row
        key={index}
        item={item}
        onView={onView}
        onTerminate={onTerminate(item.uniqueId)}
        userRoles={userRoles}
        onSelectRow={onSelectRow}
        {...itemProps}
      />
    )
  }, [linker, fleetIds, onRemove, onSelectRow]) // eslint-disable-line

  // Prevents re-rendering if ids or data updated - no longer needed
  const { flags, apiResponse } = model

  useEffect(() => {
    if(flags.create === "processed" || (flags.update === "processed" && flags.isAssigned) || flags.delete === "processed") {
      setShowConfirmation(true)
    }
    if(flags.create === "error" || (flags.update === "error" && flags.isAssigned) || flags.delete === "error") {
      setShowError(true)
    }
    // eslint-disable-next-line
  }, [flags.create, flags.update, flags.delete])

  const inviteDriverToAppButtonLabel = selectedDrivers.length > 1
    ? "Invite Driver(s) To App"
    : "Invite Driver To App"

  return (
    <>
      <Listable
        actions={actions}
        config={config}
        filters={Filters}
        model={model}
        renderItem={renderItem}
        routes={ChildRoutes}
        actionButtons={
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "180px" }}>
              <Button
                fullWidth
                color="primary"
                disabled={model.selected.length === 0 || disableButtons}
                variant="outlined"
                onClick={() => setShowDriverInviteConfirmationModal(true)}
              >
                {inviteDriverToAppButtonLabel}
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "180px" }}>
                <Button
                  fullWidth
                  color="primary"
                  disabled={isAlfaDown || disableButtons}
                  variant="contained"
                  onClick={linker(constants.PATH_ADD)}
                >
                  {"Add Driver"}
                </Button>
              </Box>
            </Stack>
          </Stack>
        }
      />

      <StyledModalV2
        open={showConfirmation || showError || showDriverInviteConfirmationMessage}
        onClose={() => {
          onReset()
          setShowConfirmation(false)
          setShowError(false)
          setShowDriverInviteConfirmationMessage(false)
        }}
        status={showConfirmation || showDriverInviteConfirmationMessage ? "success" : "error"}
        content={
          <Typography variant="h4" textAlign="center">
            {showDriverInviteConfirmationMessage ? "Invitation email has been sent" : apiResponse}
          </Typography>
        }
      />

      {/* Driver Invite To App Confirmation Modal */}
      <StyledModalV2
        open={showDriverInviteConfirmationModal}
        onClose={() => setShowDriverInviteConfirmationModal(false)}
        content={
          <Stack gap={2}>
            <Typography variant="h4" textAlign="center">
              Please confirm that you wish to invite the following user/s to the TFM MyDrive Mobile App.
            </Typography>
            <Stack>
              {selectedDrivers.map((driverId, index) => model.data[driverId] && (
                <Stack key={`invite-driver-${index}`}>
                  <Typography variant="h4" textAlign="center" fontWeight={600}>
                    {model.data[driverId].driver}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        }
        actions={
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="center"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "180px" }}>
              <Button
                fullWidth
                color="error"
                disable={model.selected.length === 0 || disableButtons}
                variant="outlined"
                onClick={() => setShowDriverInviteConfirmationModal(false)}
              >
                Cancel
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "180px" }}>
                <Button
                  fullWidth
                  color="primary"
                  disable={isAlfaDown || disableButtons}
                  variant="contained"
                  onClick={() => {
                    onInviteDrivers(selectedDrivers)
                    setShowDriverInviteConfirmationModal(false)
                    setShowDriverInviteConfirmationMessage(true)
                    onSelectAll([])
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Stack>
          </Stack>
        }
      />
    </>
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDrivers: PropTypes.array.isRequired,
  // func
  onRemove: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  onInviteDrivers: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ drivers, settings }) => ({
  model: drivers,
  selectedDrivers: drivers.selected,
  fleetIds: settings.fleet.selected,
  userRoles: settings.roles
})

const mapDispatchToProps = (dispatch) => ({
  onRemove: (uniqueId) => {
    dispatch(remove(null, { resourceId: uniqueId }))
  },
  onReset: () => {
    dispatch(reset())
  },
  onSelectRow: (payload) => {
    dispatch(actions.selectRow(payload))
  },
  onInviteDrivers: (uniqueIds) => {
    dispatch(inviteDriver(uniqueIds))
  },
  onSelectAll: (selectedIds) => {
    dispatch({      
      type: "drivers_select_row",
      payload: selectedIds,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(List)
