import React from 'react'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

const FleetsToggle = ({
  isMax,
  title,
  seeLabel,
  onSeeMore,
  onToggle,
}) => {
  return (
    <ListItem>
      {isMax && (
        <Typography variant="body2">
          <Tooltip title={title}>
            <Typography
              component='span'
              onClick={onSeeMore}
              color='white'
              textDecoration='none'
              marginRight={2}
              sx={{
                cursor: 'pointer',
              }}
            >
              {seeLabel}
            </Typography>
          </Tooltip>
        </Typography>
      )}
      <Typography
        component='span'
        color='white'
        textDecoration='none'
        marginRight={2}
        sx={{
          cursor: 'pointer',
        }}
        onClick={onToggle}
      >
        Change
      </Typography>
    </ListItem>
  )
}

export default FleetsToggle