import React, { Fragment, useRef, useState } from "react"
import PropTypes from "prop-types"

import MoreIcon from "@material-ui/icons/MoreVert"
import ViewIcon from "@material-ui/icons/Subject"
import DeleteIcon from "@material-ui/icons/Delete"

import { useEnabledFeatureToggleList } from "features/settings"
import { useIsAlfaDownDisableFeatures } from "features/drivers"
import { hasRoles } from "acl"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const RowActions = ({ onView, onTerminate,userRoles, driverName, ...props }) => {
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes("OutOfHoursDisabled") // "ALFA out of hours DISABLE FEATURES" limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  const disableTerminateDriverFeatureToggle = featureToggleList.includes("TFP1169")
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")
  const disableButtons = userPermissionsFeatureToggle && hasRoles(["fleetcoordinator"], userRoles)

  const handleMenuOpen = () =>
    setOpenMenu(true)

  const handleMenuClose = () =>
    setOpenMenu(false)

  const onViewHandler = () => {
    handleMenuClose()
    onView()
  }

  const onTerminateHandler = () => {
    setOpenMenu(false)
    setOpenDeleteModal(true)
  }

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem onClick={onViewHandler}>
          <ListItemIcon>
            <ViewIcon />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>

        {onTerminate && !disableTerminateDriverFeatureToggle && !isAlfaDown && (
          <MenuItem
            onClick={onTerminateHandler}
            disabled={disableButtons}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Terminate" />
          </MenuItem>
        )}
      </Menu>

      <StyledModalV2
        open={openDeleteModal}
        title="Terminate Driver"
        content={
          <Stack justifyContent="center" alignItems="center" gap={1}>
            <Typography variant="bodyLarge">
              <strong>IMPORTANT: Please confirm only if {driverName || "the driver"} no longer works for your organisation.</strong>
            </Typography>
            <Typography variant="bodyLarge">
              Please <strong>CANCEL</strong> if you are simply changing a driver/vehicle combination and use <strong>CHANGE VEHICLE</strong>.
            </Typography>
          </Stack>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={onTerminate}
          />
        }
      />
    </Fragment>
  )
}

RowActions.propTypes = {
  className: PropTypes.string,
  onView: PropTypes.func.isRequired,
  onTerminate: PropTypes.func,
  driverName: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default RowActions
