import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Modal, Card, CardContent, CardActions, Typography } from '@material-ui/core'
import { PageLoader } from 'frame/components'

import Button from '@mui/material/Button'
import { DialogClose } from '../components/dialog/dialog-close'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  body: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  actions: {
    // marginBottom: theme.spacing(1),
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    "&:hover": {
      cursor: 'pointer',
      opacity: '80%',
    }
  },
  animatedIcon: {
    margin: theme.spacing(2, 0, 0, 0)
  },  
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(244, 246, 248, 0.8)',
    zIndex: 9999,
  }
}))

const StyledModal = ({
  className,
  open,
  title,
  titleVariant,
  children,
  onCancel,
  onCancelLabel,
  onConfirm,
  onConfirmLabel,
  options = [],
  animatedIcon,
  flag,
  ...rest
}) => {

  const classes = useStyles()
  const [lock, setLock] = useState(false) // to lock the modal during api call/loading state

  const noButtons = options.includes('noButtons')
  const noCancelButton = options.includes('noCancelButton')
  const boldHeading = options.includes('boldHeading')

  useEffect(() => {   
    if(flag && (flag === 'processed' || flag === 'error')) { // unlock modal/loading state when flag becomes processed or errored
      setLock(false)
      onCancel()
    }
  // eslint-disable-next-line
  }, [flag])

  const onContinue = () => {
    flag && setLock(true)
    onConfirm()
  }

  const onClose = () => lock ? null : onCancel()

  return (
    <Modal open={open ? true : false} onClose={onClose}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <div style={{
          position: 'absolute',
          top: '10px',
          right: '10px'
          }}
        >
          <DialogClose onClose={onClose} />
        </div>
        <CardContent>

          {title && (
            <Typography variant={titleVariant ? titleVariant : 'h4'} align="center">
              {boldHeading ? <strong>{title}</strong> : title}
            </Typography>
          )}

          <Typography className={classes.body} variant="body1" align="center">
            {children}
          </Typography>

          {animatedIcon && (
            <div className={classes.animatedIcon}>
              {animatedIcon}
            </div>
          )}
          
          {lock && (
            <div className={classes.loader}>
              <PageLoader />
            </div>
          )}
        </CardContent>

        {!noButtons && (
          <CardActions className={classes.actions}>
            <Button fullWidth disabled={lock} data-cy={`delete-btn`} color="primary" variant="contained" onClick={onContinue}>
              {onConfirmLabel ? onConfirmLabel : 'Confirm'}
            </Button>
            {!noCancelButton && (
              <Button fullWidth disabled={lock} data-cy={`cancel-btn`} color='secondary' variant="outlined" onClick={onCancel}>
                {onCancelLabel ? onCancelLabel : 'Cancel'}
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Modal>
  )
}

StyledModal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onCancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmLabel: PropTypes.string,
  options: PropTypes.array,
  flag: PropTypes.string,
}

export default StyledModal
