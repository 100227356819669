import React from 'react'
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from "@mui/material/styles"

const Loading = () => {
  const theme = useTheme();

  return (
    <>
      {Array.from(Array(3).keys()).map((_, index) => (
        <Box pt={2} key={`industry-news-skeleton-${index}`}>
          <Grid
            container
            sx={{
              cursor: 'pointer',
              padding: 1,
            }}
          >
            <Grid
              item
              xs={12}
              sm={2}
              md={3}
              sx={{
                textAlign: 'left',
                [theme.breakpoints.down(1500)]: { textAlign: 'center' },
              }}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                height={85}
                sx={{
                  width: '100px',
                  height: 'auto',
                  backgroundPositionX: 'top left',
                  [theme.breakpoints.down(1550)]: {
                    width: '80px',
                  },
                  [theme.breakpoints.down('xs')]: {
                    marginBottom: theme.spacing(1),
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={9}>
              <Box
                sx={{
                  paddingLeft: 0,
                  [theme.breakpoints.down(1500)]: { paddingLeft: 1 },
                  [theme.breakpoints.down('xs')]: { paddingLeft: 0 }
                }}
              >
                <Skeleton animation="wave" variant="rect" height={15} width={180} />
                <Skeleton animation="wave" variant="rect" height={15} sx={{ margin: 1 }} />
                <Skeleton animation="wave" variant="rect" height={15} sx={{ margin: 1 }} />
                <Skeleton animation="wave" variant="rect" height={15} width={100} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  )
}

export default Loading