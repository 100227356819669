import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { types } from '../../module'
import { makeStyles } from '@material-ui/styles'
import { hasRoles } from 'acl'
import moment from 'moment'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import MoreIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/Subject'

const RowOptions = ({ onView, onRequestVariation, userRoles, item, ...props }) => {
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)

  const handleMenuOpen = () =>
    setOpenMenu(true)

  const handleMenuClose = () =>
    setOpenMenu(false)

  const onViewHandler = () => {
    handleMenuClose()
    onView()
  }

  const onRequestVariationHandler = () => {
    handleMenuClose()
    onRequestVariation()
  }

  // cut off to allow variations is Maturity Date - 1 Month - 1 Day
  const hideRequestVariation = item && moment() > moment(item.maturityDate).subtract(1,'month').subtract(1,'day')

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem onClick={onViewHandler}>
          <ListItemIcon>
            <ViewIcon />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>

        {!hasRoles(['fleetcoordinator'], userRoles) && !hideRequestVariation && (
          <MenuItem onClick={onRequestVariationHandler}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Request Variation" />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )
}

RowOptions.propTypes = {
  className: PropTypes.string,
  onView: PropTypes.func.isRequired,
  onRequestVariation: PropTypes.func,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  item: types.ModelType.isRequired,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RowOptions)

