import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from "@mui/material/Tooltip"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box";
import { getInitials, getInitialsColor } from 'utils'

const FleetIcon = ({ fleetName }) => {
  return (
    <>
      {fleetName ? (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Tooltip title={fleetName}>
            <Avatar
              style={{ backgroundColor: getInitialsColor(getInitials(fleetName))}}
              sx={{
                mr: 1,
                width: 20,
                height: 20,
                fontSize: 14,
              }}
            >
              {getInitials(fleetName)}
            </Avatar>
          </Tooltip>
        </Box>
      ) : '-'}
    </>
  )
}

FleetIcon.propTypes = {
  fleetName: PropTypes.string,
}

export default FleetIcon
