import React from "react"

import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"

const ServiceBookings = ({ children }) => {
  const theme = useTheme()
  return (
    <Card
      sx={{
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    >
      <CardContent
        sx={{
          p: 3
        }}
      >
        <Stack
          border='1px solid'
          borderColor={theme.palette.accent.sky}
          padding={2}
          borderRadius={1}
          gap={2}
        >
          <Stack>
            <Typography variant="h5" fontWeight={600}>
              Service Bookings
            </Typography>
            <Typography variant="bodyMedium" fontWeight={300}>
              Add booking details or confirm a completed booking
            </Typography>
          </Stack>
          {children}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ServiceBookings