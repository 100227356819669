import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { LightboxGallery, StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { useEnabledFeatureToggleList } from 'features/settings'

import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Typography from '@mui/material/Typography'
import { useTheme } from "@mui/material/styles"
import KeyValue from 'frame/components/mui-textfield'
import IncidentDetails from './incident-details'

const Form = ({ data, flags, errorMessage, onClose, onSaveIncidentNotes }) => {

  const form = useRef()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [values, setValues] = useState({
    id: data.id,
    thirdPartyNumber: data.thirdPartyNumber,
    notes: data?.notes || ''
  })
  const readOnly = isAlfaDown
  const disableSave = readOnly || `${data?.notes || ''}`.trim() === values.notes

  const onSubmitForm = () => {
    onSaveIncidentNotes(data.id, values);
  } 
  const onSave = () => form.current && form.current.submit()

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({
      id: data.id,
      thirdPartyNumber: data.thirdPartyNumber,
      notes: value
    })
  }
  
  const displayVehicle = (data) => {
    let vehicle = `${data.year || ''} ${data.make || ''}`.trim()
    vehicle = `${vehicle} ${data.model || ''}`.trim()
    vehicle = `${vehicle} ${data.variant || ''}`.trim()
    vehicle = vehicle.replace('(Unknown)', '').trim()
    return vehicle !== '' ? vehicle : '-'
  }

  useEffect(() => {
    if(flags.saveNotes === 'error' || flags.saveNotes === 'processed') {
      setShowConfirmation(true)
    }
  }, [flags.saveNotes])
  useEffect(() => {
    setValues({
      id: data.id,
      thirdPartyNumber: data.thirdPartyNumber,
      notes: values.notes || data.notes
    })
  }, [data])
  
  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card
        sx={{
          boxShadow: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
        }}
      >
        <CardContent
           sx={{
            p: 3,
          }}
        >
          <Stack gap={2} width={{ xs: "100%", md: "100%" }} paddingBottom={2}>
            <Stack>
              <Typography variant="h5" fontWeight={600}>
                Incident Details
              </Typography>
            </Stack>

            <Stack gap={2}>
              <IncidentDetails
                items={[
                  {
                    label: 'Driver',
                    value: data.driver,
                  },
                  {
                    label: 'Incident Type',
                    value: data.incidentType ?? '-',
                  },
                  {
                    label: 'Incident Date',
                    value: data.incidentDate ? moment(data.incidentDate).format('Do MMMM YYYY') : '-',
                  },
                  {
                    label: 'Incident Time',
                    value: data.incidentDate ? moment(data.incidentDate).format('h:mm A') : '-',
                  },
                  {
                    label: `Description`,
                    value: data.description || '-',
                  },
                  {
                    label: 'Cost Centre',
                    value: data.costCentre || '-',
                  },
                  {
                    label: `Vehicle`,
                    value: displayVehicle(data),
                  },
                ]}
              />
            </Stack>
          </Stack>

          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
            <Stack width="10%" paddingTop={1}>
              <Typography variant="h6" fontWeight={600}>
                Files
              </Typography>
            </Stack>
            <Stack width="40%" paddingTop={1}>
              <LightboxGallery
                attachments={data.attachments}
                attachmentsCount={data.attachmentsCount}
                isLoading={flags.fetchDetails === 'processing'}
              />
            </Stack>
            <Stack width="50%">
              <KeyValue
                multiline
                fullWidth
                disabled={readOnly}
                rows={3}
                id="notes"
                label='Add Notes'
                value={values.notes || ''}
                inputProps={{ maxLength: 50 }}
                onChange={onInputChange}
              />
            </Stack>
          </Stack>

          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>

              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={disableSave}
                  onClick={onSave}
                >
                  {"Save Changes"}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      
      {/* Save Confirmation Button */}
      <StyledModal
        open={showConfirmation}
        onCancel={() => {
          setShowConfirmation(false)
        }}
        options={['noButtons']}
        animatedIcon={<Box>
          {showConfirmation && flags.saveNotes === 'processed'
            ? <AnimatedSuccessTick size={50} />
            : <AnimatedErrorCross size={50} />}
          </Box>}>
        {flags.saveNotes === 'processed'
          ? 'Incidents notes saved successfully'
          : `Save incident notes failed. Error: ${errorMessage}`
        }
      </StyledModal>
    </ValidatorForm>
  )
}

Form.propTypes = {
  data: PropTypes.object,
  flags: PropTypes.object,
  errorMessage: PropTypes.string,
  // func
  onClose: PropTypes.func.isRequired,
  onSaveIncidentNotes: PropTypes.func.isRequired,
}

export default Form
