import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { useDashboardFilterByDate } from 'filter'
import FilterAttributes from './filter-attributes'
import { actions, types } from '../../module'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'

const Filters = ({
  className,
  model,
  filters,
  filtered,
  visible,
  onClear,
  onFilter,
  onHide,
  ...rest
}) => {
  const dispatch = useDispatch()

  useDashboardFilterByDate(onFilter, onClear, {
    range: 'wofExpiry',
    status: 'wofStatus',
  })

  const isEmptyList = list => list && list.length === 0

  const noFilters = isEmptyList(Object.entries(filters)
    .filter(([key, value]) => !isEmptyList(value)))

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        style: {
          width: 420,
          maxWidth: '100%',
        }
      }}
      onClose={onHide}
      open={visible}
      variant="temporary">
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            paddingY: 2,
            paddingX: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            onClick={onHide}
            sx={{
              display: 'flex',
              margin: 0,
              padding: 0,
              width: 30,
              height: 30,
              background: 'transparent',
              borderRadius: 15,
              border: 'none',
              transition: '0.3s all ease-out',
              cursor: 'pointer',
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.6033 4.39669C22.7727 1.56198 19.0041 0 15 0C10.9959 0 7.22727 1.56198 4.39669 4.39256C1.56612 7.22314 0 10.9959 0 15C0 19.0041 1.56198 22.7727 4.39256 25.6033C7.22727 28.438 10.9959 30 15 30C19.0041 30 22.7727 28.438 25.6033 25.6074C28.438 22.7727 30 19.0041 30 15C30 10.9959 28.438 7.22727 25.6033 4.39669ZM24.438 24.438C21.9174 26.9587 18.5661 28.3471 15 28.3471C11.4339 28.3471 8.08264 26.9587 5.56198 24.438C3.04132 21.9174 1.65289 18.5661 1.65289 15C1.65289 11.4339 3.04132 8.08264 5.56198 5.56198C8.08264 3.04132 11.4339 1.65289 15 1.65289C18.5661 1.65289 21.9174 3.04132 24.438 5.56198C26.9587 8.08264 28.3471 11.4339 28.3471 15C28.3471 18.5661 26.9587 21.9174 24.438 24.438ZM19.8099 9.02479L15 13.8306L10.1901 9.02479L9.02479 10.1901L13.8306 15L9.02479 19.8099L10.1901 20.9752L15 16.1694L19.8099 20.9752L20.9752 19.8099L16.1694 15L20.9752 10.1901L19.8099 9.02479Z" fill="#EB0A1E"/>
            </svg>
          </IconButton>
        </Box>
        <Box
          sx={{
            paddingX: 3,
            flexGrow: 1,
          }}
        >
          {noFilters
            ? 'No Filters Avaliable'
            : <FilterAttributes
                filters={filters}
                filtered={filtered}
                onFilter={(event) => {
                  dispatch({ type: 'reportWofRego_set_dashboard_filter', payload: false })
                  onFilter(event)
                }} />
          }
        </Box>
        <Box
          sx={{
            padding: 3,
            '& > * + *': {
              marginTop: 2,
            }
          }}
        >
          <Button
            fullWidth
            onClick={onClear}
            variant="contained">
            <DeleteIcon
              sx={{
                marginRight: 1,
              }}
            />
            Clear
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

Filters.propTypes = {
  className: PropTypes.string,
  model: types.ModuleType.isRequired,
  visible: PropTypes.bool,
  filters: types.FiltersType.isRequired,
  filtered: types.FilteredType.isRequired,
  onClear: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}

const mapStateToProps = ({ reportWofRego }) => ({
  model: reportWofRego,
  visible: reportWofRego.showFilters,
  filters: reportWofRego.filters,
  filtered: reportWofRego.filtered,
})

const mapDispatchToProps = {
  onClear: actions.clearFilters,
  onFilter: actions.updateFilters,
  onHide: actions.hideFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
