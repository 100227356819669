import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'
import { getRole } from 'listable'
import { useSettingsFleetValues } from 'features/settings'
import { useSelector } from 'react-redux'
import { getUserInvitationStatus } from 'features/admin-users/utils'

import Box from '@mui/material/Box'

const FilterAttributes = ({ roleList, filters, filtered, onFilter }) => {
  const selectedFleets = useSettingsFleetValues()

  const userInvitationData = useSelector(({ adminInvitations }) => adminInvitations.data)

  const hasStatus = (status) => {
    const recordWithStatus = Object.entries(userInvitationData).filter(([key, value]) => getUserInvitationStatus(value) === status)
    return recordWithStatus && recordWithStatus.length > 0
  }

  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list && list.length > 0
  const { roles, fleets, statuses, verifyStatuses } = filters

  const mappedRoles = roleList.filter((role) => {
    return roles && roles.find(( roleId  ) => roleId === role.id)
  });

  const getFleetName = (fleetId) => {
    const fleet = selectedFleets.find(item => item.value === fleetId)
    return fleet ? fleet.label : fleetId
  }

  let filtersConfig = [
    {
      label: 'Role',
      value: filtered.role,
      options: mappedRoles ? mappedRoles.map(({ id, name }) => ({
        label: getRole(name),
        value: id
      })) : [],
      onChange: onSelect('role')
    }, 
    {
      label: 'Fleet',
      value: filtered.fleets,
      options: hasList(fleets) ? [...(new Set(fleets.map(fleetId => getFleetName(fleetId))))] : [],
      onChange: onSelect('fleets')
    },
    {
      label: 'Status',
      value: filtered.status,
      options: hasList(statuses) ? statuses : [],
      onChange: onSelect('status')
    },
    {
      label: 'Invitation',
      value: filtered.verifyStatus,      
      options: hasList(verifyStatuses)
        ? verifyStatuses.map(value => hasStatus(value) ? value : null).filter(x => x) // clean up any unused statuses
        : [],
      onChange: onSelect('verifyStatus')
    },
  ]
  
  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 0,
      }}
    >
      {filtersConfig.map(({ label, value, options, onChange }, key) =>
        hasList(options) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0,
            }}
            key={`${label}${key}`}
          >
            <SingleSelect
              id={label.toLowerCase()}
              label={label}
              options={options}
              tight
              value={value}
              onChange={onChange} />
          </Box>
        )
      )}
    </Box>
  )
}

FilterAttributes.propTypes = {
  roleList: PropTypes.array,
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
