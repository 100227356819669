import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getDateColor, getStatusColor } from 'listable'
import { capitalize, createRowSelector } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import RowActions from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, onView }) => {
  const onSafeRowClick = createRowSelector(onView)

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')  

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {item.agreementNo || '-'}
        </TableCellView>
      )}
      {columns.ordNumber && (
        <TableCellView>
          {item.ordNumber || '-'}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}      
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.currentMaturityDate && (
        <TableCellView>
          {item.currentMaturityDate ? (
            <Label
              color={getDateColor(item.currentMaturityDate)}
              variant="outlined">
              {moment(item.currentMaturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.maturityDate && (
        <TableCellView>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('MMM YYYY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.currentNoOfTyres && (
        <TableCellView>
          {item.currentNoOfTyres || '-'}
        </TableCellView>
      )}      
      {columns.noOfTyres && (
        <TableCellView>
          {item.noOfTyres || '-'}
        </TableCellView>
      )}      
            {columns.currentContractKm && (
        <TableCellView>
          {item.currentContractKm ? parseInt(item.currentContractKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.contractKm && (
        <TableCellView>
          {item.contractKm ? parseInt(item.contractKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.status && (
        <TableCellView>
          {item.status ? (
            <Label
              color={getStatusColor(item.status)}
              variant="outlined">
              {capitalize(item.status)}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.createdAt && (
        <TableCellView>
          {item.createdAt ? (moment(item.createdAt).format('DD/MM/YY')) : '-'}
        </TableCellView>
      )}
      <TableCellView sx={{ width: "50px", maxWidth: "50px" }}>
        <RowActions className="ignore" id={item.id} onView={onView} />
      </TableCellView>
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
