import React from "react"
import PropTypes from "prop-types"
import { createOnFilter } from "filter"

import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import MuiFormSelect from "frame/components/mui-form-select"

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { costCentres, maturityExpiry, fmoTypes } = filters

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingY: 1,
          paddingX: 0,
          cursor: "pointer",
          gap: 3,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
        >
          Filters
        </Typography>
      </Box>
      <Stack gap={2} py={2}>
        {hasList(costCentres) && (
          <MuiFormSelect
            id="costCentre"
            label="Cost Centre"
            options={costCentres}
            value={filtered.costCentre}
            onChange={onSelect("costCentre")}
          />
        )}
        {hasList(maturityExpiry) && (
          <MuiFormSelect
            id="maturityExpiry"
            label="Maturity Date"
            options={maturityExpiry}
            value={filtered.maturityExpiry}
            onChange={onSelect("maturityExpiry")}
          />
        )}
          {hasList(fmoTypes) && (
            <MuiFormSelect
              id="fmoType"
              label="FMO"
              options={fmoTypes}
              value={filtered.fmoType}
              onChange={onSelect("fmoType")}
            />
          )}        
      </Stack>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
