import { useEffect } from 'react'
import moment from 'moment'
import qs from 'query-string'
import { useRouter } from 'utils'


export const useDashboardFilterByDate = (onFilter, onClear, config = {}) => {

  const { location } = useRouter()

  useEffect(() => {
    const parsed = qs.parse(location.search)
    const options = { cleanUpdate: true }

    if (onFilter && config.range && parsed.range && config.booked && parsed.booked) { // Service tracking stacked graph column filters
      // onClear()
      onFilter({ [config.range]: parsed.range, [config.booked]: parsed.booked }, options)
    }
    else if(onFilter && parsed.overdue && config.booked && parsed.booked) { // vehicle servcing - dashboard overdue column filter
      // onClear()
      onFilter({ overdue: parsed.overdue, [config.booked]: parsed.booked }, options)
    }
    else if (onFilter && config.status && parsed.status) { // first column filter
      // onClear()
      onFilter({ [config.status]: parsed.status }, options)
    }
    else if (onFilter && config.range && parsed.range) { // date range column filter
      // onClear()
      onFilter({ [config.range]: parsed.range }, options)
    }
    else if(onFilter && parsed.matured) { // upcoming maturites - dashboard matured column filter
      // onClear()
      onFilter({ matured: parsed.matured }, options)
    }
    else if(onFilter && parsed.overdue) { // vehicle on order - dashboard overdue column filter
      // onClear()
      onFilter({ overdue: parsed.overdue }, options)
    }
    else if(onFilter && parsed.expired) { // wof expiry - dashboard expired column filter
      // onClear()
      onFilter({ expired: parsed.expired }, options)
    }
    else if(onFilter && parsed.wofStatus) { // wof expiry - dashboard summary - wof expired column filter
      // onClear()
      onFilter({ wofStatus: parsed.wofStatus }, options)
    }
    else if(onFilter && parsed.registrationStatus) { // wof expiry - dashboard summary - rego expired column filter
      // onClear()
      onFilter({ registrationStatus: parsed.registrationStatus }, options)
    }
    else if(onFilter && parsed.completedStatus) { // fbt outstanding - dashboard summary - fbt submission column filter
      // onClear()
      onFilter({ completedStatus: parsed.completedStatus }, options)
    }

    // history.push(location.pathname)

  }, [onFilter, config.range, config.status, config.booked]) // eslint-disable-line

}

export const useDashboardFilterByStatus = (onFilter, onClear, key) => {

  const { location } = useRouter()

  useEffect(() => {

    const parsed = qs.parse(location.search)

    if (onFilter && key && parsed.status) {
      // Tomasz: as we're dealing with raw db values, no need for these maps anymore??? don't want to touch this
      const map = {
        'Under': 'Under',
        'On Track': 'On Track',
        'Over': 'Over',
        'No Data Available': 'No Data Available',
      }

      const value = map[parsed.status]

      if (value) {
        onFilter({ [key]: value })
      }
    }

    // history.push(location.pathname)

  }, [onFilter, key]) // eslint-disable-line

}

export const createOnFilter = (onFilter) => (key) => (value = '') => {
  onFilter({
    [key]: typeof value === "string" ? value : value?.target?.value,
  })
}

export const createMonthSelection = (from, to) => {
  const first = moment(from).date(1)
  const last = moment(to).date(1)
  const count = Math.abs(last.diff(first, 'months'))
  const selection = []
  let prevYear = ''

  for (let i = 0; i < count; i ++) {

    const range = moment(from)
      .add(i, 'months')
      .date(1)

    if (range.format('YYYY') !== prevYear) {
      prevYear = range.format('YYYY')
      selection.push({
        label: range.format('YYYY'),
        value: null,
      })
    }

    const label = range.format('MMMM YYYY')
    const value = range.format('MM-DD-YYYY')
    selection.push({ label, value, html: true })
  }

  return selection
}

export const createMonthSelectionAroundNow = (left, right) => {
  const from = moment(new Date()).subtract(left, 'months')
  const to = moment(new Date()).add(right, 'months')
  return createMonthSelection(from, to)
}

export const isLastMonth = (date) =>
  moment(date).isBefore(moment().subtract(1,'months').endOf('month'))

export const isThisOrNextMonth = (date) =>
  moment(date).isBefore(moment().startOf('month'))
  && moment(date).isBefore(moment().add(2,'months').endOf('month'))

export const isExpired = (date) =>
  moment(date) < moment().startOf('day')
  // moment(date).isBefore(moment().startOf('day'))

export const isUpcoming = (date) =>
  moment(date) >= moment().startOf('day') 
  && moment(date) <= moment().add(2,'months')
// moment(date).isAfter(moment().startOf('day'))  // issues with isBefore
// && moment(date).isBefore(moment().add(2,'months'))

export const isValid = (date) =>
  moment(date).isAfter(moment().startOf('day'))

export const isEventual = (date) => {
  const then = moment()
    .add(1, 'months')
  return moment(date).isAfter(then)
}

export const isSameMonth = (date, selected) =>
  date && moment(date).isSame(selected, 'month')

export const sortAlphaNumeric = (a = '', b = '', direction) => {
  const left = typeof a === 'string' ? a.toLowerCase() : a.toString()
  const right = typeof b === 'string' ? b.toLowerCase() : b.toString()

  return direction === 'desc'
    ? right.localeCompare(left)
    : left.localeCompare(right)
}

export const sortNumerically = (a, b, direction, defaultValue = 0) => {

  const left = parseFloat(a || defaultValue, 10)
  const right = parseFloat(b || defaultValue, 10)

  return direction === 'desc'
    ? left > right ? 1 : -1
    : left < right ? 1 : -1
}

export const sortByMap = (map) => (a, b, direction) => {
  const left = map[a] || 0
  const right = map[b] || 0

  if (direction === 'desc') {
    return left > right ? -1 : 1
  }

  return left > right ? 1 : -1
}
