import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  fetchMyFleetSummaryServiceOverdue,
  fetchMyFleetSummaryWofOverdue,
  fetchMyFleetSummaryKmTracking,
  fetchMyFleetSummaryRegoOverdue,
  fetchMyFleetSummaryFbtOutstanding
} from '../actions'
import { useLatestFbtSubmission } from 'features/fbt/navigation-config'
import { setFbtCustomerView } from 'features/fbt-submissions/actions'
import { useSelectedPremiumFleets } from 'features/settings'
import { useNavigate } from 'utils'
import CountUp from 'react-countup'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from "@mui/styles"
import { CardTitle } from './common/card-title'

const MyFleetSummary = ({ flags, myFleetSummary, onFetchMyFleetSummary, onSelectFbtCustomer }) => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate()
  const latestFbtSubmission = useLatestFbtSubmission()
  const fleetOptions = useSelectedPremiumFleets()

  const hasFbtFleets = fleetOptions.length > 0
  const isLoading = (flag) => flag && flag === 'processing'

  const [delayLoading, setDelayLoading] = useState(true)

  useEffect(() => {
    onFetchMyFleetSummary()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchMyFleetSummary])

  const onNavigate = (path) => () => {
    path.includes('/fbt') && onSelectFbtCustomer(fleetOptions[0])
    navigate(path)()
  }

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <CardTitle title='My Fleet Summary' />
          </Grid>

          {[
            {
              label: 'Service Overdue',
              value: myFleetSummary?.serviceOverdue,
              path: `/reports/vehicle-servicing?overdue=true&booked=No`,
              flag: flags?.fetchMyFleetSummaryServiceOverdue
            },
            {
              label: 'WoF Overdue',
              value: myFleetSummary?.wofOverdue,
              path: `/reports/wof-registration?expired=true`,
              flag: flags?.fetchMyFleetSummaryWofOverdue
            },
            {
              label: 'KM Tracking (over)',
              value: myFleetSummary?.kmTrackingOver,
              path: `/reports/km-tracking?status=Over`,
              flag: flags?.fetchMyFleetSummaryKmTracking
            },
            {
              label: 'Registration Overdue',
              value: myFleetSummary?.registrationOverdue,
              path: `/reports/wof-registration?registrationStatus=Overdue`,
              flag: flags?.fetchMyFleetSummaryRegoOverdue
            },
            {
              label: 'FBT Outstanding',
              value: myFleetSummary?.fbtOutstanding,
              path: `${latestFbtSubmission.link}&completedStatus=Not%20Completed`,
              hide: !hasFbtFleets,
              flag: flags?.fetchMyFleetSummaryFBTOutstanding
            },
          ].filter(x => !x.hide).map(({ label, value, path, flag }, index) => (
            <Grid item xs={12} sm={6} md={6} lg key={`my-fleet-summary-item-${index}`}>
              {delayLoading || isLoading(flag) ? (
                <Skeleton
                  animation="wave"
                  variant="rect"
                  sx={{
                    width: { xs: '100%', md: '340px' },
                    minHeight: '40px',
                  }}
                />
              ) : (
                <ButtonGroup
                  size="large"
                  aria-label="large outlined button group"
                  fullWidth={mobileView}
                  onClick={navigate(path)}
                >
                  <Button
                    sx={{
                      width: { xs: '65%', md: '180px'},
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: '16px',
                      color: '#4D4F53',
                      border: '1px solid #E53935',
                    }}
                  >
                    {label}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={onNavigate(path)}
                    sx={{
                      width: { xs: '65%', md: '160px'},
                      fontSize: '18px',
                      fontWeight: 'bold',
                      lineHeight: '24px',
                      backgroundColor: '#E53935',
                      color: '#FFFFFF',
                    }}
                  >
                    <CountUp start={0} end={value} duration={0.75} />
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
          ))}

          {/* to balance out 5x grid structure */}
          {hasFbtFleets && (
            <Grid item xs={12} sm={6} md={6} lg key={`my-fleet-summary-item-extra`}></Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

MyFleetSummary.propTypes = {
  flags: PropTypes.object,
  myFleetSummary: PropTypes.object,
  onFetchMyFleetSummary: PropTypes.func.isRequired,
  onSelectFbtCustomer: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  flags: homepage?.flags,
  myFleetSummary: homepage?.myFleetSummary,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchMyFleetSummary: () => {
    dispatch(fetchMyFleetSummaryServiceOverdue())
    dispatch(fetchMyFleetSummaryWofOverdue())
    dispatch(fetchMyFleetSummaryKmTracking())
    dispatch(fetchMyFleetSummaryRegoOverdue())
    dispatch(fetchMyFleetSummaryFbtOutstanding())
  },
  onSelectFbtCustomer: (payload) => dispatch(setFbtCustomerView(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyFleetSummary)
