import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { status, createdDates, isFromApp } = filters

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
      <Box>
        {hasList(createdDates) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="createdDate"
              label="Created Date"
              options={createdDates}
              tight
              value={filtered.createdDate}
              onChange={onSelect('createdDate')} />
          </Box>
        )}
        {hasList(status) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="status"
              label="Status"
              options={status}
              tight
              value={filtered.status}
              onChange={onSelect('status')} />
          </Box>
        )}
        {hasList(isFromApp) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              id="isFromApp"
              label="Is App Record"
              options={isFromApp}
              tight
              value={filtered.isFromApp}
              onChange={onSelect('isFromApp')} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
