import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-md-spinner'

import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const DashboardGraph = ({
  children,
  className,
  title,
  subtitle,
  to,
  loading,
  error,
  onRefresh,
  data,
  minHeight,
  isInertia,
}) => {

  const theme = useTheme()
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [rotateIcon, setRotateIcon] = useState(false)

  const errored = ( ! data && error) || ( ! data && ! loading)

  const onRefreshHandler = () => {
    setIsRefreshing(true)
    setTimeout(() => setIsRefreshing(false), 2000)
    
    setRotateIcon(!rotateIcon)
    onRefresh && onRefresh()
  }
  
  return (
    <Card
      sx={{
        boxShadow: 'none',
        borderRadius: '5px',
      }}
    >
      <CardHeader
        title={
          <Stack>
            <Typography variant="h5" fontWeight={600}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="bodyMedium">
                {subtitle}
              </Typography>
            )}
          </Stack>
        }
        action={
          <Stack flexDirection="row" gap={1}>
            <Tooltip title="refresh">
              <IconButton aria-label="refresh" onClick={() => onRefreshHandler()}>
                <CachedOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="view">
              <IconButton
                LinkComponent={"a"}
                aria-label="view"
                onClick={() => onRefreshHandler()}
                href={to}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      />
      
      {!isInertia && <Divider />}

      <CardContent
        sx={{
          p: 0,
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            position: 'relative',
            paddingTop: 2,
            paddingRight: 2,
            paddingBottom: 0,
            paddingLeft: 2,
            minHeight: minHeight ? minHeight : 265,
          }}
        >
          {((! data && loading) || isRefreshing) && (
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <Spinner
                singleColor={theme.palette.dark}
                size={18} />
            </Stack>
          )}
          {errored && (
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <Typography
                textAlign="center"
                color="error"
                variant="bodyLarge">
                Whoops!
                <br />Something went wrong
                <br />while getting this data.
              </Typography>
            </Stack>
          )}
          {data && !isRefreshing && children}
        </Stack>
      </CardContent>
    </Card>
  )
}

DashboardGraph.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  to: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onRefresh: PropTypes.func,
  data: PropTypes.any,
  minHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isInertia: PropTypes.bool,
}

export default DashboardGraph
