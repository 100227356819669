import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from 'frame'
import * as serviceWorker from './serviceWorker'


const render = Component => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./frame/containers/app', () => {
    const NextApp = require('./frame/containers/app').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
