import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { fetchContactDetails } from '../actions'
import { getInitials, getInitialsColor } from 'utils'
import { useSelectedFleetIds } from 'features/settings'
import { CardTitle } from './common/card-title'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'

import { useTheme } from "@mui/material/styles"

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  divider: {
    margin: theme.spacing(1.5, 0, 2, 0),
  },
  name: {    
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  role: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: 10,
  },
  contactDetails: {
    padding: theme.spacing(1, 0),
  },
  label: { fontSize: '12px', color: '#666666' },
  value: { fontSize: '16px' },
  email: { fontSize: '16px', color: '#7099DB' },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'cover',
    backgroundSize: '100%',
    width: '100%',
    height: 'auto',
    minHeight: '160px',
    [theme.breakpoints.down('md')]: {
      width: '160px',
      minHeight: '160px',
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    }
  },
  spacing: { margin: theme.spacing(1, 0) }
}))

const ContactInformation = ({ flags, contactDetails, onFetchContactDetails }) => {
  const theme = useTheme()
  const classes = useStyles()
  const selectedFleets = useSelectedFleetIds()

  console.log(contactDetails, 'contactDetails');

  const [delayLoading, setDelayLoading] = useState(true)
  const isLoading = flags?.fetchNotifications === 'processing'

  const LoadingView = () => (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={4}
        sx={{
          textAlign: 'left',
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Skeleton
          animation="wave"
          variant="rect"
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'cover',
            backgroundSize: '100%',
            width: { xs: '100%', md: '160px' },
            height: 'auto',
            minHeight: '160px',
            [theme.breakpoints.down('md')]: {
              minHeight: '160px',
              height: 'auto',
            },
            [theme.breakpoints.down('xs')]: {
              height: 'auto',
            }
          }}
        />  
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={8} gap={1}>
        {[...Array(6)]?.map(() => (
          <Skeleton animation="wave" variant="rect" height={15} width={120} />  
        ))}
      </Grid>
    </Grid>
  )

  useEffect(() => {
    onFetchContactDetails()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchContactDetails])
 
  return (
    <Card
      sx={{
        height: '100%',
        minHeight: '360px',
        [theme.breakpoints.down('md')]: { minHeight: '100%' },
        [theme.breakpoints.down("sm")]: { minHeight: "100%" },
      }}
    >
      <CardContent>
        <CardTitle title="Contact Information" />
        <Divider />
        
        <Box py={3}>
          {delayLoading || isLoading ? (
            <LoadingView />
          ) : selectedFleets.length > 1 ? (
            <Typography variant="body1">
              Contact information is only available with one fleet selected.
            </Typography>
          ) : (
            <>          
              {contactDetails && contactDetails?.name ? (
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    sx={{
                      textAlign: 'left',
                      [theme.breakpoints.down('md')]: {
                        textAlign: 'center',
                      },
                    }}
                  >
                    {contactDetails?.uri ? (
                      <Box
                        component="img"
                        src={contactDetails?.uri}
                        alt="Relationship Manager"
                        sx={{
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'cover',
                          backgroundSize: '100%',
                          width: { xs: '100%', md: '160px' },
                          height: 'auto',
                          minHeight: '160px',
                          [theme.breakpoints.down('md')]: {
                            minHeight: '160px',
                            height: 'auto',
                          },
                          [theme.breakpoints.down('xs')]: {
                            height: 'auto',
                          }
                        }}
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{
                          backgroundColor: getInitialsColor(getInitials(contactDetails?.name)),
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'cover',
                          backgroundSize: '100%',
                          width: { xs: '100%', md: '160px' },
                          height: 'auto',
                          minHeight: '160px',
                          [theme.breakpoints.down('md')]: {
                            minHeight: '160px',
                            height: 'auto',
                          },
                          [theme.breakpoints.down('xs')]: {
                            height: 'auto',
                          }
                        }}
                      >
                        {getInitials(contactDetails?.name)}
                      </Avatar>
                    )}
                    
                  </Grid>
      
                  <Grid item xs={12} sm={6} md={6} lg={8}>
                    <Stack gap={1}>
                      <Stack>
                        <Typography
                          variant="h5"
                          sx={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            letterSpacing: 0,
                            lineHeight: '20px',
                          }}
                        >
                          {contactDetails?.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            lineHeight: '18px',
                            paddingBottom: 2,
                          }}
                        >
                          Relationship Manager
                        </Typography>
                      </Stack>

                      <Stack>
                        <Typography variant="body1" sx={{ fontSize: '12px', color: '#666666' }}>
                          Phone:
                        </Typography>

                        <Typography variant="body1" sx={{ fontSize: '16px' }}>
                          {contactDetails?.mobile || '-'}
                        </Typography>
                      </Stack>

                      <Stack>
                        <Typography variant="body1" sx={{ fontSize: '12px', color: '#666666' }}>
                          Email:
                        </Typography>

                        {contactDetails?.email ? (
                          <Link
                            href={`mailto:${contactDetails?.email}`}
                            sx={{
                              fontSize: '16px',
                              color: '#7099DB'
                            }}
                          >
                            {contactDetails?.email}
                          </Link>
                        ) : '-'}
                      </Stack>
                    <Typography variant="body1" py={1}>
                    </Typography>

                    </Stack>
                  </Grid>
                </Grid>
              ): (
                <Typography variant="body1">
                  No contact details available for current fleet.
                </Typography>
              )}
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

ContactInformation.propTypes = {
  flags: PropTypes.object,
  contactDetails: PropTypes.object,
  onFetchContactDetails: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  contactDetails: homepage?.contactDetails,
  flags: homepage?.flags,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchContactDetails: () => dispatch(fetchContactDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation)