import React from "react"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const MuiFormSelect = (props) => {
  return (
    <FormControl
      variant="filled"
      sx={{
        "& label": {
          fontSize: 12,
          color: props?.error ? '#EB0A1E' : props?.disabled ? "rgba(0, 0, 0, 0.38)" : "#00B6B0",
          top: '3px',
          '&.Mui-disabled': {
            color: '#00B6B0',
          }
        },
        "& .MuiInputBase-root": {
          overflow: 'hidden',
          borderRadius: '5px',
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: '#98B6BE',
          '&.Mui-disabled': {
            background: '#F3F5F8'
          }
        },
        "& .MuiSelect-select": {
          paddingTop: "20px",
        },
        "& .MuiInputBase-root:after": {
          content: "none",
        },
        "& .MuiInputBase-root:before": {
          content: "none",
        },
        '& .Mui-error': {
          borderColor: '#EB0A1E',
        }
      }}
    >
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      <Select
        {...props}
        labelId={props.labelId}
        inputProps={{
          style: {
            fontSize: 12,
            color: '#00B6B0',
            top: 0,
            '&.Mui-disabled': {
              color: '#00B6B0',
            }
          }
        }}
      >
        {props?.options?.map((option) => (
          <MenuItem
            value={option?.value || option}
            fontSize={14}
            key={`option-${String(option?.value || option).toLowerCase()}`}
          >
            {option?.label || option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MuiFormSelect;