
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import moment from 'moment'

const VehicleRegistration = ({ data }) => {
  const theme = useTheme()
  const noData = !data.registrationExpiry
  const isExpired = moment(data.registrationExpiry).isBefore(moment())

  const ViewNoData = () => {
    if (!noData) {
      return null
    }

    return (
      <Stack>
        <Typography
          variant="h5"
          color={theme.palette.brand.red}
          fontWeight={600}
          textAlign="center"
        >
          No Vehicle Registration data available
        </Typography>
      </Stack>
    )
  }

  const ViewMain = () => {
    if (noData) {
      return null
    }

    return (
      <Stack
        gap={1}
        width="100%"
        height="100%"
      >
        <Stack
          gap={2}
          width="100%"
          height="100%"
          justifyContent="center"
        >
          <Typography variant="h1" fontWeight={800}>
            {data.registrationExpiry ? moment(data.registrationExpiry).format('DD/MM/YYYY') : '-'}
          </Typography>

          <Typography variant="h5" fontWeight={600}>
            {`${data.year} ${data.make} ${data.model}`.trim() !== ''
              ? `${data.year} ${data.make} ${data.model}`.trim(): '-'}
          </Typography>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h2" fontWeight={800}>
              {data.registration || '-'}
            </Typography>
            <Typography variant="h2" fontWeight={800}>
              G
            </Typography>
          </Stack>
        </Stack>
        {isExpired && (
          <Typography
            variant="h5"
            color={theme.palette.brand.red}
            textAlign="center"
            fontWeight={600}
          >
            Expired {data.registrationExpiry && moment(data.registrationExpiry).format('DD/MM/YYYY')}
          </Typography>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      border={isExpired ? '4px solid' : '2px solid'}
      borderColor={isExpired ? theme.palette.brand.red : theme.palette.accent.sky}
      padding={2}
      borderRadius={1}
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <ViewNoData />
      <ViewMain />
    </Stack>  
  )
}

export default VehicleRegistration