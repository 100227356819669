import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { types } from "../../module"
import { useSelectedFleets, useSelectedFleetIds, useEnabledFeatureToggleList } from "features/settings"
import { lookupRequest } from "utils"
// import { getDriverStatusColor } from "listable"
// import ReadOnlyForm from "./read-only-form"
import { useInView } from "react-intersection-observer"
import { useIsAlfaDownDisableFeatures } from "features/drivers"
import { hasRoles } from "acl"

import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"

import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "./details/details-schema"

import { Driver as FormDriverDetails } from "./details/driver"
import { Vehicle as FormVehicleDetails } from "./details/vehicle"

const Form = ({
  values,
  setValues,
  disabled,
  onClose,
  onSubmit,
  onChange,
  driverName,
  tryChangingVehicle,
  setTryChangingVehicle,
  onShowAssignForm,
  saveStatus,
  doneFetchingVehicle,
  setDoneFetchingVehicle,
  vehicleDetails,
  setVehicleDetails,
  originalValues,
  setOriginalValues,
  onLookup,
  apiResponse,
  userRoles
}) => {

  const form = useRef()
  const [ref, inView] = useInView({ threshold: 0 }) // for triggering the error message timer when its in view 

  const theme = useTheme()
  const userSelectedFleets = useSelectedFleets()
  const fleetIds = useSelectedFleetIds()
  const featureToggleList = useEnabledFeatureToggleList()

  const disableOutOfHoursFeatureToggle = featureToggleList.includes("OutOfHoursDisabled") // "ALFA out of hours DISABLE FEATURES" limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")

  const [loaded, setLoaded] = useState(false)
  const [isChangingVehicle, setIsChangingVehicle] = useState(false)
  const [showStatusMessage, setShowStatusMessage] = useState(false)
  const [disableValidation, setDisableValidation] = useState(false)
  const [saveCounter, setSaveCounter] = useState(0)

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all"
  })

  const onSubmitForm = () => {
    if(isChangingVehicle) { // for changing vehicle      
      onShowAssignForm()

    } else {
      // for save changes
      onSubmit(values)
      setDisableValidation(true)

      let counter = saveCounter + 1
      setSaveCounter(counter)
    }
  }

  const onSave = () => {
    if(form.current) {
      setIsChangingVehicle(false)
      form.current.submit()
    }
  }

  useEffect(() => { // tryChangingVehicle is set true on Change Vehicle button out side the form to trigger form validation
    if (tryChangingVehicle) {
      console.log("tryChangingVehicle")

      // quick fix for email already exist when u try to assign vehicle straight after editing email
      saveCounter > 0 && originalValues.email === values?.email && setDisableValidation(true)

      setIsChangingVehicle(true)
      setTryChangingVehicle(false)
      onSubmitForm()
    }// eslint-disable-next-line
  }, [tryChangingVehicle, setTryChangingVehicle])

  const onSelectChange = (key, value) => {
    setValues({ ...values, [key]: value })
    methods.setValue(key, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  useEffect(() => {
    async function fetchVehicle() {
      const data = await lookupRequest(fleetIds, "/lookup/driverAgreement", originalValues?.registration || "")
      // console.log("[fetchVehicle]: ", data)
      setVehicleDetails(data)
      setDoneFetchingVehicle(true)
    }
    
    !values?.terminated && values?.status !== "Unassigned" && !doneFetchingVehicle
      ? fetchVehicle()
      : setDoneFetchingVehicle(true)
  }, []) // eslint-disable-line

  
  useEffect(() => {
    async function fetchAndsetThirdPartyNumber() {
      if(userSelectedFleets.length === 1) {
        const data = await lookupRequest(fleetIds, "/lookup/fleetId", userSelectedFleets[0].label)
        // console.log("[fetchAndsetThirdPartyNumber]: ", data)
        data && setValues({
          ...values,
          thirdPartyNumber: data.value || "-",
          accountName: data.text || "-",
        })
      }
    }
    setLoaded(true)
    fetchAndsetThirdPartyNumber()
  }, []) // eslint-disable-line

  useEffect(() => {
    if(saveStatus === "processed" || saveStatus === "error") {
      loaded && setShowStatusMessage(true)
      if(saveStatus === "processed") {
        onChange(values)
        setOriginalValues(values)
      }
    }
    // eslint-disable-next-line
  }, [saveStatus])

  useEffect(() => {
    if(inView && showStatusMessage && (saveStatus === "processed" || saveStatus === "error")) {
      setTimeout(() => { // clear status message after 3 secs after error message is in view
        setShowStatusMessage(false)
      }, 3000)
    } // eslint-disable-next-line
  }, [inView])
  
  const readonly = isAlfaDown // false

  const isEditable = !readonly && values?.status !== "Terminated"
  const editable = userPermissionsFeatureToggle ? hasRoles(["admin", "superadmin", "fleetcontroller"] ,userRoles) && isEditable : isEditable
  
  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "5px",
      }}
    >
      <FormProvider>
        <form
          id="drivers-edit-driver-form"
          onSubmit={methods.handleSubmit(onSubmitForm)}
        >
          <CardContent
            sx={{
              p: 3
            }}
          >
            <Stack gap={3}>
              <FormDriverDetails
                values={values}
                onInputChange={onInputChange}
                methods={methods}
                disabled={disabled}
                onSelectNotification={(value) => {
                  onSelectChange("driverNotification", value)
                }}
              />

              <FormVehicleDetails
                values={values}
                vehicleDetails={vehicleDetails}
                onInputChange={onInputChange}
                methods={methods}
                disabled={disabled}
                onLookup={onLookup}
                driverName={driverName}
                isLoading={!doneFetchingVehicle}
                onSelectChange={(key, value) => {
                  onSelectChange(key, value)
                }}
                hasDetails={vehicleDetails && doneFetchingVehicle}
              />
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

            <Stack
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              gap={1}
            >
              <Box width={{ xs: "100%", sm: "205px" }}>
                <Button
                  fullWidth
                  color="error"
                  disabled={disabled}
                  variant="outlined"
                  onClick={onClose}
                >
                  Close
                </Button>
              </Box>
              {editable ? (
                <Stack flexDirection="row" gap={2}>
                  <Box width={{ xs: "100%", sm: "205px" }}>
                    <Button
                      fullWidth
                      disabled={disabled}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={onSave}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Stack>
              ): <Stack />}
            </Stack>
          </CardContent>
        </form>
      </FormProvider>
    </Card>
  )
}

Form.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  driverName: PropTypes.string,
  tryChangingVehicle: PropTypes.bool.isRequired,
  setTryChangingVehicle: PropTypes.func.isRequired,
  onShowAssignForm: PropTypes.func.isRequired,
  saveStatus: PropTypes.string,
  doneFetchingVehicle: PropTypes.bool,
  setDoneFetchingVehicle: PropTypes.func.isRequired,
  vehicleDetails: PropTypes.object,
  setVehicleDetails: PropTypes.func.isRequired,
  originalValues: PropTypes.object,
  setOriginalValues: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  apiResponse: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default Form
