import React from "react"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCellView from "frame/components/table-cell"
import Skeleton from '@mui/material/Skeleton';

export const VariationsRequestTableLoading = () => {
  return (
    <TableBody>
      {[...Array(3)]?.map((_, index) => (
        <TableRow
          key={`variation-row-loading-${index}`}
          sx={{
            height: "40px",
            borderBottom: "5px solid white",
          }}
        >
          <TableCellView isFirst>
            <Skeleton variant="rectangular" animation="wave" width="100%" height={12} />
          </TableCellView>
          <TableCellView>
            <Skeleton variant="rectangular" animation="wave" width="100%" height={12} />
          </TableCellView>
          <TableCellView>
            <Skeleton variant="rectangular" animation="wave" width="100%" height={12} />
          </TableCellView>
          <TableCellView sx={{ textTransform: "uppercase" }}>
            <Skeleton variant="rectangular" animation="wave" width="100%" height={12} />
          </TableCellView>
          <TableCellView isLast>
            <Skeleton variant="circular" animation="wave" width={16} height={16} />
          </TableCellView>
        </TableRow>
      ))}
    </TableBody>
  )
}