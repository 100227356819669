
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import moment from 'moment'

const VehicleService = ({ data }) => {
  const theme = useTheme()
  return (
    <Stack
      border='2px solid'
      borderColor={theme.palette.accent.sky}
      padding={2}
      borderRadius={1}
      gap={2}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <Stack justifyContent="center" alignItems="center">
        <img
          src="/static/images/digital_service_book/wrench.png"
          style={{
            width: 70,
            height: 70
          }}
        />
      </Stack>
      <Stack>
        <Typography
          variant="h5"
          color={theme.palette.brand.red}
          fontWeight={600}
        >
          {data.estNextServiceDueDate
            ? moment(data.estNextServiceDueDate).format('DD/MM/YYYY')
            : "N/A"
          }
        </Typography>
      </Stack>
    </Stack>  
  )
}

export default VehicleService