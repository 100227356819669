import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { Controller } from 'react-hook-form';
import MuiTextfield from "frame/components/mui-textfield"
import MuiFormSelect from "frame/components/mui-form-select"

export const Driver = ({
  values,
  onInputChange,
  methods,
  disabled,
  onSelectNotification
}) => {
  const theme = useTheme()

  const details = [
    {
      label: "Fleet",
      value: values?.accountName,
    }, {
      label: "Name",
      value: values?.driver,
    }, {
      label: "Email",
      value: values?.email,
    }, {
      label: "Status",
      value: values?.status,
    }
  ]

  return (
    <Stack gap={2} width="100%">
      <Stack>
        <Typography variant="h5" fontWeight={600}>
          Driver Details
        </Typography>
      </Stack>

      <Stack
        borderRadius='5px'
        border='1px solid'
        borderColor={theme.palette.accent.sky}
        p={3}
        gap={2}
      >
        <Stack flexDirection="row">
          {details?.map((detail, index) => (
            <Stack
              key={`driver-edit-details-${index}`}
              flex={1}
              minWidth='calc(100%/4)'
            >
              <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
                {detail?.label}
              </Typography>
              <Typography variant="bodyLarge">
                {detail?.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
        
        <Stack gap={2} flexDirection={{ xs: "column", md: "row" }} width="100%">
          {/* Mobile */}
          <Stack gap={1} flex={1}>
            <Controller
              name="mobile"
              control={methods.control}
              rules={{ required: true }}
              render={({ field }) => (
                <MuiTextfield
                  {...field}
                  id="mobile"
                  error={Boolean(methods.formState.errors?.mobile?.message)}
                  label="Mobile"
                  type="tel"
                  value={values?.mobile || ""}
                  onChange={onInputChange}
                />
              )}
            />
            <Typography color="error" fontSize={12}>
              {methods.formState.errors?.mobile?.message}
            </Typography>
          </Stack>
          {/* Notification */}
          <Stack gap={1} flex={1}>
            <Controller
              name="driverNotification"
              control={methods.control}
              rules={{ required: true }}
              render={({ field }) => (
                <MuiFormSelect
                  {...field}
                  id="driverNotification"
                  disabled={disabled}
                  label="Notification Method"
                  labelId="driverNotification-label"
                  options={['Email', 'Text', 'In App', 'N/A']}
                  value={values?.driverNotification || 'In App'}
                  onChange={(event) => {
                    onSelectNotification(event?.target?.value)
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}