import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ListItemLoadingSkeleton from './list-item-skeleton'
import { fetchStatusUpdates } from '../actions'
import { useNavigate } from 'utils'
import moment from 'moment'

import InfoIcon from '@material-ui/icons/Info'
import { statusUpdateTypes, statusUpdateEnums } from '../actions/types'
import InfiniteScroll from './infinite-scroll'
import { CardTitle } from './common/card-title'

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"

const useStyles = makeStyles(theme => ({
  root: {
    height: '457px',
    maxHeight: '457px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: '100%',
    }
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
    paddingBottom: theme.spacing(1.5),
  },
  list: {
    height: '368px',
    maxHeight: '368px',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: { height: '100%' },
  },
  scroll: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': { backgroundColor: '#fff', width: '0.5em' },
    '&::-webkit-scrollbar-track': { '-webkit-box-shadow': '#fff' },
    '&::-webkit-scrollbar-track:hover': { '-webkit-box-shadow': '#f4f4f4' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#babac0', borderRadius: '0.5em', outline: '5px solid #fff' },
    '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#a0a0a5', outline: '4px solid #f4f4f4' },
  },
  listItem: { paddingTop: theme.spacing(1.5) },
  clickable: { cursor: 'pointer' },
  icon: {
    width: 40,
    height: 40,
    display: 'inline-block',
    verticalAlign: 'text-top',
    color: '#00b6b0',
  },
  bold: { fontWeight: 'bold' },
  date: {
    paddingRight: 40,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 20,
    }
  },
  noRecords: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(4) },
  },
}))

const StatusUpdates = ({ flags, statusUpdates, onFetchStatusUpdates }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const navigate = useNavigate()

  const [delayLoading, setDelayLoading] = useState(true)
  const isLoading = flags?.fetchStatusUpdates === 'processing'

  useEffect(() => {
    onFetchStatusUpdates()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchStatusUpdates])
  
  const renderListData = (data) => data.map(({ recordId, createdAt, thirdPartyNumber, activityType, activityTitle, activityMessage }, index) => {
    
    const Icon = statusUpdateTypes[activityType]?.Icon || InfoIcon
    const uri = statusUpdateTypes[activityType]?.uri || ''
    const link = activityType === statusUpdateEnums.VEHICLE_INCIDENT
      ? uri.replace('{id}', thirdPartyNumber)
      : uri.replace('{id}', recordId)
  
    return (
      <Grid
        container
        spacing={2}
        key={`mynotification-item-${index}`}
        onClick={navigate(link)}
        sx={{
          paddingTop: 1.5,
          cursor: uri ? 'pointer' : 'default',
        }}
      >
        
        <Grid item xs={3} sm={2}>
          <Stack justifyContent='center' alignItems='center'>
            <Icon
              style={{
                width: 40,
                height: 40,
                display: 'inline-block',
                verticalAlign: 'text-top',
                color: '#00b6b0',
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={9} sm={10}>
          <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
            <Typography
              component="span"
              fontWeight={700}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {moment(createdAt).format('DD/MM/YYYY')}
            </Typography>
            <Typography
              variant="h6"
              component="span"
              fontWeight={700}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {activityTitle}
            </Typography>
          </Stack>

          <Typography
            variant='body2'
            color='#263238'
          >
            {uri && activityMessage.includes('Click here')
              ? (
                <>
                  {activityMessage.split('Click here')[0]}
                  <Link to={link}>Click here</Link>
                  {activityMessage.split('Click here')[1]}
                </>
              ) : activityMessage
            }
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    )
  })

  return (
    <Card>
      <CardContent>
        <CardTitle title='Status Updates' />
        <Divider />

        {delayLoading || isLoading ? (
          <ListItemLoadingSkeleton />
        ) : (
          <Box
            sx={{
              height: { xs: '100%', sm: '368px' },
              maxHeight: '368px',
              overflowX: 'hidden',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': { backgroundColor: '#fff', width: '0.5em' },
              '&::-webkit-scrollbar-track': { '-webkit-box-shadow': '#fff' },
              '&::-webkit-scrollbar-track:hover': { '-webkit-box-shadow': '#f4f4f4' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: '#babac0', borderRadius: '0.5em', outline: '5px solid #fff' },
              '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#a0a0a5', outline: '4px solid #f4f4f4' },
            }}
          >
            {statusUpdates.length > 0 ? (              
              <InfiniteScroll
                data={statusUpdates}
                renderListData={renderListData}
              />
            ) : (
              <Box
                sx={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  marginTop: { xs: 4, sm: 0 },
                }}
              >
                <Typography variant="body1" align="center">
                  No notifications
                </Typography>
              </Box>
            )}
          </Box>
        )}        
      </CardContent>
    </Card>
  )
}

StatusUpdates.propTypes = {
  flags: PropTypes.object,
  statusUpdates: PropTypes.array.isRequired,
  onFetchStatusUpdates: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  flags: homepage?.flags,
  statusUpdates: homepage?.statusUpdates || [],
})

const mapDispatchToProps = (dispatch) => ({
  onFetchStatusUpdates: () => dispatch(fetchStatusUpdates()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusUpdates)