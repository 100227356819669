import React from 'react'
import Box from '@mui/material/Box'
import PageLoader from '../page-loader'

export const DialogLoader = ({
  style,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(244, 246, 248, 0.8)',
        zIndex: 9999,
      }}
      style={style}
    >
      {children ? children : <PageLoader />}
    </Box>
  )   
}