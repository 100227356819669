import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledModal } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import moment from 'moment'
import {isMiles, isSourceSystemLoaded} from 'utils/sourceSystem'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import MuiSwitch from 'frame/components/switch/switch'
import { useTheme } from '@mui/material/styles'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

const UnassignedView = ({
  driverFlags,
  fleetData,
  driverData,
  onCreateNewDriver,
  onAssignExistingDriver,
  onTogglePoolVehicle,
  userRoles,
  onOpenDateForm
}) => {
  const theme = useTheme()
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures

  const [isPoolVehicle, setIsPoolVehicle] = useState(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes')

  // modal flags
  const [openConfirmPoolVehicleToggleModal, setConfirmPoolVehicleToggleModal] = useState(false)

    const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) : true
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)

  useEffect(() => {
    // reset toggle if togglePoolVehicle fails with error flag 
    if(driverFlags.togglePoolVehicle === 'error') {
      setIsPoolVehicle(!isPoolVehicle)
    }
    
  // eslint-disable-next-line
  }, [driverFlags.togglePoolVehicle])

  useEffect(() => {
    // update pool vehicle toggle when fetch once done
    setIsPoolVehicle(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes')    
  // eslint-disable-next-line
  }, [fleetData.poolVehicle])
  
  const handlePoolVehicleToggle = (checked) => {
    setIsPoolVehicle(checked === 'on' ? true : false)
    if (isMiles(fleetData.sourceSystem)) {
      onOpenDateForm(true)
    } else {
      setConfirmPoolVehicleToggleModal(true)
    }
  }

  const onConfirmPoolVehicleChange = () => {
    setConfirmPoolVehicleToggleModal(false)
    onTogglePoolVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber, moment(), isPoolVehicle || true)
  }
  
  const onCancelHandler = () => {
    handlePoolVehicleToggle(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes') // reset default values
    setConfirmPoolVehicleToggleModal(false)
  }

  return (
    <Card
      sx={{
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    >
      <CardContent
        sx={{
          p: 3
        }}
      >
        <Stack pb={3}>
          {showButtons && isSourceSystemLoaded(fleetData.sourceSystem) && (
            <Stack flexDirection="column" alignItems="flex-start">
              <Typography
                variant="h5"
                color={theme.palette.black}
                fontWeight={600}
              >
                Pool Vehicle
              </Typography>
                
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Typography
                  variant="h5"
                  color={theme.palette.black}
                >
                  Indicate if you would like to switch this to a pool vehicle
                </Typography>
                <MuiSwitch
                  disabled={isAlfaDown}
                  checked={isPoolVehicle ? true : false}
                  onChange={(event) => {
                    handlePoolVehicleToggle(event.target.value === "on" ? true : false)
                  }}
                  name="poolVehicleSwitch"
                />
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack
          flexDirection="column"
          alignItems="flex-start"
          pb={2}
        >
          <Typography
            variant="h5"
            color={theme.palette.black}
            fontWeight={600}
          >
            Assign Driver
          </Typography>
          <Typography variant="h5">
            There is no driver currently assigned to this vehicle. Click on one of the options below if you would like to assign a driver to this vehicle.
          </Typography>
        </Stack>

        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          gap={{ xs: 1, md: 3 }}
        >
          <Stack minWidth={{ xs: '100%', md: '205px' }}>
            <Button
              disabled={isAlfaDown || disableButtons}
              color="primary"
              id="assign-driver-option"
              data-cy="assign-driver-option"
              variant="contained"
              onClick={onAssignExistingDriver}
            >
              Assign Existing Driver
            </Button>
          </Stack>
          <Stack minWidth={{ xs: '100%', md: '205px' }}>
            <Button
              disabled={isAlfaDown || disableButtons}
              color="primary"
              id="create-new-driver-option"
              data-cy="create-new-driver-option"
              variant="contained"
              onClick={onCreateNewDriver}
            >
              Create New Driver
            </Button>
          </Stack>
        </Stack>

        <StyledModalV2
          open={openConfirmPoolVehicleToggleModal}
          onClose={() => setConfirmPoolVehicleToggleModal(false)}
          content={
            <Typography variant="h4" textAlign="center">
              {isPoolVehicle
                ? driverData.driver
                  ? 'Please confirm that you wish to designate this as a pool vehicle. By doing so, you will be removing the assigned driver from the vehicle. '
                  : 'Please confirm that you wish to designate this as a pool vehicle.'
                : 'You are about to switch this to a non pool vehicle'
              }
            </Typography>
          }
          actions={
            <StyledModalV2Actions
              onCancel={onCancelHandler}
              onConfirm={onConfirmPoolVehicleChange}
            />
          }
        />
      </CardContent>
    </Card>
  )
}

UnassignedView.propTypes = {
  driverFlags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  driverData: PropTypes.object.isRequired,
  onCreateNewDriver: PropTypes.func.isRequired,
  onAssignExistingDriver: PropTypes.func.isRequired,
  onTogglePoolVehicle: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default UnassignedView

