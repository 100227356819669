import React from 'react'
import { useRouter } from 'utils'
import { useDispatch } from 'react-redux'
import { createBasicGraphNodeClicker, DashboardGraph, PieChart } from 'graph'
import { useGraphFetch, constants } from '../module'
import { useSelectedFleetIds } from 'features/settings'
import uuid from 'uuid/v4'
import Typography from '@mui/material/Typography'


const Graph = () => {

  const dispatch = useDispatch()
  const { history } = useRouter()
  const selectedFleetIds = useSelectedFleetIds()

  const { loading, error, data } = useGraphFetch()
  const path = '/reports/km-tracking'

  const onClick = createBasicGraphNodeClicker(status => {
    history.push(`${path}?status=${status}`)
  })

  const onRefresh = () => {
    dispatch({
      type: constants.REQUEST_DATA,
      payload: null,
      options: { queryParams: { FleetId: selectedFleetIds.join() }, requestId: uuid() }
    })
  }
  
  const hasData = data && data.values && data.values.filter(x => x !== 0).length !== 0

  return (
    <DashboardGraph
      title="KM Tracking"
      to={path}
      loading={loading}
      error={error}
      onRefresh={onRefresh}
      data={data}
    >
      {hasData ? (
        <PieChart
          data={data}
          onClick={onClick}
        />
      ) : (
        <Typography variant="h5" textAlign="center">
          Our current data shows that you have no km tracking in the next 6 months.
        </Typography>
      )}
    </DashboardGraph>
  )
}

export default Graph
