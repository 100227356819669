import React, { useState, useRef } from "react"
import moment from "moment"
import PropTypes from "prop-types"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { hasRoles } from "acl"
import { getStatusColor } from "listable"
import { useEnabledFeatureToggleList } from "features/settings"
import { getReliefVehicleTypes } from "utils/reliefVehicleTypes"

import DetailsInfoWrapper from "frame/components/details-info-wrapper"
import CardContent from "frame/components/card-content"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import DatePicker from "frame/components/date-picker-v5"

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import MuiTextfield from "frame/components/mui-textfield"
import MuiFormSelect from "frame/components/mui-form-select"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { schema } from "./form-schema"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

const Form = ({ data, userRoles, onClose, onSave, onDelete, disabled, error, errorMessage }) => {
  const form = useRef()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")

  const { booking, contract } = data
  const [values, setValues] = useState(booking)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const isChanged = JSON.stringify(booking) !== JSON.stringify(values)

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
    defaultValues: {
      pickupAddress: values?.pickupAddress ?? ""
    }
  })

  const onDeleteHandler = () => {
    onDelete(booking.id, contract.thirdPartyNumber)
    onClose()
  }

  const tfp1865FeatureToggle = featureToggleList.includes("TFP1865");
  let vehicleTypes = getReliefVehicleTypes(tfp1865FeatureToggle);

  const onSelectChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    })
    methods.setValue(key, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  // if lock all from data if userPermissionsFeatureToggle is true and dont have permissions"
  const isEditable = values.status && (`${values.status}`.toLowerCase() !== "cancelled" && `${values.status}`.toLowerCase() !== "compconsted")
  const editable = userPermissionsFeatureToggle ? hasRoles(["admin", "superadmin", "fleetcontroller"] ,userRoles) && isEditable : isEditable

  const getMaxReturnDate = () => {
    if (contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  const getStatusOptions = (status) => {
    const recordStatus = String(status).toLowerCase()

    // if record is in status "driver requested"
    if (recordStatus === "driver requested") { 
      if (hasRoles(["superadmin"], userRoles)) {
        return [{
          value: "driver requested",
          label: "Driver Requested",
        }, {
          value: "sent to tfs",
          label: "Sent to TFS",
        }, {
          value: "approved",
          label: "Approved",
        }, {
          value: "cancelled",
          label: "Cancelled",
        }, {
          value: "completed",
          label: "Completed",
        }]
      }

      // default to minimum
      return [{
        value: "driver requested",
        label: "Driver Requested",
      }, {
        value: "sent to tfs",
        label: "Sent to TFS",
      }]
    }
    // else ensure status "driver requested" is not available
    else
    
    if (hasRoles(["superadmin"], userRoles)) {
      return [{
        value: "sent to tfs",
        label: "Sent to TFS",
      }, {
        value: "approved",
        label: "Approved",
      }, {
        value: "cancelled",
        label: "Cancelled",
      }, {
        value: "completed",
        label: "Completed",
      }]
    }

    // default to minimum
    return [{
      value: "sent to tfs",
      label: "Sent to TFS",
    }]
  }

  const onSubmitForm = (data) => {
    console.log(data, "data")
    if(values.collectionDate && values.bookedReturnDate) {
      onSave({
        ...values,
        id: values.id || 0,
      }) 
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormProvider>
          <form
            id="service-record-form"
            onSubmit={methods.handleSubmit(onSubmitForm)}
          >
            <CardContent>
              <Stack gap={4}>
                <Stack gap={2}>
                  <Typography variant="h5" fontWeight={600}>
                    Relief Request Details
                  </Typography>
                  {/* Form */}
                  <Stack flex={1} gap={1}>
                    <Controller
                      name="status"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiFormSelect
                          {...field}
                          id="status"
                          disabled={disabled || !editable}
                          label="Status"
                          labelId="status-label"
                          options={getStatusOptions(booking?.status)}
                          value={String(values.status).toLowerCase() || ""}
                          onChange={(event) =>
                            onSelectChange("status", event?.target?.value)
                          }
                        />
                      )}
                    />
                  </Stack>

                  <Stack flex={1} gap={1}>
                    <Controller
                      name="vehicleType"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiFormSelect
                          {...field}
                          id="vehicleType"
                          disabled={disabled || !editable}
                          label="Vehicle Type"
                          labelId="vehicleType-label"
                          options={vehicleTypes}
                          value={values.vehicleType || ""}
                          onChange={(event) =>
                            onSelectChange("vehicleType", event?.target?.value)
                          }
                        />
                      )}
                    />
                  </Stack>

                  <Stack flex={1} flexDirection="row" gap={1}>
                    <DatePicker
                      label="Start Date"
                      format="Do MMMM YYYY"
                      minDate={values?.collectionDate && dayjs(values?.collectionDate) < dayjs() ? dayjs(values?.collectionDate) : dayjs()}
                      maxDate={getMaxReturnDate()}
                      value={typeof values.collectionDate === "undefined" ? null : values?.collectionDate}
                      disabled={!editable}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          collectionDate: value,
                        })
                      }}
                    />

                    <DatePicker
                      label="End Date"
                      format="Do MMMM YYYY"
                      minDate={values?.collectionDate && dayjs(values?.collectionDate) < dayjs() ? dayjs(values?.collectionDate) : dayjs()}
                      maxDate={getMaxReturnDate()}
                      value={typeof values.bookedReturnDate === "undefined" ? null : values?.bookedReturnDate}
                      disabled={!editable}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          bookedReturnDate: value,
                        })
                      }}
                    />
                  </Stack>

                  <Stack gap={1} flex={1}>
                    <Controller
                      name="pickupAddress"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="pickupAddress"
                          name="pickupAddress"
                          disabled={disabled || !editable}
                          error={Boolean(methods.formState.errors?.pickupAddress?.message)}
                          label="Pickup Address"
                          value={values.pickupAddress || ""}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.pickupAddress?.message}
                    </Typography>
                  </Stack>

                  <Stack gap={1} flex={1}>
                    <Controller
                      name="comments"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <MuiTextfield
                            {...field}
                            multiline
                            rows={10}
                            id="comments"
                            disabled={disabled || !editable}
                            error={Boolean(methods.formState.errors?.comments?.message)}
                            label="Comments"
                            value={values.comments || ""}
                            onChange={onInputChange}
                          />
                          <small>Please include contact name, email address and mobile number.</small>
                        </>
                      )}
                    />
                  </Stack>
                </Stack>

                <Stack gap={2}>
                  <Typography variant="h5" fontWeight={600}>
                    Vehicle
                  </Typography>
                  <DetailsInfoWrapper
                    dataId="relief-vehicle-booking-details"
                    items={[{
                      label: "Driver",
                      value: contract?.poolVehicle
                        ? `Pool Vehicle${contract?.registration ? ` - ${contract?.registration}` : ""}`
                        : contract.driver || "-"
                    },
                    {
                      label: "Registration",
                      value: contract?.location ?? "-",
                    },
                    {
                      label: "Make",
                      value: contract?.registration ?? "-",
                    },
                    {
                      label: "Year",
                      value: contract?.costCentre ?? "-",
                    },
                    {
                      label: "Location",
                      value: contract?.make ?? "-",
                    },
                    {
                      label: "Cost Centre",
                      value: contract?.model ?? "-",
                    },
                    {
                      label: "Model",
                      value: contract?.year ?? "-",
                    },
                    {
                      label: "Colour",
                      value: contract?.colour ?? "-",
                    },
                    {
                      label: "Accessories",
                      value: contract?.accessories ?? "-",
                    },
                  ]}
                  />
                </Stack>
              </Stack>

              <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

              <Stack
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                rowGap={2}
              >
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </Box>
                <Stack flexDirection="row" gap={1}>
                  {hasRoles(["superadmin", "fleetcontroller"], userRoles) && editable && (
                    <Box width={{ xs: "100%", md: "205px" }}>
                      <Button
                        fullWidth
                        color="error"
                        variant="outlined"
                        onClick={() => setOpenDeleteModal(true)}
                      >
                        Cancel Request
                      </Button>
                    </Box>
                  )}

                  {hasRoles(["superadmin", "fleetcontroller"], userRoles) && (booking.status !== values.status || editable) && (
                    <Box width={{ xs: "100%", md: "205px" }}>
                      <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        disabled={disabled || !isChanged}
                        variant="contained"
                      >
                        Save Changes
                      </Button>
                      </Box>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </form>
        </FormProvider>
      </LocalizationProvider>

      <StyledModalV2
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        content={
          <Stack gap={2}>
            <Typography variant="h4" textAlign="center">
              Please confirm that you wish to cancel this relief vehicle request.
            </Typography>
          </Stack>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={onDeleteHandler}
          />
        }
      />
    </>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Form
