import * as z from "zod";

export const schema = z.object({
  status: z
    .string()
    .optional(),
  vehicleType: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "Please enter a vehicle type" }),
  pickupAddress: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "Please enter a pickup address" }),
  collectionDate: z.date().nullable().refine(date => date !== null, { message: "Please enter a start date" }),
  bookedReturnDate: z.date().nullable().refine(date => date !== null, { message: "Please enter a end date" }),
  comments: z
    .string()
    .optional(),
});
