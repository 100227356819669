import React from 'react'
import PropTypes from 'prop-types'
import MenuV5 from '@mui/material/Menu'
import { MenuItemView } from './popover/MenuItemView'

import { constants as fromReportEndOfLease } from 'features/report-end-of-lease'
import { constants as fromReportExtensionRentals } from 'features/report-extension-rentals'
import { constants as fromReportKmTracking } from 'features/report-km-tracking'
import { constants as fromReportMaturities } from 'features/report-upcoming-maturities'
import { constants as fromReportServicing } from 'features/report-vehicle-servicing'
import { constants as fromReportVehiclesOnOrder } from 'features/report-vehicles-on-order'
import { constants as fromReportWofRego } from 'features/report-wof-rego'
import { constants as fromReportIncidentManagement} from 'features/report-incident-management'
import { constants as fromDocuments } from 'features/report-documents'
import { useEnabledFeatureToggleList } from 'features/settings'

import { useLocation } from "react-router"

const MenuList = [
  {
    title: 'Upcoming Maturities',
    path: fromReportMaturities.PATH_ALL
  },
  {
    title: 'Vehicles On-Order',
    path: fromReportVehiclesOnOrder.PATH_ALL
  },
  {
    title: 'Vehicle Servicing',
    path: fromReportServicing.PATH_ALL
  },
  {
    title: 'KM Tracking',
    path: fromReportKmTracking.PATH_ALL
  },
  {
    title: 'WoF & Registration',
    path: fromReportWofRego.PATH_ALL
  },
  {
    title: 'Extension Rentals',
    path: fromReportExtensionRentals.PATH_ALL
  },
  {
    title: 'End of Lease',
    path: fromReportEndOfLease.PATH_ALL
  },
  {
    title: 'Incident Management',
    path: fromReportIncidentManagement.PATH_ALL
  },
  {
    title: 'Documents',
    path: fromDocuments.PATH_ALL
  },
]

const ActionsPopover = ({ anchor, linker, open, onClose }) => {
  const location = useLocation()
  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')

  if (!anchor.current) {
    return null
  }

  return (
    <MenuV5
      keepMounted
      id="menu-reports"
      anchorEl={anchor.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: {
            '& ul': {
              padding: 0,
            }
          }
        },
      }}
    >
      <div>
        {MenuList?.map((menuListItem) => {
          if (menuListItem?.path === fromDocuments.PATH_ALL && documentsReportFeatureToggle) {
            return (
              <MenuItemView
                key={menuListItem?.title}
                title={menuListItem?.title}
                onClick={linker(menuListItem?.path)}
                isActive={menuListItem?.path === location.pathname}
              />
            )
          }

          return (
            <MenuItemView
              key={menuListItem?.title}
              title={menuListItem?.title}
              onClick={linker(menuListItem?.path)}
              isActive={menuListItem?.path === location.pathname}
            />
          )
        })}
      </div>
    </MenuV5>
  )
}

ActionsPopover.propTypes = {
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default ActionsPopover
