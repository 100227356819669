import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from "@mui/material/styles"
import MuiSwitch from "frame/components/switch"
import TextField from "@mui/material/TextField"

const accessoriesMenu = [
  {
    title: "Sign Writing",
    name: "SignWriting"
  },
  {
    title: "Canopy (incl. dust seal)",
    name: "Canopy"
  },
  {
    title: "Seat Covers",
    name: "SeatCovers"
  },
  {
    title: "Towbar",
    name: "Towbar"
  },
  {
    title: "Seat Required (Van only)",
    name: "SeatRequiredVanOnly"
  },
  {
    title: "Roof Rack",
    name: "RoofRack"
  },
]

export const AccessoriesForm = ({
  title,
  accessories,
  setAccessories,
  accessoriesNotes,
  setAccessoriesNotes,
}) => {
  const theme = useTheme()
  return (
    <Stack>
      <Stack
        pb={3}
        borderBottom="1px solid"
        borderColor={theme.palette.neutral.mid}
      >
        <Typography variant="h6" fontWeight={600}>{title}</Typography>
      </Stack>
      <Stack>
        {accessoriesMenu?.map((accessory) => {
          return (
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1}
              py={2}
              borderBottom="1px solid"
              borderColor={theme.palette.neutral.mid}
              key={accessory.name}
            >
              <Stack flex={1}>
                <Typography variant="h6" maxWidth={190}>{accessory.title}</Typography>
              </Stack>
              <Stack flex={1}>
                <Stack flexDirection="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        checked={Boolean(accessories[accessory?.name])}
                        onChange={() => {
                          setAccessories({
                            ...accessories,
                            [accessory?.name]: !Boolean(accessories[accessory?.name])
                          })
                        }}
                        name={accessory.name}
                      />
                    }
                    label=""
                  />
                  <TextField
                    placeholder="Notes"
                    size="small"
                    sx={{
                      minHeight: "30px",
                      "& fieldset": {
                        border: "none",
                      }
                    }}
                    InputProps={{
                      sx: {
                        minHeight: "30px",
                        borderRadius: "5px",
                        bgcolor: theme.palette.neutral.mid,
                        border: "none",
                      }
                    }}
                    value={accessoriesNotes[accessory.name]}
                    onChange={(event) => {
                      setAccessoriesNotes({
                        ...accessoriesNotes,
                        [accessory.name]: event.target.value
                      })
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
