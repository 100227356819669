import React, { useState } from "react"
import PropTypes from "prop-types"
import { Alert } from "frame/components"
import AssignFleetLookupField from "./assign-fleet-lookup-field"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

/* used in add/edit driver form  */
const AssignForm = ({
  values,
  setValues,
  disabled,  
  selectedVehicle,
  searchHistory,
  setSearchHistory,
  originalValues,
  // func
  onBack,
  onLookup,
  onProceed,
  onFetchContract,
  setSelectedVehicle,
}) => {
  const theme = useTheme()
  const [showError, setShowError] = useState(false)

  const onFleetLookupChange = (option) =>
    option && setValues({
      ...values,
      agreementNo: option.value || null,
      registration: option.text || null,
      costCentre: option.costCentre || null,
      location: option.location || null,
      controller: option.controller || null,
      vehicleNotes: option.vehicleNotes || null,
    })

  const onProceedHandler = () => {
    if (selectedVehicle) {
      if(onFetchContract && values?.agreementNo && values?.thirdPartyNumber) {
        onFetchContract(values.agreementNo, values?.thirdPartyNumber)
      }
      onProceed()
    } else {
      setShowError(true)
      setTimeout(() => setShowError(false), 3000)
    }
  }

  const onBackHandler = () => {    
    originalValues && setValues({ // reset values if originalValues props exists - for edit driver form only
      ...values,
      agreementNo: originalValues.agreementNo || null,
      registration: originalValues.registration || null,
      costCentre: originalValues.costCentre || null,
      location: originalValues.location || null,
      controller: originalValues.controller || null,
      vehicleNotes: originalValues.vehicleNotes || null,
    })
    onBack()
  }

  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "5px",
      }}
    >
      <CardContent
        sx={{
          p: 3,
        }}
      >
        <Stack gap={1}>
          <Typography variant="h5" fontWeight={600}>
            Search for a Vehicle
          </Typography>
          <Stack>
            <AssignFleetLookupField
              id="registration"
              resourceModel="drivers"
              lookupPath="driverAgreement"
              customFleetId={values.thirdPartyNumber}
              currentDriverUniqueId={values.uniqueId}
              searchHistory={searchHistory}
              setSearchHistory={setSearchHistory}
              setSelectedVehicle={setSelectedVehicle}
              onLookup={onLookup}
              onChange={onFleetLookupChange}
            />
          </Stack>
        </Stack>

        <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", sm: "205px" }}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                disabled={disabled}
                onClick={onBackHandler}
              >
                Back
              </Button>
            </Box>
            <Box width={{ xs: "100%", sm: "205px" }}>
              <Button
                fullWidth
                color="primary"
                disabled={disabled}
                variant="contained"
                onClick={onProceedHandler}
              >
                Proceed
              </Button>
            </Box>
          </Stack>
      </CardContent>
    </Card>
  )
}

AssignForm.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  setSelectedVehicle: PropTypes.func.isRequired,
  searchHistory: PropTypes.object,
  setSearchHistory: PropTypes.func,
  originalValues: PropTypes.object,
  // func
  onBack: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
}

export default AssignForm
