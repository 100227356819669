import { createTheme as createThemeV5 } from '@mui/material/styles';

import palette from './palette'
import typography from './typography'
import overrides from './overrides'

import MuiAvatar from "./overrides/MuiAvatar"
import MuiAutocomplete from "./overrides/MuiAutocomplete"
import MuiButton from "./overrides/MuiButton"
import MuiInputBase from "./overrides/MuiInputBase"
import MuiTableCell from "./overrides/MuiTableCell"
import MuiListItemText from "./overrides/MuiListItemText"
import MuiMenuItem from "./overrides/MuiMenuItem"
import MuiList from "./overrides/MuiList"

export const themeV5 = createThemeV5({
  palette: palette,
  typography: {
    allVariants: {
      fontFamily: 'Inter, sans-serif',
      textTransform: "none",
    },
    ...typography
  },
  components: {
    MuiAutocomplete,
    MuiAvatar,
    MuiButton,
    MuiInputBase,
    MuiTableCell,
    MuiListItemText,
    MuiMenuItem,
    MuiList,
  },
  overrides,
});
