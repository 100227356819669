import React from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

const VehicleOdometerReading = ({
  children,
}) => {
  const theme = useTheme()
  return (
    <Stack
      gap={2}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight={600}>
          Odometer Reading
        </Typography>
      </Stack>

      <Stack
        border='1px solid'
        borderColor={theme.palette.accent.sky}
        borderRadius={1}
        gap={2}
        p={2}
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        {children}
      </Stack>
    </Stack>
  )
}

export default VehicleOdometerReading