import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Bar } from 'react-chartjs-2'
import { Typography } from '@material-ui/core'
import { Modal } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
}))

const BarChart = ({ data, onClick, noDataMessage, showHealthAndSafetyDisclaimer }) => {

  const classes = useStyles()
  const theme = useTheme()
  const [openDisclaimerMessage, setOpenDisclaimerMessage] = useState(false) // for disclaimerMessage modal

  const getGraphData = () => ({
    labels: data.labels,
    datasets: [
      {
        label: 'This year',
        data: data.values,
        backgroundColor: data.colors,
      }
    ]
  })

  const graph = getGraphData()

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // animation: true,
    cornerRadius: 20,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          barThickness: 22,
          maxBarThickness: 26,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            precision: 0
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: (tooltipItem) => {
          return ' ' + Math.round(tooltipItem.yLabel * 100) / 100
        },
      }
    },
    onClick,
  }

  const dataAvailable = data.values.length > 1

  return (
    <>
      {dataAvailable ? (
        <Bar
          className={classes.root}
          height={130}
          data={graph}
          options={options}
        />
        ) : (
          <Typography variant="body1">
            {noDataMessage ? noDataMessage : 'No data available'}
            
            {showHealthAndSafetyDisclaimer && (
              <>
                <br />
                <span className={classes.link} onClick={() => setOpenDisclaimerMessage(true)}>
                  View Health and Safety Disclaimer
                </span>{'.'}
              </>
            )} 
          </Typography>
        )}

        {openDisclaimerMessage && (
          <Modal
            open={openDisclaimerMessage}
            title="Health and Safety Disclaimer"
            onClose={() => setOpenDisclaimerMessage(false)}
            children="Please note there are possible implications to your obligations both under your finance contract/lease and the Health 
            and Safety at Work Act 2015 if the servicing requirements are not completed when due."
          />
        )}   
    </> 
  )
}

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  noDataMessage: PropTypes.string,
  showHealthAndSafetyDisclaimer: PropTypes.bool,
}

export default BarChart
