import React, { useEffect } from "react"
import PropTypes from "prop-types"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import MuiTextfield from "frame/components/mui-textfield/mui-textfield"
import MuiFormSelect from "frame/components/mui-form-select/mui-form-select"
import { schema } from "./schema"
import { useCheckEmailAvailable } from "frame/hooks/useCheckEmailAvailable"

const NewDriverForm = ({
  disabled,
  driverFlags,
  values,
  // functions
  onChange,
  onProceed,
  onBack,
}) => {
  const theme = useTheme()
  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const { isLoading, isAlreadyExists } =
    useCheckEmailAvailable({
      value: methods.getValues("email"),
      isValid: methods.formState.errors.email?.message === undefined,
    })

  const onInputChange = ({ target: { id, name, value } }) => {
    onChange({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSelectChange = (key, value) => {
    onChange({ ...values, [key]: value })
  }
 
  const onSubmit = () => onProceed()

  const triggerValidate = () => {
    methods.setError("email", {
      type: "root.random",
      message: "Email already exists",
    })
    methods.setError("emailValue", {
      type: "root.random",
      message: "Email already exists",
    })
    methods.trigger("driver")
  }

  useEffect(() => {
    isAlreadyExists && triggerValidate()
  }, [isAlreadyExists])

  return (
    <Card
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
      }}
    >
      <FormProvider>
        <form
          id="new-driver-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <CardContent
            sx={{
              p: 3
            }}
          >
            <Stack gap={2} width={{ xs: "100%", md: "50%" }}>
              <Stack>
                <Typography variant="h5" fontWeight={600}>
                  New Driver Details
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Stack gap={1}>
                  <Controller
                    name="driver"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="driver"
                        error={Boolean(methods.formState.errors?.driver?.message)}
                        label="Name"
                        value={values.driver || ""}
                        onChange={onInputChange}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.driver?.message}
                  </Typography>
                </Stack>
                <Stack gap={1}>
                  <Controller
                    name="email"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="email"
                        error={
                          Boolean(methods.formState.errors?.email?.message)
                        }
                        label="Email"
                        value={values.email || ""}
                        onChange={onInputChange}
                        // onBlur={handleEmailAvailability}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.email?.message}
                  </Typography>
                </Stack>
                <Stack gap={1}>
                  <Controller
                    name="mobile"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="mobile"
                        error={Boolean(methods.formState.errors?.mobile?.message)}
                        label="Mobile"
                        type="tel"
                        value={values.mobile || ""}
                        onChange={onInputChange}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.mobile?.message}
                  </Typography>
                </Stack>
                <Stack gap={1}>
                  <Controller
                    name="driverNotification"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiFormSelect
                        {...field}
                        id="driverNotification"
                        disabled={disabled}
                        label="Notification Method"
                        labelId="driverNotification-label"
                        options={["Email", "Text", "In App", "N/A"]}
                        value={values.driverNotification || "In App"}
                        onChange={(event) =>
                          onSelectChange("driverNotification", event?.target?.value)
                        }
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              gap={1}
            >
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="secondary"
                  disabled={disabled}
                  variant="contained"
                  onClick={onBack}
                >
                  Back
                </Button>
              </Box>
              <Stack flexDirection="row" gap={2}>
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    type={isAlreadyExists ? "button" : "submit" }
                    fullWidth
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    disable={isLoading}
                    onClick={() => isAlreadyExists ? triggerValidate() : null}
                  >
                    {"Create & Assign Vehicle"}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </form>
      </FormProvider>
    </Card>
  )
}

NewDriverForm.propTypes = {
  disabled: PropTypes.bool,
  driverFlags: PropTypes.object,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default NewDriverForm
