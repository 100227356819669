import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import VehicleService from "./section/vehicle-service"
import VehicleWOF from "./section/vehicle-wof"
import VehicleRegistration from "./section/vehicle-registration"
import VehicleHistory from "../vehicle-history"

const VehicleView = ({
  data,
  vehicleHistory,
  contractTyres,
  odometerReading,
}) => {
  const theme = useTheme()
  console.log(data, 'data')

  return (
    <Card
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
      }}
    >
      <CardContent
        sx={{
          p: 3
        }}
      >
        <Stack gap={3}>
          {/* Service, WOF and Registration Dates */}
          <Stack
            border='1px solid'
            borderColor={theme.palette.accent.sky}
            padding={2}
            borderRadius={1}
            gap={2}
          >
            <Typography variant="h5" fontWeight={600}>
              Service, WoF and Registration Dates
            </Typography>
            <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
              <Stack
                flex={1}
                minHeight={'100px'}
              >
                <VehicleService data={data} />
              </Stack>
              <Stack
                flex={1}
                minHeight={'100px'}
              >
                <VehicleWOF data={data} />
              </Stack>
              <Stack
                flex={1}
                minHeight={'100px'}
              >
                <VehicleRegistration data={data} />
              </Stack>
            </Stack>
          </Stack>

          <Stack
            border='1px solid'
            borderColor={theme.palette.accent.sky}
            padding={2}
            borderRadius={1}
            gap={2}
          >
            {vehicleHistory}
          </Stack>

          <Stack
            border='1px solid'
            borderColor={theme.palette.accent.sky}
            padding={2}
            borderRadius={1}
            gap={2}
          >
            {contractTyres}
          </Stack>

          <Stack
            border='1px solid'
            borderColor={theme.palette.accent.sky}
            padding={2}
            borderRadius={1}
            gap={2}
          >
            {odometerReading}
          </Stack>

        </Stack>

      </CardContent>
    </Card>
  )
}

export default VehicleView