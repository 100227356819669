import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ValidatorForm } from "react-material-ui-form-validator"
import { OfflineLookupField, ScrollToTop } from "frame/components"
import { lookup } from "features/drivers/actions"
import moment from "moment"

import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import KeyValue from "frame/components/mui-textfield"
import DatePicker from "frame/components/date-picker-v5"

dayjs.extend(advancedFormat)

const ConfirmDriverForm = ({
  values,
  disabled,
  fleetData,
  // functions
  onChange,
  onSubmit,
  onBack,
  onClose,
  onLookup,
}) => {

  const form = useRef()
  const theme = useTheme()
  const [showError, setShowError] = useState(false)

  const onLookupChange = (key) => (option) =>
    onChange({ ...values, [key]: option ? option.value : null })

  const onInputChange = ({ target: { id, name, value } }) =>
    onChange({ ...values, [id || name]: value })

  const onDateChange = (value) => {
    console.log(value, "value");
    onChange({ ...values, effectiveDate: value })
  }

  useEffect(() => {
    onDateChange(moment());
  }, [])

  const onSubmitForm = () => onSubmit(values)

  const displayVehicle = (data) => {
    let vehicle = `${data.year || ""} ${data.make || ""}`.trim()
    vehicle = `${vehicle} ${data.model || ""}`.trim()
    vehicle = `${vehicle} ${data.variant || ""}`.trim()
    return vehicle.replace("(Unknown)", "").trim()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack>
        <ScrollToTop />
        <Card
          sx={{
            boxShadow: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <CardContent
            sx={{
              p: 3,
            }}
          >
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              gap={3}
            >
              {fleetData && fleetData.driver && (
                <Stack flex={1}>
                  <Stack>
                    <Stack
                      border="1px solid"
                      borderColor={theme.palette.accent.sky}
                      padding={2}
                      borderRadius={1}
                      gap={1}
                      height="100px"
                    >
                      <Typography variant="h5" fontWeight={600}>
                        Change Driver Details
                      </Typography>
                      <Stack flex={1}>
                        <Typography
                          variant="bodyMedium"
                          color={theme.palette.accent.sky}
                        >
                          Current Driver
                        </Typography>
                        <Typography
                          variant="bodyLarge"
                          fontWeight={600}
                        >
                          {fleetData.driver}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}

              <Stack flex={1} gap={1}>
                <Stack
                  border="1px solid"
                  borderColor={theme.palette.accent.sky}
                  padding={2}
                  borderRadius={1}
                  gap={1}
                  height={{ xs: "auto", md: "100px"}}
                >
                  <Typography variant="h5" fontWeight={600}>
                    Change Driver To
                  </Typography>
                  <Stack alignItems="flex-start" flexDirection={{ xs: "column", md: "row" }} gap={2}>
                    <Stack flex={1}>
                      <Typography
                        variant="bodyMedium"
                        color={theme.palette.accent.sky}
                      >
                        New Driver
                      </Typography>
                      <Typography
                        variant="bodyLarge"
                        fontWeight={600}
                      >
                        {values && values.driver ? values.driver : "unassigned"}
                      </Typography>
                    </Stack>
                    <Stack
                      flex={fleetData && fleetData.driver ? 2 : 1}
                      mt="-5px"
                      width="100%"
                    >
                      <DatePicker
                        label="Start Date"
                        format="Do MMMM YYYY"
                        disableFuture
                        minDate={typeof values?.startDate === "undefined"
                          ? dayjs().add(-90, "days")
                          : dayjs(values?.startDate)
                        }
                        value={values?.effectiveDate}
                        onChange={onDateChange}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack gap={3}>
              <Stack flex={1} gap={1} pt={3}>
                <Stack
                  border="1px solid"
                  borderColor={theme.palette.accent.sky}
                  padding={2}
                  borderRadius={1}
                  gap={1}
                >
                  <Typography variant="h5" fontWeight={600}>
                    Vehicle Details
                  </Typography>
                  <Stack flexDirection="row" gap={3}>
                    <Stack flex={1}>
                      <Typography
                        variant="bodyMedium"
                        color={theme.palette.accent.sky}
                      >
                        Vehicle
                      </Typography>
                      <Typography
                        variant="bodyLarge"
                        fontWeight={600}
                      >
                        {fleetData ? displayVehicle(fleetData) : "-"}
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography
                        variant="bodyMedium"
                        color={theme.palette.accent.sky}
                      >
                        Registration
                      </Typography>
                      <Typography
                        variant="bodyLarge"
                        fontWeight={600}
                      >
                        {fleetData && fleetData.registration ? fleetData.registration : "-"}
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography
                        variant="bodyMedium"
                        color={theme.palette.accent.sky}
                      >
                        Colour
                      </Typography>
                      <Typography
                        variant="bodyLarge"
                        fontWeight={600}
                      >
                        {fleetData && fleetData.colour ? fleetData.colour : "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              {/* Form Fields */}
              <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
                <Stack flex={1} gap={2}>
                  <Stack>
                    <OfflineLookupField
                      fullWidth showAllResult allowFreeText
                      size="small"
                      id="costCentre"
                      label="Cost Centre"
                      disabled={!values.accountName || disabled}
                      customFleetId={values.thirdPartyNumber}
                      lookupPath="drivercostcentre"
                      value={values.costCentre || ""}
                      variant="outlined"
                      onChange={onLookupChange("costCentre")}
                      defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                      onLookup={onLookup}
                      resourceModel="drivers"
                    />
                  </Stack>
                  <Stack>
                    <OfflineLookupField
                      fullWidth showAllResult
                      size="small"
                      id="location"
                      label="Location"
                      disabled={!values.accountName || disabled}
                      lookupPath="location"
                      value={values.location || ""}
                      variant="outlined"
                      onChange={onLookupChange("location")}
                      defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                      onLookup={onLookup}
                      resourceModel="drivers"
                    />
                  </Stack>
                  <Stack>
                    <OfflineLookupField
                      fullWidth showAllResult allowFreeText
                      id="controller"
                      size="small"
                      label="Fleet Controller"
                      disabled={disabled}
                      customFleetId={values.thirdPartyNumber}
                      lookupPath="driverfleetcontroller"
                      value={values.controller || ""}
                      variant="outlined"
                      onChange={onLookupChange("controller")}
                      defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                      onLookup={onLookup}
                      resourceModel="drivers"
                    />
                  </Stack>
                </Stack>
                <Stack flex={1} height="100%">
                  <KeyValue
                    multiline
                    fullWidth
                    rows={7}
                    size="small"
                    id="vehicleNotes"
                    label="Vehicle Notes"
                    disabled={disabled}
                    value={values.vehicleNotes || ""}
                    inputProps={{ maxLength: 50 }}
                    onChange={onInputChange}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

            <Stack pb={3} justifyContent="flex-end" alignItems="flex-end">
              <Stack gap={2} width={{ xs: "100%", md: "50%" }}>
                <Typography variant="bodyMedium">
                  *Please note, there cannot be more than one driver assigned to a vehicle.
                </Typography>
                <Typography variant="bodyMedium">
                  You have chosen to change the driver {fleetData?.driver && <>from <strong>{fleetData.driver}</strong></>}{" to "}
                  <strong>{values.driver || "unassigned"}</strong>. Would you like to proceed?
                </Typography>
              </Stack>
            </Stack>

            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              gap={1}
            >
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="secondary"
                  disabled={disabled}
                  variant="contained"
                  onClick={onBack}
                >
                  Back
                </Button>
              </Box>
              <Stack flexDirection="row" gap={2}>
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    onClick={onSubmitForm}
                  >
                    {`Yes, ${fleetData && fleetData.driver ? "Change" : "Assign"} the Driver`}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </LocalizationProvider>
  )
}

ConfirmDriverForm.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fleetData: PropTypes.object,
  // functions
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDriverForm)
