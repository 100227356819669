import palette from "theme/v5/palette"

export const statusColor = (status) => {
  const value = String(status).toLowerCase()
  switch(value) {
    case "rejected":
    case "matured":
    case "cancelled": {
      return palette.status.error
    }
    case "pending": {
      return palette.status.warning
    }
    case "scheduled":
    case "live":
    case "completed": {
      return palette.status.success
    }
    case "requested": {
      return palette.accent.teal
    }
    default:
      return palette.accent.teal
  }
}