import React, { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { editDraft, deleteDraft, newDraft, editSave, addFbtSubmission } from "../actions"
import { constants } from "../module"
import { useNavigate, useRouter, useFbtWebForm } from "utils"
import { ScrollToTop } from "frame/components"
import { useProfile } from "features/account"
import moment from "moment"

// components
import FbtSubmissionsTable from "./fbt-submissions-table"
import { useEnabledFeatureToggleList } from "features/settings"
import { hasRoles } from "acl"

import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import Divider from "@mui/material/Divider"

import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

// used in add new fbt driver record modal, edit fbt row entry, fbt web form
const AddEditSubmission = ({
  id,
  data,
  flags,
  drafts,
  newSubmissionDetails,
  filtered,
  fbtWebForm,
  userRoles,
  // func
  onEditDraft,
  onDeleteDraft,
  onNewDraft,
  onEditSave,
  onAddFbtSubmission,
}) => {

  const profile = useProfile()
  const theme = useTheme()
  
  const navigate = useNavigate()
  const { location, history } = useRouter()
  const { onSubmitFbtWebForm } = useFbtWebForm()
  
  const isAddForm = window.location.pathname.includes("/add/")
  const isWebForm = window.location.pathname.includes("fbt-web-form")

  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")
  const showButtons = isWebForm 
    ? true // never hide on web form (!)
    : userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(["superadmin","fleetcontroller"], userRoles) : true

  // model states
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false)
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false)
  
  const disabledSave = isAddForm || isWebForm
    ? !drafts || drafts.length === 0
    : flags.fetch === "processing" || !flags?.unsavedChanges

  const onClose = () => {
    const isFiltered = filtered?.year && filtered?.month
    navigate(`${constants.PATH_ALL}${isFiltered ? `?year=${filtered?.year}&month=${filtered?.month}`: ""}`)()
  }

  const onAdd = () => {
    onNewDraft()
    navigate(isWebForm ? `/fbt-web-form/form/add-submission` : `${constants.PATH_ALL}/${id}${constants.PATH_ADD_SUBMISSION}`)()
  }

  const onSave = (deleteLastingEntry = false) => {
    const entries = deleteLastingEntry ? [] : drafts.map(item => ({
      // remove uuids that are used for FE CRUD purposes only - api will assign new ids for new entries automatically
      id: Number.isInteger(item?.id) ? item?.id : 0,
      daysOfMonth: item?.days.join(","),
      reason: item?.reason
    }))

    const month = filtered?.month ? moment().month(filtered?.month).format("M") : moment().format("M")
    const year = filtered?.year || moment().format("YYYY")

    if(isAddForm) {
      const payload = {
        driverName: newSubmissionDetails?.driverName,
        costCentre: newSubmissionDetails?.costCentre,
        thirdPartyNumber: newSubmissionDetails?.thirdPartyNumber,
        availableForPvtUseAllMonth: false,
        entries, month, year
      }
      console.log("[onAddFbtSubmission]: ", payload)
      onAddFbtSubmission(payload)

    } else if (isWebForm) {
      const payload = {
        email: fbtWebForm?.email,
        token: fbtWebForm?.token,
        availableForPvtUseAllMonth: false,
        entries
      }
      console.log("[onSubmitFbtWebForm]: ", payload)
      onSubmitFbtWebForm(payload)
      
    } else {
      console.log("[onEditSave]: ", { ...data, entries })
      onEditSave(data?.originalId, { ...data, entries, month, year })
    }
    setShowConfirmSubmitModal(false)
  }

  const onEditHandler = (selectedSubmission) => {
    onEditDraft(selectedSubmission)
    history.push(location.pathname.replace("view-submissions", "edit-submission"))
  }
  
  return (
    <>
      <ScrollToTop />
      <Card
        elevation={isWebForm ? 0 : 1}
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <CardContent
           sx={{
            p: 3,
          }}
        >
          <Stack
            gap={2}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" fontWeight={600}>
                Submissions
              </Typography>

              <Stack
                flexDirection="row"
                gap={1}
              >
                <Stack width="160px">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    id="add-fbt"
                    onClick={onAdd}
                  >
                    {"Add"}
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              border="1px solid"
              borderColor={theme.palette.accent.sky}
              borderRadius={1}
              gap={2}
            >
              <FbtSubmissionsTable
                data={drafts}
                loading={flags.fetch === "processing"}
                onEdit={onEditHandler}
                onDelete={onDeleteDraft}
                onSave={isAddForm || isWebForm ? null : onSave}
                userRoles={userRoles}
              />
            </Stack>
          </Stack>

          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            {isWebForm ? (
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={navigate(`/fbt-web-form/availability`)}
                >
                  Back
                </Button>
              </Box>
            ) : (
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={() => flags?.unsavedChanges ? setOpenUnsavedChangesModal(true) : onClose()}
                >
                  Close
                </Button>
              </Box>
            )}
            {showButtons && 
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={disabledSave}
                  onClick={() => setShowConfirmSubmitModal(true)}
                  >
                  {isAddForm || isWebForm ? "Submit" : "Save"}
                </Button>
              </Box>
            }
          </Stack>
        </CardContent>
      </Card>
      
      {/* Confirmation Modal */}
      <StyledModalV2
        open={showConfirmSubmitModal}
        title={`Confirm Submission`}
        onClose={() => setShowConfirmSubmitModal(false)}
        onCancelLabel="Go Back"
        content={
          <Typography variant="h4" textAlign="center">
            {isWebForm
              ? `Please confirm to submit your FBT submission`
              : isAddForm
                ? `Please confirm you have completed ${newSubmissionDetails?.driverName}"s FBT submission`
                : `Please confirm to save your changes to ${data?.driverName}"s FBT submission`
            }
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setShowConfirmSubmitModal(false)}
            onConfirm={onSave}
          />
        }
      />

      {/* unsave changes prompt */}
      <StyledModalV2
        open={openUnsavedChangesModal}
        title={`Confirm Close`}
        onClose={() => setOpenUnsavedChangesModal(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenUnsavedChangesModal(false)}
            onConfirm={onClose}
          />
        }
      />
    </>
  )
}

AddEditSubmission.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.object,
  flags: PropTypes.object,
  drafts: PropTypes.array,
  newSubmissionDetails: PropTypes.object,
  filtered: PropTypes.object,
  fbtWebForm: PropTypes.object,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onNewDraft: PropTypes.func.isRequired,
  onEditDraft: PropTypes.func.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  onAddFbtSubmission: PropTypes.func.isRequired,
}


const mapStateToProps = ({ fbtSubmissions,settings }, ownProps) => ({
  id: ownProps.match.params?.id,
  data: fbtSubmissions?.fbtDetails, //fbtSubmissions?.data[ownProps.match.params?.id],
  flags: {
    ...fbtSubmissions.flags,
    unsavedChanges: JSON.stringify(fbtSubmissions?.originalDrafts) !== JSON.stringify(fbtSubmissions?.drafts),
  },
  drafts: fbtSubmissions?.drafts,
  newSubmissionDetails: fbtSubmissions?.newSubmissionDetails,
  filtered: fbtSubmissions?.filtered,
  fbtWebForm: fbtSubmissions?.fbtWebForm,
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onNewDraft: () => {
    dispatch(newDraft())
  },
  onEditDraft: (payload) => {
    dispatch(editDraft(payload))
  },
  onDeleteDraft: (payload) => {
    dispatch(deleteDraft(payload))
  },
  onEditSave: (id, payload) => {  // for edit form save button
    dispatch(editSave(payload, {
      resourceId: id
    }))
  },
  onAddFbtSubmission: (payload) => {
    dispatch(addFbtSubmission(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSubmission)
