import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'
import { useSettingsFleetValues } from 'features/settings'
import { getRole } from 'listable'
import { useSelector } from 'react-redux'
import { getUserStatus } from '../../utils'

import Box from '@mui/material/Box'

const FilterAttributes = ({ roleList, filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)

  const userData = useSelector(({ adminUsers }) => adminUsers.data)

  const hasStatus = (status) => {
    const recordWithStatus = Object.entries(userData).filter(([key, value]) => getUserStatus(value) === status)
    return recordWithStatus && recordWithStatus.length > 0
  }

  const hasList = (list) => list && list.length > 0
  const { /*roles, fleets, verifyStatuses*/ statuses } = filters

  let filtersConfig = [
    {
      label: 'Role',
      value: filtered.role,
      options: roleList ? roleList.map(({ id, name }) => ({
        label: getRole(name),
        value: id
      })) : [],
      onChange: onSelect('role')
    }, 
    {
      label: 'Fleet',
      value: filtered.fleets,
      options: useSettingsFleetValues(),
      onChange: onSelect('fleets')
    },
    {
      label: 'Status',
      value: filtered.status,
      options: hasList(statuses)
        ? statuses.map(value => hasStatus(value)
          ? { label: value, value: value } : null).filter(x => x) // clean up any unused statuses
        : [],
      onChange: onSelect('status')
    },
  ]
  
  return (
    <Box>
      <Box>
        {filtersConfig.map(({ label, value, options, onChange }, key) =>
          hasList(options) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
              key={`${label}${key}`}
            >
              <SingleSelect
                id={label.toLowerCase()}
                label={label}
                options={options}
                tight
                value={value}
                onChange={onChange} />
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  roleList: PropTypes.array,
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
