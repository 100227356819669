import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../module'
import Form from './form'
import ReactGA from 'react-ga'

import { formTabs } from "../../config"
import TabMenu from "../../../../features/fleet/components/drill-down-modal/tab-menu"

export const NewVehicleSummaryContext = createContext({})

const New = ({
  relatedContract,
  fleetIds,
  fetchContractStatus,
  saveStatus,
  onFetchContract,
  onSaveLeaseInformation,
  onSaveVehicleInformation,
  onSaveAdditionalServices,
  onSaveAccessories,
  onLookup,
  onReset,
  errorMessage,
  userRoles
}) => {
  const navigate = useNavigate()
  const [saved, setSaved] = useState(false)

  const [activeTab, setActiveTab] = useState(formTabs?.[0]?.value ?? "")
  const [disabledTabs, setDisabledTabs] = useState({
    leaseInformation: false,
    vehicleInformation: true,
    additionalServices: true,
    accessories: true,
  });

  const onClose = navigate(constants.PATH_ALL)
  const onCloseHandler = () => {
    onReset()
    onClose()
  }

  const onSubmit = (payload) => {
    console.log('[GA] event tracked', { category: 'Request', action: 'Submit New', label: 'Vehicle Summary' })
    ReactGA.event({ category: 'Request', action: 'Submit New', label: 'Vehicle Summary' })
    if( activeTab === 'lease_information'){
      onSaveLeaseInformation(payload)
    } else if( activeTab === 'vehicle_information'){
      onSaveVehicleInformation(payload)
    } else if( activeTab === 'additional_services'){
      onSaveAdditionalServices(payload)
    } else if( activeTab === 'accessories'){
      onSaveAccessories(payload)
    }
    setSaved(true)
  }

  useEffect(() => {
    if (saved && saveStatus === 'processed') {
      onClose()
    }
    navigate(`${match.url}${constants.PATH_LEASE_INFORMATION}`)(0)
  }, [saveStatus])
  
  const isSaving = saveStatus === 'processing'
  const savingError = saveStatus === 'error'

  const { match } = useRouter()

  const tabsConfig = [
    {
      label: 'Lease Information',
      path: `${match.url}${constants.PATH_LEASE_INFORMATION}`,
      visible: true,
      disabled: disabledTabs.leaseInformation,
    }, {
      label: 'Vehicle Information',
      path: `${match.url}${constants.PATH_VEHICLE_INFORMATION}`,
      visible: true,
      disabled: disabledTabs.vehicleInformation,
    }, {
      label: 'Additional Services',
      path: `${match.url}${constants.PATH_ADDITIONAL_SERVICES}`,
      visible: true,
      disabled: disabledTabs.additionalServices,
    }, {
      label: 'Accessories',
      path: `${match.url}${constants.PATH_ACCESSORIES}`,
      visible: true,
      disabled: disabledTabs.accessories,
    },
  ].filter(x => x.visible)

  /**
   * This effect will trigger navigation
   */
  useEffect(() => {
    const tabFromUrl = window.location.pathname?.split('/').pop();
    setActiveTab(tabFromUrl || formTabs[0].value);
  }, [window.location.pathname]);

  return (
    <Dialog
      open
      autoSize
      size="md"
      aria-labelledby="Request Vehicle Order"
      breadcrumbs=""
      title={`Request Vehicle Order`}
      loading={isSaving}
      onClose={onCloseHandler}
      tabsConfig={tabsConfig}
      tabMenu={<TabMenu config={tabsConfig} />}
    >
      <Grid container>
        <Grid item md={12} xs={12}>
          <NewVehicleSummaryContext.Provider value={{
            activeTab,
            setActiveTab,
            disabledTabs,
            setDisabledTabs,
          }}>
            <Form
              relatedContract={relatedContract}
              disabled={isSaving}
              fleetIds={fleetIds}
              error={savingError}
              fetchContractStatus={fetchContractStatus}
              onClose={onCloseHandler}
              onFetchContract={onFetchContract}
              onSubmit={onSubmit}
              onLookup={onLookup}
              errorMessage={errorMessage}
              userRoles={userRoles}
              activeTab={activeTab}
            />
          </NewVehicleSummaryContext.Provider>
        </Grid>
      </Grid>
    </Dialog>
  )
}

New.propTypes = {
  relatedContract: PropTypes.object.isRequired,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchContractStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  onFetchContract: PropTypes.func.isRequired,
  onSaveLeaseInformation: PropTypes.func.isRequired,
  onSaveVehicleInformation: PropTypes.func.isRequired,
  onSaveAdditionalServices: PropTypes.func.isRequired,
  onSaveAccessories: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default New