import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { encodeUserId } from '../../utils'
import { actions, config, constants } from '../../module'

import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const List = ({ model, userRoles, onRequestRoles, onReset, onDelete }) => {

  const linker = useNavigate()

  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, encodeUserId(item.id))

    return (
      <Row
        key={index}
        item={item}
        onView={onView}
        userRoles={userRoles}
        onDelete={onDelete}
        {...itemProps}/>
    )
  }, [linker, userRoles, onDelete])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  useEffect(() => {
    onReset()
    onRequestRoles()
  }, [onReset, onRequestRoles])

  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}
      actionButtons={
        <Stack
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent='space-between'
          gap={1}
        >
          <Stack flexDirection="row" gap={2}>
            <Box width={{ xs: '100%', md: '180px' }}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={linker(constants.PATH_ADD)}
              >
                {"Add User"}
              </Button>
            </Box>
          </Stack>
        </Stack>
      }
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRequestRoles: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const mapStateToProps = ({ adminUsers, settings, account }) => ({
  model: adminUsers,
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onRequestRoles: () => {
    dispatch(actions.requestRoles())
  },
  onReset: () => {
    dispatch(actions.reset())
  },
  onDelete: (userId) => {
    console.log('Delete userId ', userId)
    dispatch(actions.requestRemoveUser(null, {
      resourceId: userId
    }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
