import React from 'react'
import PropTypes from 'prop-types'
import { types } from '../../module'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns }) => {
  const allowDelete = false

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.name && (
        <TableCellView isFirst>
          {item.name || '-'}
        </TableCellView>
      )}
      
      {columns.email && (
        <TableCellView>
          {item.email || '-'}
        </TableCellView>
      )}

      {columns.isExisting && (
        <TableCellView>
          {item.isExisting !== null
            ? item.isExisting ? 'Yes' : 'No'
            : 'No'}
        </TableCellView>
      )}

      {columns.lastSentDate && (
        <TableCellView>
          {item.lastSentDate ? moment(item.lastSentDate).format('DD/MM/YYYY hh:mm A') : '-'}
        </TableCellView>
      )}      

      {allowDelete && (
        <TableCellView>
          <DeleteIcon
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.5,
              },
            }}
          />
        </TableCellView>
      )}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,  
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
