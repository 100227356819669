import React from 'react'
import PropTypes from 'prop-types'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const Title = ({ title, filtering, subtitle}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
    >
      <Typography variant="h3" id="report_title">
        {title}
        {subtitle && (
          <Typography variant="h5" id="report_subtitle"
          >
            {subtitle}
          </Typography>
        )}
      </Typography>
      
      {filtering && (
        <Typography
          variant="body2"
          sx={{
            paddingLeft: theme.spacing(1)
          }}
        >
          <em>Filtered</em>
        </Typography>
      )}
    </Box>
  )
}

Title.propTypes = {
  filtering: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

const PageListHeader = ({ actions, filtering, title, subtitle, customComponent }) => {
  const theme = useTheme()
  return (
    <>
      <CardHeader
        sx={{
          bgcolor: 'white',
        }}
        action={actions}
        title={customComponent ? customComponent : <Title title={title} subtitle={subtitle} filtering={filtering} />} />
      <Divider sx={{ borderColor: theme.palette.accent.sky, mx: 2, my: 0 }} />
    </>
  )
}

PageListHeader.propTypes = {
  actions: PropTypes.node,
  filtering: PropTypes.bool,
  title: PropTypes.string.isRequired,
  customComponent: PropTypes.any,
  subtitle: PropTypes.string,
}

export default PageListHeader
