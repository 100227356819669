import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert, SingleSelectValidator, ScrollToButton, StyledModal, AnimatedErrorCross } from 'frame/components'
import { getRole } from 'listable'
import { useSettingsFleetValues } from 'features/settings'

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import MuiTextfield from 'frame/components/mui-textfield'
import MuiSelectfield from 'frame/components/mui-form-select'

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from "./schema"
import StyledModalV2 from 'frame/components/styled-modal-v2'

const Form = ({ flags, errorMessage, roles, onSubmit, onClose }) => {
  const theme = useTheme()
  // refs
  const saveButtonRef = useRef()

  const fleets = useSettingsFleetValues()

  // states
  const [values, setValues] = useState({})
  const [fleetIds, setFleetIds] = useState([])
 
  const [repeat, setRepeat] = useState(false)
  const [disableFields, setDisableFields] = useState(false)

  const [submitType, setSubmitType] = useState(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showRequiredFleetsError, setShowRequiredFleetsError] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  // fleet is required except for SuperAdmin Role
  const isValidFleetSelction = (values?.role && values?.role?.name === 'SuperAdmin') || fleetIds.length > 0
  const isLoading = flags?.create === 'processing'

  const isSelected = fleetId => fleetIds.filter(node => node.id === fleetId).length > 0

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const roleOptions = roles && roles.map(node => {
    return {
      label: getRole(node.name),
      value: node.id,
    }
  })

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({
      ...values,
      [id || name]: value,
    })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSelectRoleChange = value => {
    let role = roles.filter(node => node.id === value)[0]
    console.log(value, "value")
    setValues({ ...values, role })
    methods.setValue("roleId", value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSelect = (fleetId) => () => {
    if (isSelected(fleetId)) {
      const next = fleetIds.filter(node => node.id !== fleetId)
      setFleetIds(next)
      return
    }

    let fleet = fleets.filter(node => node.value === fleetId)[0]
    setFleetIds([
      ...fleetIds,
      { id: fleet.value, name: fleet.label }
    ])
  }

  const onSubmitForm = () => {
    const payload = {
      ...values,
      nickname: values?.nickname,
      fleets: fleetIds,
    }

    console.log(isValidFleetSelction, "isValidFleetSelction")

    !isValidFleetSelction && setShowRequiredFleetsError(true)

    if (isValidFleetSelction) {
      setDisableFields(true)

      if (submitType === "save-changes") {
        setRepeat(false)
        onSubmit(payload)
        return
      }
  
      setRepeat(true)
      onSubmit(payload)
      return
    }
  }

  useEffect(() => { // triggered on create user action success
    if (flags?.create === 'processed' && repeat) { // for save and add another user
      setDisableFields(false)
      setFleetIds([])
      setValues({})
      setShowSuccessMessage(true)      
      setTimeout(() => setShowSuccessMessage(false), 3000) // clear after 3 seconds
    }
    else if (flags?.create === 'processed') { // for save and close
      onClose()
    }
    flags?.create === 'error' && setDisableFields(false)
  }, [flags?.create]) // eslint-disable-line

  useEffect(() => {
    isValidFleetSelction && setShowRequiredFleetsError(false)
  }, [isValidFleetSelction])  

  useEffect(() => {
    if(errorMessage && errorMessage != ""){
      setShowErrorModal(true);
    }
  }, [errorMessage])

  return (
    <>
      <Card
        sx={{
          boxShadow: 'none',
          borderRadius: '5px',
        }}
      >
        <FormProvider>
          <form
            id="new-driver-form"
            onSubmit={methods.handleSubmit(onSubmitForm)}
          >
            <CardContent
              sx={{
                p: 3,
              }}
            >
              <Stack gap={3}>
                {/* Details */}
                <Stack
                  flexDirection="column"
                  gap={3}
                  border='1px solid'
                  borderColor={theme.palette.accent.sky}
                  padding={2}
                  borderRadius={1}
                >
                  <Stack flex={1}>
                    <Typography variant="h5" fontWeight={600}>
                      Details
                    </Typography>
                  </Stack>

                  <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
                    <Stack gap={1} flex={1}>
                      <Controller
                        name="nickname"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="nickname"
                            error={Boolean(methods.formState.errors?.nickname?.message)}
                            label="Name"
                            disabled={isLoading || disableFields}
                            value={values.nickname || ""}
                            onChange={onInputChange}
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.nickname?.message}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap={2} flex={2}>
                      <Stack gap={1} flex={1}>
                        <Controller
                          name="email"
                          control={methods.control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <MuiTextfield
                              {...field}
                              id="email"
                              error={Boolean(methods.formState.errors?.email?.message)}
                              label="Email"
                              disabled={isLoading || disableFields}
                              value={values.email || ""}
                              onChange={onInputChange}
                            />
                          )}
                        />
                        <Typography color="error" fontSize={12}>
                          {methods.formState.errors?.email?.message}
                        </Typography>
                      </Stack>
                      <Stack gap={1} flex={1}>
                        <Controller
                          name="roleId"
                          control={methods.control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <MuiSelectfield
                              {...field}
                              id="roleId"
                              error={Boolean(methods.formState.errors?.email?.message)}
                              label="Role"
                              options={roleOptions || []}
                              disabled={isLoading || disableFields}
                              value={(values.role && values.role.id) || ''}
                              onChange={(event) => onSelectRoleChange(event?.target?.value)}
                            />
                          )}
                        />
                        <Typography color="error" fontSize={12}>
                          {methods.formState.errors?.roleId?.message}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                {/* Fleets */}
                <Stack
                  flexDirection="column"
                  gap={3}
                  border='1px solid'
                  borderColor={theme.palette.accent.sky}
                  padding={2}
                  borderRadius={1}
                >
                  <Stack flex={1}>
                    <Typography variant="h5" fontWeight={600}>
                      Fleets
                    </Typography>
                    <Typography variant="bodyMedium">
                      Please select and assign at least one fleet to this user.
                    </Typography>
                  </Stack>

                  <Stack flexDirection="row" gap={2}>
                    <Grid container py={2}>
                      {fleets.map((node, index) => (
                        <Grid key={index} item md={2} sm={4} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={isLoading || disableFields}
                                checked={isSelected(node.value)}
                                onChange={onSelect(node.value)}
                                value={node.value}
                                color="default"
                                sx={{
                                  color: theme.palette.accent.sky,
                                  '&.Mui-checked': {
                                    color: theme.palette.accent.sky,
                                  },
                                }}
                              />
                            }
                            label={node.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Stack>

              </Stack>

              <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

              <Stack
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent='space-between'
                gap={1}
              >
                <Box width={{ xs: '100%', sm: '205px' }}>
                  <Button
                    fullWidth
                    color='error'
                    disabled={isLoading}
                    variant="outlined"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Box>
                <Stack flexDirection="row" gap={2}>
                  <Box width={{ xs: '100%', sm: '205px' }}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={() => setSubmitType("save-changes")}
                    >
                      Save Changes
                    </Button>
                  </Box>
                  <Box width={{ xs: '100%', sm: '220px' }}>
                    <Button
                      name="save-add-user"
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isLoading}
                      onClick={() => setSubmitType("save-add-user")}
                    >
                      Save And Add Another User
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </form>
        </FormProvider>
        
        <ScrollToButton scrollToRef={saveButtonRef} />
      </Card>

      <StyledModalV2
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        status="error"
        content={
          <Typography variant="h4" textAlignCenter>
            {errorMessage}
          </Typography>
        }
      />

      <StyledModalV2
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
        status="success"
        content={
          <Typography variant="h4" textAlignCenter>
            User created successfully
          </Typography>
        }
      />
    </>
  )
}

Form.propTypes = {
  flags: PropTypes.object,
  errorMessage: PropTypes.string,  
  roles: PropTypes.array.isRequired,
  // func
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Form
