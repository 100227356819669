import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'utils'
import { hasRoles } from 'acl'
import MoreIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/Subject'
import { constants } from '../../module'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const RowActions = ({onView, id, userRoles, ...props }) => {
  const linker = useNavigate()
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)

  const onRequestVariation = linker(constants.PATH_SINGLE, id, 'variations')

  const handleMenuOpen = () =>
    setOpenMenu(true)

  const handleMenuClose = () =>
    setOpenMenu(false)

  const onViewHandler = () => {
    handleMenuClose()
    onView()
  }

  const onRequestVariationHandler = () => {
    onRequestVariation()
    setOpenMenu(false)
  }

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <MenuItem onClick={onViewHandler}>
          <ListItemIcon>
            <ViewIcon />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>
        {! hasRoles(['fleetcoordinator'], userRoles) && (
          <MenuItem onClick={onRequestVariationHandler}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Request Variation" />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )
}

RowActions.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onView: PropTypes.func.isRequired,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RowActions)

