import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchIndustryNews } from '../../actions'

import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { CardTitle } from '../common/card-title'
import SkeletonLoading from './loading'
import IndustryNewsList from './industry-news-list'

import { useTheme } from "@mui/material/styles"

const IndustryNews = ({ flags, industryNews, onFetchIndustryNews }) => {
  const theme = useTheme();

  const [delayLoading, setDelayLoading] = useState(true)
  const isLoading = flags?.fetchIndustryNews === 'processing'

  useEffect(() => {
    onFetchIndustryNews()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchIndustryNews, setDelayLoading])

  return (
    <>
      <Card
        sx={{
          height: '440px',
          maxHeight: '440px',
        }}
      >
        <CardContent>
          <CardTitle title='Industry News' />
          <Divider />

          {delayLoading || isLoading ? (
            <SkeletonLoading />
          ) : industryNews.length > 0 ? (
            <IndustryNewsList items={industryNews} />
          ) : (
            <Box
              sx={{
                height: '368px',
                maxHeight: '368px',
                overflowX: 'hidden',
                [theme.breakpoints.down('sm')]: { height: '100%' },
              }}
            >
              <Typography
                variant="body1"
                align="center"
                sx={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(4) },
                }}
              >
                No industry news
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  )
}

IndustryNews.propTypes = {
  flags: PropTypes.object,
  industryNews: PropTypes.array,
  onFetchIndustryNews: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  flags: homepage?.flags,
  industryNews: homepage?.industryNews || [],
})

const mapDispatchToProps = (dispatch) => ({
  onFetchIndustryNews: () => dispatch(fetchIndustryNews()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IndustryNews)
