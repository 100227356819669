import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { wofStatuses, registrationStatuses, agreementTypes, fmoTypes } = filters

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">Filters</Typography>
      </Box>
      <Divider />
        <Box>
          {hasList(wofStatuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="wofStatus"
                label="WoF Status"
                options={wofStatuses}
                value={filtered.wofStatus}
                tight
                onChange={onSelect('wofStatus')} />
            </Box>
          )}
          {hasList(registrationStatuses) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                id="registrationStatus"
                label="Registration Status"
                options={registrationStatuses}
                tight
                value={filtered.registrationStatus}
                onChange={onSelect('registrationStatus')} />
            </Box>
          )}
          {hasList(agreementTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </Box>
          )}
          {hasList(fmoTypes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </Box>
          )}
        </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
