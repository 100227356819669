import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useTheme } from "@mui/material/styles"
import { fetchDownloadGuides } from "../../actions"
import PdfPreview from "./pdf-preview"

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Fade from "@mui/material/Fade"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"

const DownloadCarousel = ({ downloadGuides, onFetchDownloadGuides }) => {
  const theme = useTheme()

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [useFallbackCover, setUseFallbackCover] = useState(false)

  const tabs = downloadGuides.length > 0
    ? Array.from(new Set([ ...downloadGuides.map(x => x.group) ]))
    : [] 

  const carouselItems = downloadGuides.filter(x => x.group === tabs[activeTabIndex])

  const onTabSelect = (index) => () => {
    setActiveTabIndex(index)
    setActiveIndex(0)
  }

  const onNext = () => {
    if (activeIndex + 1 === carouselItems.length) {
      return null
    }

    return setActiveIndex(activeIndex + 1)
  }

  const onPrevious = () => {
    if (activeIndex === 0) {
      return null
    }

    return setActiveIndex(activeIndex - 1)
  }

  useEffect(() => {
    onFetchDownloadGuides()
  }, [onFetchDownloadGuides])

  return (
    <>
      {carouselItems && carouselItems.length > 0 ? (
        <>
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            pb={1}
          >
            {tabs?.map((tabName, index) => (
              <Box key={`download-tab-${index}`}>
                <Typography
                  role="button"
                  component="span"
                  onClick={onTabSelect(index)}
                  sx={{
                    color: index === activeTabIndex ? "black" : theme.palette.accent.sky,
                    fontSize: "14px",
                    fontWeight: "bold",
                    letterSpacing: 0,
                    lineHeight: "19px",
                    "&:hover": { color: "black", cursor: "pointer" }
                  }}
                >
                  {tabName}
                </Typography>

                {index !== tabs.length - 1 && (
                  <Typography
                    component="span"
                    sx={{
                      color: "#BABABA",
                      px: 1,
                    }}
                  >
                    |
                  </Typography>
                )}
              </Box>
            ))}
          </Stack>

          <Stack
            sx={{
              borderRadius: "5px",
              border: "2px solid #e1e3e5",
              padding: 2,
              mt: 1,
              height: "100%",
            }}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              {carouselItems.length > 1 && (
                <IconButton
                  onClick={onPrevious}
                  disabled={activeIndex === 0}
                  sx={{
                    color: activeIndex === 0 ? theme.palette.neutral.mid700 : theme.palette.black,
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )}
            </Stack>

            <Stack>
              {carouselItems.map(({ title, uri }, index) => activeIndex === index && (
                <Fade in={true} key={`guide-${index}`}>          
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Stack>
                      {useFallbackCover ? ( // fallback if invalid src - reads & displays pdf page 1 as cover
                        <PdfPreview file={uri} />
                      ) : (
                        <Box
                          component="img"
                          src={`/static/images/guides/${encodeURIComponent(title)}.png`}
                          alt={`${title} cover`}
                          onError={() => setUseFallbackCover(true)}
                          sx={{
                            zIndex: 999,
                            height: "150px",
                            maxWidth: "100%",
                            width: "auto",
                          }}
                        />
                      )}
                    </Stack>
                    <Stack gap={1}>
                      <Typography
                        variant="h5"
                        fontWeight={700}
                      >
                        {title}
                      </Typography>
                      <Button
                        component="a"
                        href={uri}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        variant="contained"
                      >
                        Download
                      </Button>
                    </Stack>
                  </Stack>
                </Fade>
              ))}
            </Stack>

            <Stack>
              {carouselItems.length > 1 && (
                <IconButton
                  onClick={onNext}
                  disabled={activeIndex + 1 === carouselItems.length}
                  sx={{
                    color: activeIndex + 1 === carouselItems.length ? theme.palette.neutral.mid400 : theme.palette.black,
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
          
          <Stack
            flexDirection="row"
            justifyContent="center"
            gap={1}
            py={2}
          >
            {carouselItems?.map((_, index) => (
              <Box
                key={`carousel-${index}`}
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "100%",
                  bgcolor: activeIndex === index ? theme.palette.status.success : theme.palette.neutral.mid600,
                  cursor: "pointer",
                }}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </Stack>
        </>
      ) : (
        <Typography variant="body2">
          No download guides available.
        </Typography>
      )}
    </>
  );
}

DownloadCarousel.propTypes = {
  downloadGuides: PropTypes.array,
  onFetchDownloadGuides: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  downloadGuides: homepage?.downloadGuides,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchDownloadGuides: () => dispatch(fetchDownloadGuides()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCarousel)
