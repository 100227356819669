export default {
  styleOverrides: {
    root: {
      padding: 0,
      height: '50px',
      '& .MuiInputBase-small': {
        paddingBottom: 0,
      },
      '& label': {
        fontSize: 12,
        color: '#00B6B0',
      },
      '& .MuiInputBase-root:before': {
        content: 'none',
      },
      '& .MuiInputBase-root:after': {
        content: 'none',
      } 
    },
    input: {
      fontSize: 14,
      paddingTop: '0 !important',
      paddingBottom: '7px !important',
    }
  },
}