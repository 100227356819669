import React from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

const VehicleHistory = ({
  actions,
  tableData,
}) => {
  const theme = useTheme()

  return (
    <Stack
      gap={2}
    >
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography variant="h5" fontWeight={600}>
          Vehicle History
        </Typography>

        <Stack
          flexDirection="row"
          gap={1}
        >
          {actions}
        </Stack>
      </Stack>

      <Stack
        border='1px solid'
        borderColor={theme.palette.accent.sky}
        borderRadius={1}
        gap={2}
      >
        {tableData}
      </Stack>
    </Stack>
  )
}

export default VehicleHistory