import React, { useEffect } from 'react'
import { useRouter } from 'utils'
import { useDispatch } from 'react-redux'
import { StackedBarChart, DashboardGraph } from 'graph'
import { useGraphFetch, constants } from '../module'
import { useSelectedFleetIds } from 'features/settings'
import uuid from 'uuid/v4'

const Graph = () => {
  const dispatch = useDispatch()
  const { history } = useRouter()
  const selectedFleetIds = useSelectedFleetIds()

  const { loading, error, data } = useGraphFetch()
  const path = '/reports/vehicle-servicing'

  useEffect(() => {
    console.log('[Service Tracking Graph] data: ', data)
    // eslint-disable-next-line
  }, [])

  const onClick = (key, value, booked) => {
    console.log('createGraphNodeClicker', { key, value, booked })
    if (key && value) {
      dispatch({ type: 'reportVehicleServicing_set_dashboard_filter', payload: true })
      history.push(`${path}?${key}=${value}&booked=${booked ? 'Yes' : 'No'}`)
      
    } else {
      history.push(`${path}?overdue=true&booked=${booked ? 'Yes' : 'No'}`)
    }
  }

  const onRefresh = () => {
    dispatch({
      type: constants.REQUEST_DATA,
      payload: null,
      options: { queryParams: { FleetId: selectedFleetIds.join() }, requestId: uuid() }
    })
  }

  return (
    <DashboardGraph
      title="Service Tracking"
      to={path}
      loading={loading}
      error={error}
      onRefresh={onRefresh}
      data={data}
    >
      {data && (
        <StackedBarChart
          data={data}
          showHealthAndSafetyDisclaimer
          noDataMessage="Our current data shows that you have no servicing requirements in the next 6 months."
          onClick={onClick}
        />
      )}
    </DashboardGraph>
  )
}

export default Graph
